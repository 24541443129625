import axios from 'axios'
const MODULE_NAME = 'team/file';
const MODULE_NAME_GROUP = 'team/file';

export const getDataDetail = (id) => axios.get(`/v1/${MODULE_NAME_GROUP}/${id}`)
export const updateData = (id, body) => axios.put(`/v1/${MODULE_NAME_GROUP}/${id}`, body)
export const deleteData = (id) => axios.delete(`/v1/${MODULE_NAME_GROUP}/${id}`)
export const batchDeleteData = (params) => axios.delete(`/v1/${MODULE_NAME_GROUP}`, { params })

export const applyData = (params) => axios.put(`/v1/${MODULE_NAME}/apply`, {}, { params })
export const issueData = (params) => axios.put(`/v1/${MODULE_NAME}/issue`, {}, { params })
export const shareData = (id, params) => axios.put(`/v1/${MODULE_NAME}/share/${id}`, {}, { params })
export const upData = (id, displayName) => axios.put(`/v1/${MODULE_NAME}/up/${id}?display_name=${displayName}`)
export const getCustomRecognition = (id) => axios.get(`/v1/${MODULE_NAME}/custom_recognition/${id}`)
export const getRecognition = (id) => axios.get(`/v1/${MODULE_NAME}/recognition/${id}`)
export const getTemplateRecognition = (id) => axios.get(`/v1/${MODULE_NAME}/template_recognition/${id}`)
export const upload = (body) => axios.post(`/v1/${MODULE_NAME}/upload`, body)

let originalFolders = []

function parallelNodes(arr) {
  return arr.reduce((pre, cur) => {
    if (cur.child && cur.child.length) {
      return [...pre, cur, ...parallelNodes(cur.child.map(x => ({ ...x, parent_id: cur.catalog_id })))]
    }
    return [...pre, { ...cur }]
  }, [])
}

export const createData = (belongType = '', body) => axios.post(`v1/${MODULE_NAME}`, body, { timeout: 50000 })

export const getDatas = async ({ isLoadMore, isFloderRefresh, ...params }) => {
  let folders = []
  if (!isLoadMore) {
    // if ((!originalFolders.length || isFloderRefresh) && params.display_name === undefined) {
    const res = await axios.get(`v1/team/catalog`, {
      params: {
        // ...params,
        catalog_type: 'FILE_CATALOG'
      }
    })
    originalFolders = parallelNodes(res.map(x => ({ ...x, parent_id: null })))
    // }
    folders = originalFolders.filter(x => x.parent_id === (params.catalogId || null)).map(x => {
      return {
        id: x.catalog_id,
        display_name: x.catalog_name,
        modify_time: x.update_time || x.create_time,
        type: 'FOLDER',
        size: x.file_size,
        raw: x
      }
    })
    if (params.display_name !== void 0) {
      folders = folders.filter(x => x.display_name.includes(params.display_name.trim().toLowerCase()))
    }
    if (params.label_id) {
      folders = []
    }
  }

  let res = await axios.get(`v1/${MODULE_NAME}`, {
    params: {
      ...params,
      is_include_subset: false
    }
  })
  let files = res.items?.map(x => ({
    id: x.file_id,
    display_name: x.display_name,
    modify_time: x.update_time || x.create_time,
    type: 'FILE',
    size: x.file_size,
    raw: { ...x, file_name: x.file_name || x.display_name }
  }))
  return [...folders, ...files]
}

// export const createFolder = (parentId, name) => {
//   let pms = {}
//   if (!parentId) {
//     pms = {
//       "catalog_name": name,
//       "catalog_type": "FILE_CATALOG"
//     }
//     return axios.post(`v1/team/catalog`, pms)
//   }

//   pms = {
//     "catalog_name": name,
//     "catalog_type": "FILE_CATALOG",
//     id: parentId
//   }
//   return axios.put(`v1/team/catalog/add_child`, {}, {params: pms})
// }


export const createFolder = (parentId, name) => {
  let pms = {}
  pms = {
    "catalog_name": name,
    "catalog_type": "FILE_CATALOG",
    parent_catalog_id: parentId || ''
  }
  return axios.post(`v1/team/catalog`, pms)
}

export const getInitialFolders = async (catalogId) => {
  if (!originalFolders.length) {
    const res = await axios.get(`v1/team/catalog`, {
      params: {
        // ...params,
        catalog_type: 'FILE_CATALOG'
      }
    })
    originalFolders = parallelNodes(res.map(x => ({ ...x, parent_id: null })))
  }
  let result = []
  let tp
  while ((tp = originalFolders.find(x => x.catalog_id === catalogId))) {
    result.unshift(tp)
    catalogId = tp.parent_id
  }
  return result.map(x => {
    return {
      id: x.catalog_id,
      name: x.catalog_name,
    }
  })
}

export const batchDeleteFile = (ids) => {
  return axios.delete(`v1/${MODULE_NAME}`, { params: { ids } })
}
export const batchDeleteFloder = (ids) => {
  return Promise.all(ids.map(x => axios.delete(`v1/team/catalog/${x}`)))
}

export const shareFile = (ids) => {
  return axios.post(`v1/${MODULE_NAME}/share`, {}, { params: { ids } })
}

export const rename = (id, name, type, label_ids) => {
  if (type === 'FOLDER') {
    return axios.put(`v1/team/catalog/${id}`, {}, { params: { catalog_name: name } })
  }
  if (type === 'FILE') {
    return axios.put(`v1/${MODULE_NAME}/${id}`, {
      display_name: name,
      // label_ids
    })
  }
}
export const upFile = (ids) => {
  return axios.put(`v1/${MODULE_NAME}/up`, {}, { params: { ids } })
}

export const applyFile = (ids, reason) => {
  return axios.put(`v1/file/apply`, {}, { params: { ids, reason } })
}
export const issueFile = (params) => {
  return axios.put(`v1/${MODULE_NAME}/issue`, {}, { params })
}

export const mergeFileCatalog = (ids, { displayName, parentCatalogId }) => {
  if (!ids || !ids.length) {
    return
  }
  return axios.put(`v1/team/catalog/merge`, {}, { params: { ids, catalog_type: 'FILE_CATALOG', catalog_name: displayName, parentCatalogId } })
}
export const mergeFileToCatalog = (ids, { displayName, parentCatalogId }) => {
  return Promise.all(ids.map(id => {
    return axios.put(`v1/${MODULE_NAME}/${id}`, {
      catalog_id: parentCatalogId,
    })
  }))
}

export const downloadFile = (id) => {
  return axios.get(`v1/team/file/download/${id}`)
}

export const mergePDF = (ids) => {
  return axios.post(`v1/team/file/generate_pdf`, {}, { params: { ids } })
}

export const getFileDetail = (id) => {
  return axios.get(`v1/team/file/${id}`).then(async x => ({
    id: x.file_id,
    display_name: x.display_name,
    modify_time: x.update_time || x.create_time,
    type: 'FILE',
    size: x.file_size,
    raw: { ...x, file_name: x.file_name || x.display_name }
  }))
}