import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Layout, Popconfirm, Button, Space, Divider } from '@om-tlh/bee';
import './index.css'
import Nav from './nav'
import UserProfile from './components/UserProfile'
import LocalStorageUtil from 'utils/localStorageUtil'
import SStorageUtil from 'utils/sessionStorageUtil'
import logo from 'theme/img/logo.png'
import logoSVG from 'theme/img/logo.svg'
import Path from 'routes/path'
import utils from 'utils/util'
import commonService from '@/modules/common/service'
// import Share from './components/Share'
import OmaiFooter from './components/Footer'

const { Header, Content, Sider, Footer } = Layout;

/**
 * 分享
 */
const Index = (props) => {
  const { intl, location, match, routeRegistry } = props;
  const { formatMessage } = intl;
  const [collapsed, setCollapsed] = useState(false);
  const [userInfo, setUserInfo] = useState(LocalStorageUtil.getUserInfo() || {})

  useEffect(() => {
    try {
      setUserInfo(JSON.parse(LocalStorageUtil.getUserInfo()))
    } catch (e) {
      // commonService.getUserInfo().then(res => {
      //   setUserInfo(res)
      //   const { roles = [] } = res
      //   let privileges = []
      //   roles && roles.forEach(i => {
      //     privileges.push(...(i.privileges || []))
      //   })
      //   LocalStorageUtil.setUserInfo(JSON.stringify(res))
      // })
    }
  }, [])
  useEffect(() => {
    if (!routeRegistry.find(i => utils.matchPath(i.path, location.pathname.replace(match.url === '/' ? '' : match.url, '')))) {
      props.history.push(`${match.url === '/' ? '' : match.url, ''}${Path.getPathname(props, Path.pathname['Error404'])}`)
    }
  }, [location.pathname])

  const handleLogout = (e) => {
    e.preventDefault();
    ['auth', 'userInfo', 'privileges', 'platform_id'].forEach(i => {
      LocalStorageUtil.clearStorage(i)
    })
    LocalStorageUtil.clearAllCookie()
    SStorageUtil.clearSStorage()
    // props.history.push(`/login?return_url=${encodeURIComponent(window.location.href)}`)
    window.location.href = `${window.__IN_CLOUD__ ? `/${props.appType ? [...props.appType.split('/'), `${window.appName}`].filter(Boolean).join('/') : `${window.appName}`}` : (props.appType ? `/${props.appType}` : ``)}/login?return_url=${encodeURIComponent(window.location.href.replace(new RegExp(`&?token_id=${getQueryStringByName(`token_id`)}`), ''))}`
  }

  return (<div className="main">
    <Header className="header" style={{
      height: 80,
      lineHeight: '80px', minWidth: 800
    }}>
      <h1 className='header-title' style={{ float: 'left' }}>
        <Space
          split={<Divider type="vertical" />}
        >
          <div className="logo mkt-logo" title={'分享'}
            style={{ backgroundColor: 'transparent' }}
          >
            <img
              style={{ cursor: 'pointer' }}
              onClick={() => {
                props.history.push('/')
              }}
              className="logo-img" src={logoSVG} alt="" />
          </div>
          <span>档证通</span>
        </Space>
      </h1>
      <div className="header-right dzt-menu-horizontal" style={{
        // backgroundColor: '#262F3E',
        lineHeight: '80px'
      }}>
        {/* {
                utils.hasPermission('VIEW_MY_INSPECTION')
                  ? <li className="dzt-menu-item">
                    <ReadMessage {...props} />
                  </li>
                  : null
              }
            <Divider key='divider2' type="vertical" /> */}
        {/* <li className="dzt-menu-item">
          <Share />
        </li> */}
        <li className="dzt-menu-item">
          <UserProfile {...props} userInfo={userInfo} handleLogout={handleLogout} />
        </li>
      </div>
      {/* <h1 className='header-title'>{formatMessage({ id: 'web_title' })}</h1> */}
      {/* <PlatformSelect /> */}
    </Header>
    <Content className='main-content'>
      <div className='content' id="content"
        style={{ maxWidth: 1200, margin: 'auto', minWidth: 800 }}
      >
        {props.children}
      </div>
    </Content>
    <Footer style={{ textAlign: 'center', padding: 0, minWidth: 800 }}>
      <OmaiFooter {...props} />
    </Footer>
  </div>)
}

export default injectIntl(Index)
