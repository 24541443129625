import axios from 'axios'
// import getMD5Value from './getMD5Value.js'
import { getAuthorization } from 'utils/http.js'
import { base64Encode } from './base64.js'
import baseConfig from 'utils/request-config'

function uc({ env }) {
  const envFilters = {
    local: {
      url: 'http://localhost:12345'
    },
    dev: {
      url: ''
    }
  }
  this.url = env ? (envFilters[env.toLowerCase()] ? envFilters[env.toLowerCase()].url : '') : ''
  this.login = (username, password) => {
    return axios.post(`uc-api/v1/login`, {
      "login_name": username,
      // "password": getMD5Value(password),
      "password": password
    })
  }
  this.regist = (username, password) => {
    return axios.post(`uc-api/v1/regist`, {
      "login_name": username,
      // "password": getMD5Value(password),
      "password": password
    })
  }

  this.getTokenId = (url, method = 'GET') => {
    return encodeURIComponent(base64Encode(getAuthorization(url, method)))
  }
  this.getUserInfo = () => {
    return Promise.resolve(baseConfig.user_info)
  }
}

export default uc
