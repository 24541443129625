import axios from 'axios'
import config from '@/config'

const baseURL = config.cd_url
const MODULE_NAME = 'payee_info';


const getQRCode = (body) => axios.post(`/v1/payments/get_payment_qr_code`, body, { baseURL })
const getOrderStatus = (bill_no) => axios.get(`v1/payments/get_pay_status/${bill_no || '331Q202212211209482248056574'}`, { baseURL })
const getPayDetail = (id) => axios.post(`v1/payments/${id}/get_bill`, {}, { baseURL })
const getPidQRCode = (id) => axios.post(`v1/payments/${id}/pay`, {}, { baseURL })

export {
  getQRCode,
  getOrderStatus,
  getPayDetail,
  getPidQRCode
}
