import axios from 'axios'
import config from '@/config'

const baseURL = config.message_url
const MODULE_NAME = 'user/messages';

const getDatas = (params) => axios.get(`v1/${MODULE_NAME}`, { params, baseURL })
const getDataDetail = (id) => axios.get(`v1/${MODULE_NAME}/${id}`, { baseURL })
const batchDeleteData = (body) => axios.post(`v1/${MODULE_NAME}/batch_delete`, body, { baseURL })
const batchReadData = (body) => {
  return axios.post(`v1/${MODULE_NAME}/batch_read`, body, { baseURL })
}
const getUnreadDataCount = () => {
  return axios.get(`v1/${MODULE_NAME}/unread_count`, { baseURL })
}

const deleteData = (id) => axios.post(`v1/${MODULE_NAME}/batch_delete`, [id], { baseURL })

export {
  getDatas,
  getDataDetail,
  batchDeleteData,
  batchReadData,
  getUnreadDataCount,
  deleteData,
}