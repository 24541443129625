import axios from 'axios'
import { notification, message } from '@om-tlh/bee'
import LocalStorageUtil from '@/utils/localStorageUtil'
import SStorageUtil from 'utils/sessionStorageUtil'
import utils from '@/utils/util'
import { camelCaseToSnakeCase, getQueryStringByName } from '@/utils/utils'
import baseConfig from './request-config'
// import '@/utils/mock'

let loadingTimer = ''
let instance = null

/**
 * 设置全局loading
 * @param {*} isLoading 
 */
let setLoadingTimer = isLoading => {
  if (loadingTimer) clearTimeout(loadingTimer)
  loadingTimer = setTimeout(() => {
    if (!isLoading && instance) {
      instance()
      instance = null
    }
    if (isLoading && !instance) {
      instance = message.loading('loading', 0)
    }
  }, 200)
}

function encodeString(str) {
  return encodeURI(str)
    .replace(/=/g, '%3D')
    .replace(/\[/g, '%5B')
    .replace(/\]/g, '%5D')
}

/**
 * 接口请求参数配置
 */
axios.interceptors.request.use(config => {
  if (!config.disableLoading) {
    setLoadingTimer(true)
  }
  let url = config.url
  if (config.params) {
    url += '?'
    for (let key in config.params) {
      if (config.params[key] && config.params[key] instanceof Array) {
        // for (let param in config.params[key]) {
        //   url += key + '=' + encodeString(config.params[key][param]) + '&'
        // }
        url += (config.noCamelToSnake ? key : camelCaseToSnakeCase(key)) + '=' + encodeString(config.params[key]) + '&'
      } else {
        if (config.params[key] !== undefined) {
          url += (config.noCamelToSnake ? key : camelCaseToSnakeCase(key)) + '=' + encodeString(config.params[key]) + '&'
        }
      }
    }
    url = url.substring(0, url.length - 1)

    config.url = url
    config.params = {}
  }

  var token = LocalStorageUtil.getAuth()

  if (!token && utils.getCookie('token')) {
    token = decodeURIComponent(utils.getCookie('token'))
  }

  if (!config.unAuth) {
    config.headers['Authorization'] = config.headers['Authorization'] || token // 'Bearer "0123456789ABCDEF"'
    config.headers['Accept'] = 'application/json'
  }

  // var lang = ''
  // if (window.config.locales && !window.config.locales['lang']) {
  //   lang = 'zh-CN'
  // } else {
  //   lang = (window.config.locales && window.config.locales['lang'] === 'zh' ? 'zh-CN' : 'en')
  // }
  // config.headers['Accept-Language'] = lang

  return Promise.resolve(config)
}, function (error) {
  setLoadingTimer(false)
  return Promise.reject(error)
})

/**
 * 接口响应配置
 */
axios.interceptors.response.use(response => {
  setLoadingTimer(false)
  const token = response.headers['x-auth-token']
  const privileges = response.headers['x-auth-privilege']
  if (privileges) {
    LocalStorageUtil.setPrivileges(privileges)
  }
  if (token) {
    LocalStorageUtil.setAuth(token)
    var cookie_token = token
    LocalStorageUtil.setCookie('token', cookie_token)
  }

  return response.data
}, (error) => {
  setLoadingTimer(false)
  if (error.response && error.response.status === 401) {
    if (window.location.pathname !== `${window.__IN_CLOUD__ ? `/${window.appName}` : ''}/login`) {
      const uc = process.env.NODE_ENV === 'production' ? '' : utils.getDomain('ucCenter') || '';
      ['auth', 'userInfo', 'privileges', 'platform_id'].forEach(i => {
        LocalStorageUtil.clearStorage(i)
      })
      LocalStorageUtil.clearAllCookie()
      SStorageUtil.clearSStorage()
      // window.location.href = `${window.location.origin}${window.__IN_CLOUD__ ? `/${window.appName}` : ''}/login?return_url=${encodeURIComponent(window.location.href)}`
      window.location.href = `${window.location.origin}${window.__IN_CLOUD__ ? `/${window.appType ? [...window.appType.split('/'), `${window.appName}`].filter(Boolean).join('/') : `/${window.appName}`}` : (window.appType ? `/${window.appType}` : ``)}/login?return_url=${encodeURIComponent(window.location.href.replace(new RegExp(`&?token_id=${getQueryStringByName(`token_id`)}`), ''))}`
    }
  } else if (error.response && error.response.status === 403) {
    // ['auth', 'userInfo', 'privileges', 'platform_id'].forEach(i => {
    //   LocalStorageUtil.clearStorage(i)
    // })
    // LocalStorageUtil.clearAllCookie()
    // SStorageUtil.clearSStorage()
  } else if (error.response && error.response.data && error.response.data.message) {
    if (!(error.config && error.config.ignoreErrorCodes && error.config.ignoreErrorCodes.indexOf(error.response.data.code) !== -1)) {
      // notification.error({ message: 'Tips' || error.response.status, description: error.response.data.message, duration: 3 })
    }
  }
  return Promise.reject(error)
})

/**
 * 初始化请求配置
 * @param {*} config 
 */
export function init(config) {
  axios.defaults.timeout = 600000;
  axios.defaults.baseURL = config.api_url;
}
export function getAuthorization(url, method) {
  let token = baseConfig.token
  return token
};
