import Path from '@/routes/path'

const MODULE_NAME = 'share'

const routes = [
    {
        id: `${MODULE_NAME}_manage`,
        name: `${MODULE_NAME}_manage`,
        componentPath: 'modules/Share/index',
        path: Path.pathname[`${MODULE_NAME}`]['home'],
        key: `${MODULE_NAME}_manage`,
    },
]

export default {
    routes
}
