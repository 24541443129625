import React from 'react'
import { Form, Input, Modal } from '@om-tlh/bee'
import DragTitle from '@/components/DragTitle'

export default function Index(props) {
  const { open, loading } = props
  // const { intl: { formatMessage } } = props;
  const [form] = Form.useForm()

  const handleOk = () => {
    form.validateFields().then(values => {
      props.onOk?.(values.name)
      form.resetFields()
    })
  }
  const handleCancel = () => {
    form.resetFields()
    props.onCancel?.()
  }

  return (
    <Modal
        okButtonProps={{ shape: 'round' }}
        cancelButtonProps={{ shape: 'round' }}
 
      width={600}
      title={<DragTitle title={props.title || `新建文件夹`} className='om_pan_folder_modal' />}
      open={open}
      confirmLoading={loading}
      onOk={() => handleOk()}
      onCancel={() => handleCancel()}
      wrapClassName='om_pan_folder_modal'
    >
      <Form form={form} >
        <Form.Item name='name' label='名称'
          rules={[{ required: true, message: '请输入名称' }, {
            pattern: /^[\w\u4e00-\u9fa5]+$/, message: '请输入至少一个数字, 字母, _ 和汉字'
          }]}
        >
          <Input maxLength={255} />
        </Form.Item>
      </Form>
    </Modal>
  )
}