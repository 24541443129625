import React, { createRef, Fragment, PureComponent } from "react";
import { toolbarTypes, cursorStyles } from './components/DiyLabel/LabelTool/enum'
import { initialTool } from './components/DiyLabel/LabelTool/toolBarData.js'

import DiyLabel from './components/DiyLabel'
import * as utils from './components/DiyLabel/LabelTool/utils'

const projectTypeMap = {
  OCR: 'OCR',
  OCR_REFER: 'OCR_REFER',
  OCR_VIEW: 'OCR_VIEW',
  OCR_REFER_VIEW: 'OCR_REFER_VIEW',
  OCR_CHECK: 'OCR_CHECK',
}
class ImageLabel extends React.Component {
  // 业务类型相关处理
  getLabelDataByProjectType = (imageLabelData) => {
    const funcs = {}
    // OCR
    funcs[projectTypeMap.OCR] = (data) => {
      return {
        // file_path: data.image.Key.replace(new RegExp(this.state.prefix), ''),
        objects: data.shapes.sort((a, b) => a.shapeId - b.shapeId).map(sp => {
          return {
            label: sp.labelName,
            position: utils.getPositionByEndpoints(sp.points)
          }
        })
      }
    }
    return funcs[this.props.projectType] ? funcs[this.props.projectType](imageLabelData) : imageLabelData
  }
  getLabelDataSingleByProjectType = (singleLabelData) => {
    const funcs = {}
    // OCR
    funcs[projectTypeMap.OCR] = (data) => {
      return utils.getPositionByEndpoints(data.points)
    }
    // OCR_REFER
    funcs[projectTypeMap.OCR_REFER] = (data) => {
      return utils.getPositionByEndpoints(data.points)
    }
    // OCR_CHECK
    funcs[projectTypeMap.OCR_CHECK] = (data) => {
      return utils.getPositionByEndpoints(data.points)
    }

    return funcs[this.props.projectType] ? funcs[this.props.projectType](singleLabelData) : singleLabelData
  }

  getOcrRecognizedTextByProjectType = async (id, params) => {
    const funcs = {}
    // OCR
    funcs[projectTypeMap.OCR] = (id, params) => {
      return this.props.service?.rectRecognize(id, params)
    }
    // OCR_REFER
    funcs[projectTypeMap.OCR_REFER] = (id, params) => {
      return this.props.service?.rectRecognize(id, params)
    }

    return funcs[this.props.projectType] ? funcs[this.props.projectType](id, params) : []
  }
  getOcrRecognizedTextSingleByProjectType = async (id, params) => {
    const funcs = {}
    // OCR
    funcs[projectTypeMap.OCR] = (id, params) => {
      return this.props.service?.rectRecognize(id, params)
    }
    // OCR_REFER
    funcs[projectTypeMap.OCR_REFER] = (id, params) => {
      return this.props.service?.rectRecognize(id, params)
    }
    // OCR_CHECK
    funcs[projectTypeMap.OCR_CHECK] = (id, params) => {
      return this.props.service?.rectRecognize(id, params)
    }

    return funcs[this.props.projectType] ? funcs[this.props.projectType](id, params) : []
  }
  getPSTFData = async (data) => {
    return this.props.service?.getPSTFData(data)
  }
  // 结构变换
  getSTDDataByProjectType = (bizData) => {
    const funcs = {}
    // 图像语义分割
    funcs[projectTypeMap.IMAGE_SEMANTIC_SEGMENTATION] = (data = {}) => {
      return (data.polygon || []).map(ps => ({
        ...ps,
        shape: toolbarTypes.POLYGON,
        points: ps.points.map(x => ({ xy: x, cursor: cursorStyles.POINTER }))
      }))
    }
    // 图像目标检测
    funcs[projectTypeMap.IMAGE_OBJECT_DETECTION] = (data) => (data.objects || []).map(b => ({
      label: b.label,
      shape: toolbarTypes.RECTANGLE,
      points: calcRectEndpointsByBbox(b.bbox)
    }))
    // OCR
    funcs[projectTypeMap.OCR] = (data) => {
      // console.log('data: ', data);
      return {
        data: (data.fields || []).map(b => ({
          labelName: b.key,
          shape: toolbarTypes.RECTANGLE,
          points: b.position ? calcRectEndpointsByBbox(utils.transformToBbox(b.position)) : [],
          nth: b.nth,
          isPrimary: b.isPrimary,
          pageNumber: data.pageNumber,
        })),
        labels: (data.fields || []).map(b => ({
          labelName: b.key,
          key: b.description,
          description: b.text,
          nth: b.nth,
          isPrimary: b.isPrimary,
          pageNumber: data.pageNumber,
        }))
      }
    }
    // OCR_REFER
    funcs[projectTypeMap.OCR_REFER] = (data) => {
      // console.log('data: ', data);
      return {
        data: (data.consult_info || []).map((b, index) => ({
          // labelName: b.key,
          labelName: `参照字段${index + 1}`,
          shape: toolbarTypes.RECTANGLE,
          points: b.position ? calcRectEndpointsByBbox(utils.transformToBbox(b.position)) : [],
          text_position: b.text_position
        })),
        labels: (data.consult_info || []).map((b, index) => ({
          // labelName: b.key,
          // key: b.description,
          labelName: `参照字段${index + 1}`,
          key: `参照字段${index + 1}`,
          description: b.text,
          text_position: b.text_position
        }))
      }
    }
    // OCR_VIEW
    funcs[projectTypeMap.OCR_VIEW] = (data) => {
      // console.log('data: ', data);
      return {
        data: (data.fields || []).map(b => ({
          labelName: b.key,
          shape: toolbarTypes.RECTANGLE,
          points: b.position ? calcRectEndpointsByBbox(utils.transformToBbox(b.position)) : [],
          nth: b.nth,
          isPrimary: b.isPrimary,
          pageNumber: data.pageNumber,
        })),
        labels: (data.fields || []).map(b => ({
          labelName: b.key,
          key: b.description,
          description: b.text,
          nth: b.nth,
          isPrimary: b.isPrimary,
          pageNumber: data.pageNumber,
        }))
      }
    }
    // OCR_REFER_VIEW
    funcs[projectTypeMap.OCR_REFER_VIEW] = (data) => {
      // console.log('data: ', data);
      return {
        data: (data.consult_info || []).map((b, index) => ({
          // labelName: b.key,
          labelName: `参照字段${index + 1}`,
          shape: toolbarTypes.RECTANGLE,
          points: b.position ? calcRectEndpointsByBbox(utils.transformToBbox(b.position)) : [],
          text_position: b.text_position
        })),
        labels: (data.consult_info || []).map((b, index) => ({
          // labelName: b.key,
          // key: b.description,
          labelName: `参照字段${index + 1}`,
          key: `参照字段${index + 1}`,
          description: b.text,
          text_position: b.text_position
        }))
      }
    }
    // OCR_CHECK
    funcs[projectTypeMap.OCR_CHECK] = (data) => {
      // console.log('data: ', data);
      return {
        data: (data.fields || []).map(b => ({
          labelName: b.key,
          shape: toolbarTypes.RECTANGLE,
          points: b.position ? calcRectEndpointsByBbox(utils.transformToBbox(b.position)) : [],
          nth: b.nth,
          isPrimary: b.isPrimary,
          pageNumber: data.pageNumber,
        })),
        labels: (data.fields || []).map(b => ({
          labelName: b.key,
          key: b.description,
          description: b.text,
          nth: b.nth,
          isPrimary: b.isPrimary,
          pageNumber: data.pageNumber,
        }))
      }
    }

    function calcRectEndpointsByBbox(bbox) {
      return [
        {
          xy: [bbox[0], bbox[1]],
          cursor: cursorStyles.NW_RESIZE
        },
        {
          xy: [bbox[0], bbox[1] + bbox[3] / 2],
          cursor: cursorStyles.W_RESIZE
        },
        {
          xy: [bbox[0], bbox[1] + bbox[3]],
          cursor: cursorStyles.SW_RESIZE
        },
        {
          xy: [bbox[0] + bbox[2] / 2, bbox[1] + bbox[3]],
          cursor: cursorStyles.S_RESIZE
        },
        {
          xy: [bbox[0] + bbox[2], bbox[1] + bbox[3]],
          cursor: cursorStyles.SE_RESIZE
        },
        {
          xy: [bbox[0] + bbox[2], bbox[1] + bbox[3] / 2],
          cursor: cursorStyles.E_RESIZE
        },
        {
          xy: [bbox[0] + bbox[2], bbox[1]],
          cursor: cursorStyles.NE_RESIZE
        },
        {
          xy: [bbox[0] + bbox[2] / 2, bbox[1]],
          cursor: cursorStyles.N_RESIZE
        }
      ]
    }
    return funcs[this.props.projectType] ? funcs[this.props.projectType](bizData) : bizData
  }

  getUploadJSONByProjectType = (labels = [], { specialLabels = [], annotationType = '', maxLabelCount = 256 } = {}) => {

    const funcs = {}
    // 图像语义分割
    funcs[projectTypeMap.IMAGE_SEMANTIC_SEGMENTATION] = () => {
      const labelsArr = [...labels]
      const res = [...specialLabels.map((sl, idx) => ({ ...(labelsArr.find(la => la.labelName === sl) || {}) })), ...labelsArr.filter(la => !specialLabels.includes(la.labelName))]
      const _labels = res.map(l => {
        let o = {
          labelName: l.labelName,
          color: l.color
        }
        if (l.images && l.images.length) {
          o.images = l.images
        }
        return o
      })
      let palette = new Array(maxLabelCount * 3).fill(0)
      const upld = _labels.reduce((pre, cur, index) => {
        pre[cur.labelName] = index
        const color = cur.color
        palette[index * 3] = parseInt(color.slice(1, 3), 16)
        palette[index * 3 + 1] = parseInt(color.slice(3, 5), 16)
        palette[index * 3 + 2] = parseInt(color.slice(5), 16)
        return pre
      }, {})
      let targetAnnotations = {
        labels: upld,
        palette,
        annotation_type: annotationType
      }
      annotationType && (targetAnnotations.annotation_type = annotationType)
      return { labels: _labels, targetAnnotations }
    }
    // 图像目标检测
    funcs[projectTypeMap.IMAGE_OBJECT_DETECTION] = () => {
      const _labels = labels.map(l => {
        let o = {
          labelName: l.label,
          color: l.color
        }
        if (l.images && l.images.length) {
          o.images = l.images
        }
        return o
      })
      let colors = new Array(labels.length * 3).fill(0)
      const upld = labels.reduce((pre, cur, index) => {
        pre.push(cur.label)
        const color = cur.color
        colors[index * 3] = parseInt(color.slice(1, 3), 16)
        colors[index * 3 + 1] = parseInt(color.slice(3, 5), 16)
        colors[index * 3 + 2] = parseInt(color.slice(5), 16)
        return pre
      }, [])
      let targetAnnotations = {
        labels: upld,
        colors,
      }
      return { labels: _labels, colors, targetAnnotations }
    }
    return funcs[this.props.projectType] ? funcs[this.props.projectType]() : [...labels, specialLabels]
  }
  getBizConfigs = () => {
    const funcs = {}
    // 图像语义分割
    funcs[projectTypeMap.IMAGE_SEMANTIC_SEGMENTATION] = () => {

      return {
        specialLabels: ['background'],
        maxLabelCount: 256
      }
    }
    // 图像目标检测
    funcs[projectTypeMap.IMAGE_OBJECT_DETECTION] = () => {
      return {
        specialLabels: [],
        maxLabelCount: Number.POSITIVE_INFINITY
      }
    }
    return funcs[this.props.projectType] ? funcs[this.props.projectType]() : {}
  }
  getToolsByProjectType = () => {
    const funcs = {}
    // OCR
    funcs[projectTypeMap.OCR] = () => {
      const tools = [
        toolbarTypes.RECTANGLE,
        // toolbarTypes.UNDO,
        // toolbarTypes.REDO,
        // toolbarTypes.CLEAR,
        toolbarTypes.ZOOM_IN,
        toolbarTypes.ZOOM_OUT,
        // toolbarTypes.SHAPE_EDIT,
        toolbarTypes.SHAPE_MOVE,
        // toolbarTypes.SHAPE_MOVE_WHOLE,
        toolbarTypes.BACK_TO_CENTER,
        // toolbarTypes.PERSPECTIVE_TRANSFORMATION
      ]
      return tools.map(t => initialTool.find(it => it.type === t))
    }
    // OCR_REFER
    funcs[projectTypeMap.OCR_REFER] = () => {
      const tools = [
        toolbarTypes.RECTANGLE,
        // toolbarTypes.UNDO,
        // toolbarTypes.REDO,
        // toolbarTypes.CLEAR,
        toolbarTypes.ZOOM_IN,
        toolbarTypes.ZOOM_OUT,
        // toolbarTypes.SHAPE_EDIT,
        toolbarTypes.SHAPE_MOVE,
        toolbarTypes.BACK_TO_CENTER,
        // toolbarTypes.PERSPECTIVE_TRANSFORMATION
      ]
      return tools.map(t => initialTool.find(it => it.type === t))
    }
    // OCR_VIEW 查看模式
    funcs[projectTypeMap.OCR_VIEW] = () => {
      const tools = [
        toolbarTypes.ZOOM_IN,
        toolbarTypes.ZOOM_OUT,
        toolbarTypes.SHAPE_MOVE_WHOLE,
        toolbarTypes.BACK_TO_CENTER,
      ]
      return tools.map(t => initialTool.find(it => it.type === t))
    }
    // OCR_CHECK
    funcs[projectTypeMap.OCR_CHECK] = () => {
      const tools = [
        toolbarTypes.RECTANGLE,
        // toolbarTypes.UNDO,
        // toolbarTypes.REDO,
        // toolbarTypes.CLEAR,
        toolbarTypes.ZOOM_IN,
        toolbarTypes.ZOOM_OUT,
        // toolbarTypes.SHAPE_EDIT,
        toolbarTypes.SHAPE_MOVE,
        // toolbarTypes.SHAPE_MOVE_WHOLE,
        toolbarTypes.BACK_TO_CENTER,
        // toolbarTypes.PERSPECTIVE_TRANSFORMATION
      ]
      return tools.map(t => initialTool.find(it => it.type === t))
    }

    funcs[projectTypeMap.OCR_REFER_VIEW] = funcs[projectTypeMap.OCR_VIEW]
    return funcs[this.props.projectType] ? funcs[this.props.projectType]() : []
  }
  getPropertyConfigByProjectType = () => {
    return {
      activeAlpha: 0.2
    }
    const funcs = {}
    // 图像语义分割
    funcs[projectTypeMap.IMAGE_SEMANTIC_SEGMENTATION] = () => {

      return {
        activeAlpha: 0.5
      }
    }
    // 图像目标检测
    funcs[projectTypeMap.IMAGE_OBJECT_DETECTION] = () => {

    }
    return funcs[this.props.projectType] ? funcs[this.props.projectType]() : []
  }
  demo = () => {
    const funcs = {}
    // 图像语义分割
    funcs[projectTypeMap.IMAGE_SEMANTIC_SEGMENTATION] = () => {

      return []
    }
    // 图像目标检测
    funcs[projectTypeMap.IMAGE_OBJECT_DETECTION] = () => {
      return []
    }
    return funcs[this.props.projectType] ? funcs[this.props.projectType]() : []
  }

  getRequestStructureByProjectType = (images, allLabels = []) => {
    const funcs = {}
    // OCR
    funcs[projectTypeMap.OCR] = () => {
      let file_pages = images.map(i => {
        return {
          file_url: i.file_url,
          page_num: i.page_num,
          fields: i.labelData?.labels.map((x, idx) => {
            const _t = allLabels.find(s => s.labelName === x.labelName)
            return {
              description: _t.key,
              key: _t.labelName,
              text: _t.description,
              nth: _t.nth,
              position: _t.points?.length ? utils.getPositionByEndpoints(_t.points) : [],
              isPrimary: _t.isPrimary,
              text_position: _t.text_position,
              pageNumber: _t.pageNumber
            }
          }) ?? [],
          consult_info: i.consult_info
        }
      })
      return file_pages
    }
    // OCR_REFER
    funcs[projectTypeMap.OCR_REFER] = () => {
      let file_pages = images.map(i => {
        return {
          file_url: i.file_url,
          page_num: i.page_num,
          consult_info: i.labelData?.labels.map((x, idx) => {
            return {
              // description: _t.key,
              // key: _t.labelName,
              text: x.description,
              text_position: x.text_position || null,
              position: x.points?.length ? utils.getPositionByEndpoints(x.points) : [],
              text_position: x.text_position,
              pageNumber: x.pageNumber
            }
          }) ?? [],
          fields: i.fields
        }
      })
      return file_pages
    }
    // OCR_VIEW
    funcs[projectTypeMap.OCR_VIEW] = () => {
      let file_pages = images.map(i => {
        return {
          file_url: i.file_url,
          page_num: i.page_num,
          fields: i.labelData?.labels.map((x, idx) => {
            const _t = allLabels.find(s => s.labelName === x.labelName)
            return {
              description: _t.key,
              key: _t.labelName,
              text: _t.description,
              nth: _t.nth,
              pageNumber: _t.pageNumber,
            }
          }) ?? [],
        }
      })
      return file_pages
    }
    // OCR_CHECK
    funcs[projectTypeMap.OCR_CHECK] = () => {
      let file_pages = images.map(i => {
        return {
          file_url: i.file_url,
          page_num: i.page_num,
          fields: i.labelData?.labels.map((x, idx) => {
            const _t = allLabels.find(s => s.labelName === x.labelName)
            return {
              description: _t.key,
              key: _t.labelName,
              text: _t.description,
              nth: _t.nth,
              position: _t.points?.length ? utils.getPositionByEndpoints(_t.points) : [],
              pageNumber: _t.pageNumber
            }
          }) ?? [],
        }
      })
      return file_pages
    }

    return funcs[this.props.projectType] ? funcs[this.props.projectType]() : []
  }

  handleChange = (data) => {
    // console.log('data: ', data);
    this.props.onChange?.(data)
  }

  render() {
    const commonProps = {
      tools: this.getToolsByProjectType(),
      // oss
      // bucketName: this.state.bucketName,
      // prefix: this.state.prefix,
      listObjects: this.listObjects,
      getFileUrl: this.getFileUrl,
      uploadFile: this.uploadFile,
      uploadJSON: this.uploadJSON,
      // funcs
      getLabelDataByProjectType: this.getLabelDataByProjectType,
      getLabelDataSingleByProjectType: this.getLabelDataSingleByProjectType,
      getSTDDataByProjectType: this.getSTDDataByProjectType,
      getUploadJSONByProjectType: this.getUploadJSONByProjectType,
      getRequestStructureByProjectType: this.getRequestStructureByProjectType,
      getPropertyConfigByProjectType: this.getPropertyConfigByProjectType,
      getOcrRecognizedTextSingle: this.getOcrRecognizedTextSingleByProjectType,
      getOcrRecognizedText: this.getOcrRecognizedTextByProjectType,
      getPSTFData: this.getPSTFData,
      ...this.getBizConfigs()
    }
    return <DiyLabel {...this.props} {...commonProps} onChange={this.handleChange} />
  }
}

export default ImageLabel
