// import List from './index'
// import ListTeam from './list_team'
// import ListAdmin from './list_admin'
// import Create from './create'
// import Detail from './detail'
// import Check from './check'
import Path from 'routes/path'

const MODULE_NAME = 'document';
const TEAM_MODULE_NAME = 'team_document';

const routes = [
    {
        id: `${MODULE_NAME}_manage`,
        name: `${MODULE_NAME}_manage`,
        // component: List,
        componentPath: 'modules/Document/index',
        path: Path.pathname[`${MODULE_NAME}`]['home'],
        key: `${MODULE_NAME}_manage`,
    },
    {
        id: `${MODULE_NAME}_team_manage`,
        name: `${MODULE_NAME}_team_manage`,
        // component: ListTeam,
        componentPath: 'modules/Document/list_team',
        path: Path.pathname[`${MODULE_NAME}`]['team'],
        key: `${MODULE_NAME}_team_manage`,
        permission: 'VIEW_DOCUMENT',
    },
    {
        id: `${MODULE_NAME}_admin_manage`,
        name: `${MODULE_NAME}_admin_manage`,
        // component: ListAdmin,
        componentPath: 'modules/Document/list_admin',
        path: Path.pathname[`${MODULE_NAME}`]['admin'],
        key: `${MODULE_NAME}_admin_manage`,
    },
    {
        id: `${MODULE_NAME}_create`,
        name: `${MODULE_NAME}_create`,
        // component: Create,
        componentPath: 'modules/Document/create',
        path: Path.pathname[`${MODULE_NAME}`]['create'],
        key: `${MODULE_NAME}_create`,
        // parentKey: `${MODULE_NAME}_manage`
    },
    {
        id: `${MODULE_NAME}_edit`,
        name: `${MODULE_NAME}_edit`,
        // component: Create,
        componentPath: 'modules/Document/create',
        path: Path.pathname[`${MODULE_NAME}`]['edit'],
        key: `${MODULE_NAME}_edit`,
        parentKey: `${MODULE_NAME}_manage`,
    },
    {
        id: `${MODULE_NAME}_detail`,
        name: `${MODULE_NAME}_detail`,
        // component: Detail,
        componentPath: 'modules/Document/detail',
        path: Path.pathname[`${MODULE_NAME}`]['detail'],
        key: `${MODULE_NAME}_detail`,
        parentKey: `${MODULE_NAME}_manage`
    },
    {
        id: `${TEAM_MODULE_NAME}_detail`,
        name: `${TEAM_MODULE_NAME}_detail`,
        // component: Detail,
        componentPath: 'modules/Document/detail',
        path: Path.pathname[`${TEAM_MODULE_NAME}`]['detail'],
        key: `${TEAM_MODULE_NAME}_detail`,
        parentKey: `document_team_manage`
    },
    {
        id: `${MODULE_NAME}_check`,
        name: `${MODULE_NAME}_check`,
        // component: Check,
        componentPath: 'modules/Document/check',
        path: Path.pathname[`${MODULE_NAME}`]['check'],
        key: `${MODULE_NAME}_check`,
        parentKey: `${MODULE_NAME}_manage`
    },
    {
        id: `${TEAM_MODULE_NAME}_check`,
        name: `${TEAM_MODULE_NAME}_check`,
        // component: Detail,
        componentPath: 'modules/Document/check',
        path: Path.pathname[`${TEAM_MODULE_NAME}`]['check'],
        key: `${TEAM_MODULE_NAME}_check`,
        parentKey: `document_team_manage`
    },
]

export default {
    routes
}
