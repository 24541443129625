import React, { useState, useEffect, useRef } from 'react'
import { Tag, Input, Card, message } from '@om-tlh/bee'
import { PlusOutlined } from '@ant-design/icons'
import { TweenOneGroup } from 'rc-tween-one';
import Nodata from '@/components/Nodata'
import tagService from '@/modules/Tag/service'

export default function Index(props) {
  const { value: tags = [], onChange, tagType = 'FILE_LABEL' } = props;
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);

  const [tagSourceData, setTagSourceData] = useState([])

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const res = await tagService.getDatas({ page_size: 100, label_type: tagType })
      setTagSourceData((res.items ?? []).map(x => ({ ...x, name: x.label_name, value: x.label_id })))
    }
    fetchData()
  }, [])
  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = async (e) => {
    e.preventDefault();
    if (!inputValue || !inputValue.trim()) {
      return setInputValue("");
    }
    let words = inputValue.split(/\||\s|,|\.|;|-|"|'|_|\n|\t/).filter(Boolean)
    words = words.reduce((pre, cur) => pre.includes(cur) ? pre : [...pre, cur], [])
    let newTags = [];
    if (words.filter(x => tagSourceData.find(xx => xx.name === x)).length) {
      message.warn(words.length > 1 ? '已自动过滤已存在的名称' : '该名称已存在')
    }
    const res = await Promise.all(words.filter(x => !tagSourceData.find(xx => xx.name === x)).map(x => tagService.createData({ label_name: x, label_type: tagType })))
    newTags = [...tags, ...res.map(x => x.id)];
    setTagSourceData([...res.map(x => ({ name: x.name, value: x.id })), ...tagSourceData]);
    handleChange(newTags);
    setInputVisible(false);
    setInputValue("");
  };

  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    handleChange(newTags);
  };
  const handleChange = (arr) => {
    onChange && onChange(arr)
  }
  const forMap = (tag) => {
    const tagObj = tagSourceData.find(x => x.value === tag) ?? {}
    tag = tagObj.name ?? tag
    const tagId = tagObj.value
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          handleClose(tagId);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: 'inline-block',
          cursor: 'pointer'
        }}
      >
        {tagElem}
      </span>
    );
  };
  const tagChild = tags && tags.map(forMap);
  return (
    <>
      {/* <Button shape='round' icon={<PlusOutlined />}>新标签</Button> */}
      {inputVisible && (
        <Input
          ref={inputRef}
          type="text"
          size="small"
          style={{
            width: 78,
          }}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
      {!inputVisible && (
        <Tag onClick={showInput} className="site-tag-plus">
          <PlusOutlined /> 新标签
        </Tag>
      )}
      <Card style={{ margin: '10px 0' }} bodyStyle={{ minHeight: '200px' }}>
        <TweenOneGroup
          enter={{
            scale: 0.8,
            opacity: 0,
            type: 'from',
            duration: 100,
          }}
          onEnd={(e) => {
            if (e.type === 'appear' || e.type === 'enter') {
              e.target.style = 'display: inline-block';
            }
          }}
          leave={{
            opacity: 0,
            width: 0,
            scale: 0,
            duration: 200,
          }}
          appear={false}
        >
          {tags.length ? tagChild : <Nodata text='暂无标签' />}
        </TweenOneGroup>
        {/* {
          value && value.map(i => <Tag key={i} closable onClose={(e) => {
            handleChange(value.filter(j => j !== i))
          }}>{i}</Tag>)
        } */}
      </Card>
      {/* {
        Array(4).fill(0).map((i, d) => <Tag key={d} disabled onClick={() => {
          var tag = `标签${['a', 'b', 'c', 'd'][d]}`
          if (tags.length && tags.find(j => j === tag)) return
          handleChange([...tags, tag])
        }}>标签{['a', 'b', 'c', 'd'][d]}</Tag>)
      } */}
      {
        !!tagSourceData.length && tagSourceData.map((i, d) => <Tag key={d}
          color={tags?.find(j => j === i.value) ? 'processing' : 'default'}
          style={{
            cursor: 'pointer',
            borderStyle: 'dashed'
          }} onClick={() => {
            if (tags.length && tags.find(j => j === i.value)) return
            handleChange([...tags, i.value])
          }}>{i.name}</Tag>)
      }
    </>
  )
}
