import React from 'react';
import { Link } from 'react-router-dom';
import SearchType from '@/utils/searchType'
import service from './service'
import { injectIntl } from 'react-intl';
import TablePage from '@/components/TablePage'
import TextMore from '@/components/TextMore'
import { Button, Tag } from '@om-tlh/bee'
import Path from 'routes/path'

const Index = (props) => {
  const { intl, match } = props
  const { params } = match || {}
  const { scope } = params || {}
  const { formatMessage } = intl
  const fields = [
    { name: 'key', desc: '序号', render: (text, record, index) => index + 1, width: 80 },
    { name: 'display_name', desc: '租户名', showDetail: true, query: true, key: 'keyword' },
    // { name: 'description', desc: '说明' },
    { name: 'roles', desc: '角色', render: (text) => text.map((i, d) => <Tag key={d}>{i.display_name}</Tag>), showRender: true  },
    { name: 'create_time', desc: '创建时间', type: SearchType.KEY_DATE_TIME },
    {
      name: 'action', desc: '操作',
      listActions: true,
      render: (text, record) => {
        return [
          <a key='edit' className='btn-action'
            onClick={e => props.history.push(`${Path.getPathname(props, Path.pathname['tenant']['edit'])}?id=${record.tenant_id}`)}>编辑</a>
        ]
      }
    },
  ]
  const insertBtns = [
    <Button shape='round' key='create' type='primary' onClick={() => props.history.push(Path.getPathname(props, Path.pathname['tenant']['create']))}>创建</Button>
  ]
  return (
    <>
      <div className="content-header">
        <h2>查看租户数据</h2>
      </div>
      <div className="content-body">
        <TablePage
          rowKey='tenant_id'
          name={'租户数据'}
          fields={fields}
          insertBtns={insertBtns}
          loadData={service.getDatas}
          deleteData={service.batchDeleteData}
          service={service}
          scope={scope}
          showDelete
          moduleName='tenant'
        />
      </div>
    </>
  )
}

export default injectIntl(Index);