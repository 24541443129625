import React from "react";
import './toolBar.less'

/**
 * 
 * @param {*} props 
 * tool type name iconComponent|icon disabled active
 */
export const Tools = (props) => {
  const toolsData = props.tools || [] // []obejct
  const activeTypes = props.activeTypes || [] // []string
  const disabledTypes = props.disabledTypes || [] // []string
  return <ul className='toolbar-wrapper'>
    {
      toolsData.map(ti => {
        return <li key={ti.type}
          className={`toolbar-item ${activeTypes.includes(ti.type) ? 'active' : ''} ${disabledTypes.includes(ti.type) ? 'disabled' : ''}`}
          onClick={() => !disabledTypes.includes(ti.type) && props.onItemClick(ti)}
        >
          <ti.Icon />
          <br />
          {/* {props.formatMessage({id: ti.type, defaultMessage: ti.name})} */}
          {ti.name}
        </li>
      })
    }
  </ul>
}
