import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Result, Button, Space, Row, Col, Card, Select, Divider } from '@om-tlh/bee';
import { PlusOutlined } from '@ant-design/icons'
import Path from 'routes/path';
import LocalStorageUtil from 'utils/localStorageUtil'
import commonService from '@/modules/common/service'
import { loginSuccessCallback } from '@/modules/Login'
import TeamCreate from '@/modules/Home/components/ButtonModalTeamInfo'
import noTeamIcon from '@/theme/img/no-team.png'
import joinTeamIcon from '@/theme/img/join_team_bg.png'
import f1 from '@/theme/img/xiezuoguanli.png'
import f2 from '@/theme/img/tuanduiziyuan.png'
import f3 from '@/theme/img/shuzihua.png'
import f4 from '@/theme/img/kefu.png'

const { Option } = Select
/**
 * 没有加入团队
 * @param {*} props 
 */

const NoTeam = (props) => {
  const { intl } = props
  const { formatMessage } = intl
  const [accountInfo, setAccountInfo] = useState([])
  const userInfoStr = LocalStorageUtil.getUserInfo()
  const [open, setOpen] = useState(false)

  let userInfo = {}
  try {
    userInfo = JSON.parse(userInfoStr)
  } catch (e) { }

  useEffect(() => {
    const fetchData = async () => {
      const res = await commonService.userAccounts(userInfo.person_id)
      setAccountInfo(res.filter(x => !!x.tenant_name))
    }
    if (!userInfo.person_id) return
    fetchData()
  }, [userInfo.person_id])

  const onChange = async (us) => {
    const data = await commonService.switchLogin({
      person_id: userInfo.person_id,
      user_id: us
    })
    loginSuccessCallback({ data: data.find(x => x.person_id === userInfo.person_id && x.user_info.user_id === us) }, '操作成功')
  }

  const descs = [{
    name: '协作与管理',
    icon: <img src={f1} style={{ height: '4em' }} alt='' />,
    description: <div>多人在线协作
      <br />审批高效流转</div>
  }, {
    name: '团队资源共享',
    icon: <img src={f2} style={{ height: '4em' }} alt='' />,
    description: <div>优质资源与模板共享
      <br />助力企业提升内部能效</div>
  }, {
    name: '企业数字化',
    icon: <img src={f3} style={{ height: '4em' }} alt='' />,
    description: <div>内容资产可视化管理
      <br />助力企业数字化转型</div>
  }, {
    name: '企业客服',
    icon: <img src={f4} style={{ height: '4em' }} alt='' />,
    description: <div>专属客服
      <br />反馈优先响应</div>
  }]

  return (
    <div style={{ padding: 40, textAlign: 'center' }}>
      <Space size={30} style={{ margin: 20 }}>
        <img src={noTeamIcon} alt='' />
        <span>
          {
            accountInfo.length > 0 ? <>
              创建/选择团队：<Select size='small' className="locale-select" onChange={onChange} value={userInfo.tenant_name ? userInfo.user_id : void 0}
                open={open}
                onDropdownVisibleChange={(open) => {
                  setOpen(open)
                }}
                getPopupContainer={() => document.getElementById('content')}
                style={{ display: 'inline-block', marginLeft: 20 }}
                bordered={true}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: '8px 0',
                      }}
                    />
                    <Space
                      style={{
                        padding: '0 8px 4px',
                      }}
                    >
                      {/* <Button type="link" icon={<PlusOutlined />} onClick={() => {
                        setOpen(false)
                        props.history.push(Path.getPathname(props, Path.pathname['team']['create']))
                      }}>
                        创建团队
                      </Button> */}
                      <TeamCreate
                        {...props}
                        buttonProps={{
                          type: 'link',
                          icon: <PlusOutlined />
                        }}
                        onClick={() => setOpen(false)}
                        buttonText='创建团队'
                        modalTitle='创建团队'
                        data={userInfo}
                        okSuccessCallback={() => {
                          // fetchUserInfo()
                        }}
                      />
                    </Space>
                  </>
                )}
              >
                {accountInfo.map((opt, index) => {
                  return <Option key={index} value={opt.user_id}>
                    {`${opt.tenant_name || opt.user_name}`}
                  </Option>
                })}
              </Select>
            </>
              : <>
                暂未加入团队，去 <TeamCreate
                  {...props}
                  buttonProps={{
                    type: 'link',
                    // icon: <PlusOutlined />
                  }}
                  onClick={() => setOpen(false)}
                  buttonText='创建团队'
                  modalTitle='创建团队'
                  data={userInfo}
                  okSuccessCallback={() => {
                    // fetchUserInfo()
                  }}
                />
                {/* <a onClick={() => props.history.push(Path.getPathname(props, Path.pathname['team']['create']))}>创建团队</a> */}
              </>
          }

        </span>
      </Space>
      <div style={{
        backgroundImage: `url(${joinTeamIcon})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        padding: 40
      }}>
        <h1 style={{ marginBottom: 40, fontSize: '1.5em', fontWeight: 600 }}>企业版VIP权益</h1>
        <Row gutter={50} justify="space-around">
          {
            descs.map((xx, idx) => (<Col key={idx} span={6} style={{ paddingBottom: 32 }}>
              <Card
                style={{ color: 'inherit' }}
              >
                <Space
                  // size={'large'}
                  style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center', marginTop: '2em', marginBottom: '2em' }}
                  direction='vertical'
                >
                  <h2 style={{ verticalAlign: 'middle', color: '#333' }}>{xx.name}</h2>
                  <div style={{ margin: '1.5em auto' }}>{xx.icon}</div>
                  <span style={{
                    verticalAlign: 'middle', color: '#777',
                    // height: '1em',
                    display: 'inline-block'
                  }}>
                    {xx.description}
                  </span>
                </Space>
              </Card>
            </Col>))
          }
        </Row>
        {/* <Button shape='round' type="primary"
        // onClick={() => props.history.goBack()}
        >了解详情</Button> */}
      </div>
    </div>
  )
}

export default injectIntl(NoTeam);