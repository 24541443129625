
import List from './index'
import Create from './create'
import Detail from './detail'
import { getRoutes } from '../utils'

const routes = getRoutes('tenant', { List, Create, Detail });

export default {
    routes
}