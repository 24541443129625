import axios from 'axios'
import config from 'config'

const baseURL = config.uc_url

export const getWXQRCode = () => {
  return axios.get(`${process.env.NODE_ENV === 'development' ? 'http://192.168.12.24/' : ''}v1/wx/get_qr_code`, { baseURL })
}

export const wxLogin = (body) => {
  return axios.post('v1/wx/login', body, { baseURL })
}

export const bindAccount = (body) => {
  return axios.post('v1/wx/bind_username/login', body, { baseURL })
}

export const bindMobile = (body) => {
  return axios.post('v1/wx/bind_mobile/login', body, { baseURL })
}