import { Row, Col, Card } from '@om-tlh/bee'
import imgRecg1 from '@/theme/img/recg1.png'
import imgRecg2 from '@/theme/img/recg2.png'

export default function (props) {
  const data = [{
    title: '待识别区域数量必须大于0',
    image: imgRecg2
  }, {
    title: '尽量扩大识别区范围',
    image: imgRecg1
  }]
  return (<div
    style={{ width: 800, ...props.style }}
  >
    <section style={{ marginBottom: 30 }}>
      <h2 style={{ fontWeight: 600 }} >什么是识别字段</h2>
      <p>
        识别字段为图片中需要识别的字段，用于构建Key-Value对应关系，输出结构化识别结果
      </p>
    </section>
    <section>
      <h2 style={{ fontWeight: 600 }} >如何框选识别字段</h2>
      <section>
        <Row gutter={[20, 20]}>
          {
            data.map((x, idx) => (<Col key={x.title} span={props.colSpan ?? 12}>
              <Card title={`${idx + 1}. ${x.title}`}
                bordered={false}
                headStyle={{ padding: 0 }}
                bodyStyle={{ padding: 0 }}
              >
                <img src={x.image} alt="" style={{ width: '100%' }} />
              </Card>
            </Col>))
          }
        </Row>
      </section>
    </section>
  </div>)
}