// import List from './index'
// import Create from './create'
// import Detail from './detail'
import Path from 'routes/path'

const MODULE_NAME = 'order';

const routes = [
    {
        id: `${MODULE_NAME}_manage`,
        name: `${MODULE_NAME}_manage`,
        // component: List,
        componentPath: 'modules/Order/index',
        path: Path.pathname[`${MODULE_NAME}`]['home'],
        key: `${MODULE_NAME}_manage`,
        permission: 'VIEW_ORDER'
    },
    {
        id: `${MODULE_NAME}_create`,
        name: `${MODULE_NAME}_create`,
        // component: Create,
        componentPath: 'modules/Order/create',
        path: Path.pathname[`${MODULE_NAME}`]['create'],
        key: `${MODULE_NAME}_create`,
        parentKey: `${MODULE_NAME}_manage`,
        permission: 'CREATE_ORDER'
    },
    // {
    //     id: `${MODULE_NAME}_edit`,
    //     name: `${MODULE_NAME}_edit`,
    //     component: Create,
    //     path: Path.pathname[`${MODULE_NAME}`]['edit'],
    //     key: `${MODULE_NAME}_edit`,
    //     parentKey: `${MODULE_NAME}_manage`,
    // },
    {
        id: `${MODULE_NAME}_detail`,
        name: `${MODULE_NAME}_detail`,
        // component: Detail,
        componentPath: 'modules/Order/detail',
        path: Path.pathname[`${MODULE_NAME}`]['detail'],
        key: `${MODULE_NAME}_detail`,
        parentKey: `${MODULE_NAME}_manage`,
        permission: 'VIEW_ORDER',
    },
]

export default {
    routes
}