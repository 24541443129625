import { useState, useEffect } from 'react'
import { Descriptions } from '@om-tlh/bee'
import { LeftOutlined } from '@ant-design/icons'
import { getDataDetail } from './dataService'
import { getQueryStringByName } from '@/utils/utils'

export default function (props) {
  const id = getQueryStringByName || ''
  const { data = {}, isLoading, isError } = getDataDetail(id)

  return (<>
    <div className="content-header" style={{ overflow: 'hidden' }}>
      <LeftOutlined style={{ fontSize: 20, float: 'left', marginRight: 20 }} onClick={() => props.history.goBack()} />
      <h2 style={{ float: 'left' }}>订单详情</h2>
    </div>
    <div className="content-body">
      <Descriptions>
        <Descriptions.Item label="订单号">{data.order_id || '-'}</Descriptions.Item>
        <Descriptions.Item label="下单时间" span={2}>{data.create_time || '-'}</Descriptions.Item>
        <Descriptions.Item label="买家帐户" span={3}>{data.buyer?.user_name ?? '-'}</Descriptions.Item>
        <Descriptions.Item label="商品名称" span={3}>{data.order_details?.product?.product_name ?? '-'}</Descriptions.Item>
        <Descriptions.Item label="商品规格" span={3}>{data.order_details?.product?.specs?.[0]?.duration ?? ''}{data.order_details?.product?.specs?.[0]?.duration_unit ?? '-'}</Descriptions.Item>
        <Descriptions.Item label="套餐资源" span={3}>{data.order_details?.product?.resource_packs?.map(x => {
          return `${x.resource_pack_type}: ${x.quota}${x.quota_unit}`
        }).join(';') ?? '-'}</Descriptions.Item>
        <Descriptions.Item label="实收款（元）" span={1}>{data.order_details?.actual_price || '-'}</Descriptions.Item>
        <Descriptions.Item label="付款方式" span={2}>{data.pay_mode || '-'}</Descriptions.Item>
        <Descriptions.Item label="订单状态" span={3}>{data.payment_state || '-'}</Descriptions.Item>
      </Descriptions>
    </div>
  </>)
}
