import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Input, Button, Checkbox, Row, Col, Card, Select, Space, Upload, message, Divider } from '@om-tlh/bee';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { getQueryStringByName } from 'utils/utils'
import service from './service'
import roleService from '../Role/service'
import SelectPrivilege from './components/SelectPrivilege'
import utils from '@/utils/util'

const { Option } = Select;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 18 },
};

const Create = (props) => {
  const [form] = Form.useForm();
  const { intl, location } = props
  const id = getQueryStringByName('id')
  const { formatMessage } = intl
  const [values, setValues] = useState({})
  const [options, setOptions] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const res = await service.getDataDetail(id)
      setValues(res)
      form.setFieldsValue(res)
    }
    if (!id) return
    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const res = await roleService.getDatas({ page_size: 100 })
      setOptions(res.items)
    }
    fetchData()
  }, [])


  const onFinish = values => {
    if (id) {
      service.updateData(id, values).then(data => {
        props.history.goBack();
        message.success('编辑数据成功')
      }).catch(error => {
        message.error(error.response && error.response.data.message)
      })
    } else {
      service.createData(values).then(data => {
        props.history.goBack();
        message.success('创建数据成功')
      }).catch(error => {
        message.error(error.response && error.response.data.message)
      })
    }
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <div className="content-header">
        <h2>{id ? '编辑' : '创建'}角色</h2>
      </div>
      <div className="content-body">
        <Form
          {...layout}
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="名称"
            name="display_name"
            rules={[{ required: true, message: '请输入名称' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="权限"
            name="privileges"
            rules={[{ required: true, message: '请选择权限' }]}
          >
            <SelectPrivilege {...props} />
          </Form.Item>
          <Form.Item
            label="描述"
            name="description"
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Divider />
          <Form.Item {...tailLayout}>
            <Button shape='round' type="primary" htmlType="submit">
              提交
            </Button>
            <Button shape='round' onClick={() => {
              const returnUrl = decodeURIComponent(utils.getQueryStringByName('return_url') || '')
              if (returnUrl) {
                return props.history.push(returnUrl)
              }
              props.history.goBack()
            }} style={{ marginLeft: 10 }}>
              取消
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  )
}

export default injectIntl(Create);