import React, { useEffect, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import config from 'config';
import { Upload, Modal, message, Button, Progress } from '@om-tlh/bee';
import { PlusOutlined } from '@ant-design/icons';
import service from './service'
import axios from 'axios'
import commonService from '@/modules/common/service'

const CancelToken = axios.CancelToken;
let source = null;
const MAX_FILE_SIZE = 200

const Dragger = Upload.Dragger

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });

const UploadCovers = (props) => {
  const { value, onChange } = props
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewPdf, setPreviewPdf] = useState('')
  const [fileList, setFileList] = useState([]);
  const [uploadFileList, setUploadFileList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [status, setStatus] = useState('');
  const [uploadPercent, setUploadPercent] = useState(0)
  const [currentUploadingFile, setCurrentUploadingFile] = useState('')

  useEffect(() => {
    if (!value) return
    setFileList(value.map((i, d) => ({
      ...i,
      uid: d * (-1) - 1,
      name: i.file_name,
      status: 'done',
      url: i.file_url
    })))
  }, [value])

  const getUploadSession = (file) => {
    let params = {}
    params.assetType = file.type.endsWith('pdf') ? 'PDF' : (props.assetType || 'PICTURE')
    params.objectName = encodeURIComponent(file.name)
    params.contentType = file.type
    return service.getUploadAccess(params)
  }

  const beforeUpload = (file, fileList) => {
    // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    // if (!isJpgOrPng) {
    //   message.error('请上传 JPG/PNG 图片!');
    //   return false
    // }
    const isLt2M = file.size < MAX_FILE_SIZE * 1024 * 1024;
    if (!isLt2M) {
      message.error(`文件大小不超过 ${MAX_FILE_SIZE} MB!`);
      return false
    }
    // setUploadFileList([file])
    setUploadFileList(fileList)
    return false;
  }

  const handleUpload = (options) => {
    if (!uploadFileList || !uploadFileList.length) return message.error('请选择文件');
    setStatus('loading')

    let queue = uploadFileList
    let fileCount = queue.length
    const totalSize = queue.map(x => x.size).reduce((p, c) => c + p)
    let pointer = 0
    let result = []
    queueUpload(queue, pointer, fileCount, () => {
      message.success('上传成功')
      setVisible(false)
      setStatus('')
      setUploadFileList([])

      onChange?.(props.multiple ? [
        ...(value || []),
        ...result

      ] : result)
    })
    function queueUpload(list, pointer, count, cb) {
      const cur = list.shift()
      if (!cur) {
        return cb?.()
      }
      setCurrentUploadingFile(cur.name)
      uploadFile(cur, pointer, count, () => {
        pointer++
        queueUpload(list, pointer, count, cb)
      })
    }

    function uploadFile(file, pointer = 0, count = 1, callback) {
      source = CancelToken.source()
      getUploadSession(file).then(uploadSession => {
        const actionUrl = uploadSession.upload_url || ''
        axios({
          url: actionUrl,
          method: 'PUT',
          data: file,
          headers: {
            'Content-Type': file.type,
          },
          onUploadProgress: (e) => {
            // console.log('e: ', e);
            // setUploadPercent(parseInt(e.loaded / e.total * 100))
            setUploadPercent(parseInt(e.loaded / totalSize * 100))
          },
          cancelToken: source.token,
          unAuth: true,
          timeout: 1000 * 60 * 5,
        }).then(() => {
          result = [...result, {
            file_name: file.name,
            display_name: file.name,
            file_url: uploadSession.access_url,
            file_size: file.size,
            file_type: file.type,
          }]
          callback?.()
        }, (error) => {
          console.log('error: ', error);
          setStatus('')
          setCurrentUploadingFile('')
          message.error(error?.response?.data?.message ?? JSON.stringify(error.message));
        });
      })
    }
    return
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      axios({
        url: actionUrl,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData,
        onUploadProgress: (e) => {
          setUploadPercent((e.loaded / e.total).toFixed(2))
        },
        cancelToken: source.token
      }).then((res) => {
        console.log('res: ', res);
        message.success('上传成功')
        setVisible(false)
        setStatus('')
        setUploadFileList([])
        onChange?.([
          ...(value || []),
          {
            file_url: res,
            file_name: file.name,
            display_name: file.name,
          }
        ])
      }, (error) => {
        setStatus('')
        message.error(error.response?.data?.message ?? JSON.stringify(error));
      });
    })
  }

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    if (file.file_type.includes('/pdf')) {
      setPreviewPdf(await commonService.getOSSTempUrl({ object_key: file.url }))
    } else {
      setPreviewImage(file.url || file.preview);
    }

    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleChange = ({ fileList: newFileList, file, ...rest }) => {
    // onChange && onChange(value.filter(i => newFileList.find(j => j.url === i.file_url)))
    onChange?.(newFileList)
  };

  const newProps = {
    // showUploadList: false,

    accept: props.accept || 'image/*',
    multiple: props.multiple || false,
    fileList: uploadFileList,
    onRemove: (file) => {
      setUploadFileList(uploadFileList.filter(x => x.uid !== file.uid))
    },
    beforeUpload: (file, files) => beforeUpload(file, files),
    onChange(info) {
    }
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        点击上传
      </div>
    </div>
  );
  return (
    <>
      {value?.length ? <>
        <Upload
          listType="picture-card"
          fileList={fileList}
          // fileList={fileList.filter(x => x.file_type.includes('image/'))}
          onPreview={handlePreview}
          onChange={handleChange}
          beforeUpload={beforeUpload}
          accept='image/*'
          style={{ width: 100 }}
        // customRequest={(options) => handleUpload(options)}
        >
          {/* {fileList.length >= 8 ? null : uploadButton} */}
        </Upload>
      </> : null}
      {/* {
        value?.filter(x => !x.file_type.includes('image/')).map(x => <span key={x.file_url} style={{marginRight: 10}}>{x.file_name}</span>)
      } */}
      {
        props.button ? React.cloneElement(props.button, {
          onClick: () => {
            if (props.onClick) {
              return props.onClick(() => setVisible(true))
            }
            setVisible(true)
          }
        }) : <Button shape='round' icon={<UploadOutlined />} {...props.buttonProps} onClick={() => setVisible(true)}> {props.buttonText || '上传'}</Button>
      }
      <Modal
        okButtonProps={{ shape: 'round' }}
        cancelButtonProps={{ shape: 'round' }}
        open={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel} width={800}>
        {
          previewImage ? <img
            alt="img"
            style={{
              width: '100%',
            }}
            src={previewImage}
          /> : <iframe src={previewPdf} frameBorder="0" style={{ width: '100%', height: 800 }}></iframe>
        }

      </Modal>
      <Modal
        okButtonProps={{ shape: 'round' }}
        cancelButtonProps={{ shape: 'round' }}

        open={visible}
        title={<>{props.title}{!value?.length ? '上传' : '修改'}</>}
        onOk={() => handleUpload()}
        onCancel={() => {
          if (status === 'loading') return
          setVisible(false)
        }}
        wrapClassName='upload_excel_modal'
        confirmLoading={status === 'loading'}
      >
        {
          !status && <Dragger {...newProps} >
            <p className="dzt-upload-drag-icon">
              {props.icon || <UploadOutlined />}
            </p>
            <p className="dzt-upload-text">点击或者拖动文件到此区域进行上传</p>
            <p className="dzt-upload-hint">
              {/* 文件格式支持jpg、png， */}
              文件大小不超过{MAX_FILE_SIZE}MB！</p>
          </Dragger>
        }
        {
          status
            ? <div style={{ textAlign: 'center' }}>
              <p>{uploadPercent > 0.98 ? '上传成功' : `正上传 ${currentUploadingFile}`}</p>
              <Progress type='circle' percent={uploadPercent * 100} />
            </div>
            : ''
        }
      </Modal>
    </>
  );
}

export default UploadCovers;
