import React, { useEffect, useState } from 'react'
import { Image, Button, message, Alert } from '@om-tlh/bee'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { downloadFile } from '@/utils/utils'
import { QRCodeCanvas } from 'qrcode.react';
import logoSrc from '@/theme/img/logo-qrcode.png'

export default function (props) {
  const { url, displayName, canvasId = 'share-file' } = props

  const handleQRCodeDownload = () => {
    const canvas = document.getElementById(canvasId)
    const imgSrc = canvas.toDataURL('image/png')
    downloadFile(imgSrc, displayName + '二维码' + '.png')
  }

  if (!url) {
    return null
  }
  return (<>
    <div>
      <div style={{ textAlign: 'center' }}>
        <QRCodeCanvas
          id={canvasId}
          value={url}
          size={200}
          level='H'
          includeMargin
          imageSettings={{
            src: logoSrc,
            width: 40,
            height: 40
          }}
        />
        <div><Button shape='round' type='primary' style={{ margin: '5px 0 40px 0' }} onClick={handleQRCodeDownload}>下载二维码</Button></div>
      </div>
      <div>
        <Alert
          message="成功创建链接"
          description={url}
          type="success"
          showIcon
          action={
            <CopyToClipboard text={url}
              onCopy={() => message.success('复制成功')}>
              <Button shape='round' type='ghost' >复制链接</Button>
            </CopyToClipboard>
          }
        // closable
        />

      </div>

    </div>
  </>)
}
