// 二维码中间有Logo
import React, { useEffect, useState, useRef } from 'react'
import { Image, Button, message, Popover } from '@om-tlh/bee'
import { ShareAltOutlined } from '@ant-design/icons'
import { QRCodeCanvas } from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { downloadFile } from '@/utils/utils'
import { getInvitationCode, getInvitationNumber } from './service'
import logoSrc from '@/theme/img/logo-qrcode.png'
import LocalStorageUtil from '@/utils/localStorageUtil'

export default function (props) {
  const shareRef = useRef(null)
  const [url, setUrl] = useState()
  const [displayName, setDisplayName] = useState(encodeURIComponent(window.location.origin + window.location.pathname))
  const [imgSrc, setImgSrc] = useState()
  const [invitationCode, setInvitationCode] = useState()
  const [number, setNumber] = useState()

  const fetchInvitationNumber = async () => {
    const res = await getInvitationNumber()
    setNumber(res)
  }

  useEffect(() => {
    const fetchInvitationCode = async () => {
      // new Promise((resolve, reject) => {
      //   setTimeout(() => {
      //     resolve(`DI09202`)
      //   }, 1000)
      // })
      getInvitationCode().then(res => {
        setInvitationCode(res)
        LocalStorageUtil.setStorage('inv_code', res)
        setUrl(`${window.location.origin}${window.__IN_CLOUD__ ? `/${window.appType ? [...window.appType.split('/'), `${window.appName}`].filter(Boolean).join('/') : `${window.appName}`}` : (window.appType ? `/${window.appType}` : ``) }/regist/${res}`)
      })
    }
    fetchInvitationCode()
    fetchInvitationNumber()
  }, [])

  const handleQRCodeDownload = () => {
    const canvas = document.getElementById('shareRef')
    const imgSrc = canvas.toDataURL('image/png')
    downloadFile(imgSrc, displayName + '(二维码)' + '.png')
  }

  if (!url) {
    return null
  }
  return (<>
    <Popover
      overlayStyle={{ width: 300 }}
      // trigger='click'
      content={<div>
        {
          typeof number !== 'undefined'
            ? (number > 0
              ? <p style={{ color: '#999' }}>恭喜！您已成功推荐<span style={{ color: '#333', fontSize: '1.2em', fontWeight: 600, padding: '0 5px' }}>{number || 0}</span>位新用户</p>
              : <p style={{ color: '#999' }}>您还没有推荐过新用户，开始<CopyToClipboard text={url}
                onCopy={() => message.success('推荐链接复制成功')}>
                <Button shape='round' type='link' >推荐</Button>
              </CopyToClipboard>吧</p>)
            : null
        }
        <p style={{ textAlign: 'center', marginBottom: 10 }}>
          <CopyToClipboard text={url}
            onCopy={() => message.success('推荐链接复制成功')}>
            <Button shape='round' type='primary' >复制推荐链接</Button>
          </CopyToClipboard>
        </p>
        <div style={{ textAlign: 'center' }}>
          <QRCodeCanvas
            id={'shareRef'}
            value={url}
            size={200}
            level='H'
            includeMargin
            imageSettings={{
              src: logoSrc,
              width: 40,
              height: 40
            }}
          />
          <Button shape='round' type='primary' style={{ margin: '5px 0 20px 0' }} onClick={handleQRCodeDownload}
          >
            分享海报
          </Button>
        </div>
      </div>} title={
        <div style={{ textAlign: 'center', margin: '10px auto' }}>
          <p style={{ marginBottom: 5 }}>您的专属推荐码</p>
          <CopyToClipboard text={invitationCode}
            onCopy={() => message.success('推荐码复制成功')}>
            <span style={{ color: '#1890ff', cursor: 'pointer' }}>{invitationCode}</span>
          </CopyToClipboard>
        </div>
      }>
      <Button type='link' style={{ display: 'flex', alignItems: 'center' }} ><ShareAltOutlined style={{ fontSize: 18, marginTop: 2 }} /></Button>
    </Popover>
  </>)
}
