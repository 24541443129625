import React, { Component } from 'react'
import { IntlProvider } from 'react-intl'
import localesBee from './modules/bee'
import moment from 'moment'
import 'moment/locale/zh-cn'
import utils from '@/utils/util'
import LocalStorageUtil from '@/utils/localStorageUtil'
const defaultLang = 'zh-CN'
let localesProject = require('./modules')

moment.locale('zh-cn')

export const { Provider, Consumer } = React.createContext(defaultLang.toLowerCase())
export const LocaleContext = React.createContext(defaultLang.toLowerCase())
/**
 * 国际化组件
 */
export default class Intl extends Component {

  resovleLocale = () => {
    let locale = this.props.locale || utils.getQueryString('locale')
    const cookieLocale = utils.getCookie('locale')
    let zoneNow = moment().utcOffset()
    // 语言优先级
    //   1. 从 url query string 中取
    // 1.5. 从cookie中取
    //   2. 从 localstorage 中拿
    //   3. 从 this.props.locale 中拿
    //   4. 从 浏览器 navigator.languages 中拿
    //   5. 从 浏览器 UI 语言中拿
    if (locale) {
      LocalStorageUtil.setLocale(locale)
    }
    if (!locale && cookieLocale) {
      LocalStorageUtil.setLocale(cookieLocale)
      utils.setCookie('locale', '', -1)
    }

    const localstorageLocale = LocalStorageUtil.getLocale()
    if (localstorageLocale && !locale) {
      locale = localstorageLocale
    }

    if (!locale) {
      let languages = global.navigator.languages || [global.navigator.language || global.navigator.userLanguage || defaultLang]
      locale = languages[0]
    }

    if (locale.toLowerCase().indexOf('zh') > -1) {
      locale = defaultLang
    }
    if (locale.toLowerCase().indexOf('en') > -1) {
      locale = 'en-US'
    }

    if (!localesProject[locale]) {
      locale = defaultLang
    }
    LocalStorageUtil.setLocale(locale)
    return locale
  }

  render() {
    const locale = this.resovleLocale()
    return (
      <IntlProvider locale={locale} key={defaultLang} messages={localesProject[locale]}>
        <LocaleContext.Provider value={locale.toLowerCase()}>
          {React.Children.map(this.props.children, child => React.cloneElement(child, {
            locale: localesBee[locale]
          }))}
        </LocaleContext.Provider>
      </IntlProvider>
    )
  }
}
