
// import Home from './index'

const routes = [
    {
        name: '首页',
        // component: Home,
        componentPath: 'modules/Home/index',
        path: '/home',
        key: 'home'
    }
]

export default {
    routes
}