import React from 'react';
import { Menu, Dropdown, Space } from '@om-tlh/bee';
import { DownOutlined } from '@ant-design/icons'
import utils from 'utils/util'

/**
 * 快速链接组件
 */
class QuickLink extends React.Component {

  render() {
    const { intl } = this.props
    const { formatMessage } = intl

    let quickLinks = []
    if (utils.hasPermission('BUSINESS')) quickLinks.push({ url: process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : '/business', titleId: 'businessend', defaultMessage: '档证通运营控制台' })

    const menu = (
      <Menu>
        {quickLinks.map((i, k) => {
          return (
            <Menu.Item key={k}>
              <a target="_blank" rel="noopener noreferrer" href={i.url}>
                {formatMessage({ id: i.titleId, defaultMessage: i.defaultMessage })}
              </a>
            </Menu.Item>
          )
        })}
      </Menu>
    );
    if (!quickLinks.length) return ''
    return (
      <div>
        <Dropdown menu={{
          items: quickLinks.map((i, k) => {
            return {
              key: k,
              label: <a target="_blank" rel="noopener noreferrer" href={i.url}>
                {i.titleId ? formatMessage({ id: i.titleId }) : i.title}
              </a>
            }
          })
        }} getPopupContainer={() => document.getElementById('content')}>
          <a className="dzt-dropdown-link">
            <Space size={4}>
              {formatMessage({ id: 'quick_links' })}
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
      </div>
    )
  }
}

export default QuickLink;