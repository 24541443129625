import React from 'react';
import config from 'config';
import { Image } from '@om-tlh/bee';
import './index.css'

const ViewSimpleImages = (props) => {
  const { images = [] } = props
  // const images = [
  //   '/1.jpg',
  //   '/2.jpg',
  //   '/3.jpg'
  // ]
  return (
    <>
      <div className="img-list">
        {
          images && images.length
            ? <ul style={{
              listStyle: 'none',
              padding: 0
            }}>
              {
                images.map((i, d) => {
                  return (
                    <li key={d} className="img-li">
                      <Image className='img'
                        src={`${config.img_url}${i}`} alt=""
                        preview={`${config.img_url}${i}`} />
                    </li>
                  )
                })

              }

            </ul>
            : <span className="dzt-form-text">暂无图片</span>
        }

      </div>
    </>
  )
}

export default ViewSimpleImages;