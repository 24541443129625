import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Layout, Popconfirm, Button, Space, Divider } from '@om-tlh/bee';
import './index.css'
import Nav from './nav'
import UserProfile from './components/UserProfile'
import LocalStorageUtil from 'utils/localStorageUtil'
import logo from 'theme/img/logo.png'
import logoSVG from 'theme/img/logo.svg'
import Path from 'routes/path'
import utils from 'utils/util'
import commonService from '@/modules/common/service'
import Share from './components/Share'
import OmaiFooter from './components/Footer'

const { Header, Content, Sider, Footer } = Layout;

/**
 * 收银台
 */
const CashDesk = (props) => {
  const { intl, location, match, routeRegistry } = props;
  const { formatMessage } = intl;

  useEffect(() => {
    if (!routeRegistry.find(i => utils.matchPath(i.path, location.pathname.replace(match.url === '/' ? '' : match.url, '')))) {
      props.history.push(`${match.url === '/' ? '' : match.url, ''}${Path.getPathname(props, Path.pathname['Error404'])}`)
    }
  }, [location.pathname])

  return (<div className="main">
    <Header className="header" style={{
      height: 80,
      lineHeight: '80px', minWidth: 800
    }}>
      <div className="header-right dzt-menu-horizontal" style={{
        // backgroundColor: '#262F3E',
        lineHeight: '80px'
      }}>
        {/* {
                utils.hasPermission('VIEW_MY_INSPECTION')
                  ? <li className="dzt-menu-item">
                    <ReadMessage {...props} />
                  </li>
                  : null
              }
            <Divider key='divider2' type="vertical" /> */}
        {/* <li className="dzt-menu-item">
          <Share />
        </li> */}
        <li className="dzt-menu-item">
          <Popconfirm
            title={'确定退出收银台吗?'}
            onConfirm={() => {
              props.history.push('/')
            }}
            getPopupContainer={() => document.body}
            placement='bottomLeft'
            // onCancel={cancel}
            okText={formatMessage({ id: 'ok' })}
            cancelText={formatMessage({ id: 'cancel' })}
          >
            <Button
              type='link'
            >退出</Button>
          </Popconfirm>
        </li>
      </div>
      <h1 className='header-title' style={{ float: 'left' }}>
        <Space
          split={<Divider type="vertical" />}
        >
          <div className="logo mkt-logo" title={'收银台'}
            style={{ backgroundColor: 'transparent', }}
          >
            <img
              style={{ cursor: 'pointer' }}
              onClick={() => {
                props.history.push('/')
              }}
              className="logo-img" src={logoSVG} alt="" />
          </div>
          <span>收银台</span>
        </Space>
      </h1>
      {/* <PlatformSelect /> */}
    </Header>
    <Content className='main-content'>
      <div className='content' id="content"
        style={{ maxWidth: 1200, margin: 'auto', minWidth: 800 }}
      >
        {props.children}
      </div>
    </Content>
    <Footer style={{ textAlign: 'center', padding: 0, minWidth: 800 }}>
      <OmaiFooter {...props} />
    </Footer>
  </div>)
}

export default injectIntl(CashDesk)
