import axios from 'axios'
import { getService } from '../utils'
import config from '@/config';

const baseURL = config.uc_url
const MODULE_NAME = 'privileges';

const getTenantsDatas = (params) => axios.get(`/v1/${MODULE_NAME}`, { params, baseURL })

export default {
    ...getService(MODULE_NAME),
    getTenantsDatas,
}