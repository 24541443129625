import React, { useEffect, useState } from 'react';
import privilegeService from '../../../Privilege/service'
import utils from 'utils/util'
import { Table, Card } from '@om-tlh/bee'

const SelectPrivilege = React.forwardRef((props, ref) => {
  const { value = [], intl, onChange } = props;
  const { formatMessage } = intl;
  const search = props.location.search;
  const tenantId = utils.getStrQuery(search, 'tenant_id')
  const [privileges, setPrivileges] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [groups, setGroups] = useState([])
  const [groupDatas, setGroupDatas] = useState({})

  useEffect(() => {
    if (!value || !value.length) return
    setSelectedRowKeys(value)
    setGroupDatas(getGroupDatas(privileges.filter(i => value && value.find(j => i.code === j))))
  }, [value, privileges])

  useEffect(() => {
    privilegeService.getTenantsDatas({ page_size: 100 }).then(data => {
      setPrivileges(data.items)
      getPrivileges()
      setGroups(groupBy(data.items))
    })
  }, [])

  const getPrivileges = () => {

  }

  const getGroupDatas = (items) => {
    let obj = {}
    if (!items.length) return obj
    items.forEach(i => {
      if (!obj[i.group_name || 'DEFAULT']) {
        obj[i.group_name || 'DEFAULT'] = []
      }
      obj[i.group_name || 'DEFAULT'].push(i.code)
    })
    return obj
  }

  const groupBy = (items) => {
    let obj = {}
    if (!items.length) return []
    items.forEach(i => {
      if (!obj[i.group_name || 'DEFAULT']) {
        obj[i.group_name || 'DEFAULT'] = []
      }
      obj[i.group_name || 'DEFAULT'].push(i)
    })
    let arr = []
    for (var i in obj) {
      arr.push({
        name: i,
        group: obj[i]
      })
    }
    return arr
  }

  const columns = [
    { title: formatMessage({ id: 'name' }), dataIndex: 'display_name', key: 'display_name', width: '50%' },
    // { title: formatMessage({ id: 'group_name' }), dataIndex: 'group_name', key: 'group_name' },
    // { title: formatMessage({ id: 'code' }), dataIndex: 'code', key: 'code' },
    { title: formatMessage({ id: 'description' }), dataIndex: 'description', key: 'description' },
  ]

  const onSelectChange = (selected, selectedRows, groupName) => {
    const newGroupDatas = {
      ...groupDatas,
      [groupName]: selected
    }
    setGroupDatas(newGroupDatas)
    let arr = []
    for (var i in newGroupDatas) {
      arr = [...arr, ...newGroupDatas[i]]
    }
    const submitVal = Array.from(new Set(arr))
    setSelectedRowKeys(submitVal)
    onChange(submitVal)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <>
      {
        groups.map((i, d) => {
          return (
            <Card title={i.name} key={d} size='small' bodyStyle={{ padding: 0 }} style={{ marginBottom: 5 }}>
              <Table bordered={false} rowKey='code' size='small' rowSelection={{
                selectedRowKeys,
                onChange: (selected, selectedRows) => onSelectChange(selected, selectedRows, i.name),
              }} pagination={false} dataSource={i.group} columns={columns} />
            </Card>
          )
        })
      }

    </>
  )
})

export default SelectPrivilege;