import React from 'react';
import { withRouter } from 'react-router-dom';

/**
 * 路由切换页面滚动到顶部
 */
class ScrollToTop extends React.Component {

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0)
      if (document.getElementById('contentWrap') && document.getElementById('contentWrap').scrollTo)
        document.getElementById('contentWrap').scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children;
  }

}

export default withRouter(ScrollToTop)