import axios from 'axios'
const MODULE_NAME = 'style_template';
const MODULE_NAME_GROUP = 'team/style_template';

const getDatas = (params) => axios.get(`/v1/${MODULE_NAME_GROUP}`, { params })
const createData = (body) => axios.post(`/v1/${MODULE_NAME_GROUP}`, body)
const getDataDetail = (id) => axios.get(`/v1/${MODULE_NAME_GROUP}/${id}`)
const updateData = (id, body) => axios.put(`/v1/${MODULE_NAME_GROUP}/${id}`, body)
const deleteData = (id) => axios.delete(`/v1/${MODULE_NAME_GROUP}/${id}`)
const batchDeleteData = (ids) => axios.delete(`/v1/${MODULE_NAME_GROUP}`, { params: { ids } })

const shareData = (id, params) => axios.put(`/v1/${MODULE_NAME}/share/${id}`, {}, { params })
const upData = (id, body) => axios.put(`/v1/${MODULE_NAME}/up/${id}`, body)

export default {
  getDatas,
  createData,
  getDataDetail,
  updateData,
  deleteData,
  batchDeleteData,

  shareData,
  upData,
}