import React from 'react'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl';
import { Menu } from '@om-tlh/bee'
import Icon, {
  SettingOutlined, HomeOutlined,
  BarChartOutlined, SolutionOutlined,
  CloudServerOutlined, AlertOutlined,
  FileTextOutlined, HddOutlined,
  CameraOutlined, ReconciliationOutlined, UserOutlined,
  WarningOutlined, BellOutlined, CodeOutlined, ContainerOutlined
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import utils from '@/utils/util'

const menus = () => {
  const HomeSvg = () => (<svg viewBox="0 0 16 16" fill="currentColor" width="1em" height="1em" >
    <path d="M16,16H10V11.133H6V16H0V5.293A15.21,15.21,0,0,0,3,3.346,14.63,14.63,0,0,0,5,1.4,3.441,3.441,0,0,1,6,.426a4.761,4.761,0,0,1,4,0c1.423,2.051,3.483,2.811,5,4.867h1V16Z" />
  </svg>)
  const HomeIcon = props => <Icon component={HomeSvg} {...props} />

  const ProfileSvg = () => (<svg viewBox="0 0 17 16" fill="currentColor" width="1em" height="1em">
    <path d="M17,12.8V17H0V12.8l2-2.1c0.368-.386.848-0.829,1-1.05A2.328,2.328,0,0,1,4,8.6a1.517,1.517,0,0,1,1,0,6.317,6.317,0,0,1-1-1.05,5.377,5.377,0,0,1-1-2.1A4.1,4.1,0,0,1,4,2.3,5.742,5.742,0,0,1,7,.2a5.459,5.459,0,0,1,3,0,5.646,5.646,0,0,1,2,1.05A5.843,5.843,0,0,1,13,2.3h0a4.214,4.214,0,0,1,1,3.151A4.116,4.116,0,0,1,12,8.6h1l1,1.05Z" />
  </svg>)
  const ProfileIcon = props => <Icon component={ProfileSvg} {...props} />
  const TeamSvg = () => (<svg viewBox="2 2 15 15" fill="currentColor" width="1em" height="1em">
    <path d="M11.2,8.692a7.382,7.382,0,0,1,4.25,6.758H3.55A8.306,8.306,0,0,1,7.8,8.692L6.95,7.848A1.907,1.907,0,0,1,6.1,7a1.942,1.942,0,0,1,0-1.689L6.95,3.624a3.936,3.936,0,0,1,3.4-.845,3.149,3.149,0,0,1,1.7.845,4.328,4.328,0,0,1,.85,1.689h0A2.505,2.505,0,0,1,12.9,7a2.487,2.487,0,0,1-.85.845A5.616,5.616,0,0,0,11.2,8.692Zm-5.1,0a8.76,8.76,0,0,1-.85.845c-0.512.443-1,.684-1.432,1.127a4.484,4.484,0,0,0-1.118,3.1H1A5.5,5.5,0,0,1,3.55,8.692H4.4a1.337,1.337,0,0,0,0-.845A1.385,1.385,0,0,0,3.55,7a2.684,2.684,0,0,1,0-2.534A2.4,2.4,0,0,1,4.4,3.624H6.1V4.469h0l-0.85.845V7h0a1.514,1.514,0,0,0,0,.845c0.21,0.17.637-.168,0.85,0A0.872,0.872,0,0,1,6.1,8.692ZM18,13.761H16.3l-0.85-2.534h0l-1.7-1.689h0a3.863,3.863,0,0,1-.85-0.845,1.341,1.341,0,0,1,0-.845h0.85V7c0-.3-0.015-0.7,0-0.845a1.833,1.833,0,0,0,0-.845,1.548,1.548,0,0,0-.85-0.845V3.624h1.7l0.85,0.845c0.392,1.124.44,2.009,0,2.534a3.077,3.077,0,0,0-.85.845,1.244,1.244,0,0,0,0,.845h0.85A5.5,5.5,0,0,1,18,13.761Z" />
  </svg>)
  const TeamIcon = props => <Icon component={TeamSvg} {...props} />
  const InferenceSvg = () => (<svg viewBox="0 0 19 19" fill="currentColor" width="1em" height="1em">
    <path d="M1,0H6V1H2S2,2,2,2H1V6H0V1H1S1,0.1,1,0ZM12,0h6V1h1V6H18V2H17s0-.95,0-1H12V0ZM3,8v3H6V8H7S7,9,7,9H8c0-.119,0-1,0-1H8L9,7H9s3,0,3,0h0s0,1,0,1H9s0,3,0,3h3v1H9L8,11V10H7v1H7L6,12H4L2,11H2V8L3,7H6L7,8H3ZM13,7h3l1,1a1.592,1.592,0,0,1,0,1,1.635,1.635,0,0,1-1,1h0l1,2h0a2.181,2.181,0,0,1-1,0c-0.616-.259-1.01-2.047-1-2H14v2H13V7Zm1,1V9h2a1.026,1.026,0,0,0,0-1,1.206,1.206,0,0,0-1,0H14ZM2,18H6v1H1c0-.662,0-1,0-1H0V13H1v4H2S2,17.9,2,18Zm16-5h1v5a1.026,1.026,0,0,0-1,0,1.9,1.9,0,0,0,0,1H12V18h5V17h1V13ZM1,17l0.015-1.922Z" />
  </svg>)
  const InferenceIcon = props => <Icon component={InferenceSvg} {...props} />
  const OrderSvg = () => (<svg viewBox="0 0 15 16" fill="currentColor" width="1em" height="1em">
    <path d="M5,0h5V1H5V0ZM1,1H4S4,2,4,2H5S5,3,5,3h5V2h1s0-1,0-1h3V15H1V1ZM3,4V6h9V4H3ZM3,7V9h9V7H3Zm0,3v2h9V10H3Z" />
  </svg>)
  const OrderIcon = props => <Icon component={OrderSvg} {...props} />
  const TMSvg = () => (<svg viewBox="0 0 17 17" fill="currentColor" width="1em" height="1em">
    <path d="M8,17H0c0-.073,0-2,0-2H0s0.807-.631,1.55-1.115A13.76,13.76,0,0,1,6,12.009,6.542,6.542,0,0,0,5,9.015a7.214,7.214,0,0,0-2-2H3s0,0,0-2A5.166,5.166,0,0,1,4,2.028a4.9,4.9,0,0,1,1-1,5.708,5.708,0,0,1,4-1l2,2h0s0.744,2.036,1,2.995a1.7,1.7,0,0,1,0,1,2.176,2.176,0,0,1-1.077,1.1A9.521,9.521,0,0,0,9,9.015H9l-1,1H8s0,1.22,0,1.843V17Zm4-6.987v2h3v-2h1v2l1,1V17H10V13.007h0a3.791,3.791,0,0,0,1-2c0.031-.226,0-1,0-1h0l1-1h3l1,1H12Z" />
  </svg>)
  const TMIcon = props => <Icon component={TMSvg} {...props} />
  const TBSvg = () => (<svg viewBox="0 0 18 17" fill="currentColor" width="1em" height="1em">
    <path d="M8,0h3c0,0.708,0,2,0,2H10s0,1.975,0,3h6V16H3V5H9C9,3.96,9,2,9,2H8S8,0.708,8,0ZM5,8v2H8V8H5Zm6,0v2h3V8H11ZM0,9H1v4H0V9Zm7,2v3h5V11H7ZM17,9s0,4,0,4h1s0-4,0-4H17Z" />
  </svg>)
  const TBIcon = props => <Icon component={TBSvg} {...props} />
  return [
    {
      key: 'home', name: "首页", iconComponent: <HomeIcon />,
    },
    {
      key: 'person', name: "个人中心", iconComponent: <ProfileIcon />,
      children: [
        {
          key: 'file_manage', name: "我的文件",
        },
        {
          key: 'model_manage', name: "我的模板",
        },
        {
          key: 'document_manage', name: "我的档案",
        },
      ]
    },
    {
      key: 'team', name: "团队中心", iconComponent: <TeamIcon />,
      permission: 'VIEW_STYLE_TEMPLATE,VIEW_CONTENT_TEMPLATE,VIEW_CATALOG,VIEW_FILE,VIEW_DOCUMENT',
      children: [
        { key: 'file_team_manage', name: "团队文件", permission: 'VIEW_CATALOG,VIEW_FILE' },
        { key: 'model_team_manage', name: "团队模板", permission: 'VIEW_STYLE_TEMPLATE,VIEW_CONTENT_TEMPLATE' },
        { key: 'document_team_manage', name: "团队档案", permission: 'VIEW_CATALOG,VIEW_DOCUMENT' },
        { key: 'up_manage', name: "入库审批", permission: 'VIEW_UP_RECORD' },
        { key: 'issue_manage', name: "调阅审批", iconComponent: <HomeIcon />, permission: 'VIEW_ISSUE_RECORD' },
      ]
    },
    {
      key: 'inference_manage', name: "识别任务", iconComponent: <InferenceIcon />,
    },

    // {
    //   key: 'order_invoice', name: "订单管理", iconComponent: <OrderIcon />,
    //   permission: 'VIEW_ORDER,VIEW_INVOICE',
    //   children: [
    //     {
    //       key: 'order_manage', name: "我的订单",
    //       permission: 'VIEW_ORDER'
    //     }, {
    //       key: 'invoice_manage', name: "我的发票",
    //       permission: 'VIEW_INVOICE'
    //     }
    //   ]
    // },
    {
      key: 'team_member_manage', name: "团队管理",
      // permission: 'MGT_TEAM',
      iconComponent: <TMIcon />
    },
    { key: 'ai_tool_box', name: "工具箱", iconComponent: <TBIcon /> },
    // { key: 'knowledge_base_qa', name: "问答知识库", iconComponent: <TBIcon /> },
    // { key: 'knowledge_base_doc', name: "文档知识库", iconComponent: <TBIcon /> },
    // { key: 'chat_setting_manage', name: "智能助手设置", iconComponent: <TBIcon /> },
    // { key: 'chat_setting_info', name: "设置身份介绍", iconComponent: <TBIcon /> },
  ]
}
export const menu = menus()
/**
 * 导航
 */
@connect(({ router: { location: { query } } }) => {
  return {
    query
  }
})
class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openKeys: this.getDefaultOpenKeys(props),
      selectedKeys: this.getDefaultSelectedKeys(props),
      getDefaultOpenKeys: this.getDefaultOpenKeys,
      getDefaultSelectedKeys: this.getDefaultSelectedKeys,
      pathname: props.location.pathname
    }
    this.rootSubmenuKeys = this.menus().map(item => item.key)
  }
  static getDerivedStateFromProps(nextProps, { pathname, ...state }) {
    if (nextProps.location.pathname !== pathname) {
      return {
        openKeys: state.getDefaultOpenKeys(nextProps),
        selectedKeys: state.getDefaultSelectedKeys(nextProps),
        pathname: nextProps.location.pathname
      }
    }
    return null
  }
  getPath = (item) => {
    const { children = {} } = this.props
    return (children.find(itm => itm.key === item.key) && children.find(itm => itm.key === item.key).props.path) || '/home'
  }

  getDefaultSelectedKeys = (props) => {
    props = props || this.props
    const { location = {}, routeRegistry } = props
    const selectPathObj = routeRegistry.find(i => utils.matchPath(i.path, location.pathname)) || {}
    return props.query && props.query.navKey ? [props.query.navKey] : [(selectPathObj && (selectPathObj.parentKey || selectPathObj.key)) || '']
  }

  getDefaultOpenKeys = (props) => {
    const menus = this.menus()
    let selectedKeys = ''
    menus.forEach(i => {
      if (i.children && i.children.find(j => j.key === this.getDefaultSelectedKeys(props)[0])) {
        selectedKeys = i.key
      }
    })
    return [selectedKeys]
  }
  onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };
  menus = menus;
  render() {
    const { intl: { formatMessage } } = this.props
    const items = this.menus().filter(item => !(item.permission && !utils.hasPermission(item.permission))).map((item, index) => {
      let result = {
        key: item.key,
        label: <span
          title={item.name}>
          {item.iconComponent && item.iconComponent}
          <span>{item.name}</span>
        </span>,
        title: item.name,
      }
      if (item.children?.length) {
        result.label = <span title={item.name}>
          {item.iconComponent && item.iconComponent}
          <span>{item.name}</span>
        </span>
        result.children = item.children.filter(i => i.permission ? utils.hasPermission(i.permission) : true).map((itm, idx) => {
          return {
            key: itm.key,
            label: <span
              title={typeof itm.name !== 'string' ? formatMessage({ id: itm.name.props.id }) : itm.name}>{itm.name}</span>,
            title: itm.name
          }
        })
      }
      return result
    })
    return (
      <Menu
        style={{ backgroundColor: '#fff' }}
        theme={this.props.theme || "light"}
        openKeys={this.state.openKeys}
        onOpenChange={this.onOpenChange}
        selectedKeys={this.state.selectedKeys}
        onClick={({ key, ...rest }) => {
          const props = this.props
          const { children = [], location = {} } = props
          const selectPathObj = children.find(i => i.props && utils.matchPath(i.props.path, location.pathname)) || {}
          if (key === selectPathObj.key) {
            return
          }
          this.setState({ selectedKeys: [key] })
          this.props.history.push(this.getPath({ key }))
        }}
        collapsed="true"
        mode={this.props.mode || "inline"}
        items={items}
      />
    )
  }
}

export default injectIntl(Nav)
