// 二维码中间有Logo
import React, { useEffect, useState, useRef } from 'react'
import { Image, Button, message, Popover } from '@om-tlh/bee'
import Icon from '@ant-design/icons'
import { QRCodeCanvas } from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { downloadFile } from '@/utils/utils'
import { getInvitationCode, getInvitationNumber } from './service'
import logoSrc from '@/theme/img/logo-qrcode.png'
import mpImg from '@/theme/img/mp.jpg'

export default function (props) {

  const MpSvg = () => (<svg viewBox="0 0 62 62" fill="currentColor" width="1em" height="1em" >
    <path d="M53.35,8.215c0.766,2.666,1.575,3.282,3.02,5.119h1.007c1.961,3.015,6.185,17.806,4.026,24.573H60.4L59.389,44.05H58.383V46.1l-2.013,1.024V49.17l-6.04,5.119-2.013,3.072L44.29,58.385v1.024H42.277v1.024H38.251c-2.574.828-11.017,2.386-15.1,1.024V60.432l-9.059-3.072-1.007-2.048H11.073l-3.02-4.1H7.046V49.17L5.033,48.146V46.1H4.026L3.02,42H2.013V38.931H1.007V33.812H0V27.668H1.007V22.549H2.013V19.477H3.02V16.406l2.013-1.024V13.334L7.046,12.31V10.263l4.026-3.072V6.167h2.013l1.007-2.048h2.013V3.1h2.013V2.072h2.013V1.048h3.02V0.024h6.04V-1h4.026V0.024h6.039V1.048h3.02V2.072L46.3,3.1V4.119h2.013V5.143C50.123,6.608,50.723,7.452,53.35,8.215ZM25.165,3.1V4.119l-6.04,1.024V6.167H17.112L16.106,8.215H14.092l-3.02,4.1L9.059,13.334v2.048L7.046,16.406c-1.179,1.847.005,2.307-2.013,4.1-0.412,6.069-2.387,14.505,0,20.477,3.692,9.234,17.161,21.052,31.2,16.382h4.026V56.337l4.026-1.024V54.289H46.3l1.007-2.048h2.013V51.217l3.02-2.048V47.122L54.356,46.1V44.05h1.007c1.535-2.884,4.671-12.986,3.02-18.43H57.376v-4.1H56.369V19.477H55.363l-1.007-4.1-2.013-1.024V12.31H51.336L49.323,9.239H47.31V8.215L44.29,7.191,43.284,5.143C39.054,2.89,31.637,2.951,25.165,3.1ZM24.158,29.716v3.072h-3.02c-1.595,2.8-2.76,2.5-3.02,7.167,2.568,1.423,3.124,2.643,7.046,3.072V42h2.013V40.979l2.013-1.024V20.5c1.146-1.166.4,0,1.007-2.048,1.813-.967,1.585-1.418,4.026-2.048,2.32-2.254,7.475-.56,10.066,0L45.3,19.477H46.3v2.048H47.31c1.721,5.7-4.787,9.884-9.059,10.239l-1.007-2.048c1.384-.7,1.291-1.538,2.013-2.048,3.682-2.6,4.025-.421,4.026-7.167l-3.02-1.024V18.454H35.231c-0.6,1.213-1.732,1.57-2.013,2.048v2.048H32.211v5.119c-0.118,8.071,1.474,15.63-5.033,17.406V46.1c-2.055,1.253-8.084-.367-9.059-1.024L16.106,42H15.1V33.812c2.142-1.241,2.191-2.306,5.033-3.072V29.716h4.026Z" />
  </svg>)
  const MpIcon = props => <Icon component={MpSvg} {...props} />

  // const [url, setUrl] = useState()
  // const [displayName, setDisplayName] = useState(encodeURIComponent(window.location.origin + window.location.pathname))
  // const [invitationCode, setInvitationCode] = useState()
  // const [number, setNumber] = useState()

  // const fetchInvitationNumber = async () => {
  //   const res = await getInvitationNumber()
  //   setNumber(res)
  // }

  // useEffect(() => {
  //   const fetchInvitationCode = async () => {
  //     // new Promise((resolve, reject) => {
  //     //   setTimeout(() => {
  //     //     resolve(`DI09202`)
  //     //   }, 1000)
  //     // })
  //     getInvitationCode().then(res => {
  //       setInvitationCode(res)
  //       setUrl(`${window.location.origin}${window.__IN_CLOUD__ ? `/${window.appName}` : ''}/regist/${res}`)
  //     })
  //   }
  //   fetchInvitationCode()
  //   fetchInvitationNumber()
  // }, [])

  // const handleQRCodeDownload = () => {
  //   const canvas = document.getElementById('shareRef')
  //   const imgSrc = canvas.toDataURL('image/png')
  //   downloadFile(imgSrc, '档证通小程序码.png')
  // }

  // if (!url) {
  //   return null
  // }
  return (<>
    <Popover
      overlayStyle={{ width: 200 }}
      // trigger='click'
      content={<div>
        <div style={{ textAlign: 'center' }}>
          {/* <QRCodeCanvas
            id={'shareRef'}
            value={mpImg}
            size={200}
            level='H'
            includeMargin
            imageSettings={{
              src: logoSrc,
              width: 40,
              height: 40
            }}
          /> */}
          <Image src={mpImg} preview={false} />
        </div>
      </div>}
      title={
        <div style={{ textAlign: 'center', margin: '10px auto' }}>
          <p style={{ marginBottom: 5 }}>使用手机微信 “扫一扫”</p>
        </div>
      }
    >
      <Button type='link' style={{ display: 'flex', alignItems: 'center' }} ><MpIcon style={{ fontSize: 18, marginTop: 2 }} /></Button>
    </Popover>
  </>)
}
