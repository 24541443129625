import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Form, Input, Button, message } from '@om-tlh/bee';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import LocalStorageUtil from 'utils/localStorageUtil'
import service from './service'
import DragTitle from 'components/DragTitle'
import { base64Encode } from 'utils/base64'

/**
 * 登录组件
 */
const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const login = (params) => {
    setLoading(true)
    return service.login(params)
  }

  const handleSubmit = values => {
    login(values).then(loginSuccessCallback).catch(loginErrorCallback)
  };

  const loginSuccessCallback = (data) => {
    const { data: obj } = data
    const { roles = [] } = obj.user_info
    let privileges = []
    roles && roles.forEach(i => {
      privileges.push(...(i.privileges || []))
    })
    LocalStorageUtil.setAuth(obj.token)
    LocalStorageUtil.setUserInfo(JSON.stringify(obj.user_info))
    LocalStorageUtil.setPrivileges(base64Encode(JSON.stringify(Array.from(new Set(privileges)))))
    message.success('登录成功')
    setLoading(false)
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }

  const loginErrorCallback = (error) => {
    setLoading(false)
    message.error((error.response && error.response.data.message) || '登录失败')
  }

  return (
    <>
      <a onClick={() => setVisible(true)}>登录</a>
      <Modal
        okButtonProps={{ shape: 'round' }}
        cancelButtonProps={{ shape: 'round' }}
 open={visible} title={<DragTitle title='用户登录' className='detail_modal' />}
        wrapClassName='detail_modal'
        getContainer={() => document.body}
        onCancel={() => setVisible(false)} footer={null}>
        {/* <Spin spinning={loading}> */}
        <Form
          initialValues={{
            login_name: 'omaiuser',
            password: 'OMAI@6A4F'
          }}
          onFinish={handleSubmit} className="login-form">
          <Form.Item name='login_name'
            rules={[{ required: true, message: '请输入用户名', whitespace: true }]}
          >
            <Input size='large'
              prefix={<UserOutlined style={{ fontSize: 20 }} />}
              placeholder='用户名'
            />
          </Form.Item>
          <Form.Item name='password'
            rules={[{ required: true, message: '请输入密码', whitespace: true }]}
          >
            <Input size='large'
              prefix={<LockOutlined style={{ fontSize: 20 }} />}
              type="password"
              placeholder='密码'
            />
          </Form.Item>
          <Button shape='round' size='large' type="primary" htmlType="submit" className="login-form-button">登录</Button>
        </Form>
        {/* </Spin> */}
      </Modal>
    </>
  )

}

export default injectIntl(Login)