import React, { useEffect, useState, useRef } from 'react';
import { injectIntl } from 'react-intl';
import {
  Form, Input, Button, Transfer, Row, Col, Tag, Result,
  Select, Modal, Divider, message, Steps, Card, Radio, Descriptions
} from '@om-tlh/bee';
import { ZoomInOutlined, PlusOutlined, ZoomOutOutlined, BorderOutlined, AppstoreOutlined } from '@ant-design/icons';
import { getQueryStringByName } from '@/utils/utils'
import * as personalService from './service'
import * as teamService from './service_group'
import tagService from '@/modules/Tag/service'
import * as OSSService from '@/modules/OSS/service'
import commonService from '@/modules/common/service'
import { getOptions, COMPANY_TYPE } from '@/utils/enum'
import UploadAttachments from '@/components/Upload/ImageWallOSS'
import Nodata from '@/components/Nodata'
import ModelList from '@/modules/Model/list';
import Path from '@/routes/path';
import { TweenOneGroup } from 'rc-tween-one';
import img from '@/theme/img/guoxin.jpg';
import OCR from '@/components/OCR'
import * as OCRService from '@/components/OCR/service'
import AddTags1 from '@/components/AddTags'
import SelectModel from '@/components/ModelSelect';
import axios from 'axios'
import utils from '@/utils/util'

const CancelToken = axios.CancelToken;
let source = null;

const recognizeTypes = [
  { label: '不做识别', value: '2' },
  { label: '标准样式识别', value: '0' },
  { label: '自由样式识别', value: '1' },
]

const { Option } = Select;
const { Step } = Steps;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 18 },
};


export async function limitTipModal(yes) {
  if (!yes) {
    Modal.warn({
      title: '提示',
      content: <div>
        您套餐的余额不足，请<Button type='link'><a onClick={() => {
          Modal.destroyAll()
          window.location.href = `${window.location.origin}${window.__IN_CLOUD__ ? `/${window.appType ? [...window.appType.split('/'), `${window.appName}`].filter(Boolean).join('/') : `${window.appName}`}` : (window.appType ? `/${window.appType}` : ``)}/buy/manage`
        }}
        >升级账户</a> </Button>
      </div>
    });
    return Promise.reject()
  }
  return Promise.resolve()
}

export const getUploadSession = (file) => {
  let params = {}
  params.assetType = file.type.endsWith('pdf') ? 'PDF' : ('PICTURE')
  params.objectName = encodeURIComponent(file.name)
  params.contentType = file.type
  return OSSService.getUploadAccess(params)
}

const Create = (props) => {
  const [form] = Form.useForm();
  const id = getQueryStringByName('id')
  const catalogId = getQueryStringByName('catalogId') || ''
  const belongType = getQueryStringByName('belongType') || ''
  const service = belongType === 'TEAM' ? teamService : personalService

  const [values, setValues] = useState({});
  const [current, setCurrent] = useState(0);

  const [isLoading, setIsLoading] = useState(false)
  const [imageList, setImageList] = useState([])
  const [ocrKey, setOcrKey] = useState(`${Date.now()}`)

  const [recognizeType, setRecognizeType] = useState(`${getQueryStringByName('recognizeType') || '0'}`)
  const handleImages = (file, fields = []) => {
    return file.file_pages.map(x => ({
      file_id: file.file_id,
      ...x,
      fields: [...(x.fields ?? []), ...fields.map(x => ({
        ...x,
        // labelName: x.key,
        // key: x.description,
        // description: x.text
      }))],
    }))
  }
  // useEffect(() => {
  //   setTimeout(() => {
  //     Promise.all(mockData.files.map(x => service.getDataDetail(x.id || x.file_id))).then(([file]) => {
  //       // setImageList(handleImages(file))
  //       setImageList(handleImages(mockData.files[0]))
  //     })
  //   }, 1000);
  // }, [])

  useEffect(() => {
    const fetchData = async () => {
      const res = await service.getDataDetail(id)
      const vals = {
        ...res,
        address: res.address || res.detail_address ? res.address + ' ' + res.detail_address : '',
      }
      setValues(vals)
      form.setFieldsValue(vals)
    }
    if (!id) return
    fetchData()
  }, [])


  const onFinish = values => {
    return
    if (id) {
      service.updateData(id, values).then(data => {
        props.history.push(Path.getPathname(props, Path.pathname['file']['home']));
        message.success('编辑数据成功')
      }).catch(error => {
        message.error(error.response && error.response.data.message)
      })
    } else {
      service.createData(values).then(data => {
        props.history.push(Path.getPathname(props, Path.pathname['file']['home']));
        message.success('创建数据成功')
      }).catch(error => {
        message.error(error.response && error.response.data.message)
      })
    }
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const submit = () => {
    form.validateFields().then(vals => {
      // console.log('vals: ', vals);
      // console.log('values: ', values);
      let body = {
        details: values.files?.[0]?.fields.map(x => ({ key: x.key, description: x.description, text: x.text, nth: x.nth, position: x.position, page_num: x.pageNumber })) ?? values.details,
        content_template_id: vals.content_template_id,
        display: vals.content_template_name
      }
      if (body.details.some(x => !x.text)) {
        return message.error('字段列的字段值均不能为空')
      }
      OCRService.customRecognize(imageList[0]?.file_id, body).then(() => {
        setIsLoading(false)
        message.success('提交成功')
        // setCurrent(2)
        props.history.push('/document/manage')
      }).catch(error => {
        message.error(error.response?.data?.message ?? JSON.stringify(error))
      })
    })
  }

  const next = async () => {
    const vals = await form.validateFields()
    // console.log('vals: ', vals);
    const filelist = vals.files
    let queue = filelist.map(x => ({ ...x, "label_ids": vals.tags }))
    let fileCount = queue.length
    let pointer = 0
    let result = []
    if (recognizeType === '0' && !queue.every(x => x.type.includes('image/') || x.type.includes('/pdf'))) {
      return message.error('文件类型需要是图片或PDF的')
    }
    if (recognizeType === '1' && queue.length === 1 && !queue.every(x => x.type.includes('image/') || x.type.includes('/pdf'))) {
      return message.error('文件类型需要是图片或PDF的')
    } else if (recognizeType === '1' && queue.length > 1) {
      return message.error('自由样式识别时只能选择一张图片')
    }

    // 检查配额
    setIsLoading(true)
    commonService.checkLimit('LABEL_NUM', vals.tags?.length ?? 0)
      .then(limitTipModal)
      .then(async () => {
        return await commonService.checkLimit('FILE_NUM', filelist.length)
      })
      .then(limitTipModal)
      .then(async () => {
        return await commonService.checkLimit('STORAGE_SPACE', filelist.map(x => x.size).reduce((a, b) => a + b))
      })
      .then(limitTipModal)
      .then(func)
      .catch((err) => {
        err && message.error(err.response?.data?.message ?? err.message ?? JSON.stringify(err))
        setIsLoading(false)
      })
    function func() {
      setIsLoading(true)
      queueUpload(queue, pointer, fileCount, result, (err, result) => {
        if (!err) {
          if (recognizeType === '0') {
            return OCRService[belongType === 'TEAM' ? 'teamTemplateRecognize' : 'templateRecognize'](result.map(x => x.id), {
              styleTemplateId: vals.style_template_id,
              contentTemplateId: vals.content_template_id,
            }).then(() => {
              setIsLoading(false)
              message.success('提交成功')
              props.history.push('/inference/manage')
            })
          }
          if (recognizeType === '2') {
            setIsLoading(false)
            message.success('提交成功')
            return belongType === 'PERSONAL' ? props.history.push(`manage?catalogId=${catalogId}`) : props.history.push(`${Path.getPathname(props, Path.pathname['file']['team'])}?catalogId=${catalogId}`);
          }
          if (recognizeType === '1') {
            message.success('文件添加成功')
            return Promise.all(result.slice(0, 1).map(x => service.getDataDetail(x.id))).then(([file]) => {
              setIsLoading(false)
              setImageList(handleImages(file, values.details))
              setCurrent(current + 1);
              setOcrKey(`${Date.now()}`)
            })
          }
          return
        }
        if (err.response?.data?.code === 'EX.7051') {
          setIsLoading(false)
          return Modal.warn({
            title: '提示',
            content: <div>
              {err.response?.data?.message ?? '您的套餐已到期'}，请<Button type='link'><a onClick={() => {
                Modal.destroyAll()
                window.location.href = `${window.location.origin}${window.__IN_CLOUD__ ? `/${window.appType ? [...window.appType.split('/'), `${window.appName}`].filter(Boolean).join('/') : `${window.appName}`}` : (window.appType ? `/${window.appType}` : ``)}/buy/manage`
              }}
              >续费</a> </Button>
            </div>
          });
        }
        message.error(err.response?.data?.message ?? '操作失败')
        setIsLoading(false)
      })
    }
  };

  function queueUpload(list, pointer, count, result, cb) {
    const cur = list.shift()
    if (!cur) {
      return cb?.(null, result)
    }
    uploadFile(cur, pointer, count, result, (error, result) => {
      if (!error) {
        pointer++
        queueUpload(list, pointer, count, result, cb)
        return
      }
      cb(error)
    })
  }

  function uploadFile(file, pointer = 0, count = 1, result, callback) {
    source = CancelToken.source()
    getUploadSession(file).then(uploadSession => {
      const actionUrl = uploadSession.upload_url || ''

      axios({
        url: actionUrl,
        method: 'PUT',
        data: file.originFileObj,
        headers: {
          'Content-Type': file.originFileObj.type,
        },
        transformRequest: [function (data, headers) {
          // Do whatever you want to transform the data
          form.setFieldsValue({
            'files': form.getFieldValue('files').map(x => x.uid === file.uid ? {
              ...x,
              percent: 0.05,
              status: 'uploading'
            } : x)
          })
          return data;
        }],
        onUploadProgress: (e) => {
          const percent = parseInt(e.loaded / e.total * 100)
          // console.log('percent: ', percent);
          form.setFieldsValue({
            'files': form.getFieldValue('files').map(x => x.uid === file.uid ? {
              ...x,
              percent: percent < 0.05 ? 0.05 : percent,
              status: percent > 99 ? 'success' : 'uploading'
            } : x)
          })
        },
        cancelToken: source.token,
        unAuth: true,
        timeout: 0,
      })
        .then(() => {
          const newFile = {
            "display_name": file.name,
            "file_name": file.name,
            "file_url": uploadSession.access_url,
            "label_ids": file.label_ids,
            "source_type": "UPLOAD",
            "catalog_id": catalogId || ''
          }
          service.createData((belongType && belongType.toLowerCase()) || 'personal', newFile)
            .then((res) => {
              result = [...result, {
                ...newFile,
                ...res,
              }]
              callback?.(null, result)
            }, (error) => {
              callback?.(error)
              message.error(error?.response?.data?.message ?? JSON.stringify(error.message));
            });
        }, (error) => {
          console.log('form.getFieldValue( ', form.getFieldValue('files'));
          form.setFieldsValue({
            'files': form.getFieldValue('files').map(x => x.uid === file.uid ? {
              ...x,
              percent: 0,
              status: 'error'
            } : x)
          })
          message.error(error?.response?.data?.message ?? JSON.stringify(error.message));
        });
    }).catch((error) => {
      callback?.(error)
    })
    return
  }

  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      title: '选择文件及识别类型',
      content: 'First-content',
    },
    {
      title: '文件识别',
      content: 'Second-content',
    },
    {
      title: '提交至档案',
      content: 'Last-content',
    },
  ];

  return (
    <>
      <div className="content-header">
        <h2>上传文件</h2>
      </div>
      <div className="content-body">
        {
          recognizeType === '1' && <>
            <Steps current={current}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <Divider />
          </>
        }

        <div style={{ display: current === 0 ? 'block' : 'none' }}>
          <Form
            {...layout}
            form={form}
            name="basic"
            initialValues={{
              recognizeType: recognizeType,
              files: [],
              tags: [],
              // tags: Array(3).fill(0).map((i, d) => `标签${d + 1}`),
              source_type: 'upload'
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="选择文件"
              name="files"
              rules={[{
                required: true, message: '请选择文件'
              }]}
            >
              <UploadAttachments
                multiple={recognizeType === '2'}
                type={recognizeType === '2' ? '*' : ''}
                accept={recognizeType === '2' ? '*' : 'image/jpeg,image/png,application/pdf'}
              />
            </Form.Item>
            <Form.Item
              // {...tailLayout}
              label="识别类型"
              name="recognizeType"
              rules={[{
                required: true, message: '请选择识别类型'
              }]}
            >
              <Radio.Group options={recognizeTypes}
                onChange={evt => {
                  setRecognizeType(evt.target.value)
                }}
              />
            </Form.Item>
            {
              (recognizeType === '0') && <>
                <Form.Item
                  // style={{display: 'none'}}
                  label="标准样式模板"
                  name="style_template_id"
                  rules={[{
                    required: true, message: '请选择标准样式模板'
                  }]}
                >
                  <SelectModel {...props} values={values} setValues={
                    (vs) => {
                      console.log('vs: ', vs);
                      const vals = {
                        ...vs,
                        style_template_name: vs.display_name,
                        content_template_id: vs.content_templates?.[0]?.content_template_id ?? vs.content_template_id,
                        content_template_name: vs.content_templates?.[0]?.display_name ?? vs.display_name
                      }
                      setValues(vals)
                      form.setFieldsValue(vals)
                    }
                  } hideContentTemplate={(0, true)} templateType="style_template" />
                </Form.Item>
                <Form.Item
                  style={{ display: 'none' }}
                  name="style_template_name"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  style={{ display: 'none' }}
                  label="自由样式模板"
                  name="content_template_id"
                // rules={[{
                //   required: true, message: '请选择自由样式模板'
                // }]}
                >
                  <SelectModel {...props} values={values} setValues={
                    (vs) => {
                      form.setFieldsValue({ ...vs })
                      setValues(vs)
                    }
                  } hideStyleTemplate={(0, true)} templateType='content_template' />
                </Form.Item>
              </>
            }
            {
              (recognizeType === '1') && <>
                <Form.Item
                  label="自由样式模板"
                  // style={{ display: 'none' }}
                  name="content_template_id"
                // rules={[{
                //   required: true, message: '请选择自由样式模板'
                // }]}
                >
                  <SelectModel {...props} values={values} setValues={
                    (vs) => {
                      form.setFieldsValue({ ...vs })
                      setValues(vs)
                    }
                  } hideStyleTemplate={(0, true)} templateType='content_template' />
                </Form.Item>
                <Form.Item
                  style={{ display: 'none' }}
                  name="content_template_name"
                >
                  <Input />
                </Form.Item>
              </>
            }
            {/* <Form.Item
              label="添加标签"
              name="tags"
            >
              <AddTags1 tagType='FILE_LABEL' />
            </Form.Item> */}

            <Form.Item
              style={{ display: 'none' }}
              name="source_type"
            >
              <Input />
            </Form.Item>
            {/* <Divider />
            <Form.Item {...tailLayout}>
              <div className="steps-action" style={{ textAlign: 'center' }}>
                <Button shape='round' type="primary" htmlType="submit" >
                  提交
                </Button>
              </div>
            </Form.Item> */}
          </Form>
        </div>
        <div style={{ display: current === 1 ? 'block' : 'none' }}>
          <Descriptions>
            <Descriptions.Item label='识别类型'>{recognizeTypes.find(x => x.value === recognizeType)?.label}</Descriptions.Item>
            {
              recognizeType === '0' && <Descriptions.Item label='标准样式模板名称'>{values.style_template_name}</Descriptions.Item>
            }
            <Descriptions.Item label='自由样式模板名称'>{values.content_template_name}</Descriptions.Item>
          </Descriptions>
          <Row>
            <Col span={24}>
              <OCR
                key={ocrKey}
                {...props}
                images={imageList}
                onChange={vs => {
                  setValues({ files: vs })
                }}
                manual
                hideEdit
                labelTextEdit
              />
            </Col>
          </Row>
          {/* <Row gutter={32}>
            <Col span={12}>
              <Card title='识别结果' extra={`已完成`}>
                <div className="dzt-table ant-table-bordered">
                  <table style={{ tableLayout: 'auto' }}>
                    <thead className="dzt-table-thead">
                      <tr>
                        <th className="dzt-table-cell">字段名</th>
                        <th className="dzt-table-cell">识别结果</th>
                      </tr>
                    </thead>
                    <tbody className="dzt-table-tbody">
                      {
                        [
                          { label: '统一社会信用代码', value: '9144604MA531TNMXH' },
                          { label: '正副本', value: '副本' },
                          { label: '名称', value: '陕西市天正建设有限公司' },
                          { label: '成立日期', value: '2001年04月10日' },
                          { label: '类型', value: '有限责任公司' },
                          { label: '营业期限', value: '长期' },
                        ].map((i, d) => <tr className='tan-table-row' key={d}>
                          <td className="dzt-table-cell">{i.label}</td>
                          <td className="dzt-table-cell">{i.value}</td>
                        </tr>)
                      }

                    </tbody>
                  </table>
                </div>
              </Card>
            </Col>
          </Row> */}
          {/* <Form.Item
              label="描述"
              name="description"
              rules={[{ required: true, message: '请输入描述' }]}
            >
              <RichEditor {...props} bizType="Supplier" />
            </Form.Item> */}
        </div>
        <div style={{ display: current === 2 ? 'block' : 'none' }}>
          <Result
            status="success"
            title="生成模板成功！"
            subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
            extra={[
              <Button shape='round' type="primary" key="console">返回文件列表</Button>,
              <Button shape='round' key="buy">重新生成</Button>,
            ]}
          />
        </div>
        <Divider />
        <div className="steps-action" style={{ textAlign: 'center' }}>
          {current > 0 && current < 2 && (
            <Button
              shape='round'
              style={{
                margin: '0 8px',
              }}
              onClick={() => prev()}
            >
              上一步
            </Button>
          )}
          {current === 0 && (
            <Button shape='round' type="primary" onClick={next} disabled={isLoading}>
              {recognizeType !== '1' ? '提交' : '下一步'}
            </Button>
          )}
          {current === 1 && (
            <Button shape='round' type="primary" onClick={() => submit()}>
              提交
            </Button>
          )}
          {
            current < 2
              ? <Button
                shape='round'
                // onClick={() => props.history.push(Path.getPathname(props, Path.pathname['model']['home']))}
                onClick={() => {
                  source?.cancel()
                  const returnUrl = decodeURIComponent(utils.getQueryStringByName('return_url') || '')
                  if (returnUrl) {
                    return props.history.push(returnUrl)
                  }
                  props.history.goBack()
                }}
                style={{
                  margin: '0 8px',
                }}
              >
                取消
              </Button>
              : null
          }
        </div>
      </div>
    </>
  )
}

export default injectIntl(Create);
