import React, { useState, useEffect } from 'react';
import { Layout, Form, Input, Button, Tabs, Checkbox, Spin, message, Row, Col, Space, Statistic, Radio, Menu, Modal, Divider } from '@om-tlh/bee';
import {
  UserOutlined,
  LockOutlined,
  QrcodeOutlined,
  MobileOutlined,
  SafetyOutlined,
} from '@ant-design/icons'
import { useParams, useLocation } from 'react-router-dom';
import * as service from './service'
import LocalStorageUtil from '@/utils/localStorageUtil'
import SStorageUtil from '@/utils/sessionStorageUtil'
import { base64Encode } from 'utils/base64'
import utils from '@/utils/util'
import config from '@/config';
import './index.css'
import commonService from '@/modules/common/service'
import getMD5Value from '@/utils/getMD5Value'
import ServiceAgreement from './components/ServiceAgreement'
import PrivacyAgreement from './components/PrivacyAgreement'
import OmaiFooter from '@/components/layouts/components/Footer'
import { WeChatIcon } from '@/modules/Pay/QRScan/QRScan'
import { getWXQRCode } from '@/modules/ThirdLogin/service'

/**
 * 登录页面
 */
const { Header, Content, Sider, Footer } = Layout;
const { Countdown } = Statistic;
let formData = null

export function loginSuccessCallback(data, msg, embed) {
  const { data: obj } = data
  const { roles = [] } = obj.user_info
  let privileges = []
  roles && roles.forEach(i => {
    privileges.push(...(i.privileges || []))
  })
  if (process.env.NODE_ENV === 'production' && privileges.includes('BUSINESS')) {
    // Modal.error({
    //   title: '提示',
    //   content: <div>抱歉！您没有登录权限</div>
    // })
    return Promise.reject({ message: `抱歉！您没有登录权限` })
  }
  SStorageUtil.clearSStorage()
  LocalStorageUtil.setAuth(obj.token)
  LocalStorageUtil.setCookie('token', obj.token)
  LocalStorageUtil.setPrivileges(base64Encode(JSON.stringify(Array.from(new Set(privileges)))))
  return commonService.getUserInfo().then(res => {
    LocalStorageUtil.setUserInfo(JSON.stringify({ ...res }))
    if (!msg) {
      message.success('登录成功')
    }
    if (embed) {
      window.location.reload()
      return
    }
    if (msg) {
      message.success(msg)
      setTimeout(() => {
        const returnUrl = decodeURIComponent(utils.getQueryStringByName('return_url') || '')
        if (returnUrl) {
          window.location.href = returnUrl
          return
        }
        window.location.href = `${window.__IN_CLOUD__ ? `/${window.appType ? [...window.appType.split('/'), `${window.appName}`].filter(Boolean).join('/') : `${window.appName}`}` : (window.appType ? `/${window.appType}` : ``)}/login?return_url=${encodeURIComponent(window.location.href.replace(new RegExp(`&?token_id=${utils.getQueryStringByName(`token_id`)}`), ''))}`
      }, 100)
      return
    }
    setTimeout(() => {
      const returnUrl = decodeURIComponent(utils.getQueryStringByName('return_url') || '')
      if (returnUrl) {
        window.location.href = returnUrl
        return
      }
      window.location.href = `${window.__IN_CLOUD__ ? `/${window.appType ? [...window.appType.split('/'), `${window.appName}`].filter(Boolean).join('/') : `${window.appName}`}` : (window.appType ? `/${window.appType}` : ``)}/login?return_url=${encodeURIComponent(window.location.href.replace(new RegExp(`&?token_id=${utils.getQueryStringByName(`token_id`)}`), ''))}`
    }, 200)
  })
}

export const checkLogin = async () => {
  var auth = LocalStorageUtil.getAuth()
  var token = auth && encodeURIComponent(auth)
  if (token || utils.getCookie('token')) {
    return true
  } else {
    return false
  }
}

const Login = (props) => {
  const location = useLocation()
  const searchP = new URLSearchParams(location.search)
  const icode = searchP.get('icode')
  const { baseURL = '', wxLoginEnabled, mobileLoginEnabled, registoryEnabled } = window.__site__config || {}
  const [activeTab, setActiveTab] = useState('0')
  const [accountList, setAccountList] = useState([])
  const [currentUser, setCurrentUser] = useState([])
  const [isCountDown, setIsCountDown] = useState(-1)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  // 图片验证码
  const [kaptcha, setKaptcha] = useState('')
  const [isLogin, setIsLogin] = useState(true)

  useEffect(() => {
    setKaptcha(`${config.uc_url}/v1/captcha?_=${Math.random()}`)
  }, [])

  useEffect(() => {
    checkLogin().then(res => {
      setIsLogin(res)
      if (res) {
        setTimeout(() => {
          const returnUrl = decodeURIComponent(utils.getQueryStringByName('return_url') || '')
          if (returnUrl) {
            window.location.href = returnUrl
            return
          }
          props.history.push('/')
        }, 200)
      }
    })
  }, [])

  if (isLogin) {
    return null
  }

  const clearStoragedInfo = () => {
    ['auth', 'userInfo', 'privileges', 'platform_id'].forEach(i => {
      LocalStorageUtil.clearStorage(i)
    })
    LocalStorageUtil.clearAllCookie()
    SStorageUtil.clearSStorage()
  }

  const handleSubmit = values => {
    clearStoragedInfo()
    formData = values
    if (activeTab === '0') {
      values.user_id ? loginAccount(values).then((res) => {
        return loginSuccessCallback({ data: res[0] }, void 0, !!props.embed)
      }).catch(loginErrorCallback) : loginAccount(values).then(newLoginSuccessCallback).catch(loginErrorCallback)
    }
    if (activeTab === '1') {
      values.user_id ? loginMobile(values).then((res) => {
        return loginSuccessCallback({ data: res[0] }, void 0, !!props.embed)
      }).catch(loginErrorCallback) : loginMobile(values).then(newLoginSuccessCallback).catch(loginErrorCallback)
    }
    if (activeTab === '2') {
      login(values)
        .then(loginSuccessCallback)
        .catch(loginErrorCallback)
    }
  };
  function newLoginSuccessCallback(data) {
    let newData = data.map((x, idx) => ({
      ...x,
      ...x?.user_info,
      // user_id: `${idx}`
    }))
    newData = [...newData.filter(x => !x.tenant_name), ...newData.filter(x => x.tenant_name)]
    setLoading(false)
    if (newData.length === 1) {
      return handleSubmit({ ...formData, user_id: newData[0].user_id })
    }
    setAccountList(newData)
    setCurrentUser([newData[0]?.user_id])
    LocalStorageUtil.setAccountInfo(JSON.stringify(newData))
  }

  const loginErrorCallback = (error) => {
    setLoading(false)
    LocalStorageUtil.clearAllCookie()
    LocalStorageUtil.clearAllStorage()
    SStorageUtil.clearSStorage()
    message.error(error.response?.data?.message ?? error.message ?? '登录失败')
  }

  function login(params) {
    setLoading(true)
    return service.login({
      ...params,
      password: getMD5Value(params.password)
    })
  }
  function loginAccount(params) {
    setLoading(true)
    return service.loginAccount({
      ...params,
      password: getMD5Value(params.password)
    })
  }
  function loginMobile(params) {
    setLoading(true)
    return service.loginMobile(params)
  }

  const handleChangeKaptcha = () => {
    setKaptcha(`${config.uc_url}/v1/captcha?_=${Math.random()}`)
  }

  const handleCaptcha = async () => {
    const [error, { mobile, captcha } = {}] = await utils.ha(form.validateFields([['mobile'], ['captcha']]))
    if (error) {
      return
    }

    const params = {
      mobile,
      captcha
    }
    const [err, cpCode] = await utils.ha(service.getCaptcha(params))
    if (!err) {
      message.success('短信发送成功，请注意查收!')
      return setIsCountDown(1)
    }
    setIsCountDown(0)
    message.error(err?.response?.data?.message ?? '短信发送失败，请稍后重试!')
  }

  const onCountDownChange = (val) => {
    if (val <= 0) {
      setIsCountDown(0)
    }
  }

  // 切换账户
  const onAccountChange = (event) => {
    const value = event.target.value
    setCurrentUser(value)
  }
  const handleSelectUser = () => {
    handleSubmit({ ...formData, user_id: currentUser[0] })
  }

  const Agreement = () => {
    return <Form.Item>
      <Space style={{ width: '100%', justifyContent: 'space-between', alignItems: 'baseline' }}>
        <Form.Item
          // label={''}
          style={{ marginBottom: 0 }}
          name='agreed'
          rules={[
            { required: true, message: '请阅读并同意服务协议和隐私协议', },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (typeof value !== 'undefined' && !value) {
                  return Promise.reject(new Error(`请阅读并同意服务协议和隐私协议`));
                }
                return Promise.resolve();
              },
            })
          ]}
          valuePropName="checked"
        >
          <Checkbox>我已阅读并同意
            <ServiceAgreement
              buttonProps={{
                type: 'link',
              }}
              data={`${baseURL}/档证通在线服务协议.pdf`}
              buttonText='档证通在线服务协议'
              okSuccessCallback={() => {
                form.setFieldValue(['agreed'], true)
                form.validateFields([['agreed']])
              }}
              modalTitle={`档证通在线服务协议`}
            />
            {/* 和 <PrivacyAgreement
              buttonProps={{
                type: 'link'
              }}
              buttonText='隐私协议'
            /> */}
          </Checkbox>
        </Form.Item>
        {
          !!props.embed && <Space style={{ fontSize: 16 }}>
            <span>没有账户? </span>
            <a className='btn'
              // onClick={props.handleRegist}
              onClick={() => props.history.push(icode ? `/regist/${icode}` : `/regist`)}
            >注册</a>
          </Space>
        }
      </Space>
    </Form.Item>
  }

  return (
    <div className="login-wrap"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Row style={{ flex: 'auto' }} >
        {
          !props.embed && <Col className='login-banner' style={{ width: 'calc(100% - 500px)' }}></Col>
        }

        <Col
          style={!props.embed ? {
            width: 500,
            padding: '30px 50px'
          } : {
            width: 600,
            padding: '0px 50px'
          }}
        >
          {
            !props.embed && <div className="login-header">
              <h2>欢迎登录<br />
                <span className='product-name'>档证通智能文档处理平台</span>
              </h2>
              {
                registoryEnabled !== 'false' && <div className='tip'>
                  <Space>
                    <span>没有账户? </span>
                    <a className='btn'
                      onClick={() => props.history.push(icode ? `/regist/${icode}` : `/regist`)}
                    >注册</a>
                  </Space>
                </div>
              }
            </div>
          }
          {
            !accountList.length ? <>
              <Tabs
                activeKey={activeTab}
                onChange={key => {
                  setActiveTab(key)
                  formData = null
                }}
                destroyInactiveTabPane
                items={[{
                  label: '账号登录',
                  key: '0',
                  children:
                    <div className="login-box">
                      <Spin spinning={loading}>
                        <div className="login-inner">
                          <Form
                            form={form}
                            layout='vertical'
                            initialValues={{
                              login_name: process.env.NODE_ENV === 'development' ? 'omaiuser' : '',
                              password: process.env.NODE_ENV === 'development' ? 'OMAI@6A4F' : '',
                            }}
                            onFinish={handleSubmit} className="login-form"
                            onFinishFailed={({ values, errorFields }) => {
                              console.log('values, errorFields: ', values, errorFields);

                            }}
                          >
                            <Form.Item name='login_name'
                              label='用户名'
                              rules={[{ required: true, message: '请输入用户名', whitespace: true }]}
                            >
                              <Input size='large'
                                prefix={<UserOutlined style={{ fontSize: 20 }} />}
                                placeholder='用户名'
                                maxLength={64}
                              />
                            </Form.Item>
                            <Form.Item name='password'
                              label='密码'
                              rules={[{ required: true, message: '请输入密码', whitespace: true }]}
                            >
                              <Input.Password size='large'
                                prefix={<LockOutlined style={{ fontSize: 20 }} />}
                                type="password"
                                placeholder='密码'
                                maxLength={64}
                              />
                            </Form.Item>
                            {/* <Form.Item>
                            <Row gutter={8}>
                              <Col span={14}>
                                <Form.Item
                                  name="verification_code"
                                  noStyle
                                  rules={[{ required: true, message: '请输入验证码' }]}
                                >
                                  <Input size='large'
                                    prefix={<QrcodeOutlined style={{ fontSize: 20 }} />}
                                    placeholder='验证码' />
                                </Form.Item>
                              </Col>
                              <Col span={10}>
                                <img title='看不清？点击刷新' src={kaptcha} onClick={handleChangeKaptcha} style={{ height: 43 }} />
                              </Col>
                            </Row>
                          </Form.Item> */}
                            {/* <Agreement /> */}
                            <Button shape='round' size='large' type="primary" htmlType="submit" className="login-form-button">登录</Button>
                          </Form>
                        </div>
                      </Spin>
                    </div>
                },
                mobileLoginEnabled !== 'false' && {
                  label: '短信登录',
                  key: '1',
                  children:
                    <div className="login-box">
                      <Spin spinning={loading}>
                        <div className="login-inner">
                          <Form
                            form={form}
                            layout='vertical'
                            initialValues={{
                              mobile: process.env.NODE_ENV === 'development' ? '18672291921' : '',
                              verification_code: process.env.NODE_ENV === 'development' ? '666666' : '',
                            }}
                            onFinish={handleSubmit} className="login-form">
                            <Form.Item name='mobile'
                              label='手机号'
                              rules={[{ required: true, message: '请输入手机号', whitespace: true },
                              {
                                pattern: /^1[3-9]\d{9}$/,
                                message: "请输入格式合法的11位手机号",
                              }]}
                            >
                              <Input size='large'
                                addonBefore="+86"
                                // prefix={<MobileOutlined style={{ fontSize: 20 }} />}
                                placeholder='手机号'
                                maxLength={11}
                              />
                            </Form.Item>
                            <Form.Item label='图形验证码'>
                              <Row gutter={8}>
                                <Col span={14}>
                                  <Form.Item
                                    name="captcha"
                                    noStyle
                                    rules={[{ required: true, message: '请输入图形验证码' }]}
                                  >
                                    <Input size='large'
                                      prefix={<QrcodeOutlined style={{ fontSize: 20 }} />}
                                      placeholder='图形验证码' />
                                  </Form.Item>
                                </Col>
                                <Col span={10}>
                                  <img title='看不清？点击刷新' src={kaptcha} onClick={handleChangeKaptcha} style={{ height: 40 }} />
                                </Col>
                              </Row>
                            </Form.Item>
                            <Form.Item label='短信验证码'>
                              <Space>
                                <Form.Item name='verification_code'
                                  noStyle
                                  rules={[{ required: true, message: '请输入短信验证码', whitespace: true }]}
                                >
                                  <Input size='large'
                                    prefix={<SafetyOutlined style={{ fontSize: 20 }} />}
                                    type="verification_code"
                                    placeholder='短信验证码'
                                    maxLength={64}
                                  />
                                </Form.Item>
                                {
                                  isCountDown < 0
                                    ? <Button size='large'
                                      onClick={handleCaptcha}
                                    >验证码</Button>
                                    :
                                    isCountDown === 0
                                      ? <Button size='large'
                                        onClick={handleCaptcha}
                                      >重新发送</Button>
                                      :
                                      <Button size='large'
                                      >
                                        <Countdown value={Date.now() + 60 * 1000}
                                          valueStyle={{ color: '#666', fontSize: '1.2em' }}
                                          format="重新发送（s 秒）"
                                          onChange={onCountDownChange}
                                        />
                                      </Button>
                                }
                              </Space>
                            </Form.Item>
                            {/* <Agreement /> */}
                            <Button shape='round' size='large' type="primary" htmlType="submit" className="login-form-button">登录</Button>
                          </Form>
                        </div>
                      </Spin>
                    </div>
                },
                process.env.NODE_ENV === 'development' && {
                  label: '团队成员登录',
                  key: '2',
                  children:
                    <div className="login-box">
                      <Spin spinning={loading}>
                        <div className="login-inner">
                          <Form
                            form={form}
                            layout='vertical'
                            initialValues={{
                              login_name: process.env.NODE_ENV === 'development' ? 'omaiuser' : '',
                              password: process.env.NODE_ENV === 'development' ? 'OMAI@6A4F' : '',
                            }}
                            onFinish={handleSubmit} className="login-form">
                            <Form.Item name='login_name'
                              label='用户名'
                              rules={[{ required: true, message: '请输入用户名', whitespace: true }]}
                            >
                              <Input size='large'
                                prefix={<UserOutlined style={{ fontSize: 20 }} />}
                                placeholder='用户名'
                                maxLength={64}
                              />
                            </Form.Item>
                            <Form.Item name='password'
                              label='密码'
                              rules={[{ required: true, message: '请输入密码', whitespace: true }]}
                            >
                              <Input.Password size='large'
                                prefix={<LockOutlined style={{ fontSize: 20 }} />}
                                type="password"
                                placeholder='密码'
                                maxLength={64}
                              />
                            </Form.Item>
                            {/* <Form.Item>
                            <Row gutter={8}>
                              <Col span={14}>
                                <Form.Item
                                  name="verification_code"
                                  noStyle
                                  rules={[{ required: true, message: '请输入验证码' }]}
                                >
                                  <Input size='large'
                                    prefix={<QrcodeOutlined style={{ fontSize: 20 }} />}
                                    placeholder='验证码' />
                                </Form.Item>
                              </Col>
                              <Col span={10}>
                                <img title='看不清？点击刷新' src={kaptcha} onClick={handleChangeKaptcha} style={{ height: 43 }} />
                              </Col>
                            </Row>
                          </Form.Item> */}
                            <Button shape='round' size='large' type="primary" htmlType="submit" className="login-form-button">登录</Button>
                          </Form>
                        </div>
                      </Spin>
                    </div>
                }].filter(Boolean)}
              />
              {
                wxLoginEnabled !== 'false' && <>
                  <Divider style={{ color: '#999', marginTop: 30 }} >第三方登录</Divider>
                  <Space
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                      width: '100%'
                    }}
                  >
                    <WeChatIcon style={{ margin: '0px 0 20px 0', color: '#28C445', fontSize: '3em', cursor: 'pointer' }}
                      title='微信'
                      onClick={async () => {
                        if (process.env.NODE_ENV === 'development') {
                          return props.history.push('/third_login')
                        }
                        const qrCodeURL = await getWXQRCode()
                        window.location.href = qrCodeURL
                      }
                      }
                    />
                    {/* <AliIcon style={{ margin: '15px 0 20px 0', color: '#06B4FD', fontSize: '3em' }} title='支付宝' />
                <UnionIcon style={{ margin: '15px 0 20px 0', color: '#107C84', fontSize: '3em' }} title='银联' /> */}
                  </Space>
                </>
              }
            </> : <div style={{ marginTop: 20 }}>
              <p>存在多个关联账号，请选择个人/团队进入系统：</p>
              <div
                style={{ textAlign: 'center' }}>
                {/* <Radio.Group
                  onChange={onAccountChange}
                  value={currentUser}
                  size="large"
                >
                  <Space direction="vertical">
                    {
                      accountList.map(a => {
                        return <Radio key={a.user_id} value={a.user_id}
                          size='large'>{a.user_name}</Radio>
                      })
                    }
                  </Space>
                </Radio.Group> */}
                <Menu
                  onClick={({ key }) => {
                    setCurrentUser([key])
                  }}
                  style={{
                    width: '100%',
                  }}
                  selectedKeys={currentUser}
                  // defaultOpenKeys={['sub1']}
                  mode="inline"
                  items={accountList.map(a => ({
                    label: `${a.tenant_name || a.user_name}`,
                    key: a.user_id,
                    title: `${a.tenant_name || a.user_name}`,
                  }))}
                />
                <p style={{ marginTop: 10 }}><Button type='primary' shape='round'
                  onClick={handleSelectUser}
                >确定</Button></p>
              </div>
            </div>
          }
        </Col>
      </Row>
      {
        !props.embed && <Footer style={{ textAlign: 'center', padding: 0, minWidth: 800 }}>
          <OmaiFooter {...props} />
        </Footer>
      }
    </div >
  );
}

export default Login;