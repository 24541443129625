// import Index from './index'
import Path from '@/routes/path'

const MODULE_NAME = 'pay_success'

const routes = [
    {
        id: `${MODULE_NAME}_manage`,
        name: `${MODULE_NAME}_manage`,
        // component: Index,
        componentPath: 'modules/Pay/Success/index',
        path: Path.pathname[`${MODULE_NAME}`]['home'],
        key: `${MODULE_NAME}_manage`,
    },
]

export {
    routes
}
