import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Select, Divider, Space, Button } from '@om-tlh/bee';
import { PlusOutlined } from '@ant-design/icons'
import LocalStorageUtil from 'utils/localStorageUtil'
// import service from 'modules/Platform/service'
import utils from 'utils/util'
import Path from 'routes/path'
import './index.css'
import commonService from '@/modules/common/service'
import { loginSuccessCallback } from '@/modules/Login'
import TeamCreate from '@/modules/Home/components/ButtonModalTeamInfo'

const { Option } = Select;

/**
 * 平台选择组件
 */
export default (props) => {
  const { userInfo = {}, setRefreshTeamInfoFunc } = props;
  const [accountInfo, setAccountInfo] = useState([])
  const [open, setOpen] = useState(false)

  const fetchData = async () => {
    const res = await commonService.userAccounts(userInfo.person_id)
    setAccountInfo([...res.filter(x => !x.tenant_name), ...res.filter(x => x.tenant_name)])
  }

  useEffect(() => {
    setRefreshTeamInfoFunc?.(() => fetchData())
    if (!userInfo.person_id) return
    fetchData()
  }, [userInfo.person_id])

  const onChange = async (us) => {
    const data = await commonService.switchLogin({
      person_id: userInfo.person_id,
      user_id: us
    })
    loginSuccessCallback({ data: data.find(x => x.person_id === userInfo.person_id && x.user_info.user_id === us) }, '操作成功')
  }

  return (
    accountInfo.length > 1 ? <Select size='small' className="locale-select" onChange={onChange} value={userInfo.user_id}
      open={open}
      onDropdownVisibleChange={(open) => {
        setOpen(open)
      }}
      getPopupContainer={() => document.getElementById('content')}
      style={{
        // float: 'left', marginTop: 28, marginLeft: 20,
        width: 120
      }}
      bordered={false}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider
            style={{
              margin: '8px 0',
            }}
          />
          <Space
            style={{
              padding: '0 8px 4px',
            }}
          >
            {/* <Button type="link" icon={<PlusOutlined />} onClick={() => {
              setOpen(false)
              props.history.push(Path.getPathname(props, Path.pathname['team']['create']))
            }}>
              创建团队
            </Button> */}
            <TeamCreate
              {...props}
              buttonProps={{
                type: 'link',
                icon: <PlusOutlined />
              }}
              onClick={() => setOpen(false)}
              buttonText='创建团队'
              modalTitle='创建团队'
              data={{ ...userInfo, tenant_name: null }}
              okSuccessCallback={() => {
                // fetchUserInfo()
              }}
            />
          </Space>
        </>
      )}
    >
      {accountInfo.map((opt, index) => {
        return <Option key={index} value={opt.user_id}>
          {`${opt.tenant_name || opt.user_name}`}
        </Option>
      })}
    </Select> : <TeamCreate
      {...props}
      buttonProps={{
        type: 'link',
        // icon: <PlusOutlined />
      }}
      // onClick={() => setOpen(false)}
      buttonText='创建团队'
      modalTitle='创建团队'
      data={{ ...userInfo, tenant_name: null }}
      okSuccessCallback={() => {
        // fetchUserInfo()
      }}
    />
  )
}
