import axios from 'axios'
import commonService from '@/modules/common/service'
import moment from 'moment'
import LocalStorageUtil from 'utils/localStorageUtil'
import config from '@/config'
const ucURL = config.uc_url

// 没有正式API之前取total
export const getUserInfo = commonService.getUserInfo
// export const getFileCount = () => axios.get(`/v1/file?page_size=1&page=1&is_include_subset=true`)
// export const getUserDocumentCount = () => axios.get(`v1/document?page=1&page_size=1`)
// export const getRecognizeCount = () => axios.get(`v1/recognition/job?page=1&page_size=1`)

// export const getTeamDocumentCount = () => axios.get(`v1/team/document?page=1&page_size=1`)
// export const getTeamFileCount = () => axios.get(`v1/team/file?page_size=1&page=1&is_include_subset=true`)
// export const getUpWaitCount = async () => {
//     const datas = await Promise.all([axios.get(`v1/up_record?page=1&page_size=1`), axios.get(`v1/up_record?page=1&page_size=1&is_approved=true`), axios.get(`v1/up_record?page=1&page_size=1&is_approved=false`)])
//     return datas[0].total - datas[1].total - datas[2].total
// }
// export const getApplyWaitCount = () => axios.get(`v1/issue_record?page_size=1&page=1&issue_status=NOT_ISSUE`)

// 有统计的API后
export const getFileCount = () => axios.get(`v1/statistics/personal/file`)
export const getUserDocumentCount = () => axios.get(`v1/statistics/personal/document`)
export const getRecognizeCount = () => axios.get(`v1/statistics/recognition`)

export const getTeamDocumentCount = () => axios.get(`v1/statistics/team/document`)
export const getTeamFileCount = () => axios.get(`v1/statistics/team/file`)
export const getUpWaitCount = async () => axios.get(`v1/statistics/up_record`)
export const getApplyWaitCount = () => axios.get(`v1/statistics/issue_record`)

export const updateTeamInfo = (id, body) => axios.put(`v1/team`, body, { params: body })
export const createTeam = (body, { auth }) => axios.post(`v1/team`, body, {
  headers: {
    'Authorization': auth
  }
})
