import React, { useState, useEffect } from 'react';
import SearchType from '@/utils/searchType'
import service from './service'
import tenantService from '../Tenant/service'
import roleService from '../Role/service'
import commonService from '@/modules/common/service'
import { injectIntl } from 'react-intl';
import TablePage from '@/components/TablePage'
import { Button, Tag, Modal, message, Form, Checkbox, Popconfirm } from '@om-tlh/bee'
import LocalStorageUtil from 'utils/localStorageUtil'
import Path from 'routes/path'
import utils from '@/utils/util'
import NoTeam from '@/modules/ErrorPage/NoTeam'
import QRCode from '@/components/OmPan/components/QRCode'
import { limitTipModal } from '@/modules/File/create'

export async function tipModal(yes) {
  if (!yes) {
    Modal.error({
      title: '抱歉，您没有该权限！',
      content: <div>
        请在团队管理员为您添加权限后，退出系统重新登录
      </div>
    })
    return Promise.reject()
  }
  return Promise.resolve()
}

const Index = (props) => {
  const { intl, match } = props
  const { params } = match || {}
  const { scope } = params || {}
  const { formatMessage } = intl
  const [roleOpts, setRoleOpts] = useState([])
  const [reload, setReload] = useState(false)
  const userInfoStr = LocalStorageUtil.getUserInfo()

  let userInfo = {}
  try {
    userInfo = JSON.parse(userInfoStr)
  } catch (e) { }

  useEffect(() => {
    const fetchData = async () => {
      const res = await roleService.getDatas({ page_size: 100 })
      setRoleOpts(res.items)
    }
    fetchData()
  }, [])

  const _handleResetPassword = (e, record) => {
    tipModal(utils.hasPermission('EDIT_USER')).then(async () => {
      Modal.confirm({
        okButtonProps: { shape: 'round' },
        cancelButtonProps: { shape: 'round' },
        title: '重置密码',
        content: '确定要重置密码吗？',
        onOk: () => {
          service.resetPassword(record.user_id).then(data => {
            message.success('重置密码成功')
            Modal.success({
              title: `密码重置成功，当前新密码是：${data.data || Math.random().toString(32).slice(4, 8)}`,
            });
          })
        },
        onCancel: () => {

        }
      })
    })
  }

  const handleInvite = async () => {
    tipModal(utils.hasPermission('MNG_TEAM'))
      .then(() => commonService.checkLimit('MEMBER_NUM', 1))
      .then(limitTipModal)
      .then(async () => {
        service.getTeamInvitationUrl().then(res => {
          Modal.success({
            okButtonProps: { shape: 'round' },
            cancelButtonProps: { shape: 'round' },
            title: <div>邀请人员</div>,
            content: <div>
              <QRCode url={`${window.location.origin}${window.__IN_CLOUD__ ? `/${window.appType ? [...window.appType.split('/'), `${window.appName}`].filter(Boolean).join('/') : `${window.appName}`}` : (window.appType ? `/${window.appType}` : ``)}/sr/team_invite/${res}`}
                displayName={'邀请团队成员'}
                id='canvas-invite'
                width={200}
              />
            </div>,
            width: 600,
            okButtonProps: {
              shape: 'round'
            },
            closable: true,
          })
        })
      })
      .catch((err) => {
        err && message.error(err.response?.data?.message ?? err.message ?? JSON.stringify(err))
      })
  }

  const handleDelete = async (record) => {
    tipModal(utils.hasPermission('MNG_TEAM')).then(async () => {
      const [err, res] = await utils.ha(service.deleteMember(record.user_id))
      if (!err) {
        message.success(`操作成功`)
        setReload(!reload)
      }
      message.error(err.response?.data?.message ?? JSON.stringify(err) ?? `操作失败`)
    })
  }

  const fields = [
    { name: 'key', desc: '序号', render: (text, record, index) => index + 1, width: 80 },
    {
      name: ['person_info', 'nick_name'], desc: '用户名', showDetail: true,
      query: true, key: 'keyword',
      type: SearchType.INPUT_SEARCH,
      queryForm: {
        placeholder: '用户名'

      },
    },
    { name: ['person_info', 'mobile'], desc: '手机号' },
    {
      name: 'roles', desc: '角色', render: (text, record) => {
        return text && text.length ? text.map((i, k) => {
          return <Tag color='blue' key={k}>{i.display_name}</Tag>
        }) : '-'
      }, showRender: true
    },
    // { name: 'description', desc: '说明' },
    { name: 'create_time', desc: '创建时间', type: SearchType.KEY_DATE_TIME },
    {
      name: 'action', desc: '操作',
      listActions: true,
      render: (text, record) => {
        return [
          <RoleForm {...props} key='assign' record={record} roleOpts={roleOpts} reload={reload} setReload={setReload} />,
          // <a key='reset' className='btn-action' onClick={(e) => _handleResetPassword(e, record)}>重置密码</a>,
          <Popconfirm
            title={`确定将该成员移出团队吗？`}
            onConfirm={() => handleDelete(record)}
            placement='topRight'
          // onCancel={cancel}
          >
            <a>移出</a>
          </Popconfirm>
        ].filter(Boolean)
      }
    },
  ]
  const insertBtns = [
    <Button shape='round' key='create' type='primary' onClick={handleInvite}>邀请</Button>
  ].filter(Boolean)
  return (
    userInfo.account_type ? (!userInfo.account_type.endsWith('PERSONAL') ? <>
      <div className="content-header">
        <h2>团队成员管理</h2>
      </div>
      <div className="content-body">
        <TablePage
          rowKey='user_id'
          name={'用户数据'}
          fields={fields}
          insertBtns={insertBtns}
          loadData={service.getDatas}
          isReload={reload}
          deleteData={service.batchDeleteData}
          service={service}
          scope={scope}
          // showDelete={ utils.hasPermission('EDIT_USER')}
          moduleName='user'
        />
      </div>
    </> : <NoTeam {...props} />) : null
  )
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const RoleForm = (props) => {
  const { record, roleOpts } = props
  // const [roleOpts, setRoleOpts] = useState([])
  const [visible, setVisible] = useState(false)
  const userInfoStr = LocalStorageUtil.getUserInfo()
  const [form] = Form.useForm()
  let userInfo = {}
  try {
    userInfo = JSON.parse(userInfoStr)
  } catch (e) { }
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const res = await roleService.getDatas({ page_size: 100 })
  //     setRoleOpts(res.items)
  //   }
  //   // fetchData()
  // }, [])

  const showModal = (e, record) => {
    tipModal(utils.hasPermission('EDIT_USER')).then(async () => {
      setVisible(true)
      form.setFieldsValue({
        'roles': record.roles.map(i => i.role_id)
      })
    })
  }

  const handleOk = () => {
    form.validateFields().then(values => {
      service.assignRoles(record.user_id, values.roles).then(data => {
        handleCancel()
        props.setReload(!props.reload)
        message.success('分配角色成功')
      }).catch(error => {
        message.error(error.response && error.response.data.message)
      })
    })
  }

  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <>
      <a key='assign' className='btn-action' onClick={e => showModal(e, record)}>分配角色</a>
      <Modal
        okButtonProps={{ shape: 'round' }}
        cancelButtonProps={{ shape: 'round' }}
        width={600}
        title='分配角色'
        open={visible}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
      >
        <Form form={form} {...layout}>
          <Form.Item label='选择角色' name='roles'>
            <Checkbox.Group options={roleOpts.map(i => ({ ...i, label: i.display_name, value: i.role_id }))} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default injectIntl(Index);