export const eventTypes = {
  CLICK: 'click',
  DBLCLICK: 'dblclick',
  MOUSEDOWN: 'mousedown',
  MOUSEMOVE: 'mousemove',
  MOUSEUP: 'mouseup',
  CONTEXTMENU: 'contextmenu',

  KEYUP: 'keyup',
  WHEEl: 'wheel'
}

export const cursorStyles = {
  POINTER: 'pointer',
  CROSSHAIR: 'crosshair',
  E_RESIZE: 'e-resize',
  W_RESIZE: 'w-resize',
  N_RESIZE: 'n-resize',
  S_RESIZE: 's-resize',
  NE_RESIZE: 'ne-resize',
  NW_RESIZE: 'nw-resize',
  SE_RESIZE: 'se-resize',
  SW_RESIZE: 'sw-resize',
  MOVE: 'move',
  GRAB: 'grab',
  GRABBING: 'grabbing',
  AUTO: 'auto'
}

export const messageTypes = {
  IF_SHAPE_COMPLETE: 'if_shape_complete',

  CREATE_POLYGON: 'create_polygon',

  CREATE_RECTANGLE: 'create_rectangle',
  DISTANCE_TOO_SMALL: 'distance_too_small',
  PRESS_MOUSE_TO_SELECT: 'press_mouse_to_select',

  SYNC_CANVAS_TO_OUTSIDE: 'sync_canvas_to_outside',
  ACTIVATE_SHAPE: 'active_shape',
  NO_MORE_ZOOM_IN: 'no_more_zoom_in',
  NO_MORE_ZOOM_OUT: 'no_more_zoom_out',

  CONTEXT_CLICK: 'context_click',
  CONTEXT_NO_CLICK: 'context_no_click',
  CONTEXT_SCROLL: 'context_scroll',

  SYNC_CANVAS_SETTING_DATA_TO_OUTSIDE: 'sync_canvas_setting_data_to_outside'

}

export const toolbarTypes = {
  RECTANGLE: 'rectangle',
  POLYGON: 'polygon',
  UNDO: 'undo',
  REDO: 'redo',
  CLEAR: 'clear',
  ZOOM_IN: 'zoom-in',
  ZOOM_OUT: 'zoom-out',
  SHAPE_EDIT: 'shape-edit',
  SHAPE_MOVE: 'shape-move',
  SHAPE_MOVE_WHOLE: 'shape-move-whole',
  BACK_TO_CENTER: 'back-to-center',
  SAVE: 'save',
  FULL_CLIENT: 'full-client',
  PERSPECTIVE_TRANSFORMATION: 'perspective-transformation'
}