import axios from 'axios'
import { shakeTreeChildren } from '@/utils/utils'
const MODULE_NAME = 'catalog';

export const getDatas = (params) => axios.get(`/v1/${MODULE_NAME}`, { params }).then(async data => {
  return { items: shakeTreeChildren(data, MODULE_NAME), total: data.length }
})
export const createData = (body) => axios.post(`/v1/${MODULE_NAME}`, body)
export const getDataDetail = (id) => axios.get(`/v1/${MODULE_NAME}/${id}`)
export const getDataDetailByName = (params) => axios.get(`/v1/${MODULE_NAME}/get_by_name`, { params })
export const updateData = (id, body) => axios.put(`/v1/${MODULE_NAME}/${id}`, body)
export const deleteData = (id) => axios.delete(`/v1/${MODULE_NAME}/${id}`)
export const batchDeleteData = (ids) => Promise.all(ids.map(id => axios.delete(`v1/${MODULE_NAME}/${id}`)))
export const mergeData = (params) => axios.put(`/v1/${MODULE_NAME}/merge`, {}, { params })

export const createFolder = (parentId, name) => {
  let pms = {}
  pms = {
    "catalog_name": name,
    "catalog_type": "DOCUMENT_CATALOG",
    parent_catalog_id: parentId || ''
  }
  return axios.post(`v1/catalog`, pms)
}

let originalFolders = []

function parallelNodes(arr) {
  return arr.reduce((pre, cur) => {
    if (cur.child && cur.child.length) {
      return [...pre, cur, ...parallelNodes(cur.child.map(x => ({ ...x, parent_id: cur.catalog_id })))]
    }
    return [...pre, { ...cur }]
  }, [])
}

export const getNewDatas = async ({ isLoadMore, isFloderRefresh, selectType = [], catalogType, ...params }) => {
  let folders = []
  if (!isLoadMore) {
    // if ((!originalFolders.length || isFloderRefresh) && params.display_name === undefined) {
    const res = await axios.get(`v1/catalog`, {
      params: {
        // ...params,
        catalogType: catalogType || 'FILE_CATALOG'
      }
    })
    originalFolders = parallelNodes(res.map(x => ({ ...x, parent_id: null })))
    // }
    folders = originalFolders.filter(x => x.parent_id === (params.catalogId || null)).map(x => {
      return {
        id: x.catalog_id,
        display_name: x.catalog_name,
        modify_time: x.update_time || x.create_time,
        type: 'FOLDER',
        size: void 0,
        raw: x
      }
    })
    if (params.display_name !== void 0) {
      folders = folders.filter(x => x.display_name.includes(params.display_name.trim().toLowerCase()))
    }
    if (params.label_id) {
      folders = []
    }
  }
  let files = []
  if (!selectType.length || selectType.includes('FILE')) {
    let res = await axios.get(`v1/document`, {
      params: {
        ...params,
        catalogId: params.catalogId || '',
        is_include_subset: false
      }
    })
    files = res.items?.map(x => ({
      id: x.file_id,
      display_name: x.display_name,
      modify_time: x.update_time || x.create_time,
      type: 'FILE',
      size: x.file_size,
      raw: x
    }))
  }

  return [...folders, ...files]
}
export const rename = (id, name, type, label_ids) => {
  // if (type === 'FOLDER') {
  return axios.put(`v1/catalog/${id}`, {}, { params: { catalog_name: name } })
  // }
  if (type === 'FILE') {
    return axios.put(`v1/document/${id}`, {
      document_name: name,
      // label_ids
    })
  }
}
export const mergeFileCatalog = (ids, { displayName, parentCatalogId }) => {
  if (!ids || !ids.length) {
    return
  }
  return axios.put(`v1/catalog/merge`, {}, { params: { ids, catalog_type: 'DOCUMENT_CATALOG', catalog_name: displayName, parentCatalogId } })
}
export const mergeFileToCatalog = (ids, { displayName, parentCatalogId }) => {
  return Promise.all(ids.map(id => {
    return axios.put(`v1/document/${id}`, {
      catalog_id: parentCatalogId,
    })
  }))
}

export const getInitialFolders = async (catalogId) => {
  if (!originalFolders.length) {
    const res = await axios.get(`v1/catalog`, {
      params: {
        // ...params,
        catalog_type: 'DOCUMENT_CATALOG'
      }
    })
    originalFolders = parallelNodes(res.map(x => ({ ...x, parent_id: null })))
  }
  let result = []
  let tp
  while ((tp = originalFolders.find(x => x.catalog_id === catalogId))) {
    result.unshift(tp)
    catalogId = tp.parent_id
  }
  result.pop()
  return result.map(x => {
    return {
      id: x.catalog_id,
      name: x.catalog_name,
    }
  })
}