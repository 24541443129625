import React, { useEffect } from 'react';
import { Spin } from '@om-tlh/bee';
import { Loading3QuartersOutlined } from '@ant-design/icons'
import LocalStorageUtil from 'utils/localStorageUtil'
import './index.css';

const LoadingPage = (props) => {
  useEffect(() => {
    const auth = LocalStorageUtil.getAuth()
    props.history && props.history.push('/')
  }, [])
  const icon = <Loading3QuartersOutlined style={{ fontSize: 50 }} spin />
  return (
    <div className='loading-page'>
      <div className='loading-icon'>
        <Spin indicator={icon} />
      </div>
    </div>
  )
}

export default LoadingPage