import React from 'react';
import { injectIntl } from 'react-intl';
import { Result, Button } from '@om-tlh/bee';
import Path from 'routes/path';

/**
 * 403页面
 * @param {*} props 
 */
const Error403 = (props) => {
  const { intl } = props
  const { formatMessage } = intl
  return (
    <Result
      status="403"
      title="403"
      // subTitle={formatMessage({ id: '403_error_tip' })}
      subTitle={`抱歉，您无权访问该页面，请在团队管理员为您添加权限后，退出系统重新登录`}
      extra={props.footer || <span>
        <Button shape='round' type="primary" onClick={() => props.history.go(-2)}>{formatMessage({ id: 'back_to_previous_page' })}</Button>
        <Button shape='round' type="primary" style={{ marginLeft: 10 }} onClick={() => props.history.push('/')}>{formatMessage({ id: 'back_home' })}</Button>
      </span>}
    />
  )
}

export default injectIntl(Error403);