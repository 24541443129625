class Canvas {
  constructor(width, height) {
    const scale = window.devicePixelRatio
    this.scale = scale

    this.canvas = document.createElement('canvas')

    this.canvas.style.width = `${width}px`
    this.canvas.style.height = `${height}px`
    this.canvas.style.position = 'absolute'
    this.canvas.style.top = `0px`
    this.canvas.style.left = `0px`
    this.canvas.style.zIndex = 0
    this.canvas.innerHTML = 'canvas not supported'

    this.canvas.width = width * scale
    this.canvas.height = height * scale
    this.ctx = this.canvas.getContext('2d')
    this.ctx.scale(scale, scale)
    console.log('scale: ', scale);
  }

  drawImage = (ctx, { image,
    fromX = 0, fromY = 0, fromWidth = 0, FromHeight = 0,
    toX = 0, toY = 0, toWidth = 0, toHeight = 0
  }) => {
    // console.log('toX, toY, toWidth, toHeight', toX, toY, toWidth, toHeight);
    ctx.drawImage(image,
      fromX, fromY, fromWidth, FromHeight,
      toX, toY, toWidth, toHeight
    )
  }
  toDataURL = (mimeType) => {
    return this.canvas.toDataURL(mimeType)
  }
  saveAsImage = (mimeType, name) => {
    const a = document.createElement('a')
    a.setAttribute('download', name || 'hl.png');
    a.setAttribute('href', this.toDataURL(mimeType))
    const evt = document.createEvent('MouseEvents')
    evt.initEvent('click', true, true)
    a.dispatchEvent(evt)
  }
  toBlob = (callback) => {
    this.canvas.toBlob(callback)
  }
  // 基础画图
  // 点
  pointPaint = (x = 0, y = 0, r = 4, color = '#f00') => {
    const ctx = this.ctx
    ctx.save()
    ctx.globalCompositeOperation = 'source-over'
    ctx.fillStyle = color
    ctx.beginPath()
    ctx.arc(x, y, r, 0, Math.PI * 2)
    ctx.fill()
    ctx.restore()
  }
  anchorPaint = (x = 0, y = 0, r = 4, color = '#f00') => {
    const ctx = this.ctx
    const strokeStyle = color || '#333'

    ctx.fillStyle = color
    ctx.lineCap = lineCap
    ctx.beginPath()
    ctx.arc(x, y, r, 0, Math.PI * 2)
    ctx.fill()
  }
  // 线
  drawLine = (options) => {
    const ctx = this.ctx
    const lineWidth = options.lineWidth || 5
    const strokeStyle = options.strokeStyle || '#333'
    const lineCap = options.lineCap || 'butt'

    const start = options.start || { x: 0, y: 0 }
    const end = options.end || { x: 0, y: 0 }

    ctx.lineWidth = lineWidth
    ctx.strokeStyle = strokeStyle
    ctx.lineCap = lineCap
    ctx.beginPath()
    ctx.moveTo(start.x, start.y)
    ctx.lineTo(end.x, end.y)
    ctx.stroke()
  }
}
export default Canvas
