import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Input, Button, Checkbox, Row, Col, Card, Select, Space, Upload, message, Divider, Modal } from '@om-tlh/bee';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { getQueryStringByName } from 'utils/utils'
import service from './service'
import roleService from '../Role/service'
import LocalStorageUtil from 'utils/localStorageUtil'
import commonService from '@/modules/common/service'
import Login, { loginSuccessCallback } from '@/modules/Login'
import utils from '@/utils/util'

const { Option } = Select;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 18 },
};

const Create = (props) => {
  const [form] = Form.useForm();
  const { intl, location } = props
  const id = getQueryStringByName('id')
  const { formatMessage } = intl
  const [values, setValues] = useState({})
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)

  let userInfo = LocalStorageUtil.getUserInfo()
  userInfo = userInfo ? JSON.parse(userInfo) : {}

  useEffect(() => {
    const fetchData = async () => {
      const res = await service.getDataDetail(id)
      setValues(res)
      form.setFieldsValue({ ...res, role_ids: res.roles.map(i => i.role_id) })
    }
    if (!id) return
    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const res = await roleService.getDatas({ page_size: 100 })
      setOptions(res.items)
    }
    // fetchData()
  }, [])

  const handleSwitchAccount = async (us) => {
    const data = await commonService.switchLogin({
      person_id: userInfo.person_id,
      user_id: us.user_id
    })
    loginSuccessCallback({ data: data.find(x => x.person_id === userInfo.person_id && x.user_info.user_id === us.user_id) }, '成功进入')
  }
  const onFinish = values => {
    if (id) {
      service.updateData(id, values).then(data => {
        props.history.goBack();
        message.success('编辑数据成功')
      }).catch(error => {
        message.error(error.response && error.response.data.message)
      })
    } else {
      service.createData(values).then(async data => {
        Modal.success({
          title: <div>您已成功创建团队 <span style={{ color: '#1890ff' }}>{data.name} </span></div>,
          content: <div>立即进入该团队吗？</div>,
          onOk: async () => {
            setLoading(true)
            const res = await commonService.userAccounts(userInfo.person_id)
            handleSwitchAccount(res.find(x => x.tenant_id === data.id))
          },
          onCancel: () => {
            props.history.push('/')
          },
          okText: '确定'
        })
      }).catch(error => {
        message.error(error.response && error.response.data.message)
      })
    }
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <div className="content-header">
        <h2>{id ? '编辑' : '创建'}团队</h2>
      </div>
      <div className="content-body">
        <Form
          {...layout}
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="团队名称"
            name="organization"
            rules={[{ required: true, message: '请输入团队名称', whitespace: true }]}
          >
            <Input maxLength={32} />
          </Form.Item>
          {
            id ? null
              : <>
                <Form.Item
                  label="管理员用户名"
                  name="admin_user_name"
                  rules={[
                    { required: true, message: '请输入管理员用户名' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        let reg = new RegExp(/^[a-zA-Z][a-zA-Z0-9_]{4,15}$/);
                        if (value && reg.test(value) || !value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('字母开头，支持字母、数字、“_”的组合，5-16个字符'));
                      },
                    })
                  ]}
                >
                  <Input />
                </Form.Item>
              </>
          }
          {/* <Form.Item
            label="描述"
            name="description"
          >
            <Input.TextArea rows={4} />
          </Form.Item> */}
          <Divider />
          <Form.Item {...tailLayout}>
            <Button shape='round' type="primary" htmlType="submit" loading={loading}>
              提交
            </Button>
            <Button shape='round' onClick={() => {
              const returnUrl = decodeURIComponent(utils.getQueryStringByName('return_url') || '')
              if (returnUrl) {
                return props.history.push(returnUrl)
              }
              props.history.goBack()
            }} style={{ marginLeft: 10 }} loading={loading}>
              取消
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  )
}

export default injectIntl(Create);