// import List from './index'
// import ListTeam from './list_team'
// import ListAdmin from './list_admin'
// import Create from './create'
// import Discern from './discern'
// import Detail from './detail'
// import NewCreate from '@/modules/ModelStyle/recognize'
// import Recognize from './recognize'
import Path from 'routes/path'

const MODULE_NAME = 'file';
const TEAM_MODULE_NAME = 'team_file';

const routes = [
    {
        id: `${MODULE_NAME}_manage`,
        name: `${MODULE_NAME}_manage`,
        // component: List,
        componentPath: 'modules/File/index',
        path: Path.pathname[`${MODULE_NAME}`]['home'],
        key: `${MODULE_NAME}_manage`,
    },
    {
        id: `${MODULE_NAME}_team_manage`,
        name: `${MODULE_NAME}_team_manage`,
        // component: ListTeam,
        componentPath: 'modules/File/list_team',
        path: Path.pathname[`${MODULE_NAME}`]['team'],
        key: `${MODULE_NAME}_team_manage`,
        permission: 'VIEW_FILE',
    },
    {
        id: `${MODULE_NAME}_admin_manage`,
        name: `${MODULE_NAME}_admin_manage`,
        // component: ListAdmin,
        componentPath: 'modules/File/list_admin',
        path: Path.pathname[`${MODULE_NAME}`]['admin'],
        key: `${MODULE_NAME}_admin_manage`,
    },
    {
        id: `${MODULE_NAME}_create`,
        name: `${MODULE_NAME}_create`,
        // component: Create,
        componentPath: 'modules/File/create',
        path: Path.pathname[`${MODULE_NAME}`]['create'],
        key: `${MODULE_NAME}_create`,
        parentKey: `${MODULE_NAME}_manage`
    },
    {
        id: `${MODULE_NAME}_create_folder`,
        name: `${MODULE_NAME}_create_folder`,
        // component: Create,
        componentPath: 'modules/File/createFolder',
        path: Path.pathname[`${MODULE_NAME}`]['create_folder'],
        key: `${MODULE_NAME}_create_folder`,
        parentKey: `${MODULE_NAME}_manage`
    },
    {
        id: `${MODULE_NAME}_team_create`,
        name: `${MODULE_NAME}_team_create`,
        // component: Create,
        componentPath: 'modules/File/create',
        path: Path.pathname[TEAM_MODULE_NAME]['create'],
        key: `${MODULE_NAME}_team_create`,
        parentKey: `${MODULE_NAME}_team_manage`
    },
    {
        id: `${MODULE_NAME}_discern`,
        name: `${MODULE_NAME}_discern`,
        // component: Discern,
        componentPath: 'modules/File/discern',
        path: Path.pathname[`${MODULE_NAME}`]['discern'],
        key: `${MODULE_NAME}_discern`,
        parentKey: `file_create`
    },
    {
        id: `${MODULE_NAME}_edit`,
        name: `${MODULE_NAME}_edit`,
        // component: Create,
        componentPath: 'modules/File/create',
        path: Path.pathname[`${MODULE_NAME}`]['edit'],
        key: `${MODULE_NAME}_edit`,
        parentKey: `${MODULE_NAME}_manage`,
    },
    {
        id: `${MODULE_NAME}_detail`,
        name: `${MODULE_NAME}_detail`,
        // component: Detail,
        componentPath: 'modules/File/detail',
        path: Path.pathname[`${MODULE_NAME}`]['detail'],
        key: `${MODULE_NAME}_detail`,
        parentKey: `${MODULE_NAME}_manage`
    },
    {
        id: `${MODULE_NAME}_new_create`,
        name: `${MODULE_NAME}_new_create`,
        // component: NewCreate,
        componentPath: 'modules/ModelStyle/recognize',
        path: Path.pathname[`${MODULE_NAME}`]['new_create'],
        key: `${MODULE_NAME}_new_create`,
        parentKey: `${MODULE_NAME}_manage`
    },
    {
        id: `${MODULE_NAME}_recognize`,
        name: `${MODULE_NAME}_recognize`,
        // component: Recognize,
        componentPath: 'modules/File/recognize',
        path: Path.pathname[`${MODULE_NAME}`]['recognize'],
        key: `${MODULE_NAME}_recognize`,
        parentKey: `${MODULE_NAME}_manage`
    },
    {
        id: `${TEAM_MODULE_NAME}_recognize`,
        name: `${TEAM_MODULE_NAME}_recognize`,
        // component: Recognize,
        componentPath: 'modules/File/recognize',
        path: Path.pathname[`${TEAM_MODULE_NAME}`]['recognize'],
        key: `${TEAM_MODULE_NAME}_recognize`,
        parentKey: `file_team_manage`
    },
]

export default {
    routes
}