import axios from 'axios'

/**
 * 上传图片
 */

const upload = (body) => {
    return axios.post('/v1/upload', body, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

export default {
    upload,
}