import React from 'react';
import { injectIntl } from 'react-intl';
import { Result, Button } from '@om-tlh/bee';
import Path from 'routes/path';

/**
 * 500页面
 * @param {*} props 
 */
const Error500 = (props) => {
  const { intl } = props
  const { formatMessage } = intl
  return (
    <Result
      status="500"
      title="500"
      subTitle={formatMessage({ id: '500_error_tip' })}
      extra={<span>
        <Button shape='round' type="primary" onClick={() => props.history.go(-2)}>{formatMessage({ id: 'back_to_previous_page' })}</Button>
        <Button shape='round' type="primary" style={{ marginLeft: 10 }} onClick={() => props.history.push('/')}>{formatMessage({ id: 'back_home' })}</Button>
      </span>}
    />
  )
}

export default injectIntl(Error500);