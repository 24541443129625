import React from 'react';
import { Empty } from '@om-tlh/bee';

const Nodata = (props) => {
  return (
    <>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={props.text || '暂无数据'} />
      {/* <div className='nodata'>
        <p className='nodata-text'>暂无数据</p>
      </div> */}
    </>
  )
}

export default Nodata;