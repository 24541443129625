import { _useSWR } from '@/utils/swr'
import {
  getDatas as _getDatas,
  createData as _createData,
  getDataDetail as _getDataDetail,
  updateData as _updateData,
  deleteData as _deleteData,
  batchDeleteData as _batchDeleteData,

} from './service'

const moduleName = 'register'

const getDatas = (params) => {
  console.log('params: ', params);
  return _useSWR([moduleName, `list`, params.page, params.page_size], () => _getDatas(params))
}

const createData = (body) => {
  return _useSWR([moduleName, `create`], () => _createData(body))
}

const getDataDetail = (id) => {
  return _useSWR([moduleName, `detail`, id], () => _getDataDetail(uid))
}

export {
  getDatas,
  createData,
  getDataDetail,
  // updateData,
  // deleteData,
  // batchDeleteData,
  // updateDataStatus,
  // proofreadData,
}