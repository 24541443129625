import basicConfig from 'utils/request-config'
import {message} from '@om-tlh/bee'
import {getQueryStringByName} from 'utils/utils'
import uc from './ucSDk'
import LocalStorageUtil from 'utils/localStorageUtil'
import axios from 'axios'
import utils from 'utils/util'

window.OMAI = {}
window.OMAI.UC = uc

export function loadCustomUcSDK() {
  const ls = LocalStorageUtil
  var auth = ls.getAuth()
  var token = auth && encodeURIComponent(auth)
  if (!token && utils.getCookie('token')) {
    token = utils.getCookie('token')
    LocalStorageUtil.setAuth(`${token}`)
  }
  return new Promise((resolve, reject) => {
    window.ucManager = new window.OMAI.UC({env: window.config.env})
    if (token) {
      basicConfig.token = decodeURIComponent(token)
    }
    resolve(window.ucManager)
  })
}

export function ucValid(tokenId, authUrl) {
  const tmpToken = `${atob(tokenId)}`
   return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.post(`v1/jwt_tokens/check`, { token: tmpToken }, { baseURL: window.config.uc_url})
      const ls = LocalStorageUtil
      ls.setAuth(`${tmpToken}`)
      ls.setCookie('token', `${tmpToken}`)
      basicConfig.token = tmpToken
      if (getQueryStringByName(`token_id`)) {
        global.location.href = global.location.href.replace(new RegExp(`&?token_id=${getQueryStringByName(`token_id`)}`), '')
      }
      resolve()
    } catch (e) {
      ['auth', 'userInfo', 'privileges'].forEach(i => {
        LocalStorageUtil.clearStorage(i)
      })
      LocalStorageUtil.clearAllCookie()
      // global.location.href = '/logout?return_url=' + encodeURIComponent(global.location.href)
      message.error(e.message)
      reject()
    }
  })
}
