import React, { useEffect, useState } from 'react';
import SearchType from '@/utils/searchType'
import service from './service'
import tagService from '@/modules/Tag/service'
import { injectIntl } from 'react-intl';
import TablePage from '@/components/TablePage'
import Nodata from '@/components/Nodata'
import { Button, Tabs, Row, message, Card, Modal, Tag, Image } from '@om-tlh/bee'
import { CloudUploadOutlined } from '@ant-design/icons'
import Path from '@/routes/path'
import { getOptions, STATUS, SOURCE_TYPE } from '@/utils/enum'
import { guid } from '@/utils/utils'
import CatalogList from '@/modules/Catalog';
import { Share, Issue } from '@/modules/common';
import ViewImages from '@/components/ViewImagesAlbum'

const Index = (props) => {
  const { match } = props
  const { params } = match || {}
  const { scope } = params || {}
  const [activeCatalogId, setActiveCatalogId] = useState()
  const [options, setOptions] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [isReload, setIsReload] = useState(false)

  const [reloadData, setReloadData] = useState(null)
  const setLoadData = (func) => {
    setReloadData(() => func)
  }

  useEffect(() => {
    const fetchData = async () => {
      const res = await tagService.getDatas({ page_size: 100, label_type: 'FILE_LABEL' })
      setOptions(res.items ?? [])
    }
    fetchData()
  }, [])

  const onSelect = (key, item) => {
    setActiveCatalogId(key || '')
    // setOptions(item?.content_template_vo?.labels)
  }

  const handleSelect = (rows) => {
    setSelectedRows(rows)
  }

  const callback = () => {
    setSelectedRows([])
    setIsReload(true)
    setTimeout(() => {
      setIsReload(false)
    }, 200)
  }

  const handleUp = (record) => {
    Modal.confirm({
      okButtonProps: { shape: 'round' },
      cancelButtonProps: { shape: 'round' },
      title: '提示',
      content: '确定要入库吗？',
      onOk: () => {
        service.upData(record.file_id, {
          catalog_id: activeCatalogId
        }).then(data => {
          message.success('入库成功')
        })
      }
    })
  }
  const fields = [
    { name: 'key', desc: '序号', render: (text, record, index) => index + 1 },
    { name: 'display_name', desc: '文件名称', showDetail: true, query: true, key: 'display_name', keyLabel: '关键词', form: { type: 'input' } },
    { name: 'source_type', desc: '来源', options: getOptions(SOURCE_TYPE), type: SearchType.KEY_CUSTOM_OPTION, query: false },
    {
      name: 'labels', desc: '标签', render: (text, record) => text?.map(i => <Tag key={i.label_id}>{i.label_name}</Tag>), showRender: true, query: true,
      type: SearchType.KEY_CUSTOM_TABS, options: options?.map((i, d) => ({
        name: i.label_name, value: i.label_id
      })) || [], key: 'label_id'
    },
    { name: 'status', desc: '文件状态', options: getOptions(STATUS), type: SearchType.KEY_CUSTOM_OPTION, query: true },
    { name: 'create_time', desc: '上传时间', type: SearchType.KEY_DATE_TIME, sorter: true },
    {
      name: 'file_pages', desc: <div style={{ textAlign: 'center' }}>查看</div>, render: (text, record) => {
        // console.log('record: ', record);
        let result = []
        if (record.event?.infer_data?.event_result?.length) {
          result = record.event.infer_data.event_result.map((x, idx) => {
            return {
              bbox: x.bbox,
              label: x.label,
              prob: x.prob,
              title: `${types.find(t => t.key === x.label)?.value || x.label}: ${parseInt(x.prob * 10000, 10) / 100}%`
            }
          })
        } else if (record.event?.infer_data?.boxes) {
          result = record.event.infer_data.boxes.map((x, idx) => {
            return {
              bbox: x,
              label: record.event.infer_data.classes[idx],
              prob: record.event.infer_data.scores[idx],
              title: `${types.find(t => t.key === record.event.infer_data.classes[idx])?.value || record.event.infer_data.classes[idx]}: ${parseInt(record.event.infer_data.scores[idx] * 10000, 10) / 100}%`
            }
          })
        }
        return <ViewImages images={text.map(x => x.file_url)} result={result} />
      }, showRender: true
    },
    // {
    //   name: 'action', desc: '操作',
    //   listActions: true,
    //   render: (text, record) => {
    //     return [
    //       <a key='rename' className='btn-action'>重命名</a>,
    //       <a key='recorgnize' className='btn-action'>识别</a>,
    //       <a key='check' className='btn-action'>校对</a>,
    //       <a key='export' className='btn-action'>导出</a>,
    //       <a key='up' className='btn-action' onClick={() => handleUp(record)}>入库</a>,
    //       <Issue key='issue'
    //         idsKey='ids' service={service.issueData} ids={[record.file_id]} />,
    //       <Share key='share' service={service.shareData} id={record.file_id} />,
    //       // <a key='delete' className='btn-action'>删除</a>,
    //     ]
    //   }
    // },
  ]
  const insertBtns = [
  ]
  const batchBtns = []
  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ width: 200, marginRight: 32 }}>
          <Card bodyStyle={{ padding: '24px 0' }}>
            <CatalogList value={activeCatalogId} onChange={onSelect} type='FILE_CATALOG' noInitSelect />
          </Card>
        </div>
        <div style={{ flex: 1 }}>
          <TablePage
            rowKey='file_id'
            name={'文件'}
            fields={fields}
            insertBtns={insertBtns}
            loadData={(catalogId, params) => {
              if (typeof catalogId === 'object') {
                params = catalogId
                catalogId = ''
              }
              return service.getDatas(catalogId, { ...params, belong_type: 'PERSONAL' })
            }
            }
            service={service}
            scope={scope}
            moduleName='file'
            getLoadData={setLoadData}
            catalogId={activeCatalogId}
            // showBatchDelete
            rowSelection={{}}
            {...props}
            // batchDelete={service.batchDeleteData}
            // showDelete
            // batchBtns={batchBtns}
            // onSelect={handleSelect}
            isReload={isReload}
          />
        </div>
      </div>
    </>
  )
}

export default injectIntl(Index);