import { useState, useEffect } from 'react';
import { Button, Modal, Form, message, Divider, Radio, Input, Checkbox, Descriptions } from '@om-tlh/bee'
import { getLabelOptions, INVOICE_TYPES } from '@/utils/enum'
import * as service from '../service'
import commonService from '@/modules/common/service'
import Login, { loginSuccessCallback } from '@/modules/Login'

const formLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 20,
  }
}
export default function (props) {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false)
  const { data = {}, buttonText } = props

  useEffect(() => {
    if (data.tenant_name) {
      form.setFieldsValue({ display_name: data.tenant?.display_name, role_ids: data.roles.map(x => x.role_id) })
    }
  }, [JSON.stringify(data)])

  const handleSwitchAccount = async (us) => {
    const res = await commonService.switchLogin({
      person_id: data.person_id,
      user_id: us.user_id
    })
    loginSuccessCallback({ data: res.find(x => x.person_id === data.person_id && x.user_info.user_id === us.user_id) }, '成功进入')
  }

  return (<>
    <Button
      {...props.buttonProps}
      onClick={() => {
        setOpen(true)
        props.onClick?.()
      }}
    >{buttonText || '详情'}</Button>
    <Modal
      title={props.modalTitle ?? `详情`}
      open={open}
      width={600}
      onCancel={() => {
        setOpen(false)
      }}
      onOk={async () => {
        const vals = await form.validateFields()
        if (data.tenant_name) {
          service.updateTeamInfo(data.tenant.tenant_id, { organization: vals.display_name.trim() }).then(data => {
            message.success('修改成功')
            setOpen(false)
            form.resetFields()
            props.okSuccessCallback?.()
          }).catch(error => {
            message.error(error.data || error.response?.data?.message)
          })
        } else {
          const resua = await commonService.userAccounts(data.person_id)
          const uid = resua.find(x => !x.tenant_name)?.user_id
          // const uid = resua.find(x => x.tenant_name === 'tlh_abc')?.user_id
          const ressl = await commonService.switchLogin({
            person_id: data.person_id,
            user_id: uid,
          })
          const auth = ressl.find(x => x.person_id === data.person_id && x.user_info.user_id === uid)?.token
          service.createTeam({ organization: vals.display_name.trim(), admin_user_name: process.env.NODE_ENV === 'development' ? `dev_om${`${Date.now()}`.slice(-5)}` : void 0 }, {
            auth
          }).then(async res0 => {
            Modal.success({
              title: <div>您已成功创建团队 <span style={{ color: '#1890ff' }}>{res0.name} </span></div>,
              content: <div>立即进入该团队吗？</div>,
              onOk: async () => {
                const res = await commonService.userAccounts(data.person_id)
                handleSwitchAccount(res.find(x => x.tenant_id === res0.id))
              },
              onCancel: () => {
                props.history.push('/')
              },
              okText: '确定',
              closable: true
            })
          }).catch(error => {
            message.error(error.response && error.response.data.message)
          })
        }
      }}
    >
      <Form
        {...formLayout}
        form={form}
        labelWrap
      >
        <Form.Item
          label="团队名称"
          name="display_name"
          rules={[{ required: true, message: '请输入团队名称', whitespace: true }]}
        >
          <Input maxLength={32} />
        </Form.Item>
        {/* <Form.Item name='role_ids'
          style={{ display: 'none' }}
        >
          <Input maxLength={32} />
        </Form.Item> */}
      </Form>
    </Modal>
  </>)
}
