
import { routes as rms } from './ModeSelect/routes'
import { routes as rqs } from './QRScan/routes'
import { routes as rtc } from './ToCompany/routes'
import { routes as rps } from './Success/routes'
import { routes as rpf } from './Fail/routes'
import { routes as rpi } from './RegistPayInfo/routes'

const routes = [
    { routes: rms },
    { routes: rqs },
    { routes: rtc },
    { routes: rps },
    { routes: rpf },
    { routes: rpi }
]

export {
    routes
}
