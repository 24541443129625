// import { dataset } from 'modules/Maintenance/DatasetManagement/reducers.js'
// import { systemConfig } from 'modules/Maintenance/ConfigMaintenance/reducers.js'
// import { distributeCities } from 'modules/Maintenance/DistributeCityManagement/reducers.js'
// import { news } from 'modules/Maintenance/NewsManagement/reducers.js'
// import { banner } from 'modules/Maintenance/BannerManagement/reducers.js'
// import { subjectManagement } from 'modules/Maintenance/SubjectManagement/reducers.js'
// import { datasetSearchFilter } from 'modules/Maintenance/DatasetSearchConditionManagement/reducers.js'
// import { userManage } from 'modules/Maintenance/UserManagement/reducers.js'
// import { institution } from 'modules/Maintenance/InstitutionManagement/reducers.js'

export default {
  // dataset,
  // systemConfig,
  // distributeCities,
  // news,
  // banner,
  // subjectManagement,
  // datasetSearchFilter,
  // userManage,
  // institution
}
