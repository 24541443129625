import axios from 'axios'
const MODULE_NAME = 'receiver';

const getDatas = (params) => axios.get(`/v1/${MODULE_NAME}`, { params })
const createData = (body) => axios.post(`/v1/${MODULE_NAME}`, body)
const getDataDetail = (id) => axios.get(`/v1/${MODULE_NAME}/${id}`)
const updateData = (id, body) => axios.put(`/v1/${MODULE_NAME}/${id}`, body)
const deleteData = (id) => axios.delete(`/v1/${MODULE_NAME}/${id}`)
const batchDeleteData = (id) => axios.delete(`/v1/${MODULE_NAME}/${id}`)
const uploadData = (body) => axios.post(`/v1/${MODULE_NAME}`, body)
const getReceiver = (id) => axios.get(`/v1/${MODULE_NAME}actions/get_receiver/${id}`)

export default {
  getDatas,
  createData,
  getDataDetail,
  updateData,
  deleteData,
  batchDeleteData,
  uploadData,
  getReceiver,
}