import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Input, Button, Select, DatePicker, Radio } from '@om-tlh/bee';
import { SearchOutlined } from '@ant-design/icons';
import SearchType from '@/utils/searchType'
import DictSelect from '../DictSelect'
import styles from './index.module.css'
import SearchInput from './components/SearchInput'

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const format = {
  DATE_FORMAT: 'YYYY-MM-DD',
  DATE_TIME_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  DATE_TIME_FORMAT2: 'YYYY-MM-DD HH:mm',
}

const DiyNumberRange = ({ value = {}, onChange, field }) => {
  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange({
        start,
        end,
        ...value,
        ...changedValue,
      });
    }
  };

  const onStartChange = (e) => {
    const start = parseInt(e.target.value || 0, 10);
    setStart(start);
    triggerChange({
      start: start,
    });
  };

  const onEndChange = (e) => {
    const end = parseInt(e.target.value || 0, 10);
    setEnd(end);
    triggerChange({
      end: end,
    });
  };

  return (
    <div>
      <Input style={{ width: 60 }} value={value.start || start} onChange={onStartChange} placeholder={field.startdesc.toLowerCase()} />
      <span> - </span>
      <Input style={{ width: 60 }} value={value.end || end} onChange={onEndChange} placeholder={field.enddesc.toLowerCase()} />
    </div>
  );
}

/**
 * 搜索组件
 */
const SimpleSearch = (props) => {
  const { intl, handleSearch, size } = props;
  const { formatMessage } = intl;
  const [form] = Form.useForm();
  const { getFieldsValue } = form
  const { fields = [] } = props;
  const [radioValues, setRadioValues] = useState({})

  useEffect(() => {
    const field = fields.find(field => field.type === SearchType.KEY_CUSTOM_TABS)
    var values = getFieldsValue && getFieldsValue()
    if (!(field && field.options[0])) return
    // handleSearch && handleSearch({ ...values, [field.name]: field.options[0].value })
  }, [])

  useEffect(() => {
    props.wrappedComponentRef && props.wrappedComponentRef(form)
  })

  const handleResetSubmit = () => {
    const { getFieldsValue, resetFields } = form
    resetFields()
    var values = getFieldsValue()
    for (var i in values) {
      values[i] = undefined
      // values['start_time'] = undefined
      // values['end_time'] = undefined
    }
    setRadioValues(Object.entries(radioValues).reduce((p, c) => ({ ...p, [c[0]]: '' }), {}))
    handleSearch && handleSearch(values)
  }

  const handleSubmit = (values) => {
    const { fields = [] } = props
    const { handleSearch } = props
    fields.forEach(field => {
      if (values[field.name]) {
        const type = field.type
        let value = values[field.name]
        switch (type) {
          case SearchType.KEY_DATE:
            value = value ? value.format(format.DATE_FORMAT) : ''
            break;
          case SearchType.KEY_DATE_TIME:
            value = value ? value.format(format.DATE_TIME_FORMAT) : ''
            break;
          case SearchType.KEY_DATE_RANGE:
            value = value[0] && value[1] ? [value[0].format(format.DATE_FORMAT), value[1].format(format.DATE_FORMAT)] : []
            break;
          case SearchType.KEY_DATE_MINUTE_RANGE:
            value = value[0] && value[1] ? [value[0].format(format.DATE_TIME_FORMAT2), value[1].format(format.DATE_TIME_FORMAT2)] : []
            break;
          case SearchType.KEY_DATE_TIME_RANGE:
            value = value[0] && value[1] ? [value[0].format(format.DATE_TIME_FORMAT), value[1].format(format.DATE_TIME_FORMAT)] : []
            break;
          default:
            break
        }
        if (type === SearchType.KEY_DATE_TIME_RANGE || type === SearchType.KEY_DATE_MINUTE_RANGE || type === SearchType.KEY_DATE_RANGE) {
          delete values[field.name]
          value[0] && (values['start_time'] = value[0])
          value[1] && (values['end_time'] = value[1])
        } else {
          values[field.name] = value
        }
      }
    })
    handleSearch && handleSearch({ ...values, ...(fields.filter(i => i.type === SearchType.KEY_CUSTOM_TABS).length ? radioValues : {}) })
  };
  const onSearch = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    const vals = await form.validateFields()
    handleSubmit(vals)
  }
  const onValuesChange = (values, allValues) => {
    if (size !== 'small') return
    handleSubmit(allValues)
  }

  const _buildFilterComponent = (field) => {
    let fc = null;
    let type = field.type;
    switch (type) {
      case SearchType.KEY_DATE:
        fc = <DatePicker onChange={(date, dateString) => new Date(dateString).getTime()} />;
        break;
      case SearchType.KEY_DATE_TIME:
        fc = <DatePicker showTime onChange={(date, dateString) => new Date(dateString).getTime()} />;
        break;
      case SearchType.KEY_DATE_RANGE:
        fc = <RangePicker />;
        break;
      case SearchType.KEY_DATE_MINUTE_RANGE:
        fc = <RangePicker showTime format="YYYY-MM-DD HH:mm" />;
        break;
      case SearchType.KEY_DATE_TIME_RANGE:
        fc = <RangePicker showTime format="YYYY-MM-DD HH:mm:ss" />;
        break;
      case SearchType.KEY_CUSTOM_OPTION:
        fc = <Select style={{ width: size === 'small' ? 110 : 160 }}
          getPopupContainer={field.getPopupContainer || (() => document.getElementById('content'))}
          onChange={field.onChange ? field.onChange : () => { }}>
          {[{ value: '', name: size === 'small' ? `全部${field.desc}` : '全部' }, ...field.options]
            .map((i, d) => <Option key={d} value={i.value}>{i.name}</Option>)}
        </Select>
        break;

      case SearchType.KEY_SEARCH_INPUT:
        fc = <SearchInput {...props} options={field.options} />
        break;
      case SearchType.KEY_CUSTOM_TABS:
        if (!field.options.length) {
          return
        }
        let options = []
        if (field.options[0].hasOwnProperty('count')) {
          options = [{ value: '', name: size === 'small' ? `全部${field.desc}` : '全部', count: field.options.reduce((pre, cur) => cur.count + pre, 0) }, ...field.options]
          fc = <Radio.Group className='home_tab' size={size} defaultValue={options[0].value} onChange={(e) => handleChangeRadioGroup(e, field)} value={radioValues[field.key || field.name]} >
            {options.map((i, d) => <Radio.Button key={d} value={i.value}>{i.name}({i.count})</Radio.Button>)}
          </Radio.Group>
        } else {
          options = [{ value: '', name: size === 'small' ? `全部${field.desc}` : '全部' }, ...field.options]
          fc = <Radio.Group defaultValue={options[0].value} onChange={(e) => handleChangeRadioGroup(e, field)} value={radioValues[field.key || field.name]} >
            {options.map((i, d) => <Radio.Button key={d} value={i.value}>{i.name}</Radio.Button>)}
          </Radio.Group>
        }

        break;
      case SearchType.DIY_COMPONENT:
        fc = <Select style={{ width: 160 }} onChange={field.onChange ? field.onChange : () => { }}>
          {(field.options || []).map((i, d) => <Option key={d} value={i.value}>{i.name}</Option>)}
        </Select>
        break;
      case SearchType.DIY_RANGE_NUMBER:
        fc = <DiyNumberRange field={field} />
        break;
      case SearchType.INPUT_SEARCH:
        fc = <Input
          placeholder={field.queryForm?.placeholder || formatMessage({ id: 'message_input' }) + (field.keyLabel || field.desc).toLowerCase()}
          allowClear
          suffix={<SearchOutlined
            style={{
              fontSize: 16,
              color: '#999',
              cursor: 'pointer'
            }}
            onClick={onSearch}
          />}
          onPressEnter={onSearch}
          onChange={(e) => {
            if (!e.target.value) {
              onSearch(e)
            }
          }}
          style={{
            width: 200,
          }}
          {
          ...field.queryForm
          }
        />
        break;
      default:
        if (type && type.indexOf(SearchType.KEY_COMMON_ENMU) !== -1) {
          let dictName = type.split('-')[1];
          fc = <DictSelect {...props} dictName={dictName} placeholder={formatMessage({ id: 'message_select' }) + field.desc.toLowerCase()} />
        } else {
          fc = <Input placeholder={formatMessage({ id: 'message_input' }) + (field.keyLabel || field.desc).toLowerCase()} />
        }
    }

    return fc
  }

  const handleChangeRadioGroup = (e, field) => {
    const { handleSearch } = props
    const { value } = e.target;
    var values = getFieldsValue && getFieldsValue()
    setRadioValues((pre) => ({ ...pre, [field.key || field.name]: value }))
    handleSearch && handleSearch({ ...values, [field.key || field.name]: value })
  }

  return (
    <div className={styles['search-bar']}>
      {
        fields.filter(i => i.type !== SearchType.KEY_CUSTOM_TABS).length
          ? <Form layout="inline" form={form} onFinish={handleSubmit} onValuesChange={onValuesChange}>
            {
              fields.filter(i => i.type !== SearchType.KEY_CUSTOM_TABS).map((field, index) => {
                return (
                  <Form.Item style={{ marginBottom: size === 'small' ? 10 : 10 }} key={index} name={field.key || field.name}
                    label={(size === 'small' && false || SearchType.INPUT_SEARCH === field.type) ? null : (field.keyLabel || field.desc)} colon={field.hideColon ? false : true} initialValue={field.defaultValue || ''} required={field.required} rules={field.rules} >
                    {_buildFilterComponent(field)}
                  </Form.Item>
                )
              })
            }
            {
              fields.filter(i => i.type !== SearchType.KEY_CUSTOM_TABS).length === 1 && fields.filter(i => i.type !== SearchType.KEY_CUSTOM_TABS)[0].type === SearchType.INPUT_SEARCH ? null : <Form.Item style={{ marginBottom: 0, display: size === 'small' ? 'none' : 'inline-block' }}>
                <Button
                  size={size} htmlType="submit"
                  type='primary'
                >{formatMessage({ id: 'search' })}</Button>
                <Button
                  size={size}
                  onClick={handleResetSubmit} style={{ marginLeft: 10 }}
                  type='primary' ghost
                >{formatMessage({ id: 'reset' })}</Button>
              </Form.Item>
            }

          </Form>
          : null
      }
      {
        fields.filter(i => i.type === SearchType.KEY_CUSTOM_TABS).map((i, d) => {
          return (
            <div key={d} style={{ marginTop: size === 'small' ? 0 : 10, display: 'flex', alignItems: 'center', marginBottom: 24 }}>
              <span style={{ display: size === 'small' ? 'none' : 'inline-block' }}>{i.desc}：</span>
              {_buildFilterComponent(i)}
            </div>
          )
        })
      }
    </div>
  )
}



export default injectIntl(SimpleSearch)

