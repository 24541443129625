import axios from 'axios';
import config from 'config';
const MODULE_NAME = 'object_storage';

/**
 * 获取对象存储ak/sk
 * @param {*} params 
 */
const getAuthInfo = (params) => axios.get(`/v1/${MODULE_NAME}/auth_info`, { params, baseURL: config.oss_url })

/**
 * 获取文件上传授权URL
 * @param {*} params 
 */
const getUploadAccess = async (params) => {
  // return {
  //   upload_url: process.env.NODE_ENV === 'development' ? 'http://192.168.1.49:3200/api/v1/file/upload' : 'http://192.168.1.49:3200/api/v1/file/upload'
  // }
  return axios.get(`/v1/${MODULE_NAME}/upload_access`, { params, baseURL: config.api_url, noCamelToSnake: false })
}

export default {
  getAuthInfo,
  getUploadAccess,
}
