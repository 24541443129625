import React, { useState, useRef, Component } from 'react';
import {
  message,
  Upload,
  Progress,
  Button, Modal
} from '@om-tlh/bee';
import UploadImage from './components/UploadImage';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons'
import ReactQuill from '@om-tlh/react-quill';
import service from '../UploadCover/service';
import commonService from '@/modules/common/service'
import axios from 'axios';
import '@om-tlh/react-quill/dist/quill.snow.css';
import './index.css';

const Dragger = Upload.Dragger

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      url: '',
      cursorPosition: 0,
      fileName: '',
      isUploading: 0,
      uploadPercent: 0,
      loading: false,
      fileList: [],

      uploadVisible: false,
      uploadSession: {}
    }
    this.quillRef = null;
  }
  getUploadSession = () => {
    if (!this.state.fileList.length) {
      return
    }
    let params = {}
    params.asset_type = 'PICTURE'
    params.object_name = this.state.fileList[0].name
    params.content_type = this.state.fileList[0].type
    return service.getUploadAccess(params)
  }
  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('请上传 JPG/PNG 图片!');
      return false
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error('图片大小不超过 5MB!');
      return false
    }
    this.setState({ fileList: [file], fileName: file.name });
    return false;
  }
  handleClick = () => {
    const quill = this.quillRef.getEditor();
    const cursorPosition = (quill.getSelection() && quill.getSelection().index) || 0;
    this.setState({ uploadVisible: true, cursorPosition })
  }
  handleUpload = () => {
    const that = this
    const { fileList, cursorPosition } = this.state;
    if (!fileList || !fileList.length) return message.error('请选择文件')
    // if (this.props.maxSize && fileList[0].size > this.props.maxSize * 1024) {
    //   return message.error(`请不要超过${this.props.maxSize / 1024}MB`)
    // }
    this.setState({ uploadStatus: 'loading' })
    this.getUploadSession().then(uploadSession => {
      const actionUrl = uploadSession.upload_url || ''
      this.setState({ uploadStatus: 'loading' })
      axios({
        url: actionUrl,
        method: 'PUT',
        headers: {
          'Content-Type': fileList[0].type
        },
        data: fileList[0],
        onUploadProgress: (e) => {
          this.setState({ uploadPercent: e.loaded / e.total })
        },
        timeout: 1000 * 60 * 5,
        unAuth: true
      }).then(async () => {
        message.success('上传成功')
        this.setState({
          uploadVisible: false, fileList: [], page: 1, uploadStatus: ''
        })
        const url = await commonService.getOSSTempUrl({ object_key: uploadSession.access_url })
        if (!url) return
        const quill = this.quillRef.getEditor();
        quill.insertEmbed(cursorPosition, "image", url);//插入图片
        quill.setSelection(cursorPosition + 1);//光标位置加1
      }, (error) => {
        this.setState({ uploadStatus: '' })
        message.error(error.response.data.message);
      });
    })
  }
  handleChange = (value) => {
    this.setState({ url: value })
  }
  handleOk = () => {
    const { url, cursorPosition } = this.state
    if (!url) return
    const quill = this.quillRef.getEditor();
    quill.insertEmbed(cursorPosition, "image", url);//插入图片
    quill.setSelection(cursorPosition + 1);//光标位置加1
    this.setState({ visible: false })
    this.setState({ url: '' })
  }

  handleCancel = () => {
    if (this.state.status === 'loading') return
    this.setState({ visible: false })
    this.setState({ url: '' })
  }
  render() {
    const { visible, url } = this.state
    let props = {
      // showUploadList: false,
      fileList: this.state.fileList,
      onRemove: () => this.setState({ fileList: [], fileName: '' }),
      beforeUpload: (file) => this.beforeUpload(file),
      accept: 'image/*',
      onChange(info) {
      }
    }
    return <>
      <ReactQuill
        ref={ref => this.quillRef = ref}
        value={this.props.value || ''}
        modules={
          {
            toolbar: {
              container: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],

                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                [{ 'direction': 'rtl' }],                         // text direction

                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'font': [] }],
                [{ 'align': [] }],

                ['link', 'image'],

                ['clean']                                         // remove formatting button
              ],
              handlers: {
                'image': this.handleClick
              }
            },
          }
        }
        // ImageDrop={true}
        onChange={this.props.onChange}
      />
      <Modal
        okButtonProps={{ shape: 'round' }}
        cancelButtonProps={{ shape: 'round' }}

        open={this.state.uploadVisible}
        title='上传图片'
        onOk={() => this.handleUpload()}
        onCancel={() => {
          if (this.state.uploadStatus === 'loading') return
          this.setState({ uploadVisible: false })
        }}
        wrapClassName='upload_excel_modal'
      >
        {
          !this.state.uploadStatus
            ? <Dragger {...props} >
              <p className="dzt-upload-drag-icon">
                <UploadOutlined />
              </p>
              <p className="dzt-upload-text">点击或者拖动文件到此区域进行上传</p>
              <p className="dzt-upload-hint">文件格式支持jpg、png，文件大小不超过5MB！</p>
            </Dragger>
            : null
        }
        {this.state.uploadStatus
          ? <div style={{ textAlign: 'center' }}>
            <p>{this.state.fileList[0] && this.state.uploadPercent > 0.98 ? '上传成功' : ''}</p>
            <Progress type='circle' percent={this.state.uploadPercent * 100} />
          </div>
          : ''}
      </Modal>
    </>
  }
}