import React, { useState } from 'react';
import SearchType from '@/utils/searchType'
import service from './service'
import { injectIntl } from 'react-intl';
import TablePage from '@/components/TablePage'
import ViewCover from '@/components/ViewCover'
import DisableEnable from '@/components/DisableEnable'
import { Button, Tabs, Row, Col, Card, Menu } from '@om-tlh/bee'
import { CloudUploadOutlined } from '@ant-design/icons'
import Path from 'routes/path'
import { getOptions, COMPANY_TYPE, ORDER_STATE, PRODUCT_TYPE } from 'utils/enum'
import util from '@/utils/util'
import { guid } from '@/utils/utils'

const Index = (props) => {
  const { match } = props
  const { params } = match || {}
  const { scope } = params || {}
  const [activeKey, setActiveKey] = useState('1')

  const [reloadData, setReloadData] = useState(null)
  const setLoadData = (func) => {
    setReloadData(() => func)
  }

  const fields = [
    { name: 'key', desc: '序号', render: (text, record, index) => index + 1, width: 70 },
    { name: 'receiver_name', desc: '姓名', showDetail: true, query: true, key: 'keyword', keyLabel: '关键词', form: {} },
    { name: 'mobile', desc: '联系方式', form: {} },
    { name: 'organization', desc: '公司名称', form: {} },
    { name: 'description', desc: '备注', form: { type: 'textarea' } },
    { name: 'create_time', desc: '创建时间', type: SearchType.KEY_DATE_TIME, sorter: true },
  ]
  const insertBtns = []
  return (
    <>
      <TablePage
        {...props}
        rowKey='receiver_id'
        name={'接收人'}
        fields={fields}
        insertBtns={insertBtns}
        loadData={service.getDatas}
        service={service}
        scope={scope}
        moduleName='receiver'
        getLoadData={setLoadData}
        // showBatchDelete
        rowSelection={{}}
      // batchDelete={service.batchDeleteData}
      // showCreate
      // showEdit
      // showDelete
      />
    </>
  )
}

export default injectIntl(Index);