import React from 'react';
import { Upload, message, Modal } from '@om-tlh/bee';
import service from './service';
import config from 'config';
import { PlusOutlined } from '@ant-design/icons';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class UploadCover extends React.Component {

  state = {
    loading: false,
    upConfig: {},
    fileList: [],
    previewVisible: false,
    previewImage: '',
    curImg: {}
  };

  componentDidMount() {
    this.setState({
      fileList: this.props.value ? this.props.value.map((i, d) => {
        const nameArr = i.name ? i.name.split('/') : i.split('/')
        return {
          uid: d + 1,
          name: nameArr[nameArr.length - 1],
          status: 'done',
          url: config.img_url + i
        }
      }) : []
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value) {
      console.log('nextProps.value: ', nextProps.value);
      const { fileList } = this.state
      this.setState({
        fileList: nextProps.value.map((i, d) => {
          if (typeof i === 'string') {
            const nameArr = i.split('/')
            return {
              uid: fileList.find(j => j.url === i) ? fileList.find(j => j.url === i).uid : d + 1,
              name: nameArr[nameArr.length - 1],
              status: 'done',
              url: i.indexOf('http') !== -1 ? i : (config.img_url + i),
              thumbUrl: i.indexOf('http') !== -1 ? i : (config.img_url + i),
              baseUrl: i
            }
          } else {
            return {
              // uid: fileList.find(j => j.name === i.name) ? fileList.find(j => j.name === i.name).uid : d + 1,
              // name: nameArr[nameArr.length - 1],
              // status: 'done',
              // url: i.thumbUrl || i.url,
              // thumbUrl: i.thumbUrl || i.url,
              ...i
            }
          }

        })
      })
    }
  }

  fetchUploadToken = async (file) => {
    const formData = new FormData();
    formData.append('file', file)
    const res = await service.upload(formData)
    console.log('res: ', res);
    this.setState({
      curImg: {
        ...file,
        url: '/img/' + String(res)
      }
    })
  }

  getUploadToken = () => {
    const { fileKey } = this.state
    const arr = fileKey.split('.')
    const ext = arr[arr.length - 1]
    const rnd = +new Date() + '_' + Math.random().toString(36).slice(-6)
    return {
      token: this.state.uploadToken,
      key: [rnd, ext].join('.')
    }
  }

  beforeUpload = async (file) => {
    let tag = false;
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('请选择JPG，PNG文件！');
      return tag;
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error('图片大小不能超过5MB！');
      return tag;
    }
    tag = isJpgOrPng && isLt2M;
    await this.fetchUploadToken(file)
    tag = true;
    return false;
  }

  handleChange = (info) => {
    console.log('info: ', info);
    const { onChange } = this.props
    const { curImg } = this.state
    this.setState({
      fileList: info.fileList
    }, () => {
      onChange && onChange(this.state.fileList.map(i => i.uid === curImg.uid ? curImg.url : i.baseUrl))
    });
    // onChange && onChange(info.fileList)
    console.log('info.fileList: ', info.fileList);
    if (info.file.status === 'uploading') {
      this.setState({
        loading: true,
        fileList: info.fileList
      });
    }
    if (info.file.status === 'done') {
      const imageUrl = `${schema}://${domain}/${info.file.response.key}`
      fileList = fileList.map(i => {
        if (i.uid === info.file.uid)
          i.url = imageUrl
        return i
      })
      this.setState({
        loading: false,
        fileList: fileList
      }, () => {
        onChange && onChange(this.state.fileList.map(i => i.url))
      })
    }
    if (info.file.status === 'removed') {
      this.setState({
        fileList: info.fileList
      }, () => {
        onChange && onChange(this.state.fileList.map(i => i.baseUrl))
      });
    }
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  render() {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { loading, previewVisible, previewImage, fileList } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined style={{ fontSize: 24 }} />
        <div className="dzt-upload-text">点击上传</div>
      </div>
    );
    return (
      <>
        <Upload
          // name="cover"
          listType="picture-card"
          className="avatar-uploader"
          fileList={fileList}
          action={`${config.img_url}/v1/upload`}
          // data={() => this.getUploadToken()}
          beforeUpload={this.beforeUpload}
          onChange={this.handleChange}
          onPreview={this.handlePreview}
          accept=".png, .jpeg, .jpg"
        >
          {fileList.length >= 5 ? null : uploadButton}
        </Upload >
        <Modal
        okButtonProps={{ shape: 'round' }}
        cancelButtonProps={{ shape: 'round' }}
 open={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </>
    )
  }

}

export default UploadCover;