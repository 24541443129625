import React, { useState, useEffect } from 'react';
import SearchType from '@/utils/searchType'
import service from './service'
import tenantService from '../Tenant/service'
import roleService from '../Role/service'
import { injectIntl } from 'react-intl';
import TablePage from '@/components/TablePage'
import { Button, Tag, Modal, message, Form, Checkbox } from '@om-tlh/bee'
import LocalStorageUtil from 'utils/localStorageUtil'
import Path from 'routes/path'
import utils from '@/utils/util'

const Index = (props) => {
  const { intl, match } = props
  const { params } = match || {}
  const { scope } = params || {}
  const { formatMessage } = intl
  const [options, setOptions] = useState([])
  const [roleOpts, setRoleOpts] = useState([])
  const [visible, setVisible] = useState(false)
  const [record, setRecord] = useState({})
  const [reload, setReload] = useState(false)
  const userInfoStr = LocalStorageUtil.getUserInfo()
  const [form] = Form.useForm()
  let userInfo = {}
  try {
    userInfo = JSON.parse(userInfoStr)
  } catch (e) { }

  useEffect(() => {
    const fetchData = async () => {
      const res = await roleService.getDatas({ page_size: 100 })
      setRoleOpts(res.items)
    }
    fetchData()
  }, [])

  const _handleResetPassword = (e, record) => {
    Modal.confirm({
      okButtonProps: { shape: 'round' },
      cancelButtonProps: { shape: 'round' },
      title: '重置密码',
      content: '确定要重置密码吗？',
      onOk: () => {
        service.resetPassword(record.user_id).then(data => {
          message.success('重置密码成功')
          Modal.success({
            title: `密码重置成功，当前新密码是：${data.data || Math.random().toString(32).slice(4, 8)}`,
          });
        })
      },
      onCancel: () => {

      }
    })
  }

  const fields = [
    { name: 'key', desc: '序号', render: (text, record, index) => index + 1, width: 80 },
    ...options.length
      ? [{
        name: 'tenant_id', desc: '租户名',
        // query: true, 
        type: SearchType.KEY_SEARCH_INPUT,
        options: options, defaultValue: userInfo.tenant_id, hide: true
      }]
      : [],
    { name: ['person_info', 'nick_name'], desc: '用户名', showDetail: true, query: true },
    // { name: 'card_no', desc: '执法证件号' },
    {
      name: 'roles', desc: '角色', render: (text, record) => {
        return text && text.length ? text.map((i, k) => {
          return <Tag color='blue' key={k}>{i.display_name}</Tag>
        }) : '-'
      }, showRender: true
    },
    { name: 'description', desc: '说明' },
    { name: 'create_time', desc: '创建时间', type: SearchType.KEY_DATE_TIME },
    {
      name: 'action', desc: '操作',
      listActions: true,
      render: (text, record) => {
        return [
          utils.hasPermission('MGT_TENANT_USER') && <RoleForm {...props} key='assign' record={record} roleOpts={roleOpts} reload={reload} setReload={setReload} />,
          // <a key='assign' className='btn-action' onClick={e => showModal(e, record)}>分配角色</a>,
          <a key='reset' className='btn-action' onClick={(e) => _handleResetPassword(e, record)}>重置密码</a>
        ].filter(Boolean)
      }
    },
  ]
  const insertBtns = [
    <Button shape='round' key='create' type='primary' onClick={() => props.history.push(Path.getPathname(props, Path.pathname['user']['create']))}>创建</Button>
  ]
  return (
    <>
      <div className="content-header">
        <h2>用户管理</h2>
      </div>
      <div className="content-body">
        <TablePage
          rowKey='user_id'
          name={'用户数据'}
          fields={fields}
          insertBtns={insertBtns}
          loadData={service.getDatas}
          isReload={reload}
          deleteData={service.batchDeleteData}
          service={service}
          scope={scope}
          showDelete={utils.hasPermission('DELETE_USER')}
          moduleName='user'
        />
      </div>
    </>
  )
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const RoleForm = (props) => {
  const { record, roleOpts } = props
  // const [roleOpts, setRoleOpts] = useState([])
  const [visible, setVisible] = useState(false)
  const userInfoStr = LocalStorageUtil.getUserInfo()
  const [form] = Form.useForm()
  let userInfo = {}
  try {
    userInfo = JSON.parse(userInfoStr)
  } catch (e) { }
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const res = await roleService.getDatas({ page_size: 100 })
  //     setRoleOpts(res.items)
  //   }
  //   // fetchData()
  // }, [])

  const showModal = (e, record) => {
    setVisible(true)
    // setRecord(record)
    form.setFieldsValue({
      'roles': record.roles.map(i => i.role_id)
    })
  }

  const handleOk = () => {
    form.validateFields().then(values => {
      (userInfo.tenant_id
        ? service.assignSystemRoles(record.user_id, values.roles, { tenant_id: userInfo.tenant_id })
        : service.assignRoles(record.user_id, values.roles)).then(data => {
          handleCancel()
          props.setReload(!props.reload)
          message.success('分配角色成功')
        }).catch(error => {
          message.error(error.response && error.response.data.message)
        })
    })
  }

  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <>
      <a key='assign' className='btn-action' onClick={e => showModal(e, record)}>分配角色</a>
      <Modal
        okButtonProps={{ shape: 'round' }}
        cancelButtonProps={{ shape: 'round' }}
 
        width={600}
        title='分配角色'
        open={visible}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
      >
        <Form form={form} {...layout}>
          <Form.Item label='选择角色' name='roles'>
            <Checkbox.Group options={roleOpts.map(i => ({ ...i, label: i.display_name, value: i.role_id }))} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default injectIntl(Index);