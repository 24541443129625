import React from 'react';
import { Tag, Button } from '@om-tlh/bee';

/**
 * tag值超出隐藏显示组件
 */
class ViewMoreTags extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: false
    }
  }

  handleChange = () => {
    this.setState({ visible: !this.state.visible })
  }

  render() {
    const { intl, value, privileges } = this.props;
    const { formatMessage } = intl;
    const { visible } = this.state;
    if (!value) return
    return (
      <div>
        {
          (visible ? value : value.slice(0, 5)).map((i, k) => {
            const privilege = privileges.find(j => i === j.code)
            return <Tag style={{ marginBottom: 5 }} color='blue' key={k}>
              {privilege ? privilege.display_name : i}
            </Tag>
          })
        }
        {
          value.length > 5
            ? <Button shape='round' size='small' onClick={this.handleChange} title={visible ? '收缩' : '展开'}>{visible ? '<' : '>'}</Button>
            : null
        }
      </div>
    )
  }

}

export default ViewMoreTags;