import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Layout, Space, Divider, Badge } from '@om-tlh/bee';
import { BellOutlined } from '@ant-design/icons'
import './index.css'
import './index.less'
import Nav from './nav'
import UserProfile from './components/UserProfile'
import LocalStorageUtil from 'utils/localStorageUtil'
import SStorageUtil from 'utils/sessionStorageUtil'
import logo from 'theme/img/logo.png'
import logoSVG from 'theme/img/logo.svg'
import Path from 'routes/path'
import utils from 'utils/util'
import commonService from '@/modules/common/service'
import Share from './components/Share'
import QuickLink from './components/QuickLink'
import OmaiFooter from './components/Footer'
import PlatformSelect from './components/PlatformSelect'
import ReadMessage from './components/ReadMessage'
import MiniProgram from './components/MiniProgram'

const { Header, Content, Sider, Footer } = Layout;

/**
 * 带导航框架
 */
const FrameMenu = (props) => {
  const { intl, location, match, routeRegistry } = props;
  const { formatMessage } = intl;
  const [collapsed, setCollapsed] = useState(false);
  const [userInfo, setUserInfo] = useState({})

  useEffect(() => {
    try {
      setUserInfo(JSON.parse(LocalStorageUtil.getUserInfo()))
    } catch (e) {
      commonService.getUserInfo().then((s) => {
        const res = { ...s }
        setUserInfo(res)
        const { roles = [] } = res
        let privileges = []
        roles && roles.forEach(i => {
          privileges.push(...(i.privileges || []))
        })
        LocalStorageUtil.setUserInfo(JSON.stringify(res))
      })
    }
    return () => {
      if (window.globalSocket) {
        window.globalSocket.close()
        window.globalSocket = null
      }
    }
  }, [])
  useEffect(() => {
    if (!routeRegistry.find(i => utils.matchPath(i.path, location.pathname.replace(match.url === '/' ? '' : match.url, '')))) {
      props.history.push(`${match.url === '/' ? '' : match.url, ''}${Path.getPathname(props, Path.pathname['Error404'])}`)
    }
  }, [location.pathname])

  const onCollapse = collapsed => {
    setCollapsed(collapsed);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    ['auth', 'userInfo', 'privileges', 'platform_id'].forEach(i => {
      LocalStorageUtil.clearStorage(i)
    })
    LocalStorageUtil.clearAllCookie()
    SStorageUtil.clearSStorage()
    props.history.push(`/login?return_url=${encodeURIComponent(window.location.href)}`)
    window.location.href = `${window.__IN_CLOUD__ ? `/${props.appType ? [...props.appType.split('/'), `${window.appName}`].filter(Boolean).join('/') : `${window.appName}`}` : (props.appType ? `/${props.appType}` : ``)}/login?return_url=${encodeURIComponent(window.location.href.replace(new RegExp(`&?token_id=${utils.getQueryStringByName(`token_id`)}`), ''))}`
    // const uc = process.env.NODE_ENV === 'production' ? '' : utils.getDomain('ucCenter') || '';
    // window.location.href = `${uc}/logout?return_url=${encodeURIComponent(window.location.href)}`
  }
  return (
    window.__IN_CLOUD__ ? <Layout>
      <Sider
        // collapsible
        width={150}
        className="sidebar"
        breakpoint='xl'
        collapsedWidth={48}
        collapsed={collapsed}
        onCollapse={onCollapse}>
        <Nav {...props} />
      </Sider>
      <Content className="cloud-content-wrap">
        <div className={`content ${location.pathname.indexOf('/home') !== -1 ? 'no-back' : ''}`} id="content">
          {props.children}
        </div>
      </Content>
    </Layout> : <Layout style={{
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      // width: '100%',
      height: '100%',
      overflow: 'auto'
    }} >
      <Header className="header" style={{ padding: '0 30px', minWidth: 800, height: 80, lineHeight: '80px' }}>
        <div className="logo mkt-logo"
          title={formatMessage({ id: 'app_name' })}>
          <img
            style={{ cursor: 'pointer' }}
            onClick={() => {
              props.history.push('/')
            }}
            className="logo-img" src={logoSVG} alt="" />
        </div>
        <div className="header-right dzt-menu-horizontal" style={{
          // backgroundColor: '#262F3E',
          lineHeight: '80px'
        }}>
          {/* <li style={{ display: 'inline-block', marginRight: 20 }}>
            <QuickLink {...props} />
          </li> */}
          <li style={{ display: 'inline-block', marginRight: 20 }}>
            <MiniProgram />
          </li>
          <li style={{ display: 'inline-block', marginRight: 20 }}>
            <Share />
          </li>
          <Divider type='vertical' style={{ margin: 0 }} />
          <li style={{ display: 'inline-block', }}>
            <PlatformSelect {...props} userInfo={userInfo} />
          </li>
          <Divider type='vertical' style={{ margin: 0 }} />
          <li className="dzt-menu-item">
            <ReadMessage {...props} />
            {/* <a onClick={() => {
              props.history.push(`/msg/manage`)
            }} rel='noopener norefferer'>
              <Badge dot={msgCount > 0}>
                <BellOutlined
                  style={{
                    fontSize: 16,
                  }}
                />
              </Badge>
            </a> */}
          </li>
          <li className="dzt-menu-item">
            <UserProfile {...props} userInfo={userInfo} handleLogout={handleLogout} />
          </li>
        </div>
        {/* <h1 className='header-title'>{formatMessage({ id: 'web_title' })}</h1> */}
        {/* <PlatformSelect {...props} userInfo={userInfo} /> */}
      </Header>
      <Content
        style={{ minHeight: 'auto', position: 'relative', backgroundColor: '#F6F9FC', minWidth: 800 }}
      >
        <Layout>
          <Sider
            // collapsible
            breakpoint='xl'
            // width={220}
            className="sidebar"
            collapsedWidth={48}
            collapsed={collapsed}
            onCollapse={onCollapse}
            style={{
              backgroundColor: '#fff',
              boxShadow: '-1px 0px 65px 0px rgba(85,85,85,0.08)'
            }}
          >
            <Nav {...props} />
            {/* <div className='copyright'>系统版本: v2.0.1<br />由东云睿连（武汉）计算技术有限公司提供技术支持</div> */}
          </Sider>
          <div className="cloud-content-wrap" id="contentWrap">
            <Content className='main-content'>
              <div className='content' id="content">
                {props.children}
              </div>
            </Content>
          </div>
        </Layout>
      </Content>
      <Footer style={{ textAlign: 'center', padding: 0, minWidth: 800 }}>
        <OmaiFooter {...props} />
      </Footer>
    </Layout>
  )
}

export default injectIntl(FrameMenu)
