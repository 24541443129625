import React, { Component } from 'react'
import { IntlProvider, injectIntl, FormattedMessage } from 'react-intl'
import { Divider } from '@om-tlh/bee'

function Footer(props) {
  const { intl: { formatMessage }, websiteInfo = {} } = props;
  const { copyright, client_name, client_email, show_authority_info } = websiteInfo || {}
  const { showFooter = 'true', showCopyright } = (window.__site__config || {})

  return <div className="footer footer-info">
    <div >
      {
        showCopyright === 'true' && <p>
          Copyright&copy;2023&nbsp;&nbsp;东云睿连&nbsp;Dongyunruilian.com 版权所有
        </p>
      }
      <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42018502004389" target="_blank">
        <img className="icon-security" src="/images/icon-security.png" alt="" />
        <span className="security-text">鄂公网安备 42018502004389号</span>
      </a>
      <Divider type="vertical" />
      <a href="https://beian.miit.gov.cn" target="_blank">鄂ICP备20000398号-1</a>
      <Divider type="vertical" />
      <a href="https://beian.miit.gov.cn/" target="_blank">增值电信业务经营许可证：鄂B1.B2-20220926</a>
    </div>
  </div>
}
export default injectIntl(Footer)