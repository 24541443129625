import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Dropdown, Menu, Avatar } from '@om-tlh/bee';
import { UserOutlined, DownOutlined, } from '@ant-design/icons';
import styles from './index.module.css'
import Path from 'routes/path'
import Login from 'components/Login';
import LocalStorageUtil from 'utils/localStorageUtil'
import commonService from '@/modules/common/service'
import { loginSuccessCallback } from '@/modules/Login'

const Index = (props) => {
  const { intl, userInfo = {} } = props;
  const username = userInfo.person_info?.nick_name ?? userInfo.user_name ?? userInfo.user_id
  const { formatMessage } = intl;
  const [accountInfo, setAccountInfo] = useState([])
  // let userInfo = LocalStorageUtil.getAccountInfo()
  // userInfo = userInfo ? JSON.parse(userInfo) : []
  // let accountInfo = LocalStorageUtil.getAccountInfo()
  // accountInfo = accountInfo ? JSON.parse(accountInfo) : []

  useEffect(() => {
    const fetchData = async () => {
      const res = await commonService.userAccounts(userInfo.person_id)
      setAccountInfo(res)
    }
    if (!userInfo.person_id) return
    // fetchData()
  }, [userInfo.person_id])
  if (!username) {
    return <a onClick={(e) => props.handleLogout(e)} >登录</a>
    // return <Login {...props} />
  }


  const handleSwitchAccount = async (us) => {
    const data = await commonService.switchLogin({
      person_id: userInfo.person_id,
      user_id: us.user_id
    })
    loginSuccessCallback({ data: data.find(x => x.person_id === userInfo.person_id && x.user_info.user_id === us.user_id) }, '切换成功')
  }
  return (
    <Dropdown
      placement="bottomRight"
      menu={{
        items: [{
          label: <a onClick={() => props.history.push(Path.getPathname(props, Path.pathname['ACCOUNT']))}>账户信息</a>,
          key: '-1'
        },
        // accountInfo.length > 1 && {
        //   label: <a>账户切换</a>,
        //   key: '0',
        //   children: accountInfo.filter(x => x.user_id !== userInfo.user_id).map(a => ({
        //     label: <a onClick={() => handleSwitchAccount(a)}>{a.user_name}</a>
        //   }))
        // },
        userInfo.account_type !== 'SUB_ACCOUNT' && {
          label: <a onClick={() => {
            props.history.push(Path.getPathname(props, Path.pathname['buy']['home']))
          }}
          >{userInfo.account_type?.startsWith('VIP') ? '升级账户' : '购买会员'}</a>,
          key: '1'
        },
        ...(userInfo.account_type !== 'SUB_ACCOUNT' ? [{
          type: 'divider',
          key: '2'
        }, {
          label: <a onClick={() => {
            props.history.push(Path.getPathname(props, Path.pathname['order']['home']))
          }}
          >我的订单</a>,
          key: '3'
        }, {
          label: <a onClick={() => {
            props.history.push(Path.getPathname(props, Path.pathname['invoice']['home']))
          }}
          >我的发票</a>,
          key: '4'
        }] : []), {
          type: 'divider',
          key: '5'
        }, {
          label: <a onClick={(e) => props.handleLogout(e)}>{formatMessage({ id: 'logout' })}</a>,
          key: '6'
        }].filter(Boolean)
      }}
      // trigger={['click']}
      overlayClassName={styles['drop-down-user']}
    >
      <div style={{ display: 'flex', alignItems: 'center', minWidth: 80 }}
      // onClick={() => props.history.push(Path.getPathname(props, Path.pathname['ACCOUNT']))}
      >
        <Avatar size="small" icon={<UserOutlined />} style={{
          marginRight: 5,
          // backgroundColor: '#A6ADB4'
        }} />
        <span>{username || '管理员'}</span>
        <DownOutlined style={{
          marginLeft: 5,
          fontSize: 10
        }} />
      </div>
    </Dropdown>
  )

}

export default injectIntl(Index)
