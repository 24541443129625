import axios from 'axios'
import config from '@/config'

let originalFolders = []

function parallelNodes(arr) {
  return arr.reduce((pre, cur) => {
    if (cur.child && cur.child.length) {
      return [...pre, cur, ...parallelNodes(cur.child.map(x => ({ ...x, parent_id: cur.catalog_id })))]
    }
    return [...pre, { ...cur }]
  }, [])
}

export const getDatas = async ({ isLoadMore, isFloderRefresh, selectType = [], ...params }) => {
  let folders = []
  if (!isLoadMore) {
    // if ((!originalFolders.length || isFloderRefresh) && params.display_name === undefined) {
    const res = await axios.get(`v1/catalog`, {
      params: {
        // ...params,
        catalog_type: 'FILE_CATALOG'
      }
    })
    originalFolders = parallelNodes(res.map(x => ({ ...x, parent_id: null })))
    // }
    folders = await Promise.all(originalFolders.filter(x => x.parent_id === (params.catalogId || null)).map(async x => {
      let info = {}
      if (x.catalog_id) {
        info = await axios.get(`v1/catalog/${x.catalog_id}`)
      }
      return {
        // ...info,
        id: x.catalog_id,
        display_name: x.catalog_name,
        modify_time: x.update_time || x.create_time,
        type: 'FOLDER',
        size: info.size,
        raw: info
      }
    }))
    if (params.display_name !== void 0) {
      folders = folders.filter(x => x.display_name.includes(params.display_name.trim().toLowerCase()))
    }
    if (params.label_id) {
      folders = []
    }
  }

  let files = []
  if (!selectType.length || selectType.includes('FILE')) {
    let res = await axios.get(`v1/file`, {
      params: {
        ...params,
        catalogId: params.catalogId || '',
        is_include_subset: false
      }
    })
    files = res.items?.map(x => ({
      id: x.file_id,
      display_name: x.display_name,
      modify_time: x.update_time || x.create_time,
      type: 'FILE',
      size: x.file_size,
      raw: { ...x, file_name: x.file_name || x.display_name }
    }))
  }

  return [...folders, ...files]
}

// export const createFolder = (parentId, name) => {
//   let pms = {}
//   if (!parentId) {
//     pms = {
//       "catalog_name": name,
//       "catalog_type": "FILE_CATALOG"
//     }
//     return axios.post(`v1/catalog`, pms)
//   }

//   pms = {
//     "catalog_name": name,
//     "catalog_type": "FILE_CATALOG",
//     id: parentId
//   }
//   return axios.put(`v1/catalog/add_child`, {}, {params: pms})
// }


export const createFolder = (parentId, name) => {
  let pms = {}
  pms = {
    "catalog_name": name,
    "catalog_type": "FILE_CATALOG",
    parent_catalog_id: parentId || ''
  }
  return axios.post(`v1/catalog`, pms)
}

export const getInitialFolders = async (catalogId) => {
  if (!originalFolders.length) {
    const res = await axios.get(`v1/catalog`, {
      params: {
        // ...params,
        catalog_type: 'FILE_CATALOG'
      }
    })
    originalFolders = parallelNodes(res.map(x => ({ ...x, parent_id: null })))
  }
  let result = []
  let tp
  while ((tp = originalFolders.find(x => x.catalog_id === catalogId))) {
    result.unshift(tp)
    catalogId = tp.parent_id
  }
  return result.map(x => {
    return {
      id: x.catalog_id,
      name: x.catalog_name,
    }
  })
}

export const batchDeleteFile = (ids) => {
  return axios.delete(`v1/file`, { params: { ids } })
}
export const batchDeleteFloder = (ids) => {
  return Promise.all(ids.map(x => axios.delete(`v1/catalog/${x}`)))
}

export const shareFile = (ids) => {
  return axios.post(`v1/file/share`, {}, { params: { ids } })
}

export const downloadFile = (id) => {
  return axios.get(`v1/file/download/${id}`)
}

export const rename = (id, name, type, label_ids) => {
  if (type === 'FOLDER') {
    return axios.put(`v1/catalog/${id}`, {}, { params: { catalog_name: name } })
  }
  if (type === 'FILE') {
    return axios.put(`v1/file/${id}`, {
      display_name: name,
      // label_ids
    })
  }
}
export const upFile = (ids, catalog_id) => {
  return axios.put(`v1/file/up`, {}, { params: { ids, catalog_id, is_include_subset: true } })
}

export const mergeFileCatalog = (ids, { displayName, parentCatalogId }) => {
  if (!ids || !ids.length) {
    return
  }
  return axios.put(`v1/catalog/merge`, {}, { params: { ids, catalog_type: 'FILE_CATALOG', catalog_name: displayName, parentCatalogId } })
}
export const mergeFileToCatalog = (ids, { displayName, parentCatalogId }) => {
  return Promise.all(ids.map(id => {
    return axios.put(`v1/file/${id}`, {
      catalog_id: parentCatalogId,
    })
  }))
}

export const mergePDF = (ids) => {
  return axios.post(`v1/file/generate_pdf`, {}, { params: { ids } })
}

export const getFileDetail = (id) => {
  return axios.get(`v1/file/${id}`).then(async x => ({
    id: x.file_id,
    display_name: x.display_name,
    modify_time: x.update_time || x.create_time,
    type: 'FILE',
    size: x.file_size,
    raw: { ...x, file_name: x.file_name || x.display_name }
  }))
}
export const getDataDetailByName = (params) => {
  return axios.get(`v1/${'catalog'}/get_by_name`, { params })
}