import React, { useEffect, useState } from 'react';
import { Menu, message, Modal, Pagination, Tree, Button, Divider, Popover, Input, Space, Dropdown } from '@om-tlh/bee'
import { DownOutlined, FolderOutlined, FolderOpenOutlined, EditOutlined, DeleteOutlined, FullscreenOutlined, EllipsisOutlined } from '@ant-design/icons'
import * as personalService from './service';
import * as groupService from './service_group';
import ModalFolderCreate from '@/components/OmPan/components/ModalFolderCreate'
import { getOptions, STATUS, SOURCE_TYPE, PATHNAME_TYPE, DOCUMENT_ACTION_TYPE } from '@/utils/enum'
import CatalogListForSelect from './CatalogListForSelect'
import './index.module.less'

let _selectedRows
let firstTimeLoad = false
const { DirectoryTree } = Tree;

const Index = (props) => {
  const { value, onChange, type, editable, noInitSelect, belongType, isReload, folderOperationsAllowed = [] } = props;
  const service = belongType === 'TEAM' ? groupService : personalService;
  const [catalogs, setCatalogs] = useState([]);
  const [total, setTotal] = useState(0)
  const [expandedKeys, setExpandedKeys] = useState([])
  const [selectedKeys, setSelectedKeys] = useState(value ?? [])
  const [checkedKeys, setCheckedKeys] = useState([])
  const [checkedRows, setCheckedRows] = useState([])
  const [checkable, setCheckable] = useState(false)
  const [params, setParams] = useState({
    page: 1,
    page_size: 10
  })

  const [folderCreateModalOpen, setFolderCreateModalOpen] = useState(false)
  const [createFolderLoading, setCreateFolderLoading] = useState(false)

  const [selectRows, setSelectRows] = useState([])
  const [visible, setVisible] = useState(false);

  const fetchData = async () => {
    const res = await service.getDatas({ ...params, catalog_type: type || 'FILE_CATALOG' });
    // const items = noInitSelect ? [...res.items, { catalog_id: '', catalog_name: '未识别' }] : res.items
    const items = res.items
    setCatalogs(items)
    setTotal(res.total)
    return items
    // !noInitSelect && onChange?.(items[0]?.catalog_id, items[0])
    // !noInitSelect && setSelectedKeys([items[0]?.catalog_id])
  }

  useEffect(() => {
    fetchData().then(res => {
      if (!firstTimeLoad) {
        firstTimeLoad = true
        setExpandedKeys(res.map(x => x.id))
        if (!value || !value.length) {
          res[0] && onTreeSelect(void 0, { node: res[0] })
        } else {
          onTreeSelect(void 0, { node: { id: value[0] } })
        }
      }
    })
    return () => {
      firstTimeLoad = false
    }
  }, [isReload])

  const onSelect = ({ key }) => {
    onChange && onChange(key, catalogs.find(i => i.id === key))
  }

  const onPageChange = (page, pageSize) => {
    setParams({
      ...params,
      page,
      page_size: pageSize
    })
    fetchData()
  }

  function onTreeSelect(selectedKeys, { node, ...rest } = {}) {
    setSelectedKeys([node.id])
    const key = node.id
    onSelect({ key })
  };

  const onCheck = ({ checked: checkedKeys }, info) => {
    setCheckedKeys(checkedKeys)
    setCheckedRows(info.checkedNodes)
  };

  const merge = () => {
    if (!checkedKeys || !checkedKeys.length) {
      return message.warning("请选择目录");
    }
    Modal.confirm({
      okButtonProps: { shape: 'round' },
      cancelButtonProps: { shape: 'round' },
      title: '提示',
      content: '确定要移动目录吗？',
      onOk: () => {
        service.mergeData({

        }).then(data => {
          fetchData();
          setCheckedKeys([]);
          message.success('移动成功');
        })
      }
    })
  }


  const handleAddCatalog = () => {
    setFolderCreateModalOpen(true)
  }
  const handleCreateFolder = (folder) => {
    setCreateFolderLoading(true)
    service.createFolder(selectedKeys[0] || '', folder).then(res => {
      // const values = { ...filters, catalogId: selectedKeys[0] || '', isFloderRefresh: !!'isFloderRefresh' }
      // handleSearch(values)
      fetchData()
      setFolderCreateModalOpen(false)
      setCreateFolderLoading(false)
    }).catch(err => {
      setCreateFolderLoading(false)
      message.error(err.response?.data?.message ?? JSON.stringify(err))
    })
  }

  const handleOperateBtnClick = (record, actionType) => {
    const actions = {
      [DOCUMENT_ACTION_TYPE.RENAME]: (record) => {
        let title = record.display_name
        Modal.confirm({
          okButtonProps: { shape: 'round' },
          cancelButtonProps: { shape: 'round' },
          title: "重命名",
          content: (
            <Input
              onChange={(e) => (title = e.target.value)}
              defaultValue={title}
              onPressEnter={(e) => {
                title = e.target.value
                service.rename(record.id, title, record.type).then(res => {
                  message.success('修改成功')
                  fetchData()
                  Modal.destroyAll()
                })
              }}
            />
          ),
          onOk: () => {
            service.rename(record.id, title, record.type).then(res => {
              message.success('修改成功')
              fetchData()
            })
          },
        });
      },
      [DOCUMENT_ACTION_TYPE.DELETE]: (record) => {
        Modal.confirm({
          okButtonProps: { shape: 'round' },
          cancelButtonProps: { shape: 'round' },
          title: '提示',
          content: <div>{ '目录下的档案将一并删除, ' }确定删除 {record.display_name} 吗?</div>,
          onOk: () => {
            const records = [record]
            const files = records.filter(x => x.type === 'FILE').map(x => x.id)
            // const folders = records.filter(x => x.type === 'FOLDER').map(x => x.id)
            const folders = records.map(x => x.id)
            service.batchDeleteData(folders).then(res => {
              message.success('删除成功')
              fetchData().then(res => {
                if (folders.includes(selectedKeys[0])) {
                  return onTreeSelect(void 0, { node: res[0] ?? {} })
                }
              })
              setCheckedKeys(checkedKeys.filter(x => x !== selectedKeys[0]))

            }).catch(error => {
              message.error(error.response?.data?.message ?? JSON.stringify(error))
            })
          }
        })
      },
      [DOCUMENT_ACTION_TYPE.BATCH_DELETE]: (records) => {
        if (!checkedKeys.length) {
          return message.warn('请选择目录')
        }
        Modal.confirm({
          okButtonProps: { shape: 'round' },
          cancelButtonProps: { shape: 'round' },
          title: '提示',
          content: <div>{ '目录下的档案将一并删除, ' }确定删除吗?</div>,
          onOk: () => {
            const folders = records
            service.batchDeleteData(folders).then(res => {
              message.success('删除成功')
              fetchData().then(res => {
                if (folders.includes(selectedKeys[0])) {
                  return onTreeSelect(void 0, { node: res.items[0] ?? {} })
                }
              })
              setCheckedKeys([])
              folders.includes(selectedKeys[0]) && onTreeSelect(void 0, { node: catalogs[0] })
            }).catch(error => {
              message.error(error.response?.data?.message ?? JSON.stringify(error))
            })
          }
        })
      },
      [DOCUMENT_ACTION_TYPE.MOVE]: (record) => {
        _selectedRows = [record].map(x => x.id)
        setVisible(true)
      },
      [DOCUMENT_ACTION_TYPE.BATCH_MOVE]: () => {
        if (!checkedKeys.length) {
          return message.warn('请选择目录')
        }
        setVisible(true)
      },
    }
    actions[actionType]?.(record)
  }

  const onCatalogSelect = (rows) => {
    setSelectRows(rows)
  }
  const handleCancel = () => {
    fetchData()
    setVisible(false)
  }
  function handleOk() {
    if (!selectRows.length) {
      return message.warn('请选择目录')
    }
    const files = (_selectedRows || checkedKeys).filter(x => x.type === 'FILE').map(x => x.id)
    // const _folders = (_selectedRows || selectedRows).filter(x => x.type === 'FOLDER').map(x => x.id)
    const _folders = (_selectedRows || checkedKeys)
    if (_folders.includes(selectRows[0].id)) {
      return message.error('不能移至原目录, 请选择其它目录')
    }
    Promise.all([_folders.length && service.mergeFileCatalog(_folders, {
      parentCatalogId: selectRows[0].id
    }), files.length && service.mergeFileToCatalog(files, {
      parentCatalogId: selectRows[0].id
    })].filter(Boolean))
      .then(res => {
        message.success('移动成功')
        fetchData()
        setVisible(false)
        setExpandedKeys(expandedKeys.includes(selectRows[0].id) ? expandedKeys : [...expandedKeys, selectRows[0].id])
        setCheckedKeys([])
        _selectedRows = void 0
      }).catch(error => {
        message.error(error.response?.data?.message ?? JSON.stringify(error))
      })
  }

  // const onDragEnter = (info) => {
  //   console.log(info);
  //   // expandedKeys 需要受控时设置
  //   // setExpandedKeys(info.expandedKeys)
  // };
  const onDrop = (info) => {
    console.log(info);
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const loop = (data, key, callback) => {

      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...catalogs];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (!info.dropToGap) {
      console.log(1);
      // Drop on the content
      loop(data, dropKey, (item) => {
        item.child = item.child || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.child.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 &&
      // Has children
      info.node.props.expanded &&
      // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      console.log(2);
      loop(data, dropKey, (item) => {
        item.child = item.child || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.child.unshift(dragObj);
        // in previous version, we use item.child.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      console.log(3);
      let ar = [];
      let i;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }
    console.log('data: ', data);
    setCatalogs(data);
  };

  return (
    <div style={{ overflowX: 'auto' }}>
      <div style={{ padding: '18px 0 0', fontWeight: 500, fontSize: '1.2em' }}>档案目录</div>
      <Divider style={{ margin: ' 12px 0' }} />
      {(editable && folderOperationsAllowed.includes(DOCUMENT_ACTION_TYPE.RENAME))
        ? <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: checkable ? 15 : 0, alignItems: 'center' }}>
          {checkable && <a onClick={() => setCheckable(!checkable)}>{!checkable ? '' : '退出'}编辑</a>}
          {/* {checkable ? <a onClick={() => merge()}>移动</a> : null} */}
          {checkable ? <a onClick={() => handleOperateBtnClick?.(checkedKeys, DOCUMENT_ACTION_TYPE.BATCH_MOVE)}>批量移动</a> : null}
          {
            checkable && <Space >
              {/* <Button shape='round' type='link' title="识别" icon={<ScanOutlined />}
              onClick={() => handleOperateBtnClick?.(record, DOCUMENT_ACTION_TYPE.RECOGNIZE)}
            ></Button> */}
              <Dropdown
                // getPopupContainer={() => document.getElementById(`card-mode-${record.id}`)}
                overlay={<Menu
                  onClick={({ key }) => handleOperateBtnClick?.(checkedKeys, key)}
                  style={{ width: 100 }}
                  items={
                    [
                      {
                        //   key: DOCUMENT_ACTION_TYPE.BATCH_MOVE,
                        //   icon: <FullscreenOutlined style={{ transform: 'rotate(45deg)' }} />,
                        //   label: <span
                        //   >
                        //     批量移动
                        //   </span>,
                        //   title: '批量移动',
                        // }, {
                        key: DOCUMENT_ACTION_TYPE.BATCH_DELETE,
                        icon: <DeleteOutlined />,
                        label: <span
                        >
                          批量删除
                        </span>,
                        title: '批量删除',
                      }
                    ]
                  }
                />}>
                <Button shape='round' htmlType='button'
                  type="link"
                >
                  <EllipsisOutlined />
                  {/* <DownOutlined /> */}
                </Button>
              </Dropdown>
            </Space>
          }
        </div>
        : null}
      <DirectoryTree
        // switcherIcon={<DownOutlined />}
        checkStrictly
        // showIcon
        icon={({ expanded, ...attrs }) => {
          return expanded && attrs.child ? <FolderOpenOutlined /> : <FolderOutlined />
        }}
        expandedKeys={expandedKeys}
        onExpand={(expandedKeys) => {
          setExpandedKeys(expandedKeys)
        }}
        selectedKeys={selectedKeys}
        checkedKeys={checkedKeys}
        onCheck={onCheck}
        onSelect={onTreeSelect}
        checkable={checkable}
        treeData={catalogs}
        fieldNames={{
          title: 'display_name',
          key: 'id',
          children: 'child'
        }}
        titleRender={(record) => {
          return checkable ? <Popover
            // trigger='click'
            overlayClassName={`doc-opt`}
            placement="rightTop"
            // getPopupContainer={() => document.body}
            content={<Menu
              onClick={({ key, domEvent, ...rest }) => {
                domEvent?.stopPropagation?.()
                handleOperateBtnClick?.(record, key)
              }}
              style={{ width: 100 }}
              items={
                [
                  {
                    key: DOCUMENT_ACTION_TYPE.RENAME,
                    icon: <EditOutlined />,
                    label: <span
                    >
                      重命名
                    </span>,
                    title: '重命名',
                  }, {
                    title: '移动',
                    key: DOCUMENT_ACTION_TYPE.MOVE,
                    icon: <FullscreenOutlined style={{ transform: 'rotate(45deg)' }} />,
                    label: <span
                    >
                      移动
                    </span>,
                  }, {
                    key: DOCUMENT_ACTION_TYPE.DELETE,
                    icon: <DeleteOutlined />,
                    label: <span
                    >
                      删除
                    </span>,
                    title: '删除',
                  }
                ]
              }
            />}>
            <span style={{ position: 'relative' }}>
              {record.display_name}
              {/* <DownOutlined /> */}
            </span>
          </Popover> : <Popover
            // trigger='click'
            overlayClassName={`doc-opt`}
            placement="rightTop"
            // getPopupContainer={() => document.body}
            content={<Menu
              onClick={({ key, domEvent, ...rest }) => {
                domEvent?.stopPropagation?.()
                setCheckable(!checkable)
              }}
              style={{ width: 100 }}
              items={
                [
                  {
                    key: DOCUMENT_ACTION_TYPE.RENAME,
                    icon: <EditOutlined />,
                    label: <span
                    >
                      编辑
                    </span>,
                    title: '编辑',
                  }
                ]
              }
            />}>
            <span style={{ position: 'relative' }}>
              {record.display_name}
              {/* <DownOutlined /> */}
            </span>
          </Popover>
        }}

      // draggable
      // blockNode
      // // onDragEnter={onDragEnter}
      // onDrop={onDrop}
      />
      {/* {total > 0 ? <Pagination
        size='small'
        total={total}
        current={params.page}
        pageSize={params.page_size}
        onChange={onPageChange}
        showTotal={(total) => `共 ${total}`}
      />
        : null} */}

      {/* {
        folderOperationsAllowed.includes(DOCUMENT_ACTION_TYPE.ADD) && <>
          <Button
            type='ghost'
            shape='round'
            onClick={handleAddCatalog}
          >新增档案目录</Button>
          <Divider style={{ margin: '15px auto' }} />
        </>
      } */}

      <Modal
        okButtonProps={{ shape: 'round' }}
        cancelButtonProps={{ shape: 'round' }}
        FolderCreate
        title={`新增档案目录`}
        open={folderCreateModalOpen}
        onOk={handleCreateFolder}
        onCancel={() => setFolderCreateModalOpen(false)}
        loading={createFolderLoading}
      />
      <Modal
        okButtonProps={{ shape: 'round' }}
        cancelButtonProps={{ shape: 'round' }}

        title='批量移动'
        width={800}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
        open={visible}
      >
        <CatalogListForSelect
          size='small'
          type='DOCUMENT_CATALOG'
          service={service}
          showFolderAdd={folderOperationsAllowed.includes(DOCUMENT_ACTION_TYPE.ADD)}
          onSelect={onCatalogSelect}
          rowSelection={{
            type: 'radio',
            // selectedRowKeys: [],
            selectType: ['FOLDER']
          }} />
      </Modal>
    </div>
  )
}

export default Index;
