import { Row, Col, Card } from '@om-tlh/bee'
import imgRef1 from '@/theme/img/ref1.png'
import imgRef2 from '@/theme/img/ref2.png'
import imgRef3 from '@/theme/img/ref3.png'
import imgRef4 from '@/theme/img/ref4.png'
import imgRef5 from '@/theme/img/ref5.png'

export default function (props) {
  const data = [{
    title: '框选4个以上参照字段，并尽量分散在四角',
    image: imgRef1
  }, {
    title: '保证框选的文字内容、位置固定不变',
    image: imgRef2
  }, {
    title: '单个参照字段不可跨行',
    image: imgRef3
  }, {
    title: '选取图片中不会重复出现的文字',
    image: imgRef4
  }, {
    title: '仅支持中英文、数字，不可包含符号、图案',
    image: imgRef5
  }]
  return (<div
    style={{ width: 1100, ...props.style }}
  >
    <section style={{ marginBottom: 30 }}>
      <h2 style={{ fontWeight: 600 }} >什么是参照字段</h2>
      <p>
        参照字段为图片中文字及位置固定的文字内容，用于相同版式识别图片的自动矫正和模板匹配
      </p>
    </section>
    <section>
      <h2 style={{ fontWeight: 600 }} >如何框选参照字段</h2>
      <section>
        <Row gutter={[20, 20]}>
          {
            data.map((x, idx) => (<Col key={x.title} span={props.colSpan ?? 8}>
              <Card title={<div title={`${idx + 1}. ${x.title}`}>{`${idx + 1}. ${x.title}`}</div>}
                bordered={false}
                headStyle={{ padding: 0 }}
                bodyStyle={{ padding: 0 }}
              >
                <img src={x.image} alt="" style={{ width: '100%' }} />
              </Card>
            </Col>))
          }
        </Row>
      </section>
    </section>
  </div>)
}