import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Input, Button, Checkbox, Row, Col, Card, Select, Space, Upload, message, Divider } from '@om-tlh/bee';
import { MinusCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { getQueryStringByName } from 'utils/utils'
import service from './service'
import roleService from '../Role/service'
import utils from '@/utils/util'

const { Option } = Select;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 18 },
};

const Create = (props) => {
  const [form] = Form.useForm();
  const { intl, location } = props
  const id = getQueryStringByName('id')
  const { formatMessage } = intl
  const [values, setValues] = useState({})
  const [options, setOptions] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const res = await service.getDataDetail(id)
      setValues(res)
      form.setFieldsValue(res)
    }
    if (!id) return
    fetchData()
  }, [])

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const res = await roleService.getDatas({ page_size: 100 })
  //     setOptions(res.items)
  //   }
  //   fetchData()
  // }, [])


  const onFinish = values => {
    service.createData(values).then(data => {
      props.history.goBack();
      message.success('创建数据成功')
    })
      .catch(err => {
        message.error(err.response?.data?.message ?? '未知错误')
      })
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <div className="content-header">
        <h2>{id ? '编辑' : '创建'}用户</h2>
      </div>
      <div className="content-body">
        <Form
          {...layout}
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="用户名"
            name="user_name"
            rules={[{ required: true, message: '请输入用户名' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="密码"
            name="password"
            rules={[
              { required: true, message: '请输入密码' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  let reg = new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)\S{8,30}$/);
                  if (value && reg.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('密码需要包含字母, 数字和非空格特殊字符的两种, 并且长度在8 - 30 之间'));
                },
              })
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="再次输入密码"
            name="confirm_password"
            dependencies={['password']}
            rules={[
              { required: true, message: '请输入确认密码' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('两次密码输入不一致'));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          {/* <Form.Item
            label="执法证件号"
            name="card_no"
          >
            <Input />
          </Form.Item> */}
          <Form.Item
            label="描述"
            name="description"
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Divider />
          <Form.Item {...tailLayout}>
            <Button shape='round' type="primary" htmlType="submit">
              提交
            </Button>
            <Button shape='round' onClick={() => {
              const returnUrl = decodeURIComponent(utils.getQueryStringByName('return_url') || '')
              if (returnUrl) {
                return props.history.push(returnUrl)
              }
              props.history.goBack()
            }} style={{ marginLeft: 10 }}>
              取消
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  )
}

export default injectIntl(Create);