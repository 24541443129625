import React, { useState, useEffect } from 'react';
import SearchType from '@/utils/searchType'
import service from './service'
import { injectIntl } from 'react-intl';
import TablePage from '@/components/TablePage'
import { Button } from '@om-tlh/bee'
import tenantService from '../Tenant/service'
import privilegeService from '../Privilege/service'
import LocalStorageUtil from 'utils/localStorageUtil'
import ViewMoreTags from 'components/ViewMoreTags'
import Path from 'routes/path'
import utils from '@/utils/util'

const Index = (props) => {
  const { intl, match } = props
  const { params } = match || {}
  const { scope } = params || {}
  const { formatMessage } = intl
  const [tenantOptions, setTenantOptions] = useState([])
  const [privileges, setPrivileges] = useState([])

  const userInfoStr = LocalStorageUtil.getUserInfo()
  let userInfo = {}
  try {
    userInfo = JSON.parse(userInfoStr)
  } catch (e) { }

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const res = await tenantService.getDatas({ page_size: 100 })
  //     setTenantOptions(res.items)
  //   }
  //   fetchData()
  // }, [])

  useEffect(() => {
    const fetchData = async () => {
      const res = await privilegeService.getTenantsDatas({ page_size: 100 })
      setPrivileges(res.items)
    }
    fetchData()
  }, [])

  const currentTenant = (tenantOptions.find(i => i.tenant_id === userInfo.tenant_id) && tenantOptions.find(i => i.tenant_id === userInfo.tenant_id).display_name) || ''

  const fields = [
    { name: 'key', desc: '序号', render: (text, record, index) => index + 1, width: 80 },
    ...tenantOptions.length || true
      ? [{
        name: 'tenant_id', desc: '租户',
        render: () => currentTenant,
        // query: true, 
        // type: SearchType.KEY_SEARCH_INPUT,
        options: tenantOptions, defaultValue: userInfo.tenant_id, hide: true, showRender: true
      }]
      : [],
    { name: 'display_name', desc: '名称', showDetail: true, query: true, key: 'keyword' },
    {
      name: 'privileges', desc: '权限',
      render: (text, record) => <ViewMoreTags {...props} privileges={privileges} value={text} />,
      width: '50%', showRender: true
    },
    { name: 'description', desc: '说明' },
    { name: 'create_time', desc: '创建时间', type: SearchType.KEY_DATE_TIME },
    {
      name: 'action', desc: '操作',
      listActions: true,
      render: (text, record) => {
        return [
          utils.hasPermission('EDIT_ROLE') && <a className='btn-action' onClick={e => props.history.push(`${Path.getPathname(props, Path.pathname['role']['edit'])}?id=${record.role_id}&tenant_id=${userInfo.tenant_id}`)}>编辑</a>
        ].filter(Boolean)
      }
    },
  ]
  const insertBtns = [
    <Button shape='round' type="primary" key='create' onClick={() => props.history.push(`${Path.getPathname(props, Path.pathname['role']['create'])}?tenant_id=${userInfo.tenant_id}`)}>创建</Button>
  ]
  return (
    <>
      <div className="content-header">
        <h2>查看角色数据</h2>
      </div>
      <div className="content-body">
        <TablePage
          rowKey='role_id'
          name={'角色数据'}
          fields={fields}
          insertBtns={insertBtns}
          loadData={service.getDatas}
          deleteData={service.batchDeleteData}
          service={service}
          scope={scope}
          showDelete={utils.hasPermission('DELETE_ROLE')}
          moduleName='role'
        />

      </div>
    </>
  )
}

export default injectIntl(Index);