// import List from './index'
// import ListTeam from './list_team'
// import ListAdmin from './list_admin'
// import Create from './create'
// import Detail from './detail'
import Path from 'routes/path'

const MODULE_NAME = 'model';

const routes = [
    {
        id: `${MODULE_NAME}_manage`,
        name: `${MODULE_NAME}_manage`,
        // component: List,
        componentPath: 'modules/Model/index',
        path: Path.pathname[`${MODULE_NAME}`]['home'],
        key: `${MODULE_NAME}_manage`,
    },
    {
        id: `${MODULE_NAME}_team_manage`,
        name: `${MODULE_NAME}_team_manage`,
        // component: ListTeam,
        componentPath: 'modules/Model/list_team',
        path: Path.pathname[`${MODULE_NAME}`]['team'],
        key: `${MODULE_NAME}_team_manage`,
        permission: 'VIEW_STYLE_TEMPLATE,VIEW_CONTENT_TEMPLATE',
    },
    {
        id: `${MODULE_NAME}_admin_manage`,
        name: `${MODULE_NAME}_admin_manage`,
        // component: ListAdmin,
        componentPath: 'modules/Model/list_admin',
        path: Path.pathname[`${MODULE_NAME}`]['admin'],
        key: `${MODULE_NAME}_admin_manage`,
    },
    {
        id: `${MODULE_NAME}_create`,
        name: `${MODULE_NAME}_create`,
        // component: Create,
        componentPath: 'modules/Model/create',
        path: Path.pathname[`${MODULE_NAME}`]['create'],
        key: `${MODULE_NAME}_create`,
        parentKey: `${MODULE_NAME}_manage`
    },
    {
        id: `${MODULE_NAME}_edit`,
        name: `${MODULE_NAME}_edit`,
        // component: Create,
        componentPath: 'modules/Model/create',
        path: Path.pathname[`${MODULE_NAME}`]['edit'],
        key: `${MODULE_NAME}_edit`,
        parentKey: `${MODULE_NAME}_manage`,
    },
    {
        id: `${MODULE_NAME}_detail`,
        name: `${MODULE_NAME}_detail`,
        // component: Detail,
        componentPath: 'modules/Model/detail',
        path: Path.pathname[`${MODULE_NAME}`]['detail'],
        key: `${MODULE_NAME}_detail`,
        parentKey: `${MODULE_NAME}_manage`
    },
]

export default {
    routes
}