import React from 'react'
import { injectIntl } from 'react-intl';
import { Route, Redirect, Switch } from 'react-router'
import { ConnectedRouter as Router } from 'connected-react-router'
import loadable from '@loadable/component'

import Frame from '../components/layouts/frame'
import FrameMenu from '../components/layouts/frameMenu'
import CaskDesk from '../components/layouts/cashDesk'
import ForShare from '../components/layouts/forShare'
import SplitLoading from '@/modules/SplitLoading'
import Error403 from '@/modules/ErrorPage/403'
import LocalStorageUtil from '@/utils/localStorageUtil'
import SStorageUtil from 'utils/sessionStorageUtil'
// import commonService from '@/modules/common/service'
import ScrollToTop from './ScrollToTop'
import LoadingPage from '@/modules/LoadingPage'

import utils from '@/utils/util'
import { menu } from '@/components/layouts/nav.js'
import { Button } from '@om-tlh/bee'

const Login = loadable(() => import('../modules/Login'))
const Regist = loadable(() => import('../modules/Regist'))
const ThirdLogin = loadable(() => import('../modules/ThirdLogin'))
// const Home = loadable(() => import('../modules/Home'))

/**
 * 路由渲染
 * @param {*} props 
 */
const Routes = (props) => {
  return (
    <Router history={props.history} context={props.context}>
      <ScrollToTop>
        <Switch>
          <Route exact path={`/`} render={() => verify(props)} />
          <Route path={`/login`} component={(pps) => <Login {...props} {...pps} />} />
          <Route path={`/third_login`} component={(pps) => <ThirdLogin {...props} {...pps} />} />
          <Route path="/regist/:inviteCode?" component={(pps) => <Regist {...props} {...pps} />} />
          <Route path="/loading" component={LoadingPage} />
          {/* <Route path="/home" component={(pps) => <Home {...props} {...pps} />} /> */}
          {/* 单页配置 */}
          <Route path={`/mkt`} component={e => FrameLayout(e, props)} />
          <Route path="/cd" component={(e) => CaskDeskLayout(e, props)} />
          <Route path="/sr" component={(e) => ForShareLayout(e, props)} />
          {/* 默认带菜单配置 */}
          <Route component={e => FrameMenuLayout(e, props)} />
        </Switch>
      </ScrollToTop>
    </Router>
  )
}

/**
 * 导航路由页面渲染
 * @param {*} e 
 * @param {*} props 
 */
const FrameMenuLayout = (e, props) => {
  let routeRegistry = props.routeRegistry
  if (!verified()) {
    return <Redirect to={`/login`} />
  }
  return (
    <FrameMenu {...e} {...props} routeRegistry={routeRegistry} >
      {
        routeRegistry && routeRegistry.map((route, index) => {
          return <Route  {...e} {...props} {...route} exact key={route.key} path={`${e.match.url === '/' ? '' : e.match.url}${route.path}`} component={route.permission && !utils.hasPermission(route.permission) ? Error403 : (route.componentPath ? loadable((ep) => {
            return import(`../${route.componentPath}`)
          }, {
            fallback: <SplitLoading />,
            resolveComponent: (imported) => (cs) => React.createElement(imported.default, { ...e, ...props, ...cs })
          }) : (rps) => <route.component {...rps} {...props} />)} />
        })
      }
    </FrameMenu>
  )
}

/**
 * 单页路由渲染
 * @param {*} e 
 * @param {*} props 
 */
const FrameLayout = (e, props) => {
  let routeRegistry = props.routeRegistry

  return (
    <Frame {...e} {...props} routeRegistry={routeRegistry}>
      {
        routeRegistry && routeRegistry.map((route, index) => {
          return <Route exact key={route.key} path={`${e.match.url === '/' ? '' : e.match.url}${route.path}`} component={route.permission && !utils.hasPermission(route.permission) ? Error403 : (route.componentPath ? loadable(() => import(`../${route.componentPath}`), {
            fallback: <SplitLoading />
          }) : route.component)} />
        })
      }
    </Frame>
  )
}

const CaskDeskLayout = (e, props) => {
  let routeRegistry = props.routeRegistry

  return (
    <CaskDesk {...e} {...props} routeRegistry={routeRegistry}>
      {
        routeRegistry && routeRegistry.map((route, index) => {
          return <Route exact key={route.key} path={`${e.match.url === '/' ? '' : e.match.url}${route.path}`} component={route.permission && !utils.hasPermission(route.permission) ? Error403 : (route.componentPath ? loadable(() => import(`../${route.componentPath}`), {
            fallback: <SplitLoading />
          }) : route.component)} />
        })
      }
    </CaskDesk>
  )
}
const ForShareLayout = (e, props) => {
  let routeRegistry = props.routeRegistry

  return (
    <ForShare {...e} {...props} routeRegistry={routeRegistry}>
      {
        routeRegistry && routeRegistry.map((route, index) => {
          return <Route exact key={route.key} path={`${e.match.url === '/' ? '' : e.match.url}${route.path}`} component={route.permission && !utils.hasPermission(route.permission) ? Error403 : (route.componentPath ? loadable(() => import(`../${route.componentPath}`), {
            fallback: <SplitLoading />
          }) : route.component)} />
        })
      }
    </ForShare>
  )
}
/**
 * 验证token
 */
const verify = ({ routeRegistry, location, ...props }) => {
  if (!verified()) {
    return <Redirect to={`/login`} />
  }
  const mn = menu.find(item => !(item.permission && !utils.hasPermission(item.permission)) || !item.permission)
  let p = ''
  if (mn && mn.children && mn.children.length && mn.children.filter(item => !(item.permission && !utils.hasPermission(item.permission)) || !item.permission).length) {
    p = (routeRegistry.find(itm => itm.key === mn.children.filter(item => !(item.permission && !utils.hasPermission(item.permission)) || !item.permission)[0].key).path) || '/home'
  }
  if (mn && !mn.children) {
    p = routeRegistry.find(itm => itm.key === mn.key)?.path ?? '/home'
  }
  return <Redirect to={`${p || "/accout"}`} />
}

const verified = () => {
  const auth = LocalStorageUtil.getAuth()
  var token = auth && encodeURIComponent(auth)
  const cookieToken = utils.getCookie('token')
  if (!token && cookieToken) {
    token = decodeURIComponent(cookieToken)
    LocalStorageUtil.setAuth(`${token}`)
  }
  if (!cookieToken) {
    ['auth', 'userInfo', 'privileges', 'platform_id'].forEach(i => {
      LocalStorageUtil.clearStorage(i)
    })
    LocalStorageUtil.clearAllCookie()
    SStorageUtil.clearSStorage()
    return false
  }
  if (!auth) {
    return false
  }
  return true
}
class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isChecked: true,
      websiteInfo: {},
    }
  }

  componentDidMount() {
    this.setDocumentTitle()
  }

  /**
   * 设置document标题
   */
  setDocumentTitle = () => {
    const { intl } = this.props;
    const { formatMessage } = intl;
    document.title = formatMessage({ id: 'page_title' })
  }

  updateHeaderReadStatus = () => {
    this.freshHeader?.()
  }
  updateHeaderTeamInfo = () => {
    this.freshTeamInfo?.()
  }

  render() {
    const { isChecked, websiteInfo } = this.state
    return utils.hasPermission('BUSINESS') ? <Error403
      footer={<Button
        shape='round' type="primary" onClick={(e) => {
          e.preventDefault();
          ['auth', 'userInfo', 'privileges', 'platform_id'].forEach(i => {
            LocalStorageUtil.clearStorage(i)
          })
          LocalStorageUtil.clearAllCookie()
          SStorageUtil.clearSStorage()
          window.location.reload()
          // window.location.href = `${window.location.origin}${window.__IN_CLOUD__ ? `/${window.appName}` : ''}/login`
          window.location.href = `${window.location.origin}${window.__IN_CLOUD__ ? `/${window.appType ? [...window.appType.split('/'), `${window.appName}`].filter(Boolean).join('/') : `${window.appName}`}` : (window.appType ? `/${window.appType}` : ``)}/login?return_url=${encodeURIComponent(window.location.href.replace(new RegExp(`&?token_id=${getQueryStringByName(`token_id`)}`), ''))}`
        }}
      >切换账号</Button>}
    /> : (isChecked ? <Routes {...this.props} websiteInfo={websiteInfo}
      updateHeaderReadStatus={this.updateHeaderReadStatus}
      setRefreshFunc={freshHeader => this.freshHeader = freshHeader}
      updateHeaderTeamInfo={this.updateHeaderTeamInfo}
      setRefreshTeamInfoFunc={freshTeamInfo => this.freshTeamInfo = freshTeamInfo}
    /> : <LoadingPage />)
  }
}

export default injectIntl(App)