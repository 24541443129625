import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import UploadCover from '@/components/TablePage/components/UploadCover';
import UploadImages from '@/components/TablePage/components/UploadImages';
// import Editor from '@/components/Editor'
import DragTitle from '@/components/DragTitle'
import { Button, Modal, Form, Input, Radio, InputNumber, Select } from '@om-tlh/bee';

const { Option } = Select

const ModalForm = (props) => {
  const { open, onCreate, onCancel, fields = [], name, type, intl, record } = props
  const { formatMessage } = intl
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields()
    form.setFieldsValue(record)
  }, [record])

  const getFormItemDom = (field) => {
    const { form, options = [], customRender } = field
    if (!form) return
    const { type = 'input' } = form
    let dom = null
    switch (type) {
      case 'input':
        dom = <Input />;
        break;
      case 'inputNumber':
        dom = <InputNumber style={{ width: '100%' }} />;
        break;
      case 'select':
        dom = <Select>
          {options.map((i, d) => <Option key={d} value={i.value}>{i.name}</Option>)}
        </Select>;
        break;
      case 'upload':
        dom = <UploadCover {...props} />;
        break;
      case 'uploadImages':
        dom = <UploadImages {...props} />;
        break;
      case 'custom':
        dom = customRender
        break;
      case 'textarea':
        dom = <Input.TextArea />;
        break;
      // case 'editor':
      //   dom = <Editor {...props} />;
      //   break;
      default:
        dom = <Input />;
        break;
    }
    return dom
  }

  return (
    <>
      <Modal
        okButtonProps={{ shape: 'round' }}
        cancelButtonProps={{ shape: 'round' }}

        open={open}
        onCancel={onCancel}
        wrapClassName='form_modal'
        width={800}
        title={props.title || <DragTitle title={type === 'create' ? `${formatMessage({ id: 'title_create' })}${name}` : `${formatMessage({ id: 'title_edit' })}${name}`} className='form_modal' />}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              form.resetFields();
              onCreate(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          // layout="vertical"
          name="form_in_modal"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 16 }}
          initialValues={record}
        >
          {
            fields.filter(i => i.form).map((field, index) => {
              const { form = {} } = field
              const { required, rules, initialValue, style } = form
              return (
                <Form.Item
                  style={style}
                  key={index}
                  label={field.desc}
                  name={field.name}
                  rules={rules}
                  initialValue={initialValue}
                  required={required}
                >
                  {getFormItemDom(field)}
                </Form.Item>
              )
            })
          }

        </Form>
      </Modal>
    </>
  )
}

export default injectIntl(ModalForm);