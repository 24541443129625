import { Button, Divider, message, Modal, Tag } from '@om-tlh/bee';
import React, { useState } from 'react';
import List from 'modules/Receiver/list';
import { unique } from 'utils/utils';

const Share = (props) => {
  const { service, id } = props;
  const [visible, setVisible] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState([])
  const onSelect = (rows) => {
    if (!rows || !rows.length) return;
    setSelectedUsers(unique([...selectedUsers, ...rows.map(i => ({ user_id: i.receiver_id, user_name: i.receiver_name }))], 'user_id'))
  }
  const onClose = (item) => {
    setSelectedUsers(selectedUsers.filter(i => i.user_id !== item.user_id))
  }
  const onOk = () => {
    if (!selectedUsers || !selectedUsers.length) {
      message.warning('请选择接收人！')
      return
    }
    Modal.confirm({
      okButtonProps: { shape: 'round' },
      cancelButtonProps: { shape: 'round' },
      title: '提示',
      content: '确定要分享吗？',
      onOk: () => {
        if (!id) return;
        service && service(id, {
          receiver_ids: selectedUsers.map(i => i.user_id)
        }).then(data => {
          message.success('分享成功');
          setVisible(false);
        }).catch(error => {
          error && message.error(error.message ?? error.response?.data?.message ?? JSON.stringify(error))
        })
      }
    })
  }
  return (
    <>
      <Button shape='round' type='link' onClick={() => setVisible(true)}>分享</Button>
      <Modal
        okButtonProps={{ shape: 'round' }}
        cancelButtonProps={{ shape: 'round' }}
        open={visible} title="分享" width={1000}
        onCancel={() => {
          setVisible(false)
        }}
        onOk={() => onOk()}>
        已选接收人：{selectedUsers.map(i => <Tag closable onClose={() => onClose(i)} key={i.user_id}>{i.user_name}</Tag>)}
        <Divider />
        <List {...props} size='small' onSelect={onSelect} />
      </Modal>
    </>
  )
}

export default Share;