import './public-path';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider, ReactReduxContext } from 'react-redux'
import { createHashHistory, createBrowserHistory } from 'history'
import configureStore from './store/configureStore'

import './index.css';
import './index.less';
import reportWebVitals from './reportWebVitals';
import Intl from './i18n/intl'
import { ConfigProvider, message } from '@om-tlh/bee'
import Route from "./routes/routeRegistry";
import App from './routes/index';

import config from './config'
import * as httpCustom from '@/utils/http'
import { getQueryStringByName } from '@/utils/utils'
import { loadCustomUcSDK, ucValid } from '@/utils/ucService'
import Loading from '@/modules/SplitLoading'
import LocalStorageUtil from '@/utils/localStorageUtil'

window.appName = 'dzt'
// 接入智慧矿山或其它基座
if (window.__MICRO_APP_CONFIG__) {
  window.__IN_CLOUD__ = true
}

const appType = window.__site__config?.appType
window.appType = appType
const history = createBrowserHistory({
  // basename: window.__IN_CLOUD__ ? `/${window.appName}` : '',
  // basename: `/${window.appName}`
  basename: window.__IN_CLOUD__ ? `/${appType ? [...appType.split('/'), `${window.appName}`].filter(Boolean).join('/') : `${window.appName}`}` : `/${appType ? [...appType.split('/')].filter(Boolean).join('/') : ``}`
})
const store = configureStore(history)
let root = null

httpCustom.init({ ...config })
window.config = { ...config }

const themePrefix = window.appName
window.themePrefix = themePrefix

ConfigProvider.config({
  prefixCls: window.themePrefix,
})
const beeConfig = {
  prefixCls: window.themePrefix,
  autoInsertSpaceInButton: false,
  getPopupContainer: () => document.getElementById('content') || document.body
}

message.config({
  top: 10,
  maxCount: 3
})

export async function bootstrap() {
  // console.log('[demo-app] react app bootstraped');
}

export async function mount(props = {}) {
  // console.log('[demo-app] props from main framework', props);
  const { container, ...rest } = props;
  root = ReactDOM.createRoot(container ? container.querySelector('#root') : document.getElementById('root'));

  new Promise((res, rej) => {
    root.render(<Loading />)
    if (props.onGlobalStateChange) {
      return props.onGlobalStateChange((state, pre) => {
        if (state.jwtToken) {
          const token = state.jwtToken
          LocalStorageUtil.setAuth(`Bearer ${token}`)
        }
        if (!state.jwtToken && state.token) {
          const token = state.token
          LocalStorageUtil.setAuth(`${token}`)
        }
        if (state.lang) {
          const locale = state.lang
          LocalStorageUtil.setLocale(`${locale}`)
        }
        res()
      }, true)
    }
    let token = getQueryStringByName(`token_id`)
    if (token) {
      return ucValid(token).then(() => {
        res()
      })
      // token = atob(token)
      // LocalStorageUtil.setAuth(`${token}`)
      // location.href = location.href.replace(new RegExp(`&?token_id=${getQueryStringByName(`token_id`)}`), '')
    }
    res()
  }).finally(() => {
    root.render(<Provider store={store} context={ReactReduxContext}>
      <Intl locale={rest.locale || rest.lang}>
        <ConfigProvider {...beeConfig}>
          <App routeRegistry={Route.routeRegistry} {...rest} history={history} context={ReactReduxContext} appType={appType} />
        </ConfigProvider></Intl>
    </Provider>)
  })
}

export async function unmount(props) {
  root?.unmount()
}

window[window.appName] = {
  bootstrap,
  mount,
  unmount
}
if (!window.__IN_CLOUD__) {
  bootstrap().then(mount);
}

// reportWebVitals(process.env.NODE_ENV === 'production' ? void 0 : console.log);
