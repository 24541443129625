import axios from 'axios'
const MODULE_NAME = 'file';

export const createData = (belongType = '', body) => axios.post(`v1/${MODULE_NAME}`, body, {timeout: 0})
export const getDataDetail = (id) => axios.get(`/v1/${MODULE_NAME}/${id}`)
export const updateData = (id, body) => axios.put(`/v1/${MODULE_NAME}/${id}`, body)
export const deleteData = (id) => axios.delete(`/v1/${MODULE_NAME}/${id}`)
export const batchDeleteData = (id) => Array.isArray(id) ? Promise.all(id.map(i => axios.delete(`/v1/${MODULE_NAME}/${i}`))) : axios.delete(`/v1/${MODULE_NAME}/${id}`)

export const applyData = (params) => axios.put(`/v1/${MODULE_NAME}/apply`, {}, { params })
export const issueData = (params) => axios.put(`/v1/${MODULE_NAME}/issue`, {}, { params })
export const shareData = (id, params) => axios.put(`/v1/${MODULE_NAME}/share/${id}`, {}, { params })
export const upData = (id, displayName) => axios.put(`/v1/${MODULE_NAME}/up/${id}?display_name=${displayName}`)
export const getCustomRecognition = (id) => axios.get(`/v1/${MODULE_NAME}/custom_recognition/${id}`)
export const getRecognition = (id) => axios.get(`/v1/${MODULE_NAME}/recognition/${id}`)
export const getTemplateRecognition = (id) => axios.get(`/v1/${MODULE_NAME}/template_recognition/${id}`)
export const upload = (body) => axios.post(`/v1/${MODULE_NAME}/upload`, body)

export * from '@/components/OmPan/service'