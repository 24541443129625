import axios from 'axios'
import { getService } from '../utils'
import config from '@/config';

const baseURL = config.uc_url
const MODULE_NAME = 'users';

const assignRoles = (id, body) => axios.put(`/v1/${MODULE_NAME}/${id}/actions/assign_roles`, body, { baseURL })
const resetPassword = (id, body) => axios.put(`/v1/${MODULE_NAME}/${id}/actions/reset_password`, body, { baseURL })
const updatePassword = (body) => axios.put(`/v1/${MODULE_NAME}/actions/update_password`, body, { baseURL })
const assignSystemRoles = (id, body, params) => axios.put(`/v1/system/${MODULE_NAME}/${id}/actions/assign_roles`, body, { params, baseURL })
const getDataDetailSystem = (id) => axios.get(`/v1/system/${MODULE_NAME}/${id}`, { baseURL })

export default {
    ...getService(MODULE_NAME),
    assignRoles,
    resetPassword,
    updatePassword,
    assignSystemRoles,
    getDataDetailSystem
}