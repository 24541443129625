import React, { useEffect, useState, useRef } from 'react';
import { injectIntl } from 'react-intl';
import {
  Form, Input, Button, Transfer, Row, Col, Tag, Result,
  Select, Modal, Divider, message, Steps, Card, Typography,
  Popconfirm, Radio, Space, InputNumber
} from '@om-tlh/bee';
import { ZoomInOutlined, PlusOutlined, ZoomOutOutlined, BorderOutlined, AppstoreOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { getQueryStringByName } from '@/utils/utils'
import * as service from './service'
import tagService from '@/modules/Tag/service'
import fileService from '@/modules/File/service'
import { getLabelOptions, GOODS_TYPES, RESOURCE_PACK_TYPES, RESOURCE_PACK_UNIT_TYPES, SPECS_UNIT_TYPES, DOC_TOOL_TYPES, DOC_TOOL_UNIT_TYPES } from '@/utils/enum'
import RichEditor from '@/components/RichEditor'
import FileList from '@/modules/File/list'
import Nodata from '@/components/Nodata'
import Address from '@/components/Address';
import Path from '@/routes/path';
import { TweenOneGroup } from 'rc-tween-one';
import img from '@/theme/img/dongyunruilian.net.png';
import OCR from '@/components/OCR'
import AddTags1 from '@/components/AddTags'
import SelectFile2 from '@/components/FileSelect'
import UploadAttachments from '@/components/UploadAttachments'

const { Option } = Select;
const { TextArea } = Input;
const { Step } = Steps;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 18 },
};

const Create = (props) => {
  const [form] = Form.useForm();
  const id = getQueryStringByName('id')

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const res = await service.getDataDetail(id)
      const vals = {
        ...res
      }
      form.setFieldsValue(vals)
    }
    if (!id) return
    fetchData()
  }, [])

  const onFinish = values => {
    console.log('values: ', values);
    if (id) {
      setIsLoading(true)
      service.updateData(id, values).then(data => {
        props.history.goBack()
        message.success('修改成功')
        setIsLoading(false)
      }).catch(error => {
        setIsLoading(false)
        message.error(error.data || error.response?.data?.message)
      })
    } else {
      setIsLoading(true)
      service.createData(values).then(data => {
        props.history.goBack()
        message.success('增加成功')
        setIsLoading(false)
      }).catch(error => {
        setIsLoading(false)
        message.error(error.data || error.response?.data?.message)
      })
    }
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };


  return (
    <>
      <div className="content-header">
        <h2>{id ? '编辑' : '新增'}商品</h2>
      </div>
      <div className="content-body">
        <Form
          {...layout}
          form={form}
          name="basic"
          initialValues={{
            product_type: 'VIP_PERSONAL',
            resource_packs: [
              {
                resource_pack_type: void 0,
                quota: void 0,
                // resource_pack_unit: 'number'
              },
            ],
            specs: [
              {
                duration: void 0,
                // duration_unit: "YEAR",
                orig_price: void 0,
                pref_price: void 0
              }
            ]
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Card bordered={false} bodyStyle={{ boxShadow: '5px 5px 30px rgb(0 0 0 / 9%)', borderRadius: 4 }}>
            <Form.Item
              label='选择商品类型'
              style={{
                marginBottom: 0
              }}
              name="product_type"
            >
              <Radio.Group
                onChange={(event) => {
                  const type = event.target.value
                  console.log(form.getFieldValue('resource_packs'));
                  form.setFieldsValue({
                    resource_packs: form.getFieldValue('resource_packs').map(x => ({
                      resource_pack_type: void 0,
                      quota: void 0,
                      resource_pack_unit: type !== 'RESOURCE_PACK' ? 'number' : 'page'
                    })),
                  });
                }}
              >
                {
                  getLabelOptions(GOODS_TYPES).map(x => (<Radio key={x.value} value={x.value}>{x.label}</Radio>))
                }
              </Radio.Group>
            </Form.Item>
          </Card>
          <Card bordered={false} style={{ marginTop: 15 }} bodyStyle={{ boxShadow: '5px 5px 30px rgb(0 0 0 / 9%)', borderRadius: 4 }}>
            <Divider orientation="left" style={{ fontWeight: '600' }} >基本信息</Divider>
            <Form.Item
              label="商品名称"
              style={{

              }}
              wrapperCol={{ span: 8 }}
              name="product_name"
              rules={[
                { required: true, message: '请输入商品名称', whitespace: true }
              ]}
            >
              <Input maxLength={64} />
            </Form.Item>

            <Form.Item
              noStyle
              shouldUpdate={(pre, cur) => cur.product_type !== pre.product_type}
            >
              {
                (form) => {
                  return form.getFieldValue('product_type') === 'VIP_TEAM' ? (<Form.Item
                    label="成员个数"
                    style={{
                      marginBottom: 0,
                    }}
                    required
                  >
                    <Space style={{ width: '100%' }} split={<div style={{ marginBottom: 24 }}>-</div>}>
                      <Form.Item
                        name='min_member'
                        style={{
                          // display: 'inline-block',
                        }}
                        rules={[
                          { required: true, message: '请输入最小成员数' }
                        ]}
                      >
                        <InputNumber style={{
                          width: 120,
                        }} min={1} max={65535} precision={0} />
                      </Form.Item>

                      <Form.Item
                        name='max_member'
                        style={{
                          // display: 'inline-block',
                        }}
                        rules={[
                          { required: true, message: '请输入最大成员数' }
                        ]}
                      >
                        <InputNumber style={{
                          width: 120,
                        }} min={1} max={65535} precision={0}
                        //  addonAfter="个" 
                        />
                      </Form.Item>
                    </Space>
                  </Form.Item>) : null
                }
              }
            </Form.Item>

            <Divider orientation="left" style={{ fontWeight: '600' }} >资源套餐</Divider>
            {/* <Form.Item label={<h3 style={{ fontWeight: '600' }}></h3>}></Form.Item> */}
            <Form.List
              name="resource_packs"
              rules={[
                // {
                //   validator: async (_, names) => {
                //     if (!names || names.length < 2) {
                //       return Promise.reject(new Error('At least 2 passengers'));
                //     }
                //   },
                // },
              ]}
            >
              {
                (fields, { add, remove }, { errors }) => {
                  return (<>
                    {fields.map(({ key, name, ...restField }, index) => (<Form.Item
                      key={key}
                      label={`类型${index + 1}`}
                      style={{
                        marginBottom: 0,
                      }}
                      shouldUpdate={(pre, cur) => cur.product_type !== pre.product_type && (pre.product_type === 'RESOURCE_PACK' || cur.product_type === 'RESOURCE_PACK')}
                    >
                      {
                        (form) => {
                          return (form.getFieldValue('product_type') !== 'RESOURCE_PACK' ? <Space
                            style={{
                              display: 'flex',
                              // marginBottom: 8,
                            }}
                            align="top"
                            size={20}
                          >
                            <Form.Item
                              {...restField}
                              // label='类型'
                              style={{
                                marginBottom: 24,
                                width: 120
                              }}
                              name={[name, 'resource_pack_type']}
                              rules={[
                                { required: true, message: '请选择类型' }
                              ]}
                            >
                              <Select >
                                {
                                  getLabelOptions(RESOURCE_PACK_TYPES).map(x => (<Option key={x.value} value={x.value}>{x.label}</Option>))
                                }
                              </Select>
                            </Form.Item>
                            <Form.Item
                              label='数量'
                              style={{
                                marginBottom: 24
                              }}
                              required
                            >
                              <Input.Group compact>
                                <Form.Item
                                  {...restField}
                                  noStyle
                                  name={[name, 'quota']}
                                  rules={[
                                    { required: true, message: '请输入数量' }
                                  ]}
                                >
                                  <InputNumber min={1} max={65535} precision={0} />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  noStyle
                                  name={[name, 'resource_pack_unit']}
                                  initialValue={'number'}
                                  // rules={[
                                  //   { required: true, message: '请选择单位' }
                                  // ]}
                                >
                                  <Select >
                                    {
                                      getLabelOptions(RESOURCE_PACK_UNIT_TYPES).map(x => (<Option key={x.value} value={x.value}>{x.label}</Option>))
                                    }
                                  </Select>
                                </Form.Item>
                              </Input.Group>

                            </Form.Item>
                            <div>
                              {
                                fields.length !== 1 && <MinusCircleOutlined style={{
                                  fontSize: '1.2em',
                                  marginTop: '0.4em',
                                  marginRight: 10
                                }} onClick={() => remove(name)} />
                              }
                              {
                                fields.length === index + 1 && <PlusCircleOutlined style={{
                                  fontSize: '1.2em',
                                  marginTop: '0.4em'
                                }} onClick={() => add()} />
                              }
                            </div>
                          </Space> : <Space
                            style={{
                              display: 'flex',
                              // marginBottom: 8,
                            }}
                            align="top"
                            size={20}
                          >
                            <Form.Item
                              {...restField}
                              // label='类型'
                              style={{
                                marginBottom: 24,
                                width: 120
                              }}
                              name={[name, 'resource_pack_type']}
                              rules={[
                                { required: true, message: '请选择类型' }
                              ]}
                            >
                              <Select >
                                {
                                  getLabelOptions(DOC_TOOL_TYPES).map(x => (<Option key={x.value} value={x.value}>{x.label}</Option>))
                                }
                              </Select>
                            </Form.Item>
                            <Form.Item
                              label='数量'
                              style={{
                                marginBottom: 24
                              }}
                              required
                            >
                              <Input.Group compact>
                                <Form.Item
                                  {...restField}
                                  noStyle
                                  name={[name, 'quota']}
                                  rules={[
                                    { required: true, message: '请输入数量' }
                                  ]}
                                >
                                  <InputNumber min={1} max={65535} precision={0} />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  noStyle
                                  name={[name, 'resource_pack_unit']}
                                  initialValue={'page'}
                                  rules={[
                                    { required: true, message: '请选择单位' }
                                  ]}
                                >
                                  <Select >
                                    {
                                      getLabelOptions(DOC_TOOL_UNIT_TYPES).map(x => (<Option key={x.value} value={x.value}>{x.label}</Option>))
                                    }
                                  </Select>
                                </Form.Item>
                              </Input.Group>

                            </Form.Item>
                            <div>
                              {
                                fields.length !== 1 && <MinusCircleOutlined style={{
                                  fontSize: '1.2em',
                                  marginTop: '0.4em',
                                  marginRight: 10
                                }} onClick={() => remove(name)} />
                              }
                              {
                                fields.length === index + 1 && <PlusCircleOutlined style={{
                                  fontSize: '1.2em',
                                  marginTop: '0.4em'
                                }} onClick={() => add()} />
                              }
                            </div>
                          </Space>)
                        }
                      }

                    </Form.Item>)
                    )}
                  </>)
                }
              }
            </Form.List>

            {/* <Form.Item label={<h3 style={{ fontWeight: '600' }}>规格与定价</h3>}></Form.Item> */}
            <Divider orientation="left" style={{ fontWeight: '600' }} >规格与定价</Divider>
            <Form.List
              name="specs"
              rules={[
                // {
                //   validator: async (_, names) => {
                //     if (!names || names.length < 2) {
                //       return Promise.reject(new Error('At least 2 passengers'));
                //     }
                //   },
                // },
              ]}
            >
              {
                (fields, { add, remove }, { errors }) => {
                  return (<>
                    {fields.map(({ key, name, ...restField }, index) => (<React.Fragment
                      key={key}
                    >
                      <Form.Item
                        colon={false}
                        label={`规格${index + 1}`}
                        style={{
                          marginTop: 24,
                          marginBottom: 8,
                        }}
                      ></Form.Item>
                      <Form.Item
                        label={`周期`}
                        required
                        style={{
                          marginBottom: 0,
                        }}
                      >
                        <Space
                          style={{
                            display: 'flex',
                            width: '100%',
                            // marginBottom: 8,
                          }}
                          align="top"
                          size={20}
                        >
                          <Form.Item
                            // label='周期'
                            style={{
                              marginBottom: 0
                            }}
                            required
                          >
                            <Input.Group compact>
                              <Form.Item
                                {...restField}
                                noStyle
                                name={[name, 'duration']}
                                rules={[
                                  { required: true, message: '请输入周期数' }
                                ]}
                              >
                                <InputNumber min={1} max={65535} precision={0} />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                noStyle
                                name={[name, 'duration_unit']}
                                initialValue={'YEAR'}
                                rules={[
                                  { required: true, message: '请选择单位' }
                                ]}
                              >
                                <Select >
                                  {
                                    getLabelOptions(SPECS_UNIT_TYPES).map(x => (<Option key={x.value} value={x.value}>{x.label}</Option>))
                                  }
                                </Select>
                              </Form.Item>
                            </Input.Group>
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            label="原价"
                            style={{
                              marginBottom: 0,
                              width: 180
                            }}
                            name={[name, 'orig_price']}
                            rules={[
                              { required: true, message: '请输入原价' }
                            ]}
                          >
                            <InputNumber min={0.01} max={65535 * 100} precision={2}
                              addonAfter="元"
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            label="优惠价"
                            style={{
                              marginBottom: 0,
                              width: 180
                            }}
                            name={[name, 'pref_price']}
                          // rules={[
                          //   { required: true, message: '请输入优惠价' }
                          // ]}
                          >
                            <InputNumber min={0.01} max={65535 * 100} precision={2}
                              addonAfter="元"
                            />
                          </Form.Item>
                          <div>
                            {
                              fields.length !== 1 && <MinusCircleOutlined style={{
                                fontSize: '1.2em',
                                marginTop: '0.4em',
                                marginRight: 10
                              }} onClick={() => remove(name)} />
                            }
                            {
                              fields.length === index + 1 && <PlusCircleOutlined style={{
                                fontSize: '1.2em',
                                marginTop: '0.4em'
                              }} onClick={() => add()} />
                            }
                          </div>

                        </Space>
                      </Form.Item>
                    </React.Fragment>)
                    )}
                  </>)
                }
              }
            </Form.List>
            <Divider />
            <Form.Item
              label="描述"
              name="description"
            // rules={[{ required: true, message: '请输入描述' }]}
            >
              {/* <RichEditor {...props} bizType="Supplier" /> */}
              <TextArea
                showCount
                maxLength={500}
                autoSize={{ minRows: 3, maxRows: 8 }}
              />
            </Form.Item>
          </Card>

          <Form.Item
          // {...tailLayout}
          >
            <div className="steps-action" style={{ textAlign: 'center', marginTop: 24 }}>
              <Button shape='round' type="primary" htmlType='submit' disabled={isLoading} >
                提交
              </Button>
              <Button shape='round' onClick={() => props.history.goBack()} style={{
                margin: '0 8px',
              }}
                disabled={isLoading}
              >
                取消
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  )
}

export default injectIntl(Create);