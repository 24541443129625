import React, { useEffect, useState } from 'react';
import { Cascader, Input, Row, Col } from '@om-tlh/bee';
import CommonService from 'modules/common/service'

export default (props) => {
  const { onChange, value, hideDetail, id } = props
  const [options, setOptions] = useState([])
  const [cascaderVal, setCascaderVal] = useState([])
  const [inputVal, setInputVal] = useState('')
  useEffect(() => {
    const fetchData = async () => {
      const res = await CommonService.getAllCities()
      setOptions(JSON.parse(JSON.stringify(res).replace(/sub_cities/g, 'cities')))
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (value) {
      setCascaderVal(value.split(' ')[0].split('-'))
      setInputVal(value.split(' ')[1])
    }
  }, [value])

  const handleChange = (value) => {
    setCascaderVal(value)
    onChange && onChange(value.join('-') + (inputVal ? (' ' + inputVal) : ''))
  }
  const handleChangeInput = (e) => {
    setInputVal(e.target.value)
    onChange && onChange(cascaderVal.join('-') + ' ' + e.target.value)
  }
  return (
    <>
      <Row id={id}>
        <Col span={12}>
          <Cascader value={cascaderVal} options={options} onChange={handleChange} fieldNames={{
            label: 'name',
            value: 'name',
            children: 'cities'
          }} />
        </Col>
      </Row>
      {
        hideDetail
          ? null
          : <Row style={{ marginTop: 24 }}>
            <Col span={24}>
              <Input value={inputVal} onChange={handleChangeInput} placeholder='填写您的详细地址，以便在有需要时给您寄送书面文件' />
            </Col>
          </Row>
      }
    </>
  )
}