import React, { useState, useEffect } from 'react';
import { Upload, message } from '@om-tlh/bee';
import { LoadingOutlined, PlusOutlined, InboxOutlined } from '@ant-design/icons';
import config from 'config';
import LocalStorageUtil from 'utils/localStorageUtil'

const { Dragger } = Upload;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export default (props) => {
  const { value, onChange } = props
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (!value) return
    if (value.indexOf('http') !== -1) {
      setImageUrl(value)
      return
    }
    setImageUrl(`${config.img_url}/img/${value}`)
  }, [value])

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('请上传 JPG/PNG 图片!');
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error('图片大小不超过 5MB!');
    }
    return isJpgOrPng && isLt2M;
  }
  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl => {
        setLoading(false)
        setImageUrl(imageUrl)
      });
      onChange && onChange(`${config.img_url}/img/${info.file.response}`)
    }
  }
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>点击上传</div>
    </div>
  );
  return (
    <Dragger
      name="file"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      action={`${config.img_url}/v1/upload`}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      headers={{
        'Authorization': LocalStorageUtil.getAuth()
      }}
    >
      <p className="dzt-upload-drag-icon">
        <UploadOutlined />
      </p>
      <p className="dzt-upload-text">点击或者拖动文件到此区域进行上传</p>
      <p className="dzt-upload-hint">文件格式支持jpg、png，文件大小不超过5MB！</p>
    </Dragger>
  )
}