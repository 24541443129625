import axios from 'axios'
import config from 'config'

const baseURL = config.uc_url

export const login = (body) => {
  return axios.post('v1/login', body, { baseURL }).then(async res => {
    const data = await axios.get(`v1/tenants/${res.data.user_info.tenant_id}`, {
      headers: {
        'Authorization': res.data.token
      },
      baseURL
    })
    return {
      ...res,
      data: {
        ...res.data,
        user_info: { ...res.data.user_info, tenant: data }
      }
    }
  })
}

export const loginAccount = (body) => {
  return axios.post('v1/account/login', body, { baseURL })
}

export const loginMobile = (body) => {
  return axios.post('v1/mobile/login', body, { baseURL })
}

export const getCaptcha = async (params) => {
  return axios.get(`v1/mobile/send_verify_code`, { baseURL, params })
}
