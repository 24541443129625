import axios from 'axios'
import config from 'config'

const baseURL = config.uc_url

export const login = (body) => {
  return axios.post('v1/login', body, { baseURL })
}

export default {
  login
}