import React from 'react';
import { Select } from '@om-tlh/bee';
import tenantService from './service';

const { Option } = Select;

class SearchInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: props.options
    }
    this.timeout = null;
    this.currentValue = '';
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.options })
  }

  getDatas = (params, callback) => {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    function fake() {
      tenantService.getDatas(params).then(data => {
        callback(data.data.items)
      })
    }
    this.timeout = setTimeout(fake, 300);
  }

  handleSearch = (value) => {
    if (value) {
      this.getDatas({ keyword: value }, data => this.setState({ data }))
    } else {
      this.setState({ data: [] })
    }
  }

  handleChange = (value) => {
    const { onChange } = this.props
    onChange && onChange(value)
  }

  render() {
    const options = this.state.data.map(d => <Option key={d.tenant_id}>{d.display_name}</Option>);
    return (
      <Select
        showSearch
        value={this.props.value}
        placeholder={this.props.placeholder}
        style={Object.assign({}, { width: 200 }, this.props.style)}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={this.handleSearch}
        onChange={this.handleChange}
        notFoundContent={null}
      >
        {options}
      </Select>
    )
  }

}

export default SearchInput;