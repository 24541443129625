/**
 * 设置cookie
 * @param {*} key 
 * @param {*} value 
 */
function setCookie(key, value, path) {
  var Days = 30;
  var exp = new Date();
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
  document.cookie = key + "=" + escape(value) + ";path=" + (path || "/") + ";expires=" + exp.toGMTString();
}

/**
 * 获取所有cookie
 */
function getAllCookies() {
  var cookies = document.cookie.split(/;\s/g);
  var cookieObj = {};
  cookies.forEach(function (item) {
    var key = item.split('=')[0];
    cookieObj[key] = item.split('=')[1];
  });
  return cookieObj;
}

/**
 * 获取cookie值
 * @param {*} key 
 */
function getCookie(key) {
  var arr, reg = new RegExp("(^| )" + key + "=([^;]*)(;|$)");
  if (arr = document.cookie.match(reg))
    return unescape(arr[2]);
  else
    return null;
}

/**
 * 清除cookie
 * @param {*} key 
 */
function clearCookie(key) {
  setCookie(key, "");
}

/**
 * 清除所有cookie
 */
function clearAllCookie() {
  var keys = Object.keys(getAllCookies());
  keys.forEach(key => {
    clearCookie(key)
  })
}

/**
 * 设置localstorage
 * @param {*} key 
 * @param {*} value 
 */
function setLocalStorage(key, value) {
  localStorage[key] = value
}

/**
 * 获取localstorage
 * @param {*} key 
 */
function getLocalStorage(key) {
  return localStorage[key]
}

/**
 * 清除localstorage
 * @param {*} key 
 */
function clearLocalStorage(key) {
  localStorage.removeItem(key)
}

/**
 * 清除所有localstorage
 */
function clearAllLocalStorage() {
  localStorage.clear()
}

/**
 * 设置缓存
 * @param {*} key 
 * @param {*} value 
 */
function setStorage(key, value) {
  window.localStorage ? setLocalStorage(key, value) : setCookie(key, value)
}

/**
 * 获取缓存
 * @param {*} key 
 */
function getStorage(key) {
  return window.localStorage ? getLocalStorage(key) : getCookie(key)
}

/**
 * 清除缓存
 * @param {*} key 
 */
function clearStorage(key) {
  window.localStorage ? clearLocalStorage(key) : clearCookie(key)
}

/**
 * 清除所有缓存
 */
function clearAllStorage() {
  window.localStorage ? clearAllLocalStorage() : clearAllCookie()
}

class LocalStorageUtil {

  /**
   * 缓存列表每页条数
   * @param {*} value 
   */
  setPageSize(value) {
    setStorage('pageSize', value)
  }

  /**
   * 获取缓存列表每页条数
   */
  getPageSize() {
    return Number(getStorage('pageSize')) || 10
  }

  /**
   * 缓存locale
   * @param {*} value 
   */
  setLocale(value) {
    setStorage('locale', value)
  }

  /**
   * 获取缓存locale
   */
  getLocale() {
    return getStorage('locale')
  }

  /**
   * 缓存auth
   * @param {*} value 
   */
  setAuth(value) {
    setStorage('auth', value)
  }

  /**
   * 获取缓存auth
   */
  getAuth() {
    return getStorage('auth')
  }
  /**
     * 缓存用户账户信息
     * @param {*} value 
     */
  setAccountInfo(value) {
    setStorage('accountInfo', value)
  }

  /**
   * 获取缓存用户账户信息
   */
  getAccountInfo() {
    return getStorage('accountInfo')
  }
  /**
   * 缓存用户信息
   * @param {*} value 
   */
  setUserInfo(value) {
    setStorage('userInfo', value)
  }

  /**
   * 获取缓存用户信息
   */
  getUserInfo() {
    return getStorage('userInfo')
  }

  /**
   * 缓存权限信息
   * @param {*} value 
   */
  setPrivileges(value) {
    setStorage('privileges', value)
  }

  /**
   * 获取缓存权限信息
   */
  getPrivileges() {
    return getStorage('privileges')
  }

  setStorage(key, value) {
    return setStorage(key, value)
  }

  getStorage(key) {
    return getStorage(key)
  }

  setCookie(key, value) {
    return setCookie(key, value)
  }

  /**
   * 清除缓存
   * @param {*} key 
   */
  clearStorage(key) {
    clearStorage(key)
  }

  /**
   * 清除所有缓存
   */
  clearAllStorage() {
    clearAllStorage()
  }

  /**
   * 清除所有cookie
   */
  clearAllCookie() {
    clearAllCookie()
  }

}

export default new LocalStorageUtil();