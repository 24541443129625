import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button, List, Avatar, Skeleton, Divider, Input } from '@om-tlh/bee';
import { UserOutlined, FireFilled, RightOutlined } from '@ant-design/icons';

const { Search } = Input;
const { Meta } = Card;

const Home = (props) => {
  const onSearch = (value) => console.log(value);
  return (
    <>
      <div className="content-header">
        <h2>知识广场</h2>
      </div>
      <div className="content-body">
        <Card style={{ marginBottom: 32 }}>
          <Row>
            <Col span={6}></Col>
            <Col span={12}><Search
              placeholder="搜索关键字"
              allowClear
              enterButton="搜索"
              size="large"
              onSearch={onSearch}
            /></Col>
            <Col span={6}></Col>
          </Row>
        </Card>
        <Row gutter={32}>
          <Col span={6}>
            <Card bodyStyle={{ textAlign: 'center' }} style={{ marginBottom: 32 }}>
              <Avatar size={64} icon={<UserOutlined />} style={{ marginBottom: 32 }} />
              <h2>用户名</h2>
              <p>个人贡献度：12</p>
            </Card>
            <Card size='small' title='热门文档' style={{ marginBottom: 32 }}>
              <List
                size="small"
                dataSource={[
                  { type: '任务提醒', title: '市场部工作总结', create_time: '2020-04-10 09:00' },
                  { type: '内部分享', title: '个人年总总结', create_time: '2020-04-10 09:00' },
                  { type: '内部分享', title: '商业PPT模板', create_time: '2020-04-10 09:00' },
                  { type: '内部分享', title: '市场部工作总结', create_time: '2020-04-10 09:00' },
                ]}
                renderItem={(item) => <List.Item>
                  <Skeleton title={false} loading={item.loading} active>
                    <List.Item.Meta
                      title={<a>【{item.type}】{item.title}</a>}
                    />
                    <FireFilled style={{ color: 'red' }} />
                  </Skeleton>
                </List.Item>
                }
              />
            </Card>
            <Card size='small' title='优秀创作者' style={{ marginBottom: 32 }}>
              <List
                size="small"
                dataSource={[
                  { title: '疯狂输出高质量作品', description: '周作栋' },
                  { title: '擅长用户研究及原型交互设计', description: '子墨' },
                  { title: '作品可复用性超高', description: '缔梦人' },
                  { title: '作品日均下载使用量过百', description: '忆设计' },
                  { title: '作品浏览量10万+', description: '小龙' },
                ]}
                renderItem={(item) => <List.Item>
                  <Skeleton title={false} loading={item.loading} active>
                    <List.Item.Meta
                      avatar={<Avatar icon={<UserOutlined />} />}
                      description={item.description}
                      title={<a>{item.title}</a>}
                    />
                  </Skeleton>
                </List.Item>
                }
              />
              <a>查看更多</a>
            </Card>
          </Col>
          <Col span={18}>
            <Card>
              <Card size='small' bordered={false} title='文档中心' extra={<RightOutlined />} style={{ marginBottom: 32 }}>
                <Row gutter={32}>
                  {
                    Array(3).fill(0).map((i, d) => <Col span={8} key={d}>
                      <Card
                        hoverable
                        cover={<img alt="example" style={{ objectFit: 'cover' }} height={120} src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />}
                      >
                        <Meta title="文档标题" description="一句话描述一句话描述" />
                        <p style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 10 }}>
                          <span>2022-08-03</span>
                          <span><Avatar size="small" style={{ marginRight: 5 }} icon={<UserOutlined />} />发布者名字</span>
                        </p>
                      </Card>
                    </Col>)
                  }
                </Row>
              </Card>
              <Card size='small' bordered={false} title='经验课堂' extra={<RightOutlined />}>
                <Row gutter={32}>
                  {
                    Array(3).fill(0).map((i, d) => <Col span={8} key={d}>
                      <Card
                        hoverable
                        bodyStyle={{ padding: 0 }}
                        cover={<img alt="example" style={{ objectFit: 'cover' }} height={120} src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />}
                      >
                        {/* <Meta title="文档标题" description="一句话描述一句话描述" />
                        <p style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 10 }}>
                          <span>2022-08-03</span>
                          <span><Avatar size="small" style={{ marginRight: 5 }} icon={<UserOutlined />} />发布者名字</span>
                        </p> */}
                      </Card>
                    </Col>)
                  }
                </Row>
              </Card>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Home;
