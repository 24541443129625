// import List from './index'
// import Create from './create'
// import Detail from './detail'
// import Recognize from '@/modules/ModelStyle/recognize'
import Path from 'routes/path'

const MODULE_NAME = 'model_content';
const TEAM_MODULE_NAME = 'team_model_content';

const routes = [
    {
        id: `${MODULE_NAME}_manage`,
        name: `${MODULE_NAME}_manage`,
        // component: List,
        componentPath: 'modules/ModelContent/index',
        path: Path.pathname[`${MODULE_NAME}`]['home'],
        key: `${MODULE_NAME}_manage`,
    },
    {
        id: `${MODULE_NAME}_create`,
        name: `${MODULE_NAME}_create`,
        // component: Create,
        componentPath: 'modules/ModelContent/create',
        path: Path.pathname[`${MODULE_NAME}`]['create'],
        key: `${MODULE_NAME}_create`,
        parentKey: `model_manage`
    },
    {
        id: `${MODULE_NAME}_edit`,
        name: `${MODULE_NAME}_edit`,
        // component: Create,
        componentPath: 'modules/ModelContent/create',
        path: Path.pathname[`${MODULE_NAME}`]['edit'],
        key: `${MODULE_NAME}_edit`,
        parentKey: `model_manage`,
    },
    {
        id: `${TEAM_MODULE_NAME}_create`,
        name: `${TEAM_MODULE_NAME}_create`,
        // component: Create,
        componentPath: 'modules/ModelContent/create',
        path: Path.pathname[`${TEAM_MODULE_NAME}`]['create'],
        key: `${TEAM_MODULE_NAME}_create`,
        parentKey: `model_team_manage`
    },
    {
        id: `${TEAM_MODULE_NAME}_edit`,
        name: `${TEAM_MODULE_NAME}_edit`,
        // component: Create,
        componentPath: 'modules/ModelContent/create',
        path: Path.pathname[`${TEAM_MODULE_NAME}`]['edit'],
        key: `${TEAM_MODULE_NAME}_edit`,
        parentKey: `model_team_manage`,
    },
    {
        id: `${MODULE_NAME}_detail`,
        name: `${MODULE_NAME}_detail`,
        // component: Detail,
        componentPath: 'modules/ModelContent/detail',
        path: Path.pathname[`${MODULE_NAME}`]['detail'],
        key: `${MODULE_NAME}_detail`,
        parentKey: `model_manage`
    },
    {
        id: `${MODULE_NAME}_recognize`,
        name: `${MODULE_NAME}_recognize`,
        // component: Recognize,
        componentPath: 'modules/ModelStyle/recognize',
        path: Path.pathname[`${MODULE_NAME}`]['recognize'],
        key: `${MODULE_NAME}_recognize`,
        parentKey: `model_manage`
    },
    {
        id: `${TEAM_MODULE_NAME}_recognize`,
        name: `${TEAM_MODULE_NAME}_recognize`,
        // component: Recognize,
        componentPath: 'modules/ModelStyle/recognize',
        path: Path.pathname[`${TEAM_MODULE_NAME}`]['recognize'],
        key: `${TEAM_MODULE_NAME}_recognize`,
        parentKey: `model_team_manage`
    },
]

export default {
    routes
}