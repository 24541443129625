import axios from 'axios'
import { getService } from '../utils'
import config from '@/config';
import commonService from '@/modules/common/service'

const baseURL = config.uc_url
const MODULE_NAME = 'users';

const assignRoles = (id, body) => axios.put(`/v1/${MODULE_NAME}/${id}/actions/assign_roles`, body, { baseURL })
const resetPassword = (id, body) => axios.put(`v1/persons/${id}/actions/reset_password`, body, { baseURL })
const updatePassword = (body) => axios.put(`v1/persons/actions/update_password`, body)
const assignSystemRoles = (id, body, params) => axios.put(`/v1/system/${MODULE_NAME}/${id}/actions/assign_roles`, body, { params, baseURL })

const createData = (body) => axios.post(`v1/account/add_sub_account`, body)
const deleteData = (id) => axios.delete(`v1/account/delete_sub_account/${id}`)
const getTeamInvitationUrl = commonService.getTeamInvitationUrl
const deleteMember = (id) => axios.delete(`v1/team/remove_member/${id}`)

export default {
    ...getService(MODULE_NAME),
    assignRoles,
    resetPassword,
    updatePassword,
    assignSystemRoles,
    createData,
    deleteData,
    getTeamInvitationUrl,
    deleteMember
}
