import Canvas from './Canvas.core'

export default class HoverLayer extends Canvas {
  constructor(width, height) {
    super(width, height)
    this.hoverLayer = document.createElement('div')
    this.__width = width
    this.__height = height
    this.hoverLayer.style.width = `${width}px`
    this.hoverLayer.style.height = `${height}px`
    this.hoverLayer.style.position = 'absolute'
    this.hoverLayer.style.top = 0
    this.hoverLayer.style.left = 0
    this.hoverLayer.style.zIndex = 1
    this.hoverLayer.style.backgroundColor = 'transparent'
  }
  addEvent = (elem, type, callback) => {
    if (!type) return
    elem.addEventListener(type, callback)
  }
  removeEvent = (elem, type, callback) => {
    if (!type) return
    elem.removeEventListener(type, callback)
  }
  getXYOnCanvas = (event) => {
    const divRect = this.hoverLayer.getBoundingClientRect()
    const left = event.clientX - divRect.left
    const top = event.clientY - divRect.top
    return {
      x: left,
      y: event.clientY - divRect.top,
      p: [left < 0 ? 0 : (left > this.__width ? this.__width : left), top < 0 ? 0 : (top > this.__height ? this.__height : top)]
    }
  }
  randomColor = () => {
    let red = parseInt(Math.random() * 256)
    red = red < 16 ? `0${red.toString(16)}` : red.toString(16)
    let green = parseInt(Math.random() * 256)
    green = green < 16 ? `0${green.toString(16)}` : green.toString(16)
    let blue = parseInt(Math.random() * 256)
    blue = blue < 16 ? `0${blue.toString(16)}` : blue.toString(16)
    const result = `#${red}${green}${blue}`
    return result
  }
  getOssKey = (url = '') => {
    return url.split('?')[0].split('//').slice(1).join('//').split('/').slice(2).join('/')
  }
}
