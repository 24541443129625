import moment from 'moment';
import SearchType from 'utils/searchType';
import config from 'config';
import LocalStorageUtil from 'utils/localStorageUtil'
import { base64Decode } from 'utils/base64'

let localesProject = require('i18n/modules')
const jexl = require('jexl');

/**
 * 获取地址栏参数值
 * @param {*} name 
 */
export const getQueryString = function (name, url) {
  let result = (url || global.location.search).match(
    new RegExp('[?&]' + name + '=([^&]+)', 'i')
  )
  result = result && result.length > 0 ? result[1] : ''
  if (!result) {
    result = (global.location.hash).match(
      new RegExp('[?&]' + name + '=([^&]+)', 'i')
    )
    result = result && result.length > 0 ? result[1] : ''
  }
  return result
}
export const getQueryStringByName = getQueryString
/**
 * 获取地址栏参数
 * @param {*} str 
 * @param {*} name 
 */
const getStrQuery = (str, name) => {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  let r = str.substring((str.search(/\?/)) + 1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null
}
const getStrQueryOriginal = (str, name) => {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  let r = str.substring((str.search(/\?/)) + 1).match(reg);
  if (r != null) {
    return r[2];
  }
  return null
}
/**
 * 获取地址栏参数
 * @param {*} type 
 * @param {*} key 
 */
const getParam = (type, key) => {
  const regExp = new RegExp(`[?&#]{1}${key}=(.*?)([&/#]|$)`)
  const value = window.location[type].match(regExp)
  return value && value[1]
}

/**
 * 设置地址栏参数
 * @param {*} type 
 * @param {*} keyValueMap 
 */
const setParam = (type, keyValueMap) => {
  if (!(type === 'search' || type === 'hash')) return
  let str = `${window.location[type]}`
  for (const key of Object.keys(keyValueMap)) {
    const value = keyValueMap[key]
    if (getParam(type, key)) {
      const regExp = new RegExp(`(.*)([#&?]${key}=)(.*?)($|&.*)`)
      const array = str.match(regExp)
      if (value === '') {
        array.splice(2, 2)
      } else {
        array[3] = value
      }
      array.shift()
      str = array.join('')
    } else {
      str = str && str.indexOf('?') !== -1
        ? `${str}&${key}=${value}`
        : `${str}?${key}=${value}`
    }
  }
  window.location[type] = str
}
/**
 * 获取所有cookie
 */
export function getAllCookies() {
  var cookies = document.cookie.split(/;\s/g);
  var cookieObj = {};
  cookies.forEach(function (item) {
    var key = item.split('=')[0];
    cookieObj[key] = item.split('=')[1];
  });
  return cookieObj;
}

/**
 * 获取cookie值
 * @param {*} key 
 */
export function getCookie(key) {
  var arr, reg = new RegExp("(^| )" + key + "=([^;]*)(;|$)");
  if (arr = document.cookie.match(reg))
    return unescape(arr[2]);
  else
    return null;
}

/**
 * 清除cookie
 * @param {*} key 
 */
export function clearCookie(key) {
  setCookie(key, "");
}

/**
 * 清除所有cookie
 */
export function clearAllCookie() {
  var keys = Object.keys(getAllCookies());
  keys.forEach(key => {
    clearCookie(key)
  })
}


/**
 * 请求参数序列化
 * @param {*} query 
 * @param {*} allowSameKey 
 */
const createQueryString = (query, allowSameKey) => {
  const queryArr = []

  for (let key in query) {
    if (query.hasOwnProperty(key)) {
      const value = query[key]

      if (typeof value === 'object' && allowSameKey) {
        value.forEach(v => {
          queryArr.push(`${key}=${v}`)
        })
      } else {
        queryArr.push(`${key}=${value}`)
      }
    }
  }
  return queryArr.length ? `?${queryArr.join('&')}` : ''
}

/**
 * 随机name
 * @param {*} prefix 
 */
const createRandomName = (prefix) => {
  return `${prefix}-${Math.random().toString(36).slice(-4)}`
}

/**
 * 键值对数组转字符串
 * @param {*} arr 
 */
const keypairsArrayToString = (arr) => {
  // arr = [{key:1,value:2},...]
  if (!arr || !arr.length) return
  return arr.map(i => `--${i.key}=${i.value}`).join(' ')
}

/**
 * 键值对数组转对象
 * @param {*} arr 
 */
const keypairsArrayToMap = (arr) => {
  // arr = [{key:1,value:2},...]
  if (!arr || !arr.length) return null
  var obj = {}
  arr.forEach(i => {
    // if (!i.key) return
    obj[i.key] = i.value
  })
  return obj
}

/**
 * 键值对对象数组转提交数组
 * @param {*} arr 
 */
const keypairsJSArrayToSubmitArray = (arr) => {
  if (!arr || !arr.length) return []
  var newArr = []
  arr.forEach(i => {
    newArr.push({ [i.key]: i.value })
  })
  return newArr
}

/**
 * 键值对提交数组转对象数组
 * @param {*} arr 
 */
const keypairsSubmitArrayToJSArray = (arr) => {
  if (!arr || !arr.length) return []
  var newArr = []
  arr.forEach(i => {
    for (var n in i) {
      newArr.push({ key: n, value: i[n] })
    }
  })
  return newArr
}

/**
 * 键值对字符串转数组
 * @param {*} str 
 */
const keypairsStringToArray = (str) => {
  // str = '--var1=123 --var2=456'
  if (!str) return
  return str.split(' ').map(i => {
    var arr = i.split('=')
    return {
      key: arr[0].replace('--', ''),
      value: arr[1]
    }
  })
}

/**
 * 键值对对象转数组
 * @param {*} obj 
 */
const keypairsMapToArray = (obj) => {
  if (!obj || typeof obj !== 'object') return
  var arr = []
  for (var i in obj) {
    arr.push({ key: i, value: obj[i] })
  }
  return arr
}

/**
 * 获取对象相应值
 * @param {*} record 
 * @param {*} fieldName 
 */
const getRecordValue = (record, fieldName) => {
  if (!record || !fieldName) return
  var name = fieldName
  if (Array.isArray(name)) {
    name = name.join('.')
  }
  if (name && name.indexOf('.') !== -1) {
    var nameArr = name.split('.')
    var s = nameArr.reduce((pre, cur, index, nameArr) => {
      if (typeof pre === 'object') return pre && pre[cur]
      return record[pre] && record[pre][cur]
    })
    if (typeof s === 'string' || typeof s === 'number' || typeof s === 'object') return s
  }
  return record[fieldName]
}

/**
 * 获取时间
 * @param {*} t 
 */
const getTimeString = (t) => {
  return t < 10 ? '0' + t : t
}

/**
 * 时间秒转时分秒
 * @param {*} t 
 */
const getSecToHMS = (t) => {
  if (!t) return
  t = t * 1
  var obj = {
    h: t / 3600,
    m: t / 60 % 60,
    s: t % 60
  }
  var arr = []
  for (var i in obj) {
    arr.push(getTimeString(Math.floor(obj[i])))
  }
  return arr.join(':')
}

/**
 * 数值大小格式化
 * @param {*} size 
 */
export const sizeFormat = (size) => {
  size = parseInt(size)
  if (!size && size !== 0) return '-'

  if (size / 1024 / 1024 / 1024 / 1024 >= 1) {
    return `${(size / 1024 / 1024 / 1024 / 1024).toFixed(2)} TB`
  }

  if (size / 1024 / 1024 / 1024 >= 1) {
    return `${(size / 1024 / 1024 / 1024).toFixed(2)} GB`
  }

  if (size / 1024 / 1024 >= 1) {
    return `${(size / 1024 / 1024).toFixed(2)} MB`
  }

  if (size / 1024 >= 1) {
    return `${(size / 1024).toFixed(2)} KB`
  }

  return `${size} Byte(s)`
}

/**
 * 值大小格式化
 * @param {*} value 
 */
const valueFormat = (value) => {
  value = value * 1
  if (value / 1024 / 1024 / 1024 / 1024 >= 1) {
    return `${(value / 1024 / 1024 / 1024 / 1024).toFixed(1)} TiB`
  }

  if (value / 1024 / 1024 / 1024 >= 1) {
    return `${(value / 1024 / 1024 / 1024).toFixed(1)} GiB`
  }

  if (value / 1024 / 1024 >= 1) {
    return `${(value / 1024 / 1024).toFixed(1)} MiB`
  }

  if (value / 1024 >= 1) {
    return `${(value / 1024).toFixed(1)} KiB`
  }

  return `${value.toFixed(1)} B`
}

const localPathPrefix = '/mnt/oss/omai-job/';
const objectStoragePathPrefix = config.object_url;

/**
 * 对象存储地址转本地地址
 * @param {*} path 
 */
const transformObjectStorageToLocal = (path) => {
  if (!path) return
  return path.replace(objectStoragePathPrefix, localPathPrefix)
}

/**
 * 本地地址转对象存储地址
 * @param {*} path 
 */
const transformLocalToObjectStorage = (path) => {
  if (!path) return
  return path.replace(localPathPrefix, objectStoragePathPrefix)
}

/**
 * 时间格式化
 * @param {*} time 
 * @param {*} type 
 */
export const timeFormat = (time, type) => {
  if (!time || time === 'null') return '-'
  const _time = Number(time)
  time = isNaN(_time) ? time : _time
  switch (type) {
    case SearchType.KEY_DATE:
      return moment(time).format('YYYY-MM-DD');
    case SearchType.KEY_DATE_TIME:
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    default:
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
  }
}

/**
 * 运行时间格式化
 * @param {*} startTime 
 * @param {*} endTime 
 * @param {*} tag 
 */
export const runtimeFormat = (startTime, endTime, tag) => {
  if (!startTime) return '-'
  if (!endTime && !tag) return '-'
  return getSecToHMS(Math.abs((endTime ? moment(endTime) : moment()).diff(startTime, 'seconds'))) || '-'
}

/**
 * 获取url
 * @param {*} protocalType 
 * @param {*} host 
 * @param {*} port 
 * @param {*} path 
 */
const getUrl = (protocalType, host, port, path) => {
  if (!protocalType && !host) return
  return `${protocalType.toLowerCase()}://${host}${port ? ':' + port : ''}${path || '/'}`
}

/**
 * 判断是否有权限（旧）
 * @param {*} permission 
 */
const hasPermission2 = (permission) => {
  if (!permission) return false
  const userInfo = LocalStorageUtil.getUserInfo()
  let userInfoObj = {}
  try {
    userInfoObj = JSON.parse(userInfo)
  } catch (e) {
    console.log(e)
  }
  let userPermissions = []

  userInfoObj.roles && userInfoObj.roles.forEach(i => {
    if (i.privileges && i.privileges.length) {
      userPermissions.push(...i.privileges)
    }
  })
  return userPermissions.includes(permission)
}

/**
 * 判断是否有权限
 * @param {*} permission 
 */
const hasPermission = (permission) => {
  // return true
  if (!permission) return false
  const privileges = LocalStorageUtil.getPrivileges()
  let privilegesArr = []
  try {
    privilegesArr = JSON.parse(base64Decode(privileges))
  } catch (e) {
  }
  // return privilegesArr.includes(permission)
  let permissionTag = false
  const permissionArr = permission.split(',').map(x => x?.trim())
  permissionArr.forEach(permission => {
    if (privilegesArr.includes(permission)) permissionTag = true
  })
  return permissionTag
}

/**
 * 获取domain
 * @param {*} name 
 */
const getDomain = (name) => {
  const componentsStr = localStorage['components']
  let components = []
  try {
    components = JSON.parse(componentsStr)
  } catch (err) {

  }
  const component = components.find(i => i.name === name) || {}
  if (!component.domain) return
  if (component.domain.indexOf('http') !== -1 || component.domain.indexOf('https') !== -1) {
    return component.domain
  } else {
    return `http${component.useSSL === '1' ? 's' : ''}://${component.domain}`
  }
}

/**
 * 构建地址参数
 * @param {*} jobName 
 * @param {*} jobType 
 * @param {*} uri 
 */
const createGatewayParams = (jobName, jobType, uri) => {
  if (!jobName) return;
  const userInfoStr = LocalStorageUtil.getUserInfo()
  let userInfo = {}
  try {
    userInfo = JSON.parse(userInfoStr)
  } catch (e) { }
  const { user_id: userId } = userInfo
  return {
    id: jobName,
    predicates: [
      {
        name: 'Path',
        args: {
          pattern: `/${jobType || 'dev'}/${userId}/${jobName}/**`
        }
      }
    ],
    uri: uri,
    order: 0
  }

}

/**
 * 获取时间间隔步长
 * @param {*} startTime 
 * @param {*} endTime 
 */
const getStepByTimeDiff = (startTime, endTime) => {
  let step = 30;
  const diff = moment(endTime).valueOf() - moment(startTime).valueOf();
  if (diff <= 6 * 3600 * 1000)
    step = 30
  else if (diff <= 8 * 3600 * 1000) // 28 800 000
    step = 40
  else if (diff <= 16 * 3600 * 1000)
    step = 60
  else if (diff <= 32 * 3600 * 1000)
    step = 120
  else if (diff <= 3 * 24 * 3600 * 1000)
    step = 240
  else if (diff <= 7 * 24 * 3600 * 1000)
    step = 600
  else if (diff <= 11 * 24 * 3600 * 1000)
    step = 1200
  else if (diff <= 15 * 24 * 3600 * 1000)
    step = 1800
  else if (diff <= 18 * 24 * 3600 * 1000)
    step = 2400
  else if (diff <= 33 * 24 * 3600 * 1000)
    step = 3600
  else if (diff <= 65 * 24 * 3600 * 1000)
    step = 7200
  else if (diff <= 109 * 24 * 3600 * 1000)
    step = 14400
  else if (diff <= 1 * 356 * 24 * 3600 * 1000)
    step = 21600
  else if (diff <= 2 * 356 * 24 * 3600 * 1000)
    step = 43200
  else if (diff <= 5 * 356 * 24 * 3600 * 1000)
    step = 86400
  else if (diff <= 10 * 356 * 24 * 3600 * 1000)
    step = 172800
  else {
    step = 30758400
  }
  return step;
}

/**
 * 获取国际化语言
 * @param {*} props 
 */
const getLocale = (props) => {
  const defaultLang = 'zh-CN'
  let locale = getQueryString('locale')
  const localstorageLocale = LocalStorageUtil.getLocale()
  if (localstorageLocale && !locale) {
    locale = localstorageLocale
  }

  if (!locale) {
    let languages = global.navigator.languages || [global.navigator.language || global.navigator.userLanguage || defaultLang]
    locale = props.locale || languages[0]
  }
  if (!localesProject[locale]) {
    locale = defaultLang
  }
  LocalStorageUtil.setLocale(locale)
  return locale
}

/**
 * 判断条件
 * @param {*} enableCondiction 
 * @param {*} obj 
 */
const isInCondition = (enableCondiction, obj) => {
  if (!enableCondiction) return true
  let condition = false
  try {
    condition = jexl.evalSync(enableCondiction, obj)
  } catch (e) { }
  return condition
}

/**
 * 获取对象数组键值
 * @param {*} arr 
 * @param {*} key 
 */
const getArrayMapValue = (arr, key) => {
  // arr = [{key1: value1}, {key2: value2}]
  let value = ''
  if (!(arr && arr.length)) return ''
  arr.forEach(i => {
    for (var j in i) {
      if (j === key)
        value = i[j]
    }
  })
  return value
}

/**
 * 获取最近六个月时间列表
 */
const getMonths = () => {
  let arr = []
  for (var i = 0; i < 6; i++) {
    const time = moment().month(moment().month() - i) //.startOf('month')
    const year = moment(time).year()
    const month = moment(time).month()
    const label = `${year}/${month + 1}`
    arr.push({
      label: label,
      value: label,
      range: [time.startOf('month').format('YYYY-MM-DD HH:mm:ss'), (i === 0 ? moment() : time.endOf('month')).format('YYYY-MM-DD HH:mm:ss')]
    })
  }
  return arr
}

const getUserInfo = () => {
  const userInfoStr = LocalStorageUtil.getUserInfo()
  let userInfo = {}
  try {
    userInfo = JSON.parse(userInfoStr)
  } catch (e) { }
  return userInfo
}

// async error handle
const ha = promise => {
  return promise.then(data => [null, data]).catch(err => [err, void 0])
}
export const getAllNodes = async ({ bucketName, prefix }, listObjects = () => []) => {
  const { objects = [], ...rest } = await listObjects({ bucketName, prefix })
  let result = objects.reduce(async (pre, cur) => {
    pre = await pre
    if (cur.type === 'file') {
      if (cur.Key && (cur.Key.endsWith('.jpg') || cur.Key.endsWith('.png') || cur.Key.endsWith('.jpeg') || cur.Key.endsWith('.tif') || cur.Key.endsWith('.gif'))) {
        pre.push(cur)
      }
      return pre
    }
    const res = await getAllNodes({ bucketName, prefix: cur.Prefix }, listObjects)
    return pre.concat(res)
  }, [])
  return result
}

const matchPath = (routePath, locationPath) => {
  if (!routePath) { return false }
  const pr = routePath.split('/')
  locationPath = locationPath.replace(window.__IN_CLOUD__ ? `/${window.appName}` : '', '')
  const pl = locationPath.split('/')
  if (pr.length === pl.length && pr.every((x, idx) => {
    if (x === pl[idx]) return true
    if (x !== pl[idx] && x.startsWith(':') && !!pl[idx]) {
      return true
    }
    return false
  }) && pl.every((x, idx) => {
    if (x === pr[idx]) return true
    if (x !== pr[idx] && pr[idx].startsWith(':') && !!x) {
      return true
    }
    return false
  })) {
    return true
  }
  return false
}

export default {
  getQueryString,
  getQueryStringByName,
  getStrQuery,
  getStrQueryOriginal,
  getCookie,
  createQueryString,
  createRandomName,
  getParam,
  setParam,
  keypairsArrayToString,
  keypairsStringToArray,
  getRecordValue,
  getSecToHMS,
  keypairsMapToArray,
  keypairsArrayToMap,
  keypairsJSArrayToSubmitArray,
  keypairsSubmitArrayToJSArray,
  sizeFormat,
  transformObjectStorageToLocal,
  transformLocalToObjectStorage,
  timeFormat,
  runtimeFormat,
  getUrl,
  hasPermission,
  getDomain,
  createGatewayParams,
  valueFormat,
  getStepByTimeDiff,
  getLocale,
  isInCondition,
  getArrayMapValue,
  getMonths,
  getUserInfo,
  ha,
  getAllNodes,
  matchPath
}
