import React from 'react';

const Detail = () => {
  return (
    <>
    Detail
    </>
  )
}

export default Detail;