import React from 'react';
import { Tooltip } from '@om-tlh/bee';

/**
 * 字符串超出范围隐藏组件
 * @param {*} props 
 */
const TextMore = (props) => {
  const { text, maxLength = 10 } = props
  return text && text.length > maxLength ? <Tooltip title={text} getPopupContainer={() => document.getElementById('content')}><span>{text.slice(0, maxLength) + '...'}</span></Tooltip> : (text || '-')
}

export default TextMore;