import React from 'react'
import { Form, List, Tag } from '@om-tlh/bee';
import utils from 'utils/util';
import SearchType from 'utils/searchType';
import DictUtil from 'utils/dictUtil';

/**
 * 数据详情组件
 */
class ParamsDetail extends React.Component {

  render() {
    const { values } = this.props;
    return (
      <div>
        {this._renderValues(values)}
      </div>
    );
  }

  _renderValues = (values) => {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const fields = this._getFields()
    return (
      <div>
        {fields.map((field, index) => {
          var value = utils.getRecordValue(values, field.key)
          value = this._handleSpecialField(field, values, value)
          if (field.type) {
            value = this._renderText(field, values)
          }
          return (value !== '' && typeof value !== 'undefined' && value !== '-' && value !== null) ?<Form.Item key={index} label={formatMessage({ id: field.id })} style={{ marginBottom: 0, wordBreak: 'break-all' }}>{value === 0 ? 0 : (value || '-')}</Form.Item> : null
        })}
      </div>
    )
  }

  getEnableCondition = (hyperParams, key) => {
    return hyperParams.find(m => m.key === key) && hyperParams.find(m => m.key === key).enable_condition
  }

  _handleSpecialField = (field, values = {}, value) => {
    const { type, hyperParams } = this.props
    const params = values.job_param || values.builtin_job_param || {}
    const id = field.id
    if (!id) return
    switch (id) {
      case 'dataset_source':
        const datasetType = params.dataset && params.dataset.source_type
        return this._handleDatasetField(datasetType, params)
      case 'algorithm_source':
        const algorithmType = params.algorithm && params.algorithm.source_type
        return this._handleAlgorithmField(algorithmType, params)
      case 'cmd_line_parameter':
        const argumentsType = params.program_config && params.program_config.arguments_type
        return this._handleCmdLineField(argumentsType, params)
      case 'env_var':
        let env_var_map = params.program_config && params.program_config.env_var_map
        let env_var_arr = []
        var obj = {}
        if (hyperParams && hyperParams.length)
          hyperParams.filter(i => i.hyper_param_type === 'EnvVarMapItem').forEach(i => {
            var value = utils.getArrayMapValue(env_var_map, i.key)
            obj[i.key] = value || i.default_value
          })
        for (var i in env_var_map) {
          if (hyperParams && hyperParams.length) {
            const enableCondition = this.getEnableCondition(hyperParams, i)
            const condition = utils.isInCondition(enableCondition, obj)
            if (condition) {
              env_var_arr.push(`${i}=${env_var_map[i]}`)
            }
          } else {
            env_var_arr.push(`${i}=${env_var_map[i]}`)
          }
        }
        return env_var_arr.join(' ')
      // case 'algorithm_builtin':
      //   return this._renderBuiltinAlgorithm(values)
      case 'AI_engine':
        return params.job_image_name || (params.job_image && params.job_image.display_name)
      case 'model_source':
        const modelType = params.model && params.model.source_type
        return this._handleModelField(modelType, params, values)
      case 'mount_items':
        return this._handleMountItemsField(params)
      case 'train_output_path':
        return this._handleTrainOutputPathField(params)
      case 'ai_engine_type':
        return this._handleAIEngineTypeField(values)
      case 'ai_engine_version':
        return this._handleAIEngineVersionField(values)
      default:
        return value
    }
  }

  _renderText = (field, record) => {
    let type = field.type || ''
    let name = field.key || ''

    if (type && type.indexOf(SearchType.KEY_COMMON_ENMU) !== -1) {
      const Dict = new DictUtil().getDict(this.props)
      let dictName = type.split('-')[1];
      let dict = Dict[dictName]
      let tmpValues = [];
      let tempText = String(utils.getRecordValue(record, name));
      if (tempText) {
        let values = tempText.split(',');
        values.forEach(value => {
          for (let index in dict) {
            if (value === String(dict[index].id)) {
              tmpValues.push(dict[index].name);
              break;
            }
          }
        })
      }
      return tmpValues.length === 0 ? '-' : <span>
        {tmpValues.join(',')}
        {/* {tmpValues.map((val, index) => <Tag color='blue' key={index}>{val}</Tag>)} */}
      </span>;
    }
  }

  _renderBuiltinAlgorithm = (values) => {
    const params = values.builtin_algorithm || {}
    return (
      <div style={{ marginBottom: 0 }}>
        <p>{params.display_name}</p>
        <p>{params.algorithm_url}</p>
        <p>{params.algorithm_boot_file}</p>
      </div>
    )
  }

  _handleModelField = (type, values, vals) => {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { model = {} } = values
    if (!type) return
    if (!model.source_type || model.source_type === 'None') return '-'
    return (
      <div style={{ marginBottom: 0 }}>
        <p style={{ marginBottom: 0 }}>{[formatMessage({ id: 'source_type' }), formatMessage({ id: `SourceType_${model.source_type}` })].join(': ')}</p>
        <p style={{ marginBottom: 0 }}>{[formatMessage({ id: 'save_path' }), model.model_url || vals.model_url].join(': ')}</p>
      </div>
    )
  }

  _handleDatasetField = (type, values) => {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { dataset = {} } = values
    if (!type) return
    if (!dataset.source_type || dataset.source_type === 'None') return '-'
    return (
      <div style={{ marginBottom: 0 }}>
        <p style={{ marginBottom: 0 }}>{[formatMessage({ id: 'source_type' }), formatMessage({ id: `SourceType_${dataset.source_type}` })].join(': ')}</p>
        <p style={{ marginBottom: 0 }}>{[formatMessage({ id: 'save_path' }), dataset.dataset_url].join(': ')}</p>
      </div>
    )
  }

  _handleAlgorithmField = (type, values) => {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const { algorithm } = values
    if (!algorithm) return
    return (
      <div>
        <p style={{ marginBottom: 0 }}>{[formatMessage({ id: 'source_type' }), formatMessage({ id: `SourceType_${algorithm.source_type}` })].join(': ')}</p>
        <p style={{ marginBottom: 0 }}>{[formatMessage({ id: 'save_path' }), algorithm.algorithm_url].join(': ')}</p>
        <p style={{ marginBottom: 0 }}>{[formatMessage({ id: 'startup_file' }), algorithm.algorithm_boot_file].join(': ')}</p>
      </div>
    )
  }

  _handleTrainOutputPathField = (values) => {
    const { intl } = this.props;
    const { formatMessage } = intl;
    if (!values.output_source_type || values.output_source_type === 'None') return '-'
    return (
      <div>
        <p style={{ marginBottom: 0 }}>{[formatMessage({ id: 'source_type' }), formatMessage({ id: `SourceType_${values.output_source_type}` })].join(': ')}</p>
        {/* <p style={{ marginBottom: 0 }}>{[formatMessage({ id: 'save_type' }), formatMessage({ id: `StorageType_${values.output_storage_type}` })].join(': ')}</p> */}
        <p style={{ marginBottom: 0 }}>{[formatMessage({ id: 'train_output_path' }), values.output_url].join(': ')}</p>
      </div>
    )
  }

  _handleCmdLineField = (type, values) => {
    const { intl, hyperParams } = this.props;
    const { formatMessage } = intl;
    const { program_config } = values
    switch (type) {
      case 'KeyValuePair':
        let arguments_map = program_config.arguments_list_map
        let arguments_arr = []
        var obj = {}
        if (hyperParams && hyperParams.length)
          hyperParams.filter(i => i.hyper_param_type === 'ArgumentsMapItem').forEach(i => {
            var value = utils.getArrayMapValue(arguments_map, i.key)
            obj[i.key] = value || i.default_value
          })
        for (var i in arguments_map) {
          for (var j in arguments_map[i]) {
            if (!j) return
            if (hyperParams && hyperParams.length) {
              if (utils.isInCondition(this.getEnableCondition(hyperParams, j), obj)) {
                arguments_arr.push(`--${j}=${arguments_map[i][j]}`)
              }
            } else {
              arguments_arr.push(`--${j}=${arguments_map[i][j]}`)
            }
          }
        }
        return [
          // formatMessage({ id: 'ArgumentsType_KeyValuePair' }),
          arguments_arr.join(' ')
        ].join(' ')
      case 'String':
        return [
          // formatMessage({ id: 'ArgumentsType_String' }),
          program_config.arguments_string].join(' ')
      case 'Array':
        return [
          // formatMessage({ id: 'ArgumentsType_Array' }),
          (program_config.arguments_array || []).join(' ')].join(' ')
      default:
        return ''
    }
  }

  _handleMountItemsField = (params) => {
    const { intl } = this.props;
    const { formatMessage } = intl;
    const dataSource = (params.program_config && params.program_config.mounts_items) || []
    if (!dataSource.length) return '-'
    return (
      <List
        size='small'
        bordered
        style={{ marginTop: 10 }}
        dataSource={dataSource}
        renderItem={item => (
          <List.Item>
            {formatMessage({ id: 'remote_path' })}: {item.remote_url} <br />
            {formatMessage({ id: 'local_path' })}: {item.local_path}
          </List.Item>
        )}
      />
    )
  }

  _handleAIEngineTypeField = (params) => {
    return utils.getRecordValue(params, 'job_param.job_image.engine_type')
      || utils.getRecordValue(params, 'builtin_job_param.job_image.engine_type')
      || utils.getRecordValue(params, 'job_param.engine_type')
      || utils.getRecordValue(params, 'builtin_job_param.engine_type')
      || utils.getRecordValue(params, 'job_param.job_image_source.job_image.engine_type')
      || utils.getRecordValue(params, 'job_param.job_image_source.user_job_image.engine_type')
  }

  _handleAIEngineVersionField = (params) => {
    return utils.getRecordValue(params, 'job_param.job_image.display_name')
      || utils.getRecordValue(params, 'builtin_job_param.job_image.display_name')
      || utils.getRecordValue(params, 'job_param.job_image_name')
      || utils.getRecordValue(params, 'builtin_job_param.job_image_name')
      || utils.getRecordValue(params, 'job_param.job_image_source.job_image.display_name')
      || utils.getRecordValue(params, 'job_param.job_image_source.user_job_image.display_name')
  }

  _getFields = () => {
    const { type, from } = this.props
    let fields = []
    switch (type) {
      case 'dev_env':
        fields = [
          ...from ? [{ id: 'description', key: 'description' }] : [],
          { id: 'ai_engine_type', key: 'job_param.job_image.engine_type' },
          { id: 'ai_engine_version', key: 'job_param.job_image.display_name' },
          { id: 'work_path', key: 'job_param.cwd_url' },
          { id: 'resource_pool', key: 'job_param.resource_config.resource_pool_display_name' },
          { id: 'resource_template', key: 'job_param.resource_config.resource_pool_display_name' },
          { id: 'mount_items', key: 'job_param.program_config.mounts_items' },
        ]
        break;
      case 'training_job':
        fields = [
          ...from ? [{ id: 'version', key: 'version' },
          { id: 'description', key: 'description' }] : [],
          { id: 'algorithm_source', key: 'job_param.algorithm_source' },
          { id: 'ai_engine_type', key: 'job_param.job_image.engine_type' },
          { id: 'ai_engine_version', key: 'job_param.job_image.display_name' },
          { id: 'training_job_type', key: 'job_param.training_job_type', type: SearchType.KEY_TRAINING_JOB_TYPE },
          { id: 'schedule_type', key: 'job_param.schedule_type', type: SearchType.KEY_SCHEDULE_TYPE },
          { id: 'dataset_source', key: 'job_param.dataset_source' },
          { id: 'cmd_line_parameter', key: 'job_param.cmd_params' },
          { id: 'env_var', key: 'job_param.env_variables' },
          { id: 'train_output_path', key: 'job_param.output_url' },
          { id: 'job_log_path', key: 'job_param.log_config.log_url' },
          { id: 'resource_pool', key: 'job_param.resource_config.resource_pool_display_name' },
          { id: 'resource_template', key: 'job_param.resource_config.resource_pool_display_name' },
          { id: 'mount_items', key: 'job_param.program_config.mounts_items' },
        ]
        break;
      case 'training_job_params':
        fields = [
          { id: 'algorithm_source', key: 'job_param.algorithm_source' },
          { id: 'ai_engine_type', key: 'job_param.job_image.engine_type' },
          { id: 'ai_engine_version', key: 'job_param.job_image.display_name' },
          { id: 'training_job_type', key: 'job_param.training_job_type', type: SearchType.KEY_TRAINING_JOB_TYPE },
          { id: 'schedule_type', key: 'job_param.schedule_type', type: SearchType.KEY_SCHEDULE_TYPE },
          { id: 'dataset_source', key: 'job_param.dataset_source' },
          { id: 'cmd_line_parameter', key: 'job_param.cmd_params' },
          { id: 'env_var', key: 'job_param.env_variables' },
          { id: 'train_output_path', key: 'job_param.output_url' },
          { id: 'job_log_path', key: 'job_param.log_config.log_url' },
          { id: 'resource_pool', key: 'job_param.resource_config.resource_pool_display_name' },
          { id: 'resource_template', key: 'job_param.resource_config.resource_pool_display_name' },
          { id: 'mount_items', key: 'job_param.program_config.mounts_items' },
        ]
        break;
      case 'visual_tool':
        fields = [
          ...from ? [{ id: 'description', key: 'description' }] : [],
          { id: 'ai_engine_type', key: 'job_param.job_image.engine_type' },
          { id: 'ai_engine_version', key: 'job_param.job_image.display_name' },
          { id: 'visual_tool_type', key: 'job_param.visual_tool_type', type: SearchType.KEY_VISUAL_TOOL_TYPE },
          { id: 'summary_file_path', key: 'job_param.summary_url' },
          { id: 'resource_pool', key: 'job_param.resource_config.resource_pool_display_name' },
          { id: 'resource_template', key: 'job_param.resource_config.resource_pool_display_name' },
        ]
        break;
      case 'inference_service':
        fields = [
          ...from ? [{ id: 'version', key: 'version' },
          { id: 'description', key: 'description' }] : [],
          { id: 'model_source', key: 'job_param.model' },
          { id: 'ai_engine_type', key: 'job_param.job_image.engine_type' },
          { id: 'ai_engine_version', key: 'job_param.job_image.display_name' },
          { id: 'inference_svc_type', key: 'job_param.inference_svc_type', type: SearchType.KEY_INFERENCE_SVC_TYPE },
          { id: 'model_accessor', key: 'job_param.model_accessor' },
          { id: 'resource_pool', key: 'job_param.resource_config.resource_pool_display_name' },
          { id: 'resource_template', key: 'job_param.resource_config.resource_pool_display_name' },
        ]
        break;
      case 'inference_service_params':
        fields = [
          { id: 'model_source', key: 'job_param.model' },
          { id: 'ai_engine_type', key: 'job_param.job_image.engine_type' },
          { id: 'ai_engine_version', key: 'job_param.job_image.display_name' },
          { id: 'inference_svc_type', key: 'job_param.inference_svc_type', type: SearchType.KEY_INFERENCE_SVC_TYPE },
          { id: 'model_accessor', key: 'job_param.model_accessor' },
          { id: 'resource_pool', key: 'job_param.resource_config.resource_pool_display_name' },
          { id: 'resource_template', key: 'job_param.resource_config.resource_pool_display_name' },
        ]
        break;
      case 'builtin_algorithm':
        fields = [
          { id: 'ai_engine_type', key: 'builtin_job_param.job_image.engine_type' },
          { id: 'ai_engine_version', key: 'builtin_job_param.job_image.display_name' },
          { id: 'dataset_source', key: 'job_param.dataset_source' },
          { id: 'cmd_line_parameter', key: 'builtin_job_param.cmd_params' },
          { id: 'env_var', key: 'builtin_job_param.env_variables' },
          { id: 'job_log_path', key: 'builtin_job_param.log_config.log_url' },
          { id: 'resource_pool', key: 'builtin_job_param.resource_config.resource_pool_display_name' },
          { id: 'resource_template', key: 'builtin_job_param.resource_config.resource_pool_display_name' },
          { id: 'mount_items', key: 'builtin_job_param.program_config.mounts_items' },
        ]
        break;
      case 'builtin_model':
        fields = [
          { id: 'algorithm_builtin', key: 'builtin_algorithm.display_name' },
          { id: 'algorithm_save_path', key: 'builtin_algorithm.algorithm_url' },
          { id: 'startup_file', key: 'builtin_algorithm.algorithm_boot_file' },
          { id: 'model_source', key: 'builtin_job_param.model' },
          { id: 'ai_engine_type', key: 'builtin_job_param.job_image.engine_type' },
          { id: 'ai_engine_version', key: 'builtin_job_param.job_image.display_name' },
          { id: 'model_accessor', key: 'job_param.model_accessor' },
          { id: 'resource_pool', key: 'builtin_job_param.resource_config.resource_pool_display_name' },
          { id: 'resource_template', key: 'builtin_job_param.resource_config.resource_pool_display_name' },
        ]
        break;
      default:
        fields = [
          { id: 'ai_engine_type', key: 'job_param.job_image.engine_type' },
          { id: 'ai_engine_version', key: 'job_param.job_image.display_name' },
          { id: 'cmd_line_parameter', key: 'job_param.cmd_params' },
          { id: 'env_var', key: 'job_param.env_variables' },
          { id: 'resource_pool', key: 'job_param.resource_config.resource_pool_display_name' },
          { id: 'resource_template', key: 'job_param.resource_config.resource_pool_display_name' },
        ]
        break;
    }
    return fields
  }
}

export default ParamsDetail
