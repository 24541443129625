import axios from 'axios'
import config from "@/config"

export const isUUID = function (id) {
  const reg = /^[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}$/
  return reg.test(id)
}

export const getQueryStringByName = function (name, url) {
  let result = (url || global.location.search).match(
    new RegExp('[?&]' + name + '=([^&]+)', 'i')
  )
  result = result && result.length > 0 ? result[1] : ''
  if (!result) {
    result = (global.location.hash).match(
      new RegExp('[?&]' + name + '=([^&]+)', 'i')
    )
    result = result && result.length > 0 ? result[1] : ''
  }
  return result
}

export const isObjEmpty = function (obj) {
  for (var i in obj) {
    return false
  }
  return true
}

export function debounce(originFunc, duration = 200) {
  let timer
  return (...params) => {
    if (timer) clearTimeout(timer)

    timer = setTimeout(() => {
      originFunc(...params)
    }, duration)
  }
}

export function formatDate(date) {
  const tmp = date.split('T')
  const d = tmp[0]
  const t = tmp[1].split('.000')[0]
  const arrD = d.split('-')
  const arrT = t.split(':')
  return `${arrD[0]}年${arrD[1]}月${arrD[2]}日`
}

export function checkUserName(rule, value, cb) {
  const regRealname = /^[.a-zA-Z\s\u4e00-\u9fa5]+$/
  const err1 = '仅支持中英文、空格或“.”'
  const err2 = '最长14个英文或7个汉字'
  if (value !== '' && !regRealname.test(value)) {
    cb(err1)
  } else if (value !== '' && value.replace(/[^\x00-\xff]/g, '01').length > 14) {
    cb(err2)
  } else {
    cb()
  }
}

export function isJSON(str) {
  if (typeof str == 'string') {
    try {
      var obj = JSON.parse(str);
      if (typeof obj == 'object' && obj) {
        return true;
      } else {
        return false;
      }

    } catch (e) {
      console.log('error：' + str + '!!!' + e);
      return false;
    }
  }
  console.log('It is not a string!')
}

export function guid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export function unique(arr, key) {
  const res = new Map();
  return arr.filter(item => !res.has(item[key]) && res.set(item[key], 1));
}

export function shakeTreeChildren(arr, moduleName) {
  if (!arr) return []
  return arr.map(a => handleNode(a, moduleName))
}

export function handleNode(node, moduleName) {
  if (node.child && !node.child.length) {
    return moduleName ? {
      ...node,
      key: node[`${moduleName}_id`],
      id: node[`${moduleName}_id`],
      display_name: node[`${moduleName}_name`],
      child: void 0
    } : {
      ...node,
      child: void 0
    }
  }
  if (node.child?.length) {
    return moduleName ? {
      ...node,
      key: node[`${moduleName}_id`],
      id: node[`${moduleName}_id`],
      display_name: node[`${moduleName}_name`],
      child: shakeTreeChildren(node.child, moduleName)
    } : {
      ...node,
      child: shakeTreeChildren(node.child, moduleName)
    }
  }
  return node
}

export const getFileBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    if (window.FileReader) {
      let filereader = new FileReader()
      filereader.readAsDataURL(file)
      filereader.onload = () => {
        const src = filereader.result
        resolve(src)
      }
      return
    }
    reject(new Error('FileReader not supported'))
  })
}

export function downloadFile(url, name) {
  const link = document.createElement('a')
  link.style.display = 'none'
  // link.href = !url.startsWith('http') ? url : (url + `${url.includes('?') ? '&' : '?'}${'response-content-disposition=' + encodeURIComponent('attachment; filename="' + name + '"')}`);
  link.href = url
  link.target = '_blank'
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()
  // var evt = document.createEvent("MouseEvents");
  // evt.initEvent("click", true, true);
  // link.dispatchEvent(evt);
  document.body.removeChild(link)
}

export async function getFileBlobByUrl(url) {
  const filename = url.split('?')[0].split('/').slice(-1)[0]
  const k = getQueryStringByName('object_key', url)
  // const blob = await fetch(url).then(res => res.blob())
  const blob = await axios.get(url, {
    responseType: 'blob', baseURL: '/', unAuth: true
  })
  const ext = filename.match(/\.(jpg|jpeg|gif|png|bmp|webp|tif|pdf)$/i)?.[1]
  const file = new File([blob], filename, {
    type: {
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      png: 'image/png',
      gif: 'image/gif',
      bmp: 'image/bmp',
      webp: 'image/webp',
      tif: 'image/tif',
      pdf: 'application/pdf',
    }[ext && ext.toLowerCase()] || blob.type, size: blob.size
  })
  return file
}

export function saveImg(imgSrc, name) {
  var base64 = imgSrc.toString();
  var byteCharacters = atob(
    base64.replace(/^data:(image\/(png|jpeg|jpg|.*)|binary\/octet\+stream);base64,/, "")
  );
  var byteNumbers = new Array(byteCharacters.length);
  for (var i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  var byteArray = new Uint8Array(byteNumbers);
  var blob = new Blob([byteArray], {
    type: undefined,
  });
  return blob
}

export function handleDownloadUrl(url) {
  return `${config.download_url}/${(url.match(/^https?:\/\//) ? url.split('/').slice(3).join('/') : url).replace(/\/\/+/g, '/')}`
}

// async error handle
export function ha(promise) {
  return promise.then(data => [null, data]).catch(err => [err, void 0])
}

export function camelCaseToSnakeCase(str) {
  return str.replace(/[A-Z]/g, ($1) => {
    return `_${$1.toLowerCase()}`
  })
}

export function camelCaseToSnakeCaseForParams(params) {
  if (!params) { return params }
  return Object.entries(params).map(x => ([camelCaseToSnakeCase(x[0]), x[1]]).reduce((pre, cur) => {
    return {
      ...pre,
      [cur[0]]: cur[1]
    }
  }, {}))
}

export function getOffsetToTop(elem) {
  let result = 0
  while (elem.offsetParent) {
    result += elem.offsetTop
    elem = elem.offsetParent
  }
  return result
}

export function customConnect(options) {
  return (Component) => {
    return (pps) => <Component {...pps} {...options} />
  }
}