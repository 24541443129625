import React, { useState } from 'react'
import { Input, Modal, Button, message } from '@om-tlh/bee'
import ModelList from '@/modules/Model/list';

const SelectModel = (props) => {
  const { value, onChange, values, setValues, templateType } = props;
  const [visible, setVisible] = useState(false);
  const [selectRows, setSelectRows] = useState([])

  const onSelect = (rows) => {
    setSelectRows(rows)
  }
  const handleOk = () => {
    if (!selectRows || !selectRows.length) {
      message.warning('请选择模板')
      return
    }
    onChange && onChange(selectRows[0]?.[`${templateType}_id`])
    setValues({
      ...values,
      ...selectRows[0],
      [`${templateType}_name`]: selectRows[0]?.display_name,
    })
    setVisible(false)
  }
  const handleCancel = () => {
    setVisible(false)
  }
  return (
    <>
      <Input.Group compact>
        <Input value={values[`${templateType}_name`] || value} disabled style={{ width: 350 }} />
        <Button shape='round' onClick={() => setVisible(true)}>选择</Button>
      </Input.Group>
      <Modal
        okButtonProps={{ shape: 'round' }}
        cancelButtonProps={{ shape: 'round' }}

        title='选择模板'
        width={1000}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
        open={visible}>
        <ModelList size='small' onSelect={onSelect} hideStyleTemplate={props.hideStyleTemplate} hideContentTemplate={props.hideContentTemplate}
          rowSelection={{ type: 'radio', selectedRowKeys: value ? [value] : [] }} />
      </Modal>
    </>
  )
}

export default SelectModel
