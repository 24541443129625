import React, { useEffect } from 'react';
import Path from 'routes/path'
import { injectIntl } from 'react-intl';
import { Layout, Divider, Select } from '@om-tlh/bee';
import UserProfile from './components/UserProfile'
import LocalStorageUtil from 'utils/localStorageUtil'
import SStorageUtil from 'utils/sessionStorageUtil'
import utils from 'utils/util'
import logo from 'theme/img/logo.png'
import logoSVG from 'theme/img/logo.svg'
import Nav from './nav'

const { Header, Content, Footer, Sider } = Layout;

/**
 * 单页框架
 */
const Frame = (props) => {
  const { intl, location, routeRegistry, match } = props;
  const { formatMessage } = intl;
  let userInfo = {}
  try {
    userInfo = JSON.parse(LocalStorageUtil.getUserInfo())
  } catch (e) {

  }

  useEffect(() => {
    if (!routeRegistry.find(i => utils.matchPath(i.path, location.pathname.replace(match.url === '/' ? '' : match.url, '')))) {
      props.history.push(`${match.url === '/' ? '' : match.url, ''}${Path.getPathname(props, Path.pathname['Error404'])}`)
    }
  }, [location.pathname])

  const handleLogout = (e) => {
    e.preventDefault();
    ['auth', 'userInfo', 'privileges', 'platform_id'].forEach(i => {
      LocalStorageUtil.clearStorage(i)
    })
    LocalStorageUtil.clearAllCookie()
    SStorageUtil.clearSStorage()
    // props.history.push(`/login?return_url=${encodeURIComponent(window.location.href)}`)
    window.location.href = `${window.__IN_CLOUD__ ? `/${props.appType ? [...props.appType.split('/'), `${window.appName}`].filter(Boolean).join('/') : `${window.appName}`}` : (props.appType ? `/${props.appType}` : ``)}/login?return_url=${encodeURIComponent(window.location.href.replace(new RegExp(`&?token_id=${utils.getQueryStringByName(`token_id`)}`), ''))}`
  }
  return (
    <div className='main'>
      <div className='main_content'>
        <Header className="header">
          <div className='inner'>
            <div className="header-right ant-menu-horizontal" style={{
              // backgroundColor: '#262F3E',
              lineHeight: '64px',
              height: '64px'
            }}>
              <li className="dzt-menu-item">
                <UserProfile {...props} userInfo={userInfo} handleLogout={handleLogout} />
              </li>
            </div>
            <img className="logo-img" src={logoSVG} alt="" style={{ float: 'left', margin: 12 }} />
            <h1 className='header-title'>{formatMessage({ id: 'web_title' })}</h1>
            <Nav {...props} theme="light" mode="horizontal" />
          </div>
        </Header>
        <div className='page' id="content">{props.children}</div>
      </div>
      <div className="footer">
        <p>&copy; 2022 东云睿连 版权所有</p>
      </div>
    </div>
  )
}

export default injectIntl(Frame)
