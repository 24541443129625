import { Button, Divider, message, Modal, Tag } from '@om-tlh/bee';
import React, { useState, useEffect } from 'react';
import List from 'modules/Auth/User/list';
import { unique } from 'utils/utils';

const Issue = (props) => {
  const { service, type, ids, title, callback, idsKey } = props;
  const [visible, setVisible] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [isReload, setIsReload] = useState(false)

  const onSelect = (rows) => {
    if (!rows || !rows.length) return;
    setSelectedUsers(unique([...selectedUsers, ...rows.map(i => ({ user_id: i.user_id, user_name: i.user_name }))], 'user_id'))
  }
  const onClose = (item) => {
    setSelectedUsers(selectedUsers.filter(i => i.user_id !== item.user_id))
  }
  const onOk = () => {
    if (!selectedUsers || !selectedUsers.length) {
      message.warning('请选择接收人！')
      return
    }
    Modal.confirm({
      okButtonProps: { shape: 'round' },
      cancelButtonProps: { shape: 'round' },
      title: '提示',
      content: '确定要转发吗？',
      onOk: () => {
        if (!ids || !ids.length) return;
        service && service({
          [idsKey || 'document_id']: ids,
          user_ids: selectedUsers.map(i => i.user_id)
        }).then(data => {
          message.success('转发成功');
          setSelectedUsers([])
          setIsReload(true)
          setVisible(false);
          setTimeout(() => {
            setIsReload(false)
          }, 200)
          callback && callback()
        }).catch(error => {
          error && message.error(error.message ?? error.response?.data?.message ?? JSON.stringify(error))
        })
      }
    })
  }
  return (
    <>
      {
        props.button ? React.cloneElement(props.button, {
          onClick: () => {
            if (props.onClick) {
              return props.onClick(() => setVisible(true))
            }
            setVisible(true)
          }
        }) : typeof props.visible === 'undefined' ? <Button shape='round' disabled={props.disabled} style={{ ...props.style }} type={type || 'link'} title={title || '转发'} onClick={() => setVisible(true)}
          {...props.buttonProps}
        >{props.hideTitle ? '' : (title || '转发')}</Button> : (title || '转发')
      }
      <Modal
        okButtonProps={{ shape: 'round' }}
        cancelButtonProps={{ shape: 'round' }}
        open={visible} title={title || '转发'} width={1000} destroyOnClose
        onCancel={() => {
          setVisible(false)
          props.setVisible?.(false)
        }}
        onOk={() => onOk()}>
        已选接收人：{selectedUsers.map(i => <Tag closable onClose={() => onClose(i)} key={i.user_id}>{i.user_name}</Tag>)}
        <Divider />
        <List {...props} size='small' onSelect={onSelect} isReload={isReload} />
      </Modal>
    </>
  )
}

export default Issue;