// 只有编辑模式
import React, { useState, useEffect } from 'react'
import { Form, InputNumber, Input, Typography, Popconfirm, Button, Modal, message, Popover, Space, Alert } from '@om-tlh/bee'
import { PlusOutlined, EllipsisOutlined } from '@ant-design/icons'
import './index.less'
import { v4 as uuidv4 } from 'uuid';

const LABEL_COUNT = 50

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  onChange,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber onChange={onChange} /> : <Input onChange={onChange} />;
  return (
    <td {...restProps} className="dzt-table-cell">
      <Form.Item
        name={dataIndex}
        style={{
          margin: 0,
          display: editing ? 'block' : 'none'
        }}
        rules={[
          {
            required: true,
            message: `${title}不能为空!`,
          },
        ]}
      >
        {inputNode}
      </Form.Item>
      {editing ? null : children}
    </td>
  );
};

export default function Index(props) {
  // const { dataSource = [
  //   { labelName: 'host', key: '域名', description: 'dongyunruilian.net' },
  //   { labelName: 'owner', key: '域名 持有者', description: '东云睿连（武汉）计算技术有限公司' },
  //   { labelName: 'regist_time', key: '域名注册日期', description: '2019-12-11' },
  //   { labelName: 'expires', key: '域名到期日期', description: '2022-12-11' },
  // ] } = props;
  const { dataSource = [], activeSelectedLabel, alwaysEditable } = props;
  const [form] = Form.useForm()
  const [tableForm] = Form.useForm()
  const [data, setData] = useState(dataSource);
  const [editingKey, setEditingKey] = useState({});

  useEffect(() => {
    tableForm.setFieldsValue({
      details: dataSource
    });
  }, [])

  useEffect(() => {
    setData(dataSource)
    tableForm.setFieldsValue({
      details: dataSource
    });
    setEditingKey(pre => {
      return {
        ...dataSource.reduce((pre, cur) => ({ ...pre, [cur.labelName]: typeof props.labelTextEdit !== 'undefined' ? props.labelTextEdit : true }), {}),
        ...editingKey
      }
    })
    // console.log('dataSource: ', dataSource);
  }, [JSON.stringify(dataSource)])

  // const isEditing = (d) => d === editingKey;
  const isEditing = (d) => editingKey[d];

  const edit = (record) => {
    // tableForm.setFieldsValue({
    //   details: [{
    //     labelName: record.labelName,
    //     key: record.key,
    //     description: record.description
    //   }]
    // });
    setEditingKey(pre => ({ ...pre, [record.labelName]: true }));
  };

  const cancel = () => {
    setEditingKey(pre => ({ ...pre, [record.labelName]: false }));
  };

  const save = async (key, viewNoChange) => {
    try {
      if (typeof key === 'undefined') {
        const rows = await tableForm.validateFields([['details']]);
        const newData = [...rows.details];
        setData(newData);
        props.onChange?.(newData)
        return
      }
      const row = await tableForm.validateFields([['details', key, 'key'], ['details', key, 'description']]);
      console.log('row: ', row);
      const newData = [...data];
      const index = newData.findIndex((item, d) => key === d);
      console.log('index: ', index);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row.details[index] });
        setData(newData);
        props.onChange?.(newData)
        if (!viewNoChange) {
          setEditingKey(pre => ({ ...pre, [newData[index].labelName]: false }));
        }
      } else {
        newData.push(row);
        setData(newData);
        if (!viewNoChange) {
          setEditingKey(pre => ({ ...pre, [newData[index].labelName]: false }));
        }
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const [open, setOpen] = useState(false);

  const handleAdd = (values) => {
    const newData = {
      labelName: values.labelName,
      key: values.key,
      description: ''
    };
    const newDataSource = [...data, newData]
    setData(newDataSource);
    props.onChange?.(newDataSource)
  };

  const deleteItem = async ({ index }) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
    props.onChange?.(newData)
  }

  return (
    <div className="dzt-table ant-table-bordered">
      {
        (!props.hideLableAdd && data.length <= LABEL_COUNT) && <div style={{ textAlign: 'left' }}>
          <Button
            shape='round'
            onClick={() => setOpen(true)}
            type="ghost"
            style={{
              marginBottom: 16,
            }}
            icon={<PlusOutlined />}
          >
            添加待识别字段
          </Button>
        </div>
      }
      <Form
        form={tableForm}
      >
        <div className="dzt-table-title"
        >
          <h3 style={{ fontWeight: 600, textAlign: 'left' }}>参照字段</h3>
          {!props.hideTips && <Alert message="至少设置4个参照字段" type="warning" />}
        </div>
        <table
          style={{ tableLayout: 'auto' }}
        >
          <thead className="dzt-table-thead">
            <tr>
              <th className="dzt-table-cell">字段名</th>
              <th className="dzt-table-cell">识别结果</th>
              {
                !props.hideEditColumn && <th className="dzt-table-cell">操作</th>
              }
            </tr>
          </thead>
          <tbody className="dzt-table-tbody"
            onMouseLeave={() => {
              // if (typeof props.valueTextEdit === 'undefined' || typeof props.labelTextEdit === 'undefined') {
              //   save(void 0, !!'viewNoChange')
              // }
              props.handleWrapLeave()
            }}
          >
            {
              data.map((record, d) => {
                const editable = isEditing(record.labelName);
                const key = ['details', d]
                return <tr className={`dzt-table-row ${record.labelName === activeSelectedLabel ? 'selected-row-active' : ''}`} key={d}
                  onMouseEnter={() => {
                    props.handleItemEnter?.(record)
                    typeof record.shapeId !== 'undefined' && props.handleItemClick?.(record)
                  }}
                >
                  <EditableCell editing={typeof props.valueTextEdit !== 'undefined' ? props.valueTextEdit : editable} dataIndex={[...key, 'key']} title='字段名'
                    // style={{ cursor: typeof record.shapeId !== 'undefined' ? 'pointer' : 'auto' }}
                    onChange={() => {
                      if (typeof props.valueTextEdit === 'undefined') {
                        save(d, !!'viewNoChange')
                      }
                    }}
                  >{record.key}</EditableCell>
                  <EditableCell editing={typeof props.labelTextEdit !== 'undefined' ? props.labelTextEdit : editable} dataIndex={[...key, 'description']} title='识别结果'
                    style={{
                      // cursor: typeof record.shapeId !== 'undefined' ? 'pointer' : 'auto',
                      width: 200
                    }}
                    onChange={() => {
                      if (typeof props.labelTextEdit === 'undefined') {
                        save(d, !!'viewNoChange')
                      }
                    }}
                  >{record.description}</EditableCell>
                  {
                    !props.hideEditColumn && <td className="dzt-table-cell"
                      id={`ocr-editable-table-cell${d}`}
                      style={{ position: 'relative', width: 96 }}
                    >
                      <Space
                        // direction='vertical'
                        style={{ textAlign: 'center' }}>
                        <Popover
                          // trigger={'click'}
                          placement='topRight'
                          arrowPointAtCenter
                          // getPopupContainer={() => document.getElementById(`ocr-editable-table-cell${d}`)}
                          content={<>
                            <Space direction='vertical' style={{ textAlign: 'center' }}>
                              <Popconfirm title="确定要删除吗？" onConfirm={(e) => {
                                deleteItem({ record, index: d })
                              }}>
                                <a>删除</a>
                              </Popconfirm>
                              {

                                props.rerecognize && record.description && !!record.points?.length && <Typography.Link
                                  style={{
                                    display: 'inline-block',
                                    width: 60
                                  }}
                                  // disabled={editingKey !== ''}
                                  onClick={(e) => {
                                    props.rerecognize({ ...record, index: d })
                                  }}
                                >
                                  重新识别
                                </Typography.Link>
                              }
                            </Space>
                          </>}>
                          <Button shape='round' htmlType='button'
                            type="link"
                            icon={<EllipsisOutlined />}
                          >
                            {/* <DownOutlined /> */}
                          </Button>
                        </Popover>
                        {/* {

                              props.rerecognize && record.description && !!record.points?.length && <Typography.Link
                                style={{
                                  display: 'inline-block',
                                  width: 60
                                }}
                                // disabled={editingKey !== ''}
                                onClick={(e) => {
                                  props.rerecognize({ ...record, index: d })
                                }}
                              >
                                重新识别
                              </Typography.Link>
                            } */}
                        {/* <Typography.Link
                              style={{
                                display: 'inline-block',
                                width: 60
                              }}
                              // disabled={editingKey !== ''}
                              onClick={() => typeof record.shapeId !== 'undefined' && props.handleItemClick?.(record, !!'showMessage')}
                            >
                              置顶
                            </Typography.Link> */}
                      </Space>
                    </td>
                  }
                </tr>
              })
            }
          </tbody>
        </table>
      </Form>
      <Modal
        okButtonProps={{ shape: 'round' }}
        cancelButtonProps={{ shape: 'round' }}

        open={open}
        title="添加待识别字段"
        okText="添加"
        cancelText="取消"
        onCancel={() => setOpen(false)}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              if (data.find(x => x.labelName === values.labelName)) {
                return message.error(`该字段代码 ${values.labelName} 已被使用, 请重新输入`)
              }
              setOpen(false)
              form.resetFields();
              handleAdd(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            labelName: process.env.NODE_ENV === 'development' ? 'title' : '',
            key: process.env.NODE_ENV === 'development' ? 'title' : ''
          }}
        >
          <Form.Item
            name="labelName"
            initialValue={uuidv4()}
            style={{ display: 'none' }}
            label="字段代码"
            rules={[
              {
                required: true,
                message: '请输入字段代码',
                whitespace: true
              },
            ]}
          >
            <Input maxLength={64} />
          </Form.Item>
          <Form.Item name="key" label="字段名称"
            rules={[
              {
                required: true,
                message: '请输入字段名称',
                whitespace: true
              },
            ]}
          >
            <Input maxLength={64} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}
