import React, { useRef, useState } from 'react'
import { useEffect } from 'react'

export default function (props) {

  const { dataSource, columns } = props

  const contentRef = useRef()
  const [selectedRowKeys, setSelectedRowKeys] = useState(props.selectedRowKeys || [])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setSelectedRowKeys(props.selectedRowKeys || [])
  }, [props.selectedRowKeys && props.selectedRowKeys.toString()])

  const handleScroll = (event) => {
    const scrollTop = event.target.scrollTop
    const totalHeight = contentRef.current.scrollHeight
    if (props.hasMore && (totalHeight - props.scroll.y - scrollTop >= 0 && totalHeight - props.scroll.y - scrollTop < 300) && !isLoading) {
      setIsLoading(true)
      props.loadMore?.().finally(() => setIsLoading(false))
    }
  }

  const handleToggleAll = (event) => {
    if (event.target.tagName !== 'INPUT') {
      return
    }
    const keys = dataSource.length && selectedRowKeys.length < dataSource.length ? dataSource.map(x => x.id) : []
    setSelectedRowKeys(keys)
    props.onSelectChange?.(keys)
  }

  const handleToggleSingle = (record) => {
    const keys = selectedRowKeys.includes(record.id) ? selectedRowKeys.filter(x => x !== record.id) : (props.rowSelection?.type === 'radio' ? [record.id] : [...selectedRowKeys, record.id])
    setSelectedRowKeys(keys)
    props.onSelectChange?.(keys)
  }

  return (<div className="dzt-table dzt-table-fixed-header">
    <div className="dzt-table-container">
      <div className="dzt-table-header" style={{ overflow: 'hidden' }}>
        <table style={{ tableLayout: 'fixed' }}>
          <colgroup>
            <col style={{ width: 40 }} className="dzt-table-selection-col" />
            <col style={{ width: 500 }} />
            <col style={{ width: 180 }} />
            <col style={{ width: 100 }} />
            <col />
            <col style={{ width: 15 }} />
          </colgroup>
          <thead className="dzt-table-thead">
            <tr>
              <th className="dzt-table-cell dzt-table-selection-column"
              >
                {
                  (!props.rowSelection?.type || (props.rowSelection?.type === 'checkbox')) && <div className="dzt-table-selection"
                    onClick={handleToggleAll}
                  ><label className={`dzt-checkbox-wrapper ${selectedRowKeys.length > 0 ? 'dzt-checkbox-wrapper-checked' : ''}`}
                  ><span className={`dzt-checkbox ${selectedRowKeys.length > 0 && selectedRowKeys.length < dataSource.length ? 'dzt-checkbox-indeterminate' : selectedRowKeys.length === dataSource.length && dataSource.length > 0 ? 'dzt-checkbox-checked' : ''}`}><input
                    type="checkbox" className="dzt-checkbox-input" checked='checked' /><span
                      className="dzt-checkbox-inner"></span></span></label></div>
                }
              </th>
              {
                columns.map(col => (<th key={col.dataIndex} className="dzt-table-cell">
                  {col.title}
                </th>))
              }

              <th className="dzt-table-cell dzt-table-cell-scrollbar" style={{ width: 15 }}></th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="dzt-table-body" style={{ overflowY: 'auto', maxHeight: props.scroll.y }}>
        <table style={{ tableLayout: 'fixed' }}>
          <colgroup>
            <col style={{ width: 40 }} />
            <col style={{ width: 500 }} />
            <col style={{ width: 180 }} />
            <col style={{ width: 100 }} />
          </colgroup>
          <tbody className="dzt-table-tbody">
            <tr aria-hidden="true" className="dzt-table-measure-row" style={{ height: 0, fontSize: 0 }}>
              <td style={{ padding: 0, border: 0, height: 0 }}>
                <div style={{ height: 0, overflow: 'hidden' }}>&nbsp;</div>
              </td>
              <td style={{ padding: 0, border: 0, height: 0 }}>
                <div style={{ height: 0, overflow: 'hidden' }}>&nbsp;</div>
              </td>
              <td style={{ padding: 0, border: 0, height: 0 }}>
                <div style={{ height: 0, overflow: 'hidden' }}>&nbsp;</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="dzt-table-body" style={{ overflowY: 'auto', minHeight: 300, maxHeight: props.scroll.y }}
        onScroll={handleScroll}
      >
        <table style={{ tableLayout: 'fixed' }} ref={contentRef}>
          <colgroup>
            <col style={{ width: 40 }} />
            <col style={{ width: 500 }} />
            <col style={{ width: 180 }} />
            <col style={{ width: 100 }} />
          </colgroup>
          <tbody className="dzt-table-tbody">
            <tr aria-hidden="true" className="dzt-table-measure-row" style={{ height: 0, fontSize: 0 }}>
              <td style={{ padding: 0, border: 0, height: 0 }}>
                <div style={{ height: 0, overflow: 'hidden' }}>&nbsp;</div>
              </td>
              {
                columns.map(col => (<td key={col.dataIndex} style={{ padding: 0, border: 0, height: 0 }}>
                  <div style={{ height: 0, overflow: 'hidden' }}>&nbsp;</div>
                </td>))
              }
            </tr>
            {
              dataSource.map(item => (<tr key={item.id}
                className={`dzt-table-row dzt-table-row-level-0 ${selectedRowKeys.includes(item.id) ? 'dzt-table-row-selected' : ''}`}
                style={props.rowSelection?.selectType && props.rowSelection?.selectType?.includes(item.type.toUpperCase()) ? { cursor: 'pointer' } : {}}
                onClick={(e) => {
                  e.stopPropagation()
                  handleToggleSingle(item)
                }}
              >
                <td className="dzt-table-cell dzt-table-selection-column"
                >
                  <label
                    className={`dzt-${props.rowSelection?.type || 'checkbox'}-wrapper ${selectedRowKeys.includes(item.id) ? `dzt-checkbox-wrapper-${props.rowSelection?.type || 'checkbox'}` : ''} ${props.rowSelection?.selectType && !props.rowSelection?.selectType?.includes(item.type.toUpperCase()) ? 'dzt-radio-wrapper-disabled' : ''}`}
                    onClick={(e) => {
                      e.stopPropagation()
                      handleToggleSingle(item)
                    }}
                  >
                    <span
                      className={`dzt-${props.rowSelection?.type || 'checkbox'} ${selectedRowKeys.includes(item.id) ? `dzt-${props.rowSelection?.type || 'checkbox'}-checked` : ''} ${props.rowSelection?.selectType && !props.rowSelection?.selectType?.includes(item.type.toUpperCase()) ? 'dzt-radio-disabled' : ''}`}
                    >
                      <input
                        type={`${props.rowSelection?.type || 'checkbox'}`}
                        className={`dzt-${props.rowSelection?.type || 'checkbox'}-input`}
                        disabled={props.rowSelection?.selectType && !props.rowSelection?.selectType?.includes(item.type.toUpperCase())}
                      />
                      <span
                        className={`dzt-${props.rowSelection?.type || 'checkbox'}-inner`}>
                      </span>
                    </span>
                  </label>
                </td>
                {
                  columns.map(col => (<td key={col.dataIndex} className="dzt-table-cell">
                    {
                      col.render?.(item[col.dataIndex], item) ?? item[col.dataIndex]
                    }
                  </td>))
                }
              </tr>))
            }
          </tbody>
        </table>
      </div>
    </div>
  </div>)
}
