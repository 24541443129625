import { useState, useEffect } from 'react';
import { Button, Modal, Form, message, Divider, Radio, Input, Checkbox, Descriptions } from '@om-tlh/bee'
import { getLabelOptions, INVOICE_TYPES } from '@/utils/enum'

export default function (props) {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false)
  const { data = '', buttonText } = props

  return (<>
    <Button
      {...props.buttonProps}
      onClick={() => {
        setOpen(true)
        props.onClick?.()
      }}
    >{buttonText || '服务协议'}</Button>
    <Modal
      title={props.modalTitle ?? `服务协议`}
      open={open}
      width={1024}
      onCancel={async () => {
        setOpen(false)
      }}
      onOk={async () => {
        // const vals = await form.validateFields()
        setOpen(false)
        props.okSuccessCallback?.()
      }}
      okText='同意'
    >
      <iframe src={data} frameBorder="0" style={{ width: '100%', height: 900 }}></iframe>
    </Modal>
  </>)
}
