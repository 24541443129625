import TCanvas from './HoverLayer'
import { eventTypes, cursorStyles, messageTypes } from './enum.js'

/**
 * javascript comment
 * @Author: flydreame
 * @Date: 2023-01-16 10:14:13
 * @Desc:
 *   editMode: 1 - 画矩形框  2 - 拖拽但可以编辑标注  3 - 仅拖拽不编辑 用来查看详情
 */
export default class Canvas extends TCanvas {
  // 可从外部设置
  activeColor = ''
  activeStrokeStyle = 1
  activeLineWidth = 1
  activeLineDash = []
  activePointRadius = 4
  activeAlpha = 0.5
  _backgroundImageMeta = null
  pointDomainRadius = 15

  cacheShapes = []
  activeShape = {}

  cacheImages = {}

  constructor(id, options, receiveMessage) {
    const container = typeof id === 'object' ? id : document.getElementById(id)
    // const containerRect = container.getBoundingClientRect()
    const ctnWidth = container.clientWidth
    const ctnHeight = container.clientHeight
    super(ctnWidth, ctnHeight)
    this.container = container
    container.innerHTML = ''
    container.appendChild(this.canvas) // 插入页面
    container.appendChild(this.hoverLayer)

    this.targetAreaWidth = ctnWidth
    this.targetAreaHeight = ctnHeight
    this._targetAreaXY = [0, 0]
    this.dpr = 1
    this.__width = this.targetAreaWidth
    this.__height = this.targetAreaHeight

    this.eventsArr = []
    if (options.properties) {
      Object.entries(options.properties).forEach(x => {
        this[x[0]] = x[1]
      })
    }
    // 上下文配置
    this.ctx.strokeStyle = this.activeStrokeStyle
    this.ctx.lineWidth = this.activeLineWidth
    // this.ctx.globalCompositeOperation = 'source-over'
    this.ctx.setLineDash(this.activeLineDash)

    this.postMessage = receiveMessage

    this.pointRadius = this.activePointRadius

    // 鼠标滚轮事件
    this.addEvent(this.hoverLayer, eventTypes.WHEEl, this.onMouseWheel)
    // 鼠标中间双击还原1：1
    // this.addEvent(this.hoverLayer, eventTypes.DBLCLICK, this.onDoubleClick)
    // 鼠标中键拖动
    this.addEvent(this.hoverLayer, eventTypes.MOUSEDOWN, this.centerDragMoveStart)
  }

  init = (imageMeta) => {
    if (!imageMeta) return
    const xDpr = (imageMeta.width * (imageMeta.scale?.[0] || 1)) / this.container.clientWidth
    const yDpr = (imageMeta.height * (imageMeta.scale?.[1] || 1)) / this.container.clientHeight

    let initDpr = 1 / (yDpr > xDpr ? yDpr : xDpr)
    initDpr = initDpr > 1 ? 1 : initDpr

    this.__ctnDprWidth = Math.floor((imageMeta.width) * initDpr) * (imageMeta.scale?.[0] || 1)
    this.__ctnDprHeight = Math.floor((imageMeta.height) * initDpr) * (imageMeta.scale?.[1] || 1)

    this.targetAreaWidth = this.__ctnDprWidth
    this.targetAreaHeight = this.__ctnDprHeight
    this._targetAreaXY = [(this.container.clientWidth - this.__ctnDprWidth) / 2, (this.container.clientHeight - this.__ctnDprHeight) / 2]

    this.xyRatio = [this.targetAreaWidth / imageMeta.width, this.targetAreaHeight / imageMeta.height]
    this.dpr = 1
    if (this.canvas.width !== this.container.clientWidth * this.scale || this.canvas.height !== this.container.clientHeight * this.scale) {
      this.canvas.style.width = `${this.container.clientWidth}px`
      this.canvas.style.height = `${this.container.clientHeight}px`
      this.canvas.width = this.container.clientWidth * this.scale
      this.canvas.height = this.container.clientHeight * this.scale
      this.ctx.scale(this.scale, this.scale)

      this.hoverLayer.style.width = `${this.container.clientWidth}px`
      this.hoverLayer.style.height = `${this.container.clientHeight}px`
      this.__width = this.container.clientWidth
      this.__height = this.container.clientHeight
    }
  }
  innerSetFromOther = ({
    targetAreaWidth,
    targetAreaHeight,
    _targetAreaXY,
    xyRatio,
    dpr
  }) => {

    this.targetAreaWidth = targetAreaWidth
    this.targetAreaHeight = targetAreaHeight
    this._targetAreaXY = _targetAreaXY

    this.xyRatio = xyRatio
    this.dpr = dpr
  }
  getGraphData = () => {
    if (!this._backgroundImageMeta) {
      return {
        image: null,
        shapes: []
      }
    }
    return {
      image: this._backgroundImageMeta,
      shapes: this.cacheShapes.map(sp => ({
        ...sp, alpha: this.activeAlpha, lineWidth: this.activeLineWidth, points: sp.points.map(p => ({
          ...p,
          xy: [p.xy[0] / this.xyRatio[0], p.xy[1] / this.xyRatio[1]],
          targetAreaXY: [this._targetAreaXY[0] / this.xyRatio[0], this._targetAreaXY[1] / this.xyRatio[1]]
        }))
      }))
    }
  }
  clear = () => {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
  }
  reCalcXYOnRectangleStretch = (pts, pp) => {
    const deltaXY = [pp.xy[0] - this._grabStartPosition.xy[0], pp.xy[1] - this._grabStartPosition.xy[1]]

    // polygon
    if (this.activeShapeType === 'polygon') {
      return pts.map(p => {
        return {
          ...p,
          xy: this.__hoveredPoint__.pointId === p.pointId ? [this._grabStartSelectedPoint.xy[0] + deltaXY[0], this._grabStartSelectedPoint.xy[1] + deltaXY[1]] : p.xy,
          // shape: this.isPointInterset(p, currentPosition) ? 'circle' : 'dot'
        }
      })
    }
    if (this.activeShapeType === 'rectangle') {
      // rectangle
      const _pts = pts
      const pl = _pts.length
      const curIndex = _pts.findIndex(p => p.pointId === this.__hoveredPoint__.pointId)
      const preIndex = (curIndex - 1 + pl) % pl
      const nextIndex = (curIndex + 1) % pl
      let nextIndex4 = (curIndex + 4) % pl
      let rps = []
      if (_pts[preIndex].xy[0] === _pts[nextIndex].xy[0]) {
        nextIndex4 = (nextIndex4 + 1) % pl
        return this.getRectangleEndpointsXY(_pts[nextIndex4], { xy: [this._grabStartSelectedPoint.xy[0] + deltaXY[0], _pts[nextIndex].xy[1]] })
      }
      if (_pts[preIndex].xy[1] === _pts[nextIndex].xy[1]) {
        nextIndex4 = (nextIndex4 + 1) % pl
        return this.getRectangleEndpointsXY(_pts[nextIndex4], { xy: [_pts[nextIndex].xy[0], this._grabStartSelectedPoint.xy[1] + deltaXY[1]] })
      }
      return this.getRectangleEndpointsXY(_pts[nextIndex4], { ...this._grabStartSelectedPoint, xy: [this._grabStartSelectedPoint.xy[0] + deltaXY[0], this._grabStartSelectedPoint.xy[1] + deltaXY[1]] })
    }
    return []
  }

  // 锚点编辑
  onMouseDownInDragMode = (event) => {
    this._grabStartPosition = {
      xy: this.getXYOnCanvas(event).p,
      pointRadius: this.activePointRadius,
      shape: 'dot'
    }
    this._grabStartSelectedPoint = this.cacheShapes.filter(shape => {
      return shape.shapeId === this.__intersetShapeId__
    })[0]?.points?.filter(p => p.pointId === this.__hoveredPoint__.pointId)[0] ?? {}
    this.clearElementEvents()
    this.addEvent(this.hoverLayer, eventTypes.MOUSEMOVE, this.onMousePress)
    this.eventsArr.push([eventTypes.MOUSEMOVE, this.onMousePress])
    this.addEvent(this.hoverLayer, eventTypes.MOUSEUP, this.onDragEnd)
    this.eventsArr.push([eventTypes.MOUSEUP, this.onDragEnd])
  }
  onMouseDown = (event) => {
    this._grabStartPosition = {
      xy: this.getXYOnCanvas(event).p,
      pointRadius: this.activePointRadius,
      shape: 'dot'
    }
    this._grabStartSelectedPoint = this.cacheShapes.filter(shape => {
      return shape.shapeId === this.__intersetShapeId__
    })[0]?.points?.filter(p => p.pointId === this.__hoveredPoint__.pointId)[0] ?? {}
    this.clearElementEvents()
    this.addEvent(this.hoverLayer, eventTypes.MOUSEMOVE, this.onMousePress)
    this.eventsArr.push([eventTypes.MOUSEMOVE, this.onMousePress])
    this.addEvent(this.hoverLayer, eventTypes.MOUSEUP, this.onMouseUp)
    this.eventsArr.push([eventTypes.MOUSEUP, this.onMouseUp])
  }
  onMousePress = (event) => {
    this._grabEndPosition = {
      xy: this.getXYOnCanvas(event).p,
      pointRadius: this.activePointRadius,
      shape: 'dot'
    }

    this.cacheShapes = this.cacheShapes.map(shape => {
      return shape.shapeId === this.__intersetShapeId__ ? {
        ...shape,
        points: this.reCalcXYOnRectangleStretch(shape.points, this._grabEndPosition)
      } : shape
    })

    this.clear()
    this.renderImageByImageData()
    this.drawCacheShapes(this.cacheShapes)
  }
  onMouseUp = () => {
    this.__hitType = void 0
    this.postMessage({
      type: messageTypes.SYNC_CANVAS_TO_OUTSIDE,
      data: this.getGraphData()
    })
    this.clearElementEvents()
    this.addEvent(this.hoverLayer, eventTypes.MOUSEMOVE, this.onDrawSelect)
  }
  // 拖动
  // 拖动模式 拖完继续拖
  onGrabStartInDragMode = (event) => {
    this.hoverLayer.style.cursor = cursorStyles.GRABBING
    this._grabStartPosition = {
      xy: this.getXYOnCanvas(event).p,
      pointRadius: this.activePointRadius,
      shape: 'dot'
    }
    // this.currentMovePosition = { ...this._grabStartPosition }

    this._cacheShapes = this.cacheShapes

    this.clearElementEvents()
    this.addEvent(this.hoverLayer, eventTypes.MOUSEMOVE, this.onGrabbing)
    this.eventsArr.push([eventTypes.MOUSEMOVE, this.onGrabbing])
    this.addEvent(this.hoverLayer, eventTypes.MOUSEUP, this.onDragEnd)
    this.eventsArr.push([eventTypes.MOUSEUP, this.onDragEnd])
  }
  // 编辑模式 拖完进入监听编辑
  onGrabStart = (event) => {
    this.hoverLayer.style.cursor = cursorStyles.GRABBING
    this._grabStartPosition = {
      xy: this.getXYOnCanvas(event).p,
      pointRadius: this.activePointRadius,
      shape: 'dot'
    }
    // this.currentMovePosition = { ...this._grabStartPosition }

    this._cacheShapes = this.cacheShapes

    this.clearElementEvents()
    this.addEvent(this.hoverLayer, eventTypes.MOUSEMOVE, this.onGrabbing)
    this.eventsArr.push([eventTypes.MOUSEMOVE, this.onGrabbing])
    this.addEvent(this.hoverLayer, eventTypes.MOUSEUP, this.onMouseUp)
    this.eventsArr.push([eventTypes.MOUSEUP, this.onMouseUp])
  }
  onGrabbing = (event) => {
    this.hoverLayer.style.cursor = cursorStyles.GRABBING
    this._grabEndPosition = {
      xy: this.getXYOnCanvas(event).p,
      pointRadius: this.activePointRadius,
      shape: 'dot'
    }

    const deltaXY = [this._grabEndPosition.xy[0] - this._grabStartPosition.xy[0], this._grabEndPosition.xy[1] - this._grabStartPosition.xy[1]]
    this.cacheShapes = this._cacheShapes.map((shape, index) => {
      return shape.shapeId === this.__intersetShapeId__ ? {
        ...shape,
        points: shape.points.map(p => {
          return {
            ...p,
            xy: [p.xy[0] + deltaXY[0], p.xy[1] + deltaXY[1]],
            // shape: this.isPointInterset(p, currentPosition) ? 'circle' : 'dot'
          }
        })
      } : { ...shape }
    })

    this.clear()
    this.renderImageByImageData()
    this.drawCacheShapes(this.cacheShapes)
  }
  // 移动图片
  onImageGrabStart = (event) => {
    this.hoverLayer.style.cursor = cursorStyles.GRABBING
    this._grabStartPosition = {
      xy: this.getXYOnCanvas(event).p,
      pointRadius: this.activePointRadius,
      shape: 'dot'
    }
    // this.currentMovePosition = { ...this._grabStartPosition }

    this._cacheShapes = this.cacheShapes
    this._targetAreaXY_ = this._targetAreaXY

    this.clearElementEvents()
    this.addEvent(this.hoverLayer, eventTypes.MOUSEMOVE, this.onImageGrabbing)
    this.eventsArr.push([eventTypes.MOUSEMOVE, this.onImageGrabbing])
    this.addEvent(this.hoverLayer, eventTypes.MOUSEUP, this.onDragEnd)
    this.eventsArr.push([eventTypes.MOUSEUP, this.onDragEnd])
  }
  onImageGrabbing = (event) => {
    this.hoverLayer.style.cursor = cursorStyles.GRABBING
    this._grabEndPosition = {
      xy: this.getXYOnCanvas(event).p,
      pointRadius: this.activePointRadius,
      shape: 'dot'
    }

    const deltaXY = [this._grabEndPosition.xy[0] - this._grabStartPosition.xy[0], this._grabEndPosition.xy[1] - this._grabStartPosition.xy[1]]
    this._targetAreaXY = [this._targetAreaXY_[0] + deltaXY[0], this._targetAreaXY_[1] + deltaXY[1]]
    this.cacheShapes = this._cacheShapes.map((shape, index) => {
      return {
        ...shape,
        points: shape.points.map(p => {
          return {
            ...p,
            xy: [p.xy[0] + deltaXY[0], p.xy[1] + deltaXY[1]],
            // shape: this.isPointInterset(p, currentPosition) ? 'circle' : 'dot'
          }
        })
      }
    })
    this.postMessage({
      type: messageTypes.SYNC_CANVAS_SETTING_DATA_TO_OUTSIDE,
      data: {
        data: this.getGraphData(),
        dpr: this.dpr,
        targetAreaWidth: this.targetAreaWidth,
        targetAreaHeight: this.targetAreaHeight,
        _targetAreaXY: this._targetAreaXY,
        xyRatio: this.xyRatio,
        bizType: this.bizType,
        relativeRatio: this.relativeRatio
      }
    })
    this.clear()
    this.renderImageByImageData()
    this.drawCacheShapes(this.cacheShapes)
  }
  // 移动整体
  onWholeGrabStart = (event) => {
    this.hoverLayer.style.cursor = cursorStyles.GRABBING
    this.editMode = 3
    this._grabStartPosition = {
      xy: this.getXYOnCanvas(event).p,
      pointRadius: this.activePointRadius,
      shape: 'dot'
    }
    // this.currentMovePosition = { ...this._grabStartPosition }

    this._cacheShapes = this.cacheShapes
    this._targetAreaXY_ = this._targetAreaXY

    this.clearElementEvents()
    this.addEvent(this.hoverLayer, eventTypes.MOUSEMOVE, this.onImageGrabbing)
    this.eventsArr.push([eventTypes.MOUSEMOVE, this.onImageGrabbing])
    this.addEvent(this.hoverLayer, eventTypes.MOUSEUP, this.onDragEnd)
    this.eventsArr.push([eventTypes.MOUSEUP, this.onDragEnd])
  }
  onShapeHoverStart = (event) => {
    this.hoverLayer.style.cursor = cursorStyles.GRABBING
    this._grabStartPosition = {
      xy: this.getXYOnCanvas(event).p,
      pointRadius: this.activePointRadius,
      shape: 'dot'
    }
    // this.currentMovePosition = { ...this._grabStartPosition }

    this._cacheShapes = this.cacheShapes
    this._targetAreaXY_ = this._targetAreaXY

    this.clearElementEvents()
    this.addEvent(this.hoverLayer, eventTypes.MOUSEMOVE, this.onImageGrabbing)
    this.eventsArr.push([eventTypes.MOUSEMOVE, this.onImageGrabbing])
    this.addEvent(this.hoverLayer, eventTypes.MOUSEUP, this.onDragEnd)
    this.eventsArr.push([eventTypes.MOUSEUP, this.onDragEnd])
  }
  onDragEnd = () => {
    this.__hitType = void 0
    this.postMessage({
      type: messageTypes.SYNC_CANVAS_TO_OUTSIDE,
      data: this.getGraphData()
    })

    this.postMessage({
      type: messageTypes.SYNC_CANVAS_SETTING_DATA_TO_OUTSIDE,
      data: {
        data: this.getGraphData(),
        dpr: this.dpr,
        targetAreaWidth: this.targetAreaWidth,
        targetAreaHeight: this.targetAreaHeight,
        _targetAreaXY: this._targetAreaXY,
        xyRatio: this.xyRatio,
        bizType: this.bizType,
        relativeRatio: this.relativeRatio
      }
    })

    this.clearElementEvents()
    if (this.editMode === 1) {
      this.startRectangle(this.activeShape.shape, this.activeShape.color)
    } else if (this.editMode === 2) {
      this.shapeMove()
    } else if (this.editMode === 3) {
      this.shapeMoveWhole()
    } else if (this.editMode === 4) {
      this.shapeHover()
    }
  }
  // 鼠标中键拖动
  centerDragMoveStart = (event) => {
    event.preventDefault()
    if (event.button === 1) {
      this.hoverLayer.style.cursor = cursorStyles.GRABBING
      this._grabStartPosition = {
        xy: this.getXYOnCanvas(event).p,
        pointRadius: this.activePointRadius,
        shape: 'dot'
      }
      // this.currentMovePosition = { ...this._grabStartPosition }

      this._cacheShapes = this.cacheShapes
      this._targetAreaXY_ = this._targetAreaXY

      this.clearElementEvents()
      this.addEvent(this.hoverLayer, eventTypes.MOUSEMOVE, this.onImageGrabbing)
      this.eventsArr.push([eventTypes.MOUSEMOVE, this.onImageGrabbing])
      this.addEvent(this.hoverLayer, eventTypes.MOUSEUP, this.centerDragMoveEnd)
      this.eventsArr.push([eventTypes.MOUSEUP, this.centerDragMoveEnd])
    }
  }
  centerDragMoveEnd = (event) => {
    event.preventDefault()
    this.__hitType = void 0
    if (event.button === 1) {
      this.postMessage({
        type: messageTypes.SYNC_CANVAS_TO_OUTSIDE,
        data: this.getGraphData()
      })

      this.clearElementEvents()
      if (this.editMode === 1) {
        this.startRectangle(this.activeShape.shape, this.activeShape.color)
      } else if (this.editMode === 2) {
        this.shapeMove()
      } else if (this.editMode === 3) {
        this.shapeMoveWhole()
      }
    }
  }
  //画矩形
  // 两次单击
  clickToDrawRectangle = (event) => {
    if (this.rectangleDiagonalEndpoints && this.rectangleDiagonalEndpoints.length === 1) {
      this.rectangleDiagonalEndpoints.push({
        xy: this.getXYOnCanvas(event).p,
      })
      this.activeShape.activePoints.push({
        ...this.pointInterset,
        pointRadius: this.activePointRadius,
        color: this.activeShape.color,
        shape: 'dot'
      })
      const _pts = this.getRectangleEndpointsXY(this.rectangleDiagonalEndpoints[0], this.rectangleDiagonalEndpoints[1])
      this.activeShape.activePoints = _pts.map(p => ({ ...p, color: this.activeShape.color }))

      this.postMessage({
        type: messageTypes.IF_SHAPE_COMPLETE,
        data: {
          labelName: '',
          color: this.activeShape.color
        }
      })
    } else {
      this.rectangleDiagonalEndpoints = [{
        pointId: `${Date.now()}`,
        zIndex: Date.now(),
        xy: this.getXYOnCanvas(event).p,
        pointRadius: this.activePointRadius,
        color: this.activeShape.color,
        shape: 'dot'
      }]
      this.addEvent(document, eventTypes.KEYUP, this.onKeyDown)
    }
  }
  // 左键按下
  mouseDownToDrawRectangle = (event) => {
    event.preventDefault()
    if (this.__isOnContextMenu) {
      this.__isOnContextMenu = false
      return
    }
    if (event.button === 0) {
      this.rectangleDiagonalEndpoints = [{
        pointId: `${Date.now()}`,
        zIndex: Date.now(),
        xy: this.getXYOnCanvas(event).p,
        pointRadius: this.activePointRadius,
        color: this.activeShape.color,
        shape: 'dot'
      }]
      this.clearElementEvents()
      this.addEvent(this.hoverLayer, eventTypes.MOUSEMOVE, this.mouseMoveToDrawRectangle)
      this.addEvent(this.hoverLayer, eventTypes.MOUSEUP, this.mouseUpToEndRectangle)
      this.addEvent(document, eventTypes.KEYUP, this.onKeyDown)
      this.eventsArr.push([eventTypes.MOUSEMOVE, this.mouseMoveToDrawRectangle])
      this.eventsArr.push([eventTypes.MOUSEUP, this.mouseUpToEndRectangle])
      this.eventsArr.push([eventTypes.KEYUP, this.onKeyDown])
    }
  }
  // 左键按住不松
  mouseMoveToDrawRectangle = (event) => {
    event.preventDefault()
    if (event.button === 0) {
      if (!this.rectangleDiagonalEndpoints || !this.rectangleDiagonalEndpoints.length) {
        return
      }
      let currentPosition = {
        xy: this.getXYOnCanvas(event).p,
        pointRadius: this.activePointRadius,
        shape: 'dot'
      }
      this.currentMovePosition = currentPosition
      const eps = this.getRectangleEndpointsXY(this.rectangleDiagonalEndpoints[0], currentPosition).map(p => ({ ...p, color: this.activeShape.color }))

      this.clear()
      this.renderImageByImageData()
      this.drawCacheShapes(this.cacheShapes)
      this.drawPolyline(eps, { closed: true })
    }
  }
  // 左键松开
  mouseUpToEndRectangle = (event) => {
    event.preventDefault()
    if (this.__isOnContextMenu) {
      this.__isOnContextMenu = false
      return
    }
    if (event.button === 0) {
      this.clearElementEvents()
      // this.addEvent(this.hoverLayer, eventTypes.MOUSEDOWN, this.mouseDownToDrawRectangle)
      // this.eventsArr.push([eventTypes.MOUSEDOWN, this.mouseDownToDrawRectangle])
      this.addEvent(this.hoverLayer, eventTypes.MOUSEMOVE, this.onDrawSelect)
      this.eventsArr.push([eventTypes.MOUSEMOVE, this.onDrawSelect])
      if (this.rectangleDiagonalEndpoints && this.rectangleDiagonalEndpoints.length === 1) {
        const endP = this.getXYOnCanvas(event)
        const pDis = this.pointDistance(this.rectangleDiagonalEndpoints[0], { xy: endP.p })
        if (pDis[0] === 0 && pDis[1] === 0) {
          this.clear()
          this.renderImageByImageData()
          this.drawCacheShapes(this.cacheShapes)
          return this.postMessage({
            type: messageTypes.PRESS_MOUSE_TO_SELECT,
            data: {
              message: '框选时请按住鼠标左键'
            }
          })
        }
        if (pDis[0] < 10 || pDis[1] < 10) {
          this.rectangleDiagonalEndpoints = null
          this.clear()
          this.renderImageByImageData()
          this.drawCacheShapes(this.cacheShapes)
          return this.postMessage({
            type: messageTypes.DISTANCE_TOO_SMALL,
            data: {
              message: '框选范围太小，请重试'
            }
          })
        }

        this.rectangleDiagonalEndpoints.push({
          xy: endP.p,
        })
        this.activeShape.activePoints.push({
          ...this.pointInterset,
          pointRadius: this.activePointRadius,
          color: this.activeShape.color,
          shape: 'dot'
        })
        const _pts = this.getRectangleEndpointsXY(this.rectangleDiagonalEndpoints[0], this.rectangleDiagonalEndpoints[1])
        this.activeShape.activePoints = _pts.map(p => ({ ...p, color: this.activeShape.color }))

        this.postMessage({
          type: messageTypes.IF_SHAPE_COMPLETE,
          data: {
            labelName: '',
            color: this.activeShape.color
          }
        })
      }
    }
  }
  pointDistance = (p_0, p_1) => {
    const p0 = p_0.xy
    const p1 = p_1.xy
    return [Math.abs(p0[0] - p1[0]), Math.abs(p0[1] - p1[1])]
  }
  getRectangleEndpointsXY = (p_0, p_1) => {
    const p0 = p_0.xy
    const p1 = p_1.xy
    return [{
      xy: [Math.min(p0[0], p1[0]), Math.min(p0[1], p1[1])],
      cursor: cursorStyles.NW_RESIZE
    }, {
      xy: [Math.min(p0[0], p1[0]), (Math.min(p0[1], p1[1]) + Math.max(p0[1], p1[1])) / 2],
      cursor: cursorStyles.W_RESIZE
    }, {
      xy: [Math.min(p0[0], p1[0]), Math.max(p0[1], p1[1])],
      cursor: cursorStyles.SW_RESIZE
    }, {
      xy: [(Math.min(p0[0], p1[0]) + Math.max(p0[0], p1[0])) / 2, Math.max(p0[1], p1[1])],
      cursor: cursorStyles.S_RESIZE
    }, {
      xy: [Math.max(p0[0], p1[0]), Math.max(p0[1], p1[1])],
      cursor: cursorStyles.SE_RESIZE
    }, {
      xy: [Math.max(p0[0], p1[0]), (Math.min(p0[1], p1[1]) + Math.max(p0[1], p1[1])) / 2],
      cursor: cursorStyles.E_RESIZE
    }, {
      xy: [Math.max(p0[0], p1[0]), Math.min(p0[1], p1[1])],
      cursor: cursorStyles.NE_RESIZE
    }, {
      xy: [(Math.min(p0[0], p1[0]) + Math.max(p0[0], p1[0])) / 2, Math.min(p0[1], p1[1])],
      cursor: cursorStyles.N_RESIZE
    }].map((x, index) => ({
      ...p_0,
      ...x,
      pointId: typeof p_0.pointId === 'number' ? (p_0.pointId + index) : `${p_0.pointId.split('.')[0]}.${index}`,
      zIndex: typeof p_0.pointId === 'number' ? (p_0.pointId + index) : `${p_0.pointId.split('.')[0]}.${index}`,
    }))
  }

  // 通用
  onClick = (event) => {
    if (this.pointInterset) {
      this.activeShape.activePoints.push({
        ...this.pointInterset,
        pointRadius: this.activePointRadius,
        color: this.activeShape.color,
        shape: 'dot'
      })
      // this.activeShape.cachePoints = this.activeShape.activePoints
      this.postMessage({
        type: messageTypes.IF_SHAPE_COMPLETE,
        data: {
          labelName: '',
          color: this.activeShape.color
        }
      })
    } else {
      this.activeShape.activePoints.push({
        pointId: Date.now(),
        zIndex: Date.now(),
        xy: this.getXYOnCanvas(event).p,
        pointRadius: this.activePointRadius,
        color: this.activeShape.color,
        shape: 'dot'
      })
      this.activeShape.cachePoints = this.activeShape.activePoints
      this._pointerIndex = this.activeShape.cachePoints.length - 1
    }

    this.clear()
    this.renderImageByImageData()
    this.drawCacheShapes(this.cacheShapes)
    this.drawPolyline(this.activeShape.activePoints)
  }
  onMouseMove = (event) => {
    let currentPosition = {
      xy: this.getXYOnCanvas(event).p,
      pointRadius: this.activePointRadius,
      shape: 'dot'
    }
    const activePoints = this.activeShape && this.activeShape.activePoints || []
    const lastPointIndex = activePoints.length - 1
    this.pointInterset = null

    if (lastPointIndex > 1) {
      activePoints.forEach((cp, index) => {
        if (this.isPointInterset(cp, currentPosition) && index === 0) {
          this.pointInterset = currentPosition = {
            ...cp,
            pointRadius: this.pointRadius * 2,
            shape: 'circle'
          }
        }
        return cp
      })
    }
    this.currentMovePosition = currentPosition

    this.hoverLayer.style.cursor = cursorStyles.CROSSHAIR

    this.clear()
    this.renderImageByImageData()
    this.drawCacheShapes(this.cacheShapes)
    activePoints.length && this.drawPolyline([...activePoints, this.currentMovePosition])
  }
  // 右键
  onContextClick = (event) => {
    event.preventDefault()
    this.__isOnContextMenu = true
    this.clearElementEvents()
    this.postMessage({
      type: messageTypes.CONTEXT_CLICK,
      data: {
        event,
        shape: {
          ...this.__hoveredShape, alpha: this.activeAlpha, lineWidth: this.activeLineWidth, points: this.__hoveredShape.points.map(p => ({
            ...p,
            xy: [p.xy[0] / this.xyRatio[0], p.xy[1] / this.xyRatio[1]],
            targetAreaXY: [this._targetAreaXY[0] / this.xyRatio[0], this._targetAreaXY[1] / this.xyRatio[1]]
          }))
        }
      }
    })
  }
  _handleContextNoClick = (event) => {
    // event.preventDefault()  // 不能阻止 否则会导致 https://pms.orientalmind.cn/issues/1773   https://pms.orientalmind.cn/issues/1775
    this.__isOnContextMenu = false
    this.postMessage({
      type: messageTypes.CONTEXT_NO_CLICK,
      data: {
        event,
      }
    })
    this.clearElementEvents()
    if (this.editMode === 1) {
      this.startRectangle(this.activeShape.shape, this.activeShape.color)
    } else if (this.editMode === 2) {
      this.shapeMove()
    } else if (this.editMode === 3) {
      this.shapeMoveWhole()
    } else if (this.editMode === 4) {
      this.shapeHover()
    }
  }
  _onContextScroll = (event) => {
    window.requestAnimationFrame(() => {
      this.postMessage({
        type: messageTypes.CONTEXT_SCROLL,
        data: {
          event,
        }
      })
    })
  }
  onContextEnd = () => {
    this.__hitType = void 0
    this.clearElementEvents()
    if (this.editMode === 1) {
      this.startRectangle(this.activeShape.shape, this.activeShape.color)
    } else if (this.editMode === 2) {
      this.shapeMove()
    } else if (this.editMode === 3) {
      this.shapeMoveWhole()
    }
  }
  // rect & polygon hover
  onDrawSelect = (event) => {
    const [__hitType, _lp, _ls] = this.getHitType(event)
    if (this.__hitType !== __hitType) {
      this.__hitType = __hitType
      let _hoveredShape = {}
      let _hoveredPoint = {}
      let cursor = cursorStyles.AUTO
      let eventType = eventTypes.MOUSEMOVE
      let eventHandler = this.onDrawSelect

      if (__hitType === 1) {
        _hoveredShape = this.cacheShapes.find(sp => sp.shapeId === _lp.shapeId)
        _hoveredPoint = _hoveredShape.points.find(p => p.pointId === _lp.pointId)
        cursor = _hoveredPoint.cursor

        this.__intersetShapeId__ = _lp.shapeId
        eventType = eventTypes.MOUSEDOWN
        eventHandler = this.onMouseDown
      }
      if (__hitType === 2 || __hitType === 3 || __hitType === 4 || __hitType === 5) {
        _hoveredShape = this.cacheShapes.find(sp => sp.shapeId === _ls)
        _hoveredPoint = {}
        cursor = cursorStyles.GRAB

        eventType = eventTypes.MOUSEDOWN
        eventHandler = this.onGrabStart
      }
      if (__hitType === 6) {
        this.__hitType = void 0
        _hoveredShape = {}
        _hoveredPoint = {}
        cursor = cursorStyles.CROSSHAIR

        eventType = eventTypes.MOUSEDOWN
        eventHandler = this.mouseDownToDrawRectangle
      }
      this.__hoveredShape = _hoveredShape
      this.__hoveredPoint__ = _hoveredPoint
      this.__intersetShapeId__ = _hoveredShape.shapeId
      this.cacheShapes = this.cacheShapes.map((shape) => {
        return shape.shapeId === this.__intersetShapeId__ ? {
          ...shape,
          alpha: 0.7,
          lineWidth: 3,
          points: this.__hoveredPoint__ ? shape.points.map(p => {
            return {
              ...p,
              shape: p.pointId === this.__hoveredPoint__.pointId ? 'circle' : 'dot'
            }
          }) : shape.points
        } : {
          ...shape,
          alpha: this.activeAlpha,
          lineWidth: 1,
          points: shape.points.map(p => {
            return {
              ...p,
              shape: 'dot'
            }
          })
        }
      })
      this.hoverLayer.style.cursor = cursor
      this.activeShapeType = _hoveredShape.shape
      this.postMessage({
        type: messageTypes.ACTIVATE_SHAPE,
        data: _hoveredShape
      })
      if (typeof this.__intersetShapeId__ !== 'undefined') {
        this.addEvent(document, eventTypes.CONTEXTMENU, this.onContextClick)
        this.addEvent(document, eventTypes.CLICK, this._handleContextNoClick)
        this.addEvent(document, eventTypes.WHEEl, this._onContextScroll)
      } else {
        this.removeEvent(document, eventTypes.CONTEXTMENU, this.onContextClick)
        // this.removeEvent(document, eventTypes.CLICK, this._handleContextNoClick)
        // this.removeEvent(document, eventTypes.WHEEl, this._onContextScroll)
      }
      this.clearElementEvents()
      this.addEvent(this.hoverLayer, eventTypes.MOUSEMOVE, this.onDrawSelect)
      this.eventsArr.push([eventTypes.MOUSEMOVE, this.onDrawSelect])
      this.addEvent(this.hoverLayer, eventType, eventHandler)
      this.eventsArr.push([eventType, eventHandler])

      this.clear()
      this.renderImageByImageData()
      this.drawCacheShapes(this.cacheShapes)
    }
  }
  onShapeMove = (event) => {
    const [__hitType, _lp, _ls] = this.getHitType(event)
    if (this.__hitType !== __hitType) {
      this.__hitType = __hitType
      let _hoveredShape = {}
      let _hoveredPoint = {}
      let cursor = cursorStyles.AUTO
      let eventType = eventTypes.MOUSEMOVE
      let eventHandler = this.onShapeMove

      if (__hitType === 1) {
        _hoveredShape = this.cacheShapes.find(sp => sp.shapeId === _lp.shapeId)
        _hoveredPoint = _hoveredShape.points.find(p => p.pointId === _lp.pointId)
        cursor = _hoveredPoint.cursor

        this.__intersetShapeId__ = _lp.shapeId
        eventType = eventTypes.MOUSEDOWN
        eventHandler = this.onMouseDownInDragMode
      }
      if (__hitType === 2 || __hitType === 3 || __hitType === 4 || __hitType === 5) {
        _hoveredShape = this.cacheShapes.find(sp => sp.shapeId === _ls)
        _hoveredPoint = {}
        cursor = cursorStyles.GRAB

        eventType = eventTypes.MOUSEDOWN
        eventHandler = this.onGrabStartInDragMode
      }
      if (__hitType === 6) {
        this.__hitType = void 0
        _hoveredShape = {}
        _hoveredPoint = {}
        cursor = cursorStyles.GRAB

        eventType = eventTypes.MOUSEDOWN
        eventHandler = this.onImageGrabStart
      }
      this.__hoveredShape = _hoveredShape
      this.__hoveredPoint__ = _hoveredPoint
      this.__intersetShapeId__ = _hoveredShape.shapeId
      this.cacheShapes = this.cacheShapes.map((shape) => {
        return shape.shapeId === this.__intersetShapeId__ ? {
          ...shape,
          alpha: 0.7,
          lineWidth: 3,
          points: this.__hoveredPoint__ ? shape.points.map(p => {
            return {
              ...p,
              shape: p.pointId === this.__hoveredPoint__.pointId ? 'circle' : 'dot'
            }
          }) : shape.points
        } : {
          ...shape,
          alpha: this.activeAlpha,
          lineWidth: 1,
          points: shape.points.map(p => {
            return {
              ...p,
              shape: 'dot'
            }
          })
        }
      })
      this.hoverLayer.style.cursor = cursor
      this.activeShapeType = _hoveredShape.shape
      this.postMessage({
        type: messageTypes.ACTIVATE_SHAPE,
        data: _hoveredShape
      })
      if (typeof this.__intersetShapeId__ !== 'undefined') {
        this.addEvent(document, eventTypes.CONTEXTMENU, this.onContextClick)
        this.addEvent(document, eventTypes.CLICK, this._handleContextNoClick)
        this.addEvent(document, eventTypes.WHEEl, this._onContextScroll)
      } else {
        this.removeEvent(document, eventTypes.CONTEXTMENU, this.onContextClick)
        // this.removeEvent(document, eventTypes.CLICK, this._handleContextNoClick)
        // this.removeEvent(document, eventTypes.WHEEl, this._onContextScroll)
      }
      this.clearElementEvents()
      this.addEvent(this.hoverLayer, eventTypes.MOUSEMOVE, this.onShapeMove)
      this.eventsArr.push([eventTypes.MOUSEMOVE, this.onShapeMove])
      this.addEvent(this.hoverLayer, eventType, eventHandler)
      this.eventsArr.push([eventType, eventHandler])

      this.clear()
      this.renderImageByImageData()
      this.drawCacheShapes(this.cacheShapes)
    }
  }
  onShapeMoveWhole = (event) => {
    const [__hitType, _lp, _ls] = this.getHitType(event)
    if (this.__hitType !== __hitType) {
      this.__hitType = __hitType
      let _hoveredShape = {}
      let _hoveredPoint = {}
      let cursor = cursorStyles.AUTO
      let eventType = eventTypes.MOUSEMOVE
      let eventHandler = this.onShapeMoveWhole

      eventType = eventTypes.MOUSEDOWN
      eventHandler = this.onWholeGrabStart

      this.__hoveredShape = _hoveredShape
      this.__hoveredPoint__ = _hoveredPoint
      this.__intersetShapeId__ = _hoveredShape.shapeId
      this.cacheShapes = this.cacheShapes.map((shape) => {
        return shape.shapeId === this.__intersetShapeId__ ? {
          ...shape,
          alpha: 0.7,
          lineWidth: 3,
          points: this.__hoveredPoint__ ? shape.points.map(p => {
            return {
              ...p,
              shape: p.pointId === this.__hoveredPoint__.pointId ? 'circle' : 'dot'
            }
          }) : shape.points
        } : {
          ...shape,
          alpha: this.activeAlpha,
          lineWidth: 1,
          points: shape.points.map(p => {
            return {
              ...p,
              shape: 'dot'
            }
          })
        }
      })
      this.hoverLayer.style.cursor = cursor
      this.activeShapeType = _hoveredShape.shape
      this.postMessage({
        type: messageTypes.ACTIVATE_SHAPE,
        data: _hoveredShape
      })
      this.clearElementEvents()
      this.addEvent(this.hoverLayer, eventTypes.MOUSEMOVE, this.onShapeMoveWhole)
      this.eventsArr.push([eventTypes.MOUSEMOVE, this.onShapeMoveWhole])
      this.addEvent(this.hoverLayer, eventType, eventHandler)
      this.eventsArr.push([eventType, eventHandler])

      this.clear()
      this.renderImageByImageData()
      this.drawCacheShapes(this.cacheShapes)
    }
  }
  onShapeHover = (event) => {
    const [__hitType, _lp, _ls] = this.getHitType(event)
    if (this.__hitType !== __hitType) {
      this.__hitType = __hitType
      let _hoveredShape = {}
      let _hoveredPoint = {}
      let cursor = cursorStyles.AUTO
      let eventType = eventTypes.MOUSEMOVE
      let eventHandler = this.onShapeHover

      if (__hitType === 1) {
        _hoveredShape = this.cacheShapes.find(sp => sp.shapeId === _lp.shapeId)
        _hoveredPoint = _hoveredShape.points.find(p => p.pointId === _lp.pointId)
        cursor = _hoveredPoint.cursor

        this.__intersetShapeId__ = _lp.shapeId
        eventType = eventTypes.MOUSEDOWN
        eventHandler = this.onShapeHoverStart
      }

      if (__hitType === 2 || __hitType === 3 || __hitType === 4 || __hitType === 5) {
        _hoveredShape = this.cacheShapes.find(sp => sp.shapeId === _ls)
        _hoveredPoint = {}
        cursor = cursorStyles.GRAB

        eventType = eventTypes.MOUSEDOWN
        eventHandler = this.onShapeHoverStart
      }

      if (__hitType === 6) {
        this.__hitType = void 0
        _hoveredShape = {}
        _hoveredPoint = {}
        cursor = cursorStyles.GRAB

        eventType = eventTypes.MOUSEDOWN
        eventHandler = this.onImageGrabStart
      }

      this.__hoveredShape = _hoveredShape
      this.__hoveredPoint__ = _hoveredPoint
      this.__intersetShapeId__ = _hoveredShape.shapeId
      this.cacheShapes = this.cacheShapes.map((shape) => {
        return shape.shapeId === this.__intersetShapeId__ ? {
          ...shape,
          alpha: 0.7,
          lineWidth: 3,
          points: this.__hoveredPoint__ ? shape.points.map(p => {
            return {
              ...p,
              shape: p.pointId === this.__hoveredPoint__.pointId ? 'circle' : 'dot'
            }
          }) : shape.points
        } : {
          ...shape,
          alpha: this.activeAlpha,
          lineWidth: 1,
          points: shape.points.map(p => {
            return {
              ...p,
              shape: 'dot'
            }
          })
        }
      })
      this.hoverLayer.style.cursor = cursor
      this.activeShapeType = _hoveredShape.shape
      this.postMessage({
        type: messageTypes.ACTIVATE_SHAPE,
        data: _hoveredShape
      })

      if (typeof this.__intersetShapeId__ !== 'undefined') {
        this.addEvent(document, eventTypes.CONTEXTMENU, this.onContextClick)
        this.addEvent(document, eventTypes.CLICK, this._handleContextNoClick)
        this.addEvent(document, eventTypes.WHEEl, this._onContextScroll)
      } else {
        this.removeEvent(document, eventTypes.CONTEXTMENU, this.onContextClick)
        // this.removeEvent(document, eventTypes.CLICK, this._handleContextNoClick)
        // this.removeEvent(document, eventTypes.WHEEl, this._onContextScroll)
      }

      this.clearElementEvents()
      this.addEvent(this.hoverLayer, eventTypes.MOUSEMOVE, this.onShapeHover)
      this.eventsArr.push([eventTypes.MOUSEMOVE, this.onShapeHover])
      this.addEvent(this.hoverLayer, eventType, eventHandler)
      this.eventsArr.push([eventType, eventHandler])

      this.clear()
      this.renderImageByImageData()
      this.drawCacheShapes(this.cacheShapes)
    }
  }
  // 旧版只处理编辑
  onShapeEdit = (event) => {
    const [__hitType, _lp, _ls] = this.getHitType(event)

    if (this.__hitType !== __hitType) {
      this.__hitType = __hitType
      let _hoveredShape = {}
      let _hoveredPoint = {}
      let cursor = cursorStyles.AUTO
      let eventType = eventTypes.MOUSEMOVE
      let eventHandler = this.onShapeEdit

      if (__hitType === 1) {
        _hoveredShape = this.cacheShapes.find(sp => sp.shapeId === _lp.shapeId)
        _hoveredPoint = _hoveredShape.points.find(p => p.pointId === _lp.pointId)
        cursor = _hoveredPoint.cursor

        this.__intersetShapeId__ = _lp.shapeId
        eventType = eventTypes.MOUSEDOWN
        eventHandler = this.onMouseDown
      }
      if (__hitType === 2 || __hitType === 3 || __hitType === 4 || __hitType === 5) {
        _hoveredShape = this.cacheShapes.find(sp => sp.shapeId === _ls)
        _hoveredPoint = {}
        cursor = cursorStyles.GRAB

        eventType = eventTypes.MOUSEDOWN
        eventHandler = this.onGrabStart

        // TODO 待需求确定
        // this.addEvent(this.hoverLayer, eventTypes.CONTEXTMENU, this.onContextClick)
      }
      if (__hitType === 6) {
        _hoveredShape = {}
        _hoveredPoint = {}
      }

      this.__hoveredPoint__ = _hoveredPoint
      this.__intersetShapeId__ = _hoveredShape.shapeId
      this.cacheShapes = this.cacheShapes.map((shape) => {
        return shape.shapeId === this.__intersetShapeId__ ? {
          ...shape,
          alpha: 0.7,
          lineWidth: 3,
          points: this.__hoveredPoint__ ? shape.points.map(p => {
            return {
              ...p,
              shape: p.pointId === this.__hoveredPoint__.pointId ? 'circle' : 'dot'
            }
          }) : shape.points
        } : {
          ...shape,
          alpha: this.activeAlpha,
          lineWidth: 1,
          points: shape.points.map(p => {
            return {
              ...p,
              shape: 'dot'
            }
          })
        }
      })
      this.hoverLayer.style.cursor = cursor
      this.activeShapeType = _hoveredShape.shape
      this.postMessage({
        type: messageTypes.ACTIVATE_SHAPE,
        data: _hoveredShape
      })

      this.clearElementEvents()
      this.addEvent(this.hoverLayer, eventTypes.MOUSEMOVE, this.onShapeEdit)
      this.eventsArr.push([eventTypes.MOUSEMOVE, this.onShapeEdit])
      this.addEvent(this.hoverLayer, eventType, eventHandler)
      this.eventsArr.push([eventType, eventHandler])

      this.clear()
      this.renderImageByImageData()
      this.drawCacheShapes(this.cacheShapes)
    }
  }
  getHitType = (event) => {
    const currentPositionXY = this.getXYOnCanvas(event).p
    const sps = this.getHovered(this.cacheShapes, currentPositionXY)
    const _lp = sps.__hps__.slice(-1)[0]
    const _ls = sps.__intersetShapes__.slice(-1)[0]

    let __hitType
    if (typeof _lp !== 'undefined' && typeof _ls === 'undefined') {
      __hitType = 1
    }
    if (typeof _lp !== 'undefined' && typeof _ls !== 'undefined' && _lp.shapeId === _ls) {
      __hitType = 2
    }
    if (typeof _lp !== 'undefined' && typeof _ls !== 'undefined' && _lp.shapeId !== _ls) {
      __hitType = 3
    }
    if (typeof _lp === 'undefined' && typeof _ls !== 'undefined') {
      if (this._ls === _ls) {
        __hitType = 4
      }
      if (this._ls !== _ls) {
        this._ls = _ls
        __hitType = 5
      }
    }
    if (typeof _lp === 'undefined' && typeof _ls === 'undefined') {
      __hitType = 6
    }
    return [__hitType, _lp, _ls]
  }
  onKeyDown = (event) => {
    switch (event.keyCode) {
      // ESC
      case 27:
        return this.undo();
    }
  }

  clearElementEvents = () => {
    if (!this.eventsArr.length) return
    this.eventsArr.forEach(evt => {
      this.removeEvent(evt[2] || this.hoverLayer, evt[0], evt[1])
    })
    this.eventsArr = []
  }

  // 暴露操作api
  startRectangle = (shape, color) => {
    this.__hitType = void 0
    this.editMode = 1
    this.hoverLayer.style.cursor = cursorStyles.CROSSHAIR
    this.activeShape = {
      shape,
      color: color,

      activePoints: this.activeShape.shape === shape ? this.activeShape.activePoints.map(x => ({ ...x, color })) : [],
      // cachePoints: this.activeShape.shape === shape ? this.activeShape.cachePoints.map(x => ({ ...x, color })) : []
    }
    this.cacheShapes = this.cacheShapes.map(sp => ({ ...sp, alpha: this.activeAlpha, lineWidth: 1 }))
    let eps = []
    if (this.rectangleDiagonalEndpoints && this.rectangleDiagonalEndpoints[0]) {
      eps = this.getRectangleEndpointsXY(this.rectangleDiagonalEndpoints[0], this.currentMovePosition).map(p => ({ ...p, color: this.activeShape.color }))
    }
    this.clear()
    this.renderImageByImageData()
    this.cacheShapes.length && this.drawCacheShapes(this.cacheShapes)
    eps.length && this.drawPolyline(eps, { closed: true })

    this.clearElementEvents()
    // 旧版 鼠标左键单击框选
    // this.addEvent(this.hoverLayer, eventTypes.CLICK, this.clickToDrawRectangle)
    // this.addEvent(this.hoverLayer, eventTypes.MOUSEMOVE, this.mouseMoveToDrawRectangle)
    // this.eventsArr.push([eventTypes.CLICK, this.clickToDrawRectangle])
    // this.eventsArr.push([eventTypes.MOUSEMOVE, this.mouseMoveToDrawRectangle])

    // 新版 鼠标左键按住框选
    // this.addEvent(this.hoverLayer, eventTypes.MOUSEDOWN, this.mouseDownToDrawRectangle)
    // this.addEvent(this.hoverLayer, eventTypes.MOUSEMOVE, this.mouseMoveToDrawRectangle)
    // this.addEvent(this.hoverLayer, eventTypes.MOUSEUP, this.mouseUpToEndRectangle)

    // this.eventsArr.push([eventTypes.MOUSEDOWN, this.mouseDownToDrawRectangle])
    // this.eventsArr.push([eventTypes.MOUSEMOVE, this.mouseMoveToDrawRectangle])
    // this.eventsArr.push([eventTypes.MOUSEUP, this.mouseUpToEndRectangle])
    // 又一版 框选完后 框可以编辑
    this.addEvent(this.hoverLayer, eventTypes.MOUSEMOVE, this.onDrawSelect)
    this.eventsArr.push([eventTypes.MOUSEMOVE, this.onDrawSelect])
  }
  startPolygon = (shape, color) => {
    this.hoverLayer.style.cursor = cursorStyles.CROSSHAIR
    this.activeShape = {
      shape,
      color: color,

      activePoints: this.activeShape.shape === shape ? this.activeShape.activePoints.map(x => ({ ...x, color })) : [],
      cachePoints: this.activeShape.shape === shape ? this.activeShape.cachePoints.map(x => ({ ...x, color })) : []
    }

    this.clear()
    this.renderImageByImageData()
    this.cacheShapes.length && this.drawCacheShapes(this.cacheShapes)
    this.drawPolyline([...this.activeShape.activePoints, this.currentMovePosition])

    this.clearElementEvents()

    this.addEvent(this.hoverLayer, eventTypes.CLICK, this.onClick)
    this.addEvent(this.hoverLayer, eventTypes.MOUSEMOVE, this.onMouseMove)
    this.eventsArr.push([eventTypes.CLICK, this.onClick])
    this.eventsArr.push([eventTypes.MOUSEMOVE, this.onMouseMove])
    // this.addEvent(this.hoverLayer, 'keydown', this.onKeyDown)
  }
  // 旧版拖动或锚点编辑
  shapeEdit = () => {
    if (this.activeShape.activePoints) {
      this.activeShape.shape = ''
    }
    this.clearElementEvents()

    this.addEvent(this.hoverLayer, eventTypes.MOUSEMOVE, this.onShapeEdit)
    this.eventsArr.push([eventTypes.MOUSEMOVE, this.onShapeEdit])
  }
  // 新版图片拖拽，标注可编辑
  shapeMove = () => {
    this.editMode = 2
    if (this.activeShape.activePoints) {
      this.activeShape.shape = ''
    }
    this.clearElementEvents()
    this.addEvent(this.hoverLayer, eventTypes.MOUSEMOVE, this.onShapeMove)
    this.eventsArr.push([eventTypes.MOUSEMOVE, this.onShapeMove])
  }
  // 新版图片整体平移
  shapeMoveWhole = () => {
    this.editMode = 3
    if (this.activeShape.activePoints) {
      this.activeShape.shape = ''
    }
    this.clearElementEvents()
    this.addEvent(this.hoverLayer, eventTypes.MOUSEMOVE, this.onShapeMoveWhole)
    this.eventsArr.push([eventTypes.MOUSEMOVE, this.onShapeMoveWhole])
  }
  // 鼠标hover
  shapeHover = () => {
    this.editMode = 4
    if (this.activeShape.activePoints) {
      this.activeShape.shape = ''
    }
    this.clearElementEvents()
    this.addEvent(this.hoverLayer, eventTypes.MOUSEMOVE, this.onShapeHover)
    this.eventsArr.push([eventTypes.MOUSEMOVE, this.onShapeHover])
  }
  // 透视变换
  pstf = () => {
    const result = this.toDataURL('image/png')
    this.postMessage({
      type: messageTypes.PSTF_DATA,
      data: {
        data: result,
        code: messageTypes.PSTF_DATA
      }
    })
  }
  undo = () => {
    if (this.rectangleDiagonalEndpoints) {
      this.__t = this.rectangleDiagonalEndpoints.pop()
      this.activeShape.cachePoints = []
      this.activeShape.activePoints = this.activeShape.cachePoints
      this.rectangleDiagonalEndpoints = null
    } else {
      if (typeof this._pointerIndex === 'undefined' || this._pointerIndex < 0) {
        return void 0
      }
      this._pointerIndex = this._pointerIndex - 1
      this.activeShape.activePoints = this.activeShape.cachePoints.slice(0, this._pointerIndex + 1)
    }

    // this.pointInterset = null
    this.clear()
    this.renderImageByImageData()
    this.drawCacheShapes(this.cacheShapes)
    this.activeShape.activePoints.length && this.drawPolyline([...this.activeShape.activePoints.map(p => ({ ...p, pointRadius: this.pointRadius, shape: 'dot' })), this.currentMovePosition])
  }
  redo = () => {
    if (this.__t) {
      this.rectangleDiagonalEndpoints = [this.__t]
      this.__t = null
    } else {
      if (typeof this._pointerIndex === 'undefined' || this._pointerIndex + 1 >= this.activeShape.cachePoints.length) {
        return void 0
      }
      this._pointerIndex = this._pointerIndex + 1 >= this.activeShape.cachePoints.length ? this._pointerIndex : this._pointerIndex + 1
      this.activeShape.activePoints = this.activeShape.cachePoints.slice(0, this._pointerIndex + 1)
    }
    this.clear()
    this.renderImageByImageData()
    this.drawCacheShapes(this.cacheShapes)
    this.activeShape.activePoints.length && this.drawPolyline([...this.activeShape.activePoints.map(p => ({ ...p, pointRadius: this.pointRadius, shape: 'dot' })), this.currentMovePosition])
  }
  onMouseWheel = (event) => {
    event.preventDefault()
    // clearTimeout(this.__t)
    // this.__t = setTimeout(() => {
    let currentPosition = {
      xy: this.getXYOnCanvas(event).p,
      pointRadius: this.activePointRadius,
      shape: 'dot'
    }
    const zoomCenter = currentPosition.xy
    if (event.deltaY < 0) {
      this.zoomIn(zoomCenter)
    } else {
      this.zoomOut(zoomCenter)
    }
    // }, 30)
  }
  onDoubleClick = (event) => {
    this.resize()
  }
  zoomIn = (zoomCenter) => {
    const newDpr = this.dpr ? (this.dpr + 0.2 >= 10 ? 10 : this.dpr + 0.2) : (1.0 + 0.2)
    if (this.dpr + 0.2 > 10) {
      return this.postMessage({
        type: messageTypes.NO_MORE_ZOOM_IN,
        data: {
          message: '不能再放大了',
          code: messageTypes.NO_MORE_ZOOM_IN
        }
      })
    }
    this.zoomByDpr(newDpr, this.dpr, zoomCenter)
  }
  zoomOut = (zoomCenter) => {
    const newDpr = this.dpr ? (this.dpr - 0.2 <= 0.03 ? 0.03 : this.dpr - 0.2) : (1.0 - 0.2)
    if (this.dpr - 0.2 < 0) {
      return this.postMessage({
        type: messageTypes.NO_MORE_ZOOM_OUT,
        data: {
          message: '不能再缩小了',
          code: messageTypes.NO_MORE_ZOOM_OUT
        }
      })
    }
    this.zoomByDpr(newDpr, this.dpr, zoomCenter)
  }
  zoomByDpr = (dpr, preDpr, zoomCenter = [this.canvas.width / 2 / this.scale, this.canvas.height / 2 / this.scale]) => {
    const imageMeta = this._backgroundImageMeta
    if (!imageMeta) {
      return
    }
    this.dpr = dpr
    // 转真实坐标再按比例转换
    this.cacheShapes = (this.cacheShapes || []).map(sp => ({ ...sp, points: [...sp.points.map(p => ({ ...p, xy: [(p.xy[0] - this._targetAreaXY[0]) / this.xyRatio[0], (p.xy[1] - this._targetAreaXY[1]) / this.xyRatio[1]] }))] }))
    this.targetAreaWidth = this.targetAreaWidth / preDpr * dpr
    this.targetAreaHeight = this.targetAreaHeight / preDpr * dpr
    this._targetAreaXY = [(this._targetAreaXY[0] - zoomCenter[0]) / preDpr * dpr + zoomCenter[0], (this._targetAreaXY[1] - zoomCenter[1]) / preDpr * dpr + zoomCenter[1]]
    this.xyRatio = [this.targetAreaWidth / imageMeta.width, this.targetAreaHeight / imageMeta.height]

    this.postMessage({
      type: messageTypes.SYNC_CANVAS_SETTING_DATA_TO_OUTSIDE,
      data: {
        data: this.getGraphData(),
        dpr: this.dpr,
        targetAreaWidth: this.targetAreaWidth,
        targetAreaHeight: this.targetAreaHeight,
        _targetAreaXY: this._targetAreaXY,
        xyRatio: this.xyRatio,
        bizType: this.bizType,
        relativeRatio: this.relativeRatio
      }
    })

    this.clear()
    this.renderImageByImageData()
    this.cacheShapes = (this.cacheShapes || []).map(sp => ({ ...sp, points: [...sp.points.map(p => ({ ...p, xy: [p.xy[0] * this.xyRatio[0] + this._targetAreaXY[0], p.xy[1] * this.xyRatio[1] + this._targetAreaXY[1]] }))] }))
    this.drawCacheShapes(this.cacheShapes)
    // this.activeShape && this.drawPolyline(this.activeShape.activePoints)
  }
  clearLabel = () => {
    // this.cacheShapes = []
    this.activeShape = {
      ...this.activeShape,
      activePoints: [],

      cachePoints: []
    }
    this.__t = null
    this.rectangleDiagonalEndpoints = null

    this.clear()
    this.renderImageByImageData()
    this.drawCacheShapes(this.cacheShapes)
    this.activeShape.activePoints && this.drawPolyline([...this.activeShape.activePoints])
  }
  polygonLabelDone = (label) => {
    const shape = {
      shapeId: Date.now(),
      zIndex: Date.now(),
      labelName: label.labelName,
      color: label.color,
      shape: this.activeShape.shape,
      points: this.activeShape.activePoints.map(p => ({ ...p, color: label.color })),
      closed: true
    }
    this.cacheShapes.push(shape)
    this.postMessage({
      type: messageTypes.CREATE_POLYGON,
      data: { ...shape, points: shape.points.map(p => ({ ...p, xy: [p.xy[0] / this.xyRatio[0], p.xy[1] / this.xyRatio[1]] })) }
    })
    // newset
    this.activeShape = {
      ...this.activeShape,
      color: label.newColor,
      activePoints: [],

      cachePoints: []
    }
    this.pointInterset = null
    this._pointerIndex = undefined

    this.drawCacheShapes(this.cacheShapes)
  }
  rectangleLabelDone = (label) => {
    const ctx = this.ctx

    const shape = {
      shapeId: Date.now(),
      zIndex: Date.now(),
      labelName: label.labelName,
      color: label.color,
      shape: this.activeShape.shape,
      points: this.activeShape.activePoints.map(p => ({ ...p, color: label.color })),
      closed: true
    }
    this.cacheShapes.push(shape)
    this.postMessage({
      type: messageTypes.CREATE_RECTANGLE,
      data: { ...shape, points: shape.points.map(p => ({ ...p, xy: [(p.xy[0] - (this._targetAreaXY[0] ?? 0)) / this.xyRatio[0], (p.xy[1] - (this._targetAreaXY[1] ?? 0)) / this.xyRatio[1]] })) }
    })
    // newset
    this.activeShape = {
      ...this.activeShape,
      color: label.newColor,
      activePoints: [],

      cachePoints: []
    }

    this.rectangleDiagonalEndpoints = null

    this.drawCacheShapes(this.cacheShapes)
    this.removeEvent(document, eventTypes.KEYUP, this.onKeyDown)
  }
  polygonLabelDoneCancel = () => {
    this.activeShape.activePoints.pop()
    this.activeShape.cachePoints = this.activeShape.activePoints
    this.clear()
    this.renderImageByImageData()
    this.drawCacheShapes(this.cacheShapes)
    this.drawPolyline(this.activeShape.activePoints, {})
  }
  rectangleLabelDoneCancel = () => {
    this.rectangleDiagonalEndpoints && this.rectangleDiagonalEndpoints.pop()
  }
  updateLabel = (labelData = {}) => {
    this.__hitType = void 0
    this.clear()
    const imageMeta = labelData.image
    if (!imageMeta) return
    this._backgroundImageMeta = this.cacheImages[imageMeta.Key]
    if (!this._backgroundImageMeta) {
      this.cacheImages[imageMeta.Key] = imageMeta
      this._backgroundImageMeta = this.cacheImages[imageMeta.Key]
    }

    this.renderImageByImageData()
    this.cacheShapes = (labelData.shapes || []).map(sp => ({
      ...sp, points: [...sp.points.map(p => ({
        ...p,
        xy: [(p.xy[0] - (p.targetAreaXY?.[0] ?? 0)) * this.xyRatio[0] + this._targetAreaXY[0], (p.xy[1] - (p.targetAreaXY?.[1] ?? 0)) * this.xyRatio[1] + this._targetAreaXY[1]]
      }))]
    }))
    this.drawCacheShapes(this.cacheShapes)
    // this.activeShape && this.drawPolyline(this.activeShape.activePoints)
  }
  // pure drawing
  renderImageByImageData = () => {
    if (!this.canvas || !this._backgroundImageMeta) return
    this.engineDrawImage()
  }
  engineDrawImage = () => {
    window.requestAnimationFrame(() => {
      const ctx = this.ctx
      ctx.save()
      ctx.globalCompositeOperation = 'destination-over'
      this.drawImage(this.ctx, {
        image: this._backgroundImageMeta.imageData,
        fromX: 0, fromY: 0, fromWidth: this._backgroundImageMeta.width, FromHeight: this._backgroundImageMeta.height,
        toX: this._targetAreaXY[0], toY: this._targetAreaXY[1], toWidth: this.targetAreaWidth, toHeight: this.targetAreaHeight
      })
      ctx.restore()
    })
  }
  getHoveredPoints = (c, x = 0, y = 0, { color = this.activeShape.color, pointRadius: r = this.pointRadius || 4, sideLength = 4, shape = 'dot', cpXY, __hps__, ...rest } = {}) => {
    const ctx = c
    ctx.beginPath()
    ctx.arc(x, y, this.pointDomainRadius, 0, Math.PI * 2)
    if (ctx.isPointInPath(cpXY[0] * this.scale, cpXY[1] * this.scale)) {
      __hps__.push({ pointId: rest.pointId, shapeId: rest.shapeId })
    }
  }
  getHoveredShapes = (points = [], { color = this.activeShape.color, closed, cpXY, __intersetShapes__, __hps__, ...rest } = {}) => {
    if (!points || !points.length || !points[0]) {
      return void 0
    }
    const ctx = this.ctx
    ctx.beginPath()
    points.forEach(p => ctx.lineTo(p.xy[0], p.xy[1]))

    if (ctx.isPointInPath(cpXY[0] * this.scale, cpXY[1] * this.scale)) {
      __intersetShapes__.push(rest.shapeId)
    }
    // 端点处理
    points.forEach((p, index, arr) => {
      this.getHoveredPoints(ctx, p.xy[0], p.xy[1], {
        ...p,
        cpXY,
        __hps__
      })
    })
  }
  getHovered = (shapes, cpXY) => {
    let __hps__ = []
    let __intersetShapes__ = []
    if (!shapes || !shapes.length) return {
      __hps__,
      __intersetShapes__
    }
    shapes.forEach(shape => {
      this.getHoveredShapes(shape.points.map(p => ({ ...p, shapeId: shape.shapeId })), {
        color: shape.color, closed: shape.closed, shapeId: shape.shapeId, alpha: shape.alpha, lineWidth: shape.lineWidth,
        cpXY, __intersetShapes__, __hps__
      })
    })
    return {
      __hps__,
      __intersetShapes__
    }
  }
  pointPaint = (c, x = 0, y = 0, { color = this.activeShape.color, pointRadius: r = this.pointRadius || 4, sideLength = 4, shape = 'dot', ...rest } = {}) => {
    // window.requestAnimationFrame(() => {
    const ctx = c
    ctx.globalCompositeOperation = 'source-over'
    ctx.fillStyle = color
    ctx.strokeStyle = color
    ctx.beginPath()
    switch (shape) {
      case 'dot':
        ctx.arc(x, y, r, 0, Math.PI * 2)
        ctx.fill()
        break;
      case 'circle':
        ctx.arc(x, y, r, 0, Math.PI * 2)
        ctx.stroke()
        ctx.fillStyle = '#fff'
        ctx.fill()
        break;
      default:
        break;
    }
    // })
  }
  drawPolyline = (points = [], { color = this.activeShape.color, closed, ...rest } = {}) => {
    if (!points || !points.length || !points[0]) {
      return void 0
    }

    // window.requestAnimationFrame(() => {
    const ctx = this.ctx
    ctx.strokeStyle = color

    ctx.lineWidth = rest.lineWidth || this.activeLineWidth
    ctx.beginPath()
    points.forEach(p => ctx.lineTo(p.xy[0], p.xy[1]))
    if (closed) {
      ctx.closePath()
    }
    ctx.stroke()
    if (closed) {
      ctx.save()
      ctx.fillStyle = color
      ctx.globalAlpha = rest.alpha || this.activeAlpha
      ctx.fill()
      ctx.restore()
    }
    // 端点处理
    points.forEach((p, index, arr) => {
      this.pointPaint(ctx, p.xy[0], p.xy[1], {
        ...p
      })
    })
    // })
  }
  isPointInterset = (p1, p2) => {
    if (!p1 || !p2) {
      return false
    }
    return Math.pow(p1.xy[0] - p2.xy[0], 2) + Math.pow(p1.xy[1] - p2.xy[1], 2) <= Math.pow(p2.pointRadius + p1.pointRadius, 2)
  }
  drawCacheShapes = (shapes) => {
    if (!shapes || !shapes.length) return void 0
    shapes.forEach(shape => {
      this.drawPolyline(shape.points, { color: shape.color, closed: shape.closed, shapeId: shape.shapeId, alpha: shape.alpha, lineWidth: shape.lineWidth })
    })
  }
}
