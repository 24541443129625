
// import Account from './index'
import Path from 'routes/path'

const routes = [
    {
        name: 'user_center',
        // component: Account,
        componentPath: 'modules/Account/index',
        path: Path.pathname['ACCOUNT'],
        key: 'user_center'
    }
]

export default {
    routes
}