import { useEffect } from "react"
import config from "@/config"
import LocalStorageUtil from '@/utils/localStorageUtil'
import utils from '@/utils/util'

const Index = props => {
  let timer = null
  let globalSocket = null
  useEffect(() => {
    initSocket()
    return () => {
      if (globalSocket) {
        globalSocket.close()
      }
      timer = null
      globalSocket = null
    }
  }, [])

  function initSocket() {
    let token = LocalStorageUtil.getAuth()

    if (!token && utils.getCookie('token')) {
      token = decodeURIComponent(utils.getCookie('token'))
    }
    let { socketUrl = process.env.NODE_ENV === 'development' ? `ws://192.168.26.124/message-api/v1/user/messages/continue/ws` : `${config.message_url}/v1/user/messages/continue/ws` } = props
    socketUrl = socketUrl.match(/^(https?:|wss?:)/) ? socketUrl.replace(/^(http(s?):|ws(s?):)/, 'ws$2$3:') : `ws${window.location.protocol.startsWith('https') ? 's' : ''}://${window.location.host}${socketUrl}`
    if (!globalSocket) {
      globalSocket = new WebSocket(socketUrl)
    }
    globalSocket.onmessage = (msgEvent) => {
      let data = {}
      try {
        data = JSON.parse(msgEvent.data)
        if (data.message_id) {
          clearTimeout(timer)
          timer = setTimeout(() => {
            props.refresh && props.refresh(data)
          }, 1000)
        }
      } catch (err) {
      }
    }
  }
  return null
}

export default Index