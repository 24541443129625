import React from 'react';
import { Link } from 'react-router-dom';
import SearchType from '@/utils/searchType'
import service from './service'
import { injectIntl } from 'react-intl';
import TablePage from '@/components/TablePage'
import TextMore from '@/components/TextMore'
import { Button } from '@om-tlh/bee'
import Path from 'routes/path'
import utils from '@/utils/util'

const Index = (props) => {
  const { intl, match } = props
  const { params } = match || {}
  const { scope } = params || {}
  const { formatMessage } = intl
  const fields = [
    { name: 'key', desc: '序号', render: (text, record, index) => index + 1, width: 80 },
    { name: 'display_name', desc: '名称', showDetail: true, query: true },
    { name: 'group_name', desc: '群组', render: (text) => text || '-' },
    { name: 'code', desc: '权限码', render: (text) => text || '-' },
    { name: 'create_time', desc: '创建时间', type: SearchType.KEY_DATE_TIME },
    { name: 'description', desc: '说明' },
    {
      name: 'action', desc: '操作',
      listActions: true,
      render: (text, record) => {
        return [
          utils.hasPermission('UPDATE_PRIVILEGE')  && <a key='edit' className='btn-action'
            onClick={e => props.history.push(`${Path.getPathname(props, Path.pathname['privilege']['edit'])}?id=${record.privilege_id}`)}>编辑</a>
        ].filter(Boolean)
      }
    },
  ]
  const insertBtns = [
    <Button shape='round' key='create' type='primary' onClick={() => props.history.push(Path.getPathname(props, Path.pathname['privilege']['create']))}>创建</Button>
  ]
  return (
    <>
      <div className="content-header">
        <h2>查看权限数据</h2>
      </div>
      <div className="content-body">
        <TablePage
          rowKey='privilege_id'
          name={'权限数据'}
          fields={fields}
          insertBtns={insertBtns}
          loadData={service.getDatas}
          deleteData={service.batchDeleteData}
          service={service}
          scope={scope}
          showDelete={utils.hasPermission('DELETE_PRIVILEGE')}
          moduleName='privilege'
        />
      </div>
    </>
  )
}

export default injectIntl(Index);