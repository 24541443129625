import React, { useEffect, useState } from 'react';
import { Form, message, Modal, Input, Alert } from '@om-tlh/bee'

const Up = (props) => {
  const [form] = Form.useForm()
  const { record, setIsReload, service } = props
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    form.setFieldsValue({
      display_name: record.display_name
    })
  }, [record])

  const handleUp = () => {
    form.validateFields().then(values => {
      service.upData(record.file_id, values.display_name).then(data => {
        message.success('入库成功')
        setVisible(false)
      }).catch(error => {
        error && message.error(error.message ?? error.response?.data?.message ?? JSON.stringify(error))
      })
    })
  }
  return (
    <>
      <a key='up' className='btn-action' onClick={() => setVisible(true)}>入库</a>
      <Modal
        okButtonProps={{ shape: 'round' }}
        cancelButtonProps={{ shape: 'round' }}
        title='入库' width={800} open={visible} onOk={handleUp} onCancel={() => setVisible(false)}>
        <Alert style={{ marginBottom: 20 }} size='small' type='info' showIcon description='入库前可以修改名称!' />
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}>
          <Form.Item label='名称' name='display_name'
            rules={[{ required: true, message: '请输入名称' }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default Up;