
import Error403 from './403';
import Error404 from './404';
import Error500 from './500';
import Path from 'routes/path'

const routes = [
    {
        id: 'error_403',
        name: 'error_403',
        component: Error403,
        path: Path.pathname['Error403'],
        key: 'error_403'
    },
    {
        id: 'error_404',
        name: 'error_404',
        component: Error404,
        path: Path.pathname['Error404'],
        key: 'error_404'
    },
    {
        id: 'error_500',
        name: 'error_500',
        component: Error500,
        path: Path.pathname['Error500'],
        key: 'error_500'
    },
]

export default {
    routes
}