import { pathname } from 'routes/path';
import axios from 'axios';
import config from '@/config';

const baseURL = config.uc_url

export const getRoutes = (moduleName, { List, Create, Detail }) => ([
  { key: 'home', component: List },
  { key: 'create', component: Create },
  { key: 'edit', component: Create },
  { key: 'detail', component: Detail },
].map(i => {
  const key = i.key === 'home' ? 'manage' : i.key;
  const name = `${moduleName}_${key}`;
  let obj = {
    id: name,
    name: name,
    component: i.component,
    path: pathname[`${moduleName}`][i.key],
    key: name,
  }
  if (i.key !== 'home') obj.parentKey = `${moduleName}_manage`
  return obj
}))

export const getService = (moduleName) => {
  const getDatas = (params) => axios.get(`/v1/${moduleName}`, { params, baseURL })
  const createData = (body) => axios.post(`/v1/${moduleName}`, body, { baseURL })
  const getDataDetail = (id) => axios.get(`/v1/${moduleName}/${id}`, { baseURL })
  const updateData = (id, body) => axios.put(`/v1/${moduleName}/${id}`, body, { baseURL })
  const deleteData = (id) => axios.delete(`/v1/${moduleName}/${id}`, { baseURL })

  return {
    getDatas,
    createData,
    getDataDetail,
    updateData,
    deleteData,
  }
}