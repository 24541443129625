
import List from './index'
import Create from './create'
import Detail from './detail'
import { getRoutes } from '../utils'
import Path from 'routes/path'

const MODULE_NAME = 'team_member'

const routes = [...getRoutes('team_member', { List, Create, Detail }), {
    id: `${MODULE_NAME}_manage`,
    name: `${MODULE_NAME}_manage`,
    componentPath: 'modules/Auth/Tenant/create',
    path: Path.pathname[`team`]['create'],
    key: `team_manage`,
    // parentKey: 'team_member'
}]

export default {
    routes
}