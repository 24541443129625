import React, { useState, useEffect } from 'react';
import { PlusOutlined, UploadOutlined, DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Modal, Upload, Button, message, Space, Spin, Popconfirm, Progress } from '@om-tlh/bee';

const { MAX_FILE_SIZE: maxFileSize = 50, MAX_FILE_COUNT: maxFileCount = 20 } = window.__site__config || {}
const MAX_FILE_SIZE = process.env.NODE_ENV === 'development' ? 500 : Number(maxFileSize)
const MAX_FILE_COUNT = process.env.NODE_ENV === 'development' ? 10 : Number(maxFileCount)

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const Index = ({ value, onChange, ...props }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewPdf, setPreviewPdf] = useState('')
  const [fileList, setFileList] = useState(value || []);

  useEffect(() => {
    if (value) {
      setFileList(value)
    }
  }, [JSON.stringify(value)])

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    if (file.type.includes('/pdf')) {
      setPreviewPdf(URL.createObjectURL(file.originFileObj))
    } else {
      setPreviewImage(file.url || file.preview);
    }
    if (!file.type.includes('/pdf') && !file.type.includes('image/')) {
      return message.info('暂时仅提供图片和PDF文件的预览')
    }
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleChange = async ({ fileList: newFileList, file }) => {
    if (newFileList.length > MAX_FILE_COUNT) {
      return message.warn(`文件个数不超过${MAX_FILE_COUNT}个`)
    }
    if (props.type !== '*' && newFileList.some(file => {
      const ext = file.name.slice(file.name.lastIndexOf('.') + 1)
      const isTypeLegal = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf' || ['jpg', 'jpeg', 'png', 'pdf'].includes(ext.toLowerCase());
      return !isTypeLegal
    })) {
      return message.error('请上传图片或PDF文件!');
    }
    if (newFileList.some(file => {
      const isLt2M = file.size <= MAX_FILE_SIZE * 1024 * 1024;
      return !isLt2M
    })) {
      return message.error(`选中单个文件的大小不超过 ${MAX_FILE_SIZE} MB!`);
    }
    const nfs = props.multiple ? newFileList.slice(0, MAX_FILE_COUNT) : newFileList.slice(-1)
    setFileList(nfs)
    onChange?.(nfs)
  };

  const beforeUpload = (file, fileList) => {
    return false;
  }

  const newUploadProps = {
    showUploadList: true,
    // listType: 'picture-card',
    accept: props.accept || 'image/*',
    multiple: props.multiple || false,

    fileList: fileList,

    onPreview: handlePreview,
    onRemove: (file) => {
      const nfs = fileList.filter(x => x.uid !== file.uid)
      setFileList(nfs)
      onChange?.(nfs)
      return file.status !== 'uploading'
    },
    beforeUpload: (file, files) => beforeUpload(file, files),
    onChange: handleChange,
    itemRender: (originNode, file, currFileList, { preview, remove }) => {
      return <div style={{ width: '60%' }}>
        <Space>
          <PaperClipOutlined />
          <a
            // onClick={(e) => preview(file, e)}
            onClick={preview}
          >
            <Space>
              {
                file.name
              }
              {
                `（${parseInt(file.size / 1024 / 1024 * 100, 10) / 100}MB）`
              }
            </Space>
          </a>
          {
            (currFileList.every(file => !file.percent) || currFileList.every(file => file.percent === 100)) && <Popconfirm
              title="确定删除吗？"
              onConfirm={(e) => remove(file, e)}
              disabled={file.status === 'uploading'}
            >
              <Button type='link' icon={<DeleteOutlined />}
              ></Button>
            </Popconfirm>
          }
        </Space>
        {(file.percent > 0 && file.percent < 100) && <Progress
          {...{
            strokeColor: {
              '0%': '#108ee9',
              '100%': '#87d068',
            },
            strokeWidth: 3,
            format: (percent) => percent && `已上传${parseFloat(percent.toFixed(2))}%`,
          }}
          status={file.status === 'uploading' ? 'active' : (file.status === 'error' ? 'exception' : '')}
          percent={file.percent}
        />}
        {
          file.percent === 100 && <Progress
            {...{
              strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
              },
              strokeWidth: 3,
              format: (percent) => `上传成功，正在处理文件，请耐心等候`,
            }}
            percent={file.percent}
          />
        }
      </div>
    }
  }

  const uploadButton = (
    <Button icon={<PlusOutlined />}>选择文件</Button>
  );
  return (
    <>
      <Upload
        {...newUploadProps}
      >
        {fileList.length >= MAX_FILE_COUNT ? null : uploadButton}
      </Upload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        {
          previewImage ? <img
            alt="img"
            style={{
              width: '100%',
            }}
            src={previewImage}
          /> : <iframe src={previewPdf} frameBorder="0" style={{ width: '100%', height: 800 }}></iframe>
        }
      </Modal>
    </>
  );
};

export default Index;
