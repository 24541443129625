import React from 'react';
import { injectIntl } from 'react-intl';
import { Result, Button } from '@om-tlh/bee';
import Path from 'routes/path';

/**
 * 404页面
 * @param {*} props 
 */
const Error404 = (props) => {
  const { intl } = props
  const { formatMessage } = intl
  return (
    <Result
      status="404"
      title="404"
      subTitle={formatMessage({ id: '404_error_tip' })}
      extra={<span>
        <Button shape='round' type="primary" onClick={() => props.history.go(-2)}>{formatMessage({ id: 'back_to_previous_page' })}</Button>
        <Button shape='round' type="primary" style={{ marginLeft: 10 }} onClick={() => props.history.push('/')}>{formatMessage({ id: 'back_home' })}</Button>
      </span>}
    />
  )
}

export default injectIntl(Error404);