
// import List from './index'
import Path from 'routes/path'

const MODULE_NAME = 'receiver';

const routes = [
    {
        id: `${MODULE_NAME}_manage`,
        name: `${MODULE_NAME}_manage`,
        // component: List,
        componentPath: 'modules/Receiver/index',
        path: Path.pathname[`${MODULE_NAME}`]['home'],
        key: `${MODULE_NAME}_manage`
    },
]

export default {
    routes
}