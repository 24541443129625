import userRoutes from './User/routes'
import teamMemberRoutes from './TeamMember/routes'
import roleRoutes from './Role/routes'
import tenantRoutes from './Tenant/routes'
import privilegeRoutes from './Privilege/routes'

export const routes = [userRoutes, roleRoutes, tenantRoutes, privilegeRoutes, teamMemberRoutes];
export const path = ['user', 'role', 'tenant', 'privilege', 'team_member']

export default {
  routes: [userRoutes, roleRoutes, tenantRoutes, privilegeRoutes, teamMemberRoutes],
  path: ['user', 'role', 'tenant', 'privilege', 'team_member']
}