import axios from 'axios'
const MODULE_NAME = 'label';

const getDatas = (params) => axios.get(`/v1/${MODULE_NAME}`, { params })
const createData = (body) => axios.post(`/v1/${MODULE_NAME}`, body)
const getDataDetail = (id) => axios.get(`/v1/${MODULE_NAME}/${id}`)
const updateData = (id, body) => axios.put(`/v1/${MODULE_NAME}/${id}`, body)
const deleteData = (id) => axios.delete(`/v1/${MODULE_NAME}/${id}`)
const batchDeleteData = (id) => Array.isArray(id) ? Promise.all(id.map(i => axios.delete(`/v1/${MODULE_NAME}/${i}`))) : axios.delete(`/v1/${MODULE_NAME}/${id}`)

export default {
  getDatas,
  createData,
  getDataDetail,
  updateData,
  deleteData,
  batchDeleteData,
}