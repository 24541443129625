import axios from 'axios'
import config from '@/config'
const baseURL = config.api_url
const aiURL = config.ai_url

// 模板识别
export const templateRecognize = (fileIds, params) => {
  return axios.post(`v1/file/template_recognition`, null, {
    params: {
      content_template_id: params.contentTemplateId,
      style_template_id: params.styleTemplateId,
      ids: fileIds.toString()
    },
    baseURL
  })
}
export const teamTemplateRecognize = (fileIds, params) => {
  return axios.post(`v1/team/file/template_recognition`, null, {
    params: {
      content_template_id: params.contentTemplateId,
      style_template_id: params.styleTemplateId,
      ids: fileIds.toString()
    },
    baseURL
  })
}

// 自由样式识别
export const customRecognize = (fileId, body) => {
  return axios.post(`v1/file/custom_recognition/${fileId}`, body, {
    baseURL
  })
}
export const teamCustomRecognize = (fileId, body) => {
  return axios.post(`v1/team/file/custom_recognition/${fileId}`, body, {
    baseURL
  })
}
// 框选识别
export const rectRecognize = async (fileId, params) => {
  return 0 && process.env.NODE_ENV === 'development' ? '商标' + new Date().getMinutes() : axios.post(`v1/file/select_rec/${fileId}`, null, {
    params: {
      positions: params.positions,
      file_url: params.file_url
    },
    baseURL,
    // timeout: 30000
  })
}
// 透视变换
export const getPSTFData = async (data) => {
  return axios.post(`/image_gray`, {
    image: data.replace(/.*base64,/, '')
  }, {
    baseURL: aiURL,
  })
}
