import accountRoutes from '@/modules/Account/routes'
import errorRoutes from '@/modules/ErrorPage/routes'

import homeRoutes from '@/modules/Home/routes'
import knowledgeRoutes from '@/modules/Knowledge/routes'
import aiLabRoutes from '@/modules/AiLab/routes'
import modelRoutes from '@/modules/Model/routes'
import fileRoutes from '@/modules/File/routes'
import documentRoutes from '@/modules/Document/routes'
import tagRoutes from '@/modules/Tag/routes'
import receiverRoutes from '@/modules/Receiver/routes'
import issueRoutes from '@/modules/Issue/routes'
import upRoutes from '@/modules/Up/routes'
import logRoutes from '@/modules/Log/routes'
import inferenceRoutes from '@/modules/Inference/routes'
import catalogRoutes from '@/modules/Catalog/routes'
import shareRoutes from '@/modules/Share/routes'
import teamInviteRoutes from '@/modules/TeamInvite/routes'
import pointRoutes from '@/modules/Point/routes'

import orderRoutes from '@/modules/Order/routes'
import invoiceRoutes from '@/modules/Invoice/routes'
import buyRoutes from '@/modules/Buy/routes'
import { routes as payRoutes } from '@/modules/Pay/routes'

import modelContentRoutes from '@/modules/ModelContent/routes'
import modelStyleRoutes from '@/modules/ModelStyle/routes'

import { routes } from '@/modules/Auth'
import msgRoutes from '@/modules/Message/routes'

const routeRegistry = flatten([

  accountRoutes,
  errorRoutes,

  homeRoutes,
  knowledgeRoutes,
  aiLabRoutes,
  modelRoutes,
  fileRoutes,
  documentRoutes,
  shareRoutes,
  teamInviteRoutes,

  tagRoutes,
  receiverRoutes,
  issueRoutes,
  upRoutes,
  logRoutes,
  inferenceRoutes,
  catalogRoutes,
  orderRoutes,
  invoiceRoutes,
  pointRoutes,

  buyRoutes,

  modelContentRoutes,
  modelStyleRoutes,

  msgRoutes,
  ...payRoutes,
  ...routes,
  
].map(i => i.routes))

function flatten(ary) {
  return ary.reduce((pre, cur) => {
    return pre.concat(Array.isArray(cur) ? flatten(cur) : cur);
  }, [])
}

export default {
  routeRegistry
}