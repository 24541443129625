import axios from 'axios'
import config from 'config'
import LocalStorageUtil from 'utils/localStorageUtil'

export const getFeatureSwitchs = () => {
    const websiteInfoStr = LocalStorageUtil.getStorage('websiteInfo')
    let websiteInfo = {}
    try {
        websiteInfo = JSON.parse(websiteInfoStr)
    } catch (e) { }
    return websiteInfo
}

const checkAuth = (body) => {
    return axios.post('/v1/jwt_tokens/check', body, { baseURL: config.uc_url })
}

export const getUserInfo = async () => {
    const ucInfo = await axios.get('/v1/users/actions/get_user_info', { baseURL: config.uc_url })
    const dztInfo = await axios.get('v1/account/get_info')
    const data = await axios.get(`v1/tenants/${ucInfo.tenant_id}`, {
        baseURL: config.uc_url
    })
    return { ...ucInfo, ...dztInfo, tenant: data }
}

const login = (body) => {
    return axios.post('/v1/login', body, { baseURL: config.uc_url }).then(res => {
        return res
    })
}

const switchLogin = (body) => {
    return axios.post('v1/switch_user/login', body, { baseURL: config.uc_url })
}

const userAccounts = (id) => {
    return axios.get(`v1/persons/get_user/${id}`, { baseURL: config.uc_url })
}

export const createGateway = (jobId, jobType) => {
    return axios.get(`/v1/jobs/${jobId}/get_url?type=${jobType}`)
}

const getWebsiteInfo = async (lang) => {
    let feature = null
    try {
        feature = await axios.get(`/v1/feature/info`, {
            params: {
                lang: lang
            }
        })
    } catch (error) {
        feature = { data: {} }
    }
    const siteInfo = await axios.get(`/v1/website_info`, {
        params: {
            lang: lang
        }
    })
    return {
        data: {
            data: {
                ...siteInfo.data.data,
                ...feature.data
            }
        }
    }
}

const getComponents = () => {
    return axios.get(`/v1/components`)
}

export const getInstitutionList = (params = {}) => {
    return axios.get('/v1/institutions/list', { params: { page_num: params.page || 1, page_size: params.pageSize || 100, ...params }, baseURL: config.api_url })
}
export const getNewsList = (params = {}) => {
    return axios.get('/v1/news/list', { params: { page_num: params.page || 1, page_size: params.pageSize || 20, ...params }, baseURL: config.api_url })
}


const getAllBusiness = () => axios.get(`/v1/all_business`)

const getAllTechnology = () => axios.get(`/v1/all_technology`)

const getAllCities = () => axios.get(`/v1/all_cities`)

const getInvitationNumber = () => axios.get(`v1/statistics/invitation`)
const getTeamInvitationUrl = () => axios.get(`v1/team/get_invite_url`)

const getUrl302 = (params) => axios.get(`/get_url_302`, { params, baseURL: process.env.NODE_ENV === 'development' ? 'http://192.168.12.21:3232' : '/' })
const getOSSTempUrl = (params) => axios.get(`v1/object_storage/access_url`, { params })

const checkLimit = (resource_pack_type, quantity) => axios.get(`v1/account/verify_quota`, {
    params: {
        resource_pack_type,
        quantity
    }
})

export const transform = async (str) => {
    const regExp = /<img[^>]+src=['"]([^'"]+)['"]+/g
    const result = [];
    let temp;
    while ((temp = regExp.exec(str)) != null) {
        result.push({ raw: temp[1], obk: temp[1].match(/https?:\/\//) ? temp[1].split('?')[0].split('/').slice(4).join('/') : temp[1] });
    }
    const mps = await Promise.all(result.map(async x => ({ key: x.raw, src: await getOSSTempUrl({ object_key: x.obk }) })))
    mps.forEach(s => {
        str = str.replace(s.key, s.src)
    })
    return str
}

export default {
    getFeatureSwitchs,
    checkAuth,
    getUserInfo,
    createGateway,
    getWebsiteInfo,
    getComponents,
    getInstitutionList,
    getNewsList,
    getAllCities,
    login,
    switchLogin,
    userAccounts,
    getInvitationNumber,
    getTeamInvitationUrl,
    getUrl302,
    getOSSTempUrl,
    checkLimit,
    transform,
}