import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment'
import { Badge, Tabs, Dropdown, List, Spin, Tag, Card } from '@om-tlh/bee'
import { BellOutlined } from '@ant-design/icons';
import * as service from '@/modules/Message/service'
import * as serviceSubscribe from '@/modules/Message/serviceSubscribe'
import styles from './index.module.css'
import { getOptions, ALARM_LEVEL, ILLEGAL_TYPE, INSPECT_TYPE } from 'utils/enum'
import utils from '@/utils/util'
import ReciveRefresh from './ReciveRefresh'

const ReadMessage = (props) => {
  const { setRefreshFunc } = props
  const locate = useLocation()
  const currentPath = locate.pathname + locate.search

  const [datas, setDatas] = useState([])
  const [total, setTotal] = useState()
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    setLoading(true)
    const res = await service.getDatas({
      read: false,
      page_size: 5
    })
    if (utils.hasPermission('VIEW_SUBSCRIPTION')) {
      const res1 = await serviceSubscribe.getDatas({
        is_read: false,
        page_size: 5
      })
      setDatas([...res.items.map(x => ({ ...x, create_time: moment(x.create_time).valueOf() })), ...res1.items.map(x => ({
        ...x,
        title: x.article.title,
        create_time: moment(x.article.pub_date).valueOf()
      }))].sort((a, b) => a.create_time - b.create_time).slice(0, 5))
      setTotal(res.total + res1.total)
      setLoading(false)
      return
    }

    setDatas(res.items)
    setTotal(res.total)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
    setRefreshFunc?.(() => fetchData())
  }, [])

  const handleClick = async (item) => {
    setDatas(datas.map(i => ({ ...i, disabled: i.disabled || (i.message_id === item.message_id) })))
    if (item.link) {
      if (!item.read) {
        await service.batchReadData([item.message_id])
        fetchData()
      }
      const newLink = `${item.link}${item.link.includes('?') ? '&' : '?'}return_url=${encodeURIComponent(currentPath)}`
      return window.open(newLink)
    }
    if (!item.is_read) {
      await serviceSubscribe.batchReadData([item.subscribed_id])
      fetchData()
    }
    props.history.push(`/msg/detail/${item.message_id || item.subscribed_id}?type=${item.subscribed_id ? 1 : 0}`)
  }

  const handleRefresh = (data) => {
    fetchData()
  }

  return (
    <>
      {
        total ? <Dropdown
          placement="bottomRight"
          overlay={
            <Spin spinning={loading}>
              <List
                // bordered
                size='small'
                itemLayout="vertical"
                dataSource={datas}
                renderItem={item => (
                  <List.Item style={{ padding: '8px 16px' }}>
                    {/* <Typography.Text mark>[ITEM]</Typography.Text>  */}
                    {/* <List.Item.Meta
                // avatar={<Avatar src={item.avatar} />}
                title={<Link to={`/message/detail/${item.message_id}`} style={{ color: '#333', fontWeight: 400, wordBreak: 'break-all' }}>{item.title}</Link>}
                description={<span style={{ color: '#999', fontSize: 12 }}>{moment(item.create_time).format('YYYY-MM-DD HH:mm:ss')}</span>}
              /> */}
                    <div style={{ marginBottom: 4 }}>
                      <a
                        onClick={() => handleClick(item)}
                        // to={`/message/detail/${item.message_id}`} 
                        style={{
                          color: item.disabled ? '#999' : '#333',
                          fontWeight: 400, wordBreak: 'break-all'
                        }}>
                        {/* <Tag color={{
                    'Level1': 'success',
                    'Level2': 'gold',
                    'Level3': 'warning',
                    'Level4': 'error',
                  }[item.alarm_level]}>{ALARM_LEVEL[item.alarm_level]}</Tag>
                  <Tag color='blue'>{ILLEGAL_TYPE[item.illegal_type]}</Tag> */}
                        {/* <Tag color='blue'>{INSPECT_TYPE[item.inspect_type]}</Tag> */}
                        {/* <Tag color='blue'>{!item.processed ? '未处置' : '已处置'}</Tag> */}
                        {item.title}
                        {/* {item.description} */}
                      </a>
                    </div>
                    <div>
                      <span style={{ color: item.disabled ? '#bbb' : '#999', fontSize: 12 }}>{moment(item.create_time).format('YYYY-MM-DD HH:mm:ss')}</span>
                    </div>
                    {/* <span style={{ color: '#999', fontSize: 12, marginLeft: 10 }}>{moment(item.create_time).format('YYYY-MM-DD HH:mm:ss')}</span> */}
                    {/* {item.title} */}
                  </List.Item>
                )}
                footer={
                  total ?
                    <div className={styles['drop-down-actions']}>
                      {/* <a style={{ color: '#333' }}>清空消息</a> */}
                      <Link to='/msg/manage'>查看更多</Link>
                    </div>
                    : null
                }
              />
            </Spin>
          }
          trigger={['hover']}
          overlayClassName={styles['drop-down']}
        >
          <div onClick={() => {
            props.history.push(`/msg/manage`)
          }}>
            <Badge count={total} overflowCount={99} size='small'>
              <BellOutlined style={{
                fontSize: 16,
                // verticalAlign: 'middle'
              }} />
            </Badge>
          </div>
        </Dropdown>
          : <a onClick={() => {
            props.history.push(`/msg/manage`)
          }} rel='noopener norefferer'>
            <div>
              <BellOutlined style={{
                fontSize: 16,
                // verticalAlign: 'middle'
              }} />
            </div>
          </a>
      }
      <ReciveRefresh refresh={handleRefresh} />
    </>
  )
}

export default ReadMessage;