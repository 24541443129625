import axios from 'axios'
import config from '@/config'

const baseURL = config.uc_url

const getDatas = (params) => axios.get(`v1/persons/points/detail`, { params, baseURL }).then(async res => {
  return {
    ...res,
    items: res.items.map(x => ({
      ...x,
    }))
  }
})

export {
  getDatas,
}
