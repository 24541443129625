import axios from 'axios'
import config from '@/config'

const baseURL = config.api_url
const MODULE_NAME = 'subscription';

const getDatas = (params) => axios.get(`v1/${MODULE_NAME}/article`, { params, baseURL })
const getDataDetail = (id) => axios.get(`v1/${MODULE_NAME}/article/${id}`, { baseURL })
const batchDeleteData = (ids) => axios.delete(`v1/${MODULE_NAME}/article`, {
  params: {
    subscribed_ids: ids
  }, baseURL
})
const deleteData = (id) => axios.delete(`v1/${MODULE_NAME}/article`, {
  params: {
    subscribed_ids: [id]
  }, baseURL
})

const batchReadData = (ids) => {
  return axios.put(`v1/${MODULE_NAME}/article/read`, null, { params: { subscribed_ids: ids }, baseURL })
}

const getSubscribeConfig = () => {
  return axios.get(`v1/${MODULE_NAME}/config`, { baseURL })
}
const updateSubscribeConfig = (id, keywords = []) => {
  return axios.put(`v1/${MODULE_NAME}/config/${id}`, null, { params: { keywords: keywords.toString() }, baseURL })
}

// 订阅源
const getSubscribeChannels = () => {
  return axios.get(`v1/${MODULE_NAME}/channel`, { baseURL })
}
const getSubscribeFeed = (id = '') => {
  return axios.get(`v1/${MODULE_NAME}/feeds`, { params: { channel_id: id, page_size: 200 }, baseURL })
}
const freshData = (params) => {
  return axios.get(`v1/${MODULE_NAME}/pull_article`, { params: params, baseURL })
}

// 订阅、取消订阅
const subscribeData = (ids = []) => {
  return axios.post(`v1/${MODULE_NAME}/subscribe`, null, { params: { feed_ids: ids.toString() }, baseURL })
}
const cancelSubscribeData = (ids) => {
  return axios.post(`v1/${MODULE_NAME}/un_subscribe`, null, { params: { feed_ids: ids.toString() }, baseURL })
}

// 置顶、取消置顶
const topData = (id) => {
  return axios.put(`v1/${MODULE_NAME}/article/top`, null, { params: { subscribed_id: id }, baseURL })
}
const cancelTopData = (id) => {
  return axios.put(`v1/${MODULE_NAME}/article/un_top`, null, { params: { subscribed_id: id }, baseURL })
}

const getSubscriptions = (params) => axios.get(`v1/${MODULE_NAME}`, { params: {page_size: 200}, baseURL })

export {
  getDatas,
  getDataDetail,
  batchDeleteData,
  batchReadData,
  deleteData,
  getSubscribeChannels,
  getSubscribeFeed,
  subscribeData,
  cancelSubscribeData,
  topData,
  cancelTopData,
  getSubscribeConfig,
  updateSubscribeConfig,
  freshData,
  getSubscriptions
}