import React, { useState, useEffect } from 'react';
import SearchType from '@/utils/searchType'
import personalStyleTemplateService from '@/modules/ModelStyle/service'
import builtinStyleTemplateService from '@/modules/ModelStyleBuiltin/service'
import teamStyleTemplateService from '@/modules/ModelStyle/service_group'
import personalContentTemplateService from '@/modules/ModelContent/service'
import teamContentTemplateService from '@/modules/ModelContent/service_group'
import tagService from '@/modules/Tag/service'
import { injectIntl } from 'react-intl';
import TablePage from '@/components/TablePage'
import ViewCover from '@/components/ViewCover'
import DisableEnable from '@/components/DisableEnable'
import { Button, Tag, Tabs, Table } from '@om-tlh/bee'
import Path from 'routes/path'
import { getOptions, COMPANY_TYPE, ORDER_STATE, PRODUCT_TYPE } from 'utils/enum'
import util from '@/utils/util'
import { guid, getQueryStringByName } from '@/utils/utils'
import OCR from '@/components/OCR'
import ViewImages from '@/components/ViewImagesAlbum';

const Index = (props) => {
  const { match } = props
  const { params } = match || {}
  const { scope } = params || {}
  const [options, setOptions] = useState([])

  const belongType = getQueryStringByName('belongType')
  const service = belongType === 'TEAM' ? teamStyleTemplateService : personalStyleTemplateService
  const contentService = belongType === 'TEAM' ? teamContentTemplateService : personalContentTemplateService

  useEffect(() => {
    const fetchData = async () => {
      const res = await tagService.getDatas({ page_size: 100, label_type: 'DOCUMENT_LABEL' })
      setOptions(res.items ?? [])
    }
    fetchData()
  }, [])

  const [reloadData, setReloadData] = useState(null)
  const setLoadData = (func) => {
    setReloadData(() => func)
  }

  const fields = [
    { name: 'key', desc: '序号', render: (text, record, index) => index + 1 },
    { name: 'display_name', desc: '模板', showDetail: true, query: true, key: 'keyword', keyLabel: '关键词', form: { type: 'input' } },
    // {
    //   name: 'labels', desc: '标签', render: (text, record) => text?.map(i => <Tag key={i.label_id}>{i.label_name}</Tag>), showRender: true, query: true,
    //   type: SearchType.KEY_CUSTOM_TABS, options: options.map((i, d) => ({
    //     name: i.label_name, value: i.label_id
    //   })) || [], key: 'label_id'
    // },
    {
      name: ['file'], desc: '模板图片', showRender: true,
      render: text => {
        return <ViewImages width={'3em'} height={'5em'} images={text?.file_pages?.map(x => x.file_url) ?? []} showFirst />
      }
    },

    props.hideContentTemplate && {
      name: 'style', desc: '参照字段', hide: true,
      showRender: true, render: (text, record) => {
        const imageList = record.detail?.file_pages?.map((x, idx) => ({ ...x, file_id: record.file?.file_id })) ?? []
        return <OCR
          {...props}
          images={imageList}
          projectType='OCR_REFER_VIEW'
          toolPlacement='top'
          isViewMode={true}
          hideEditColumn={true}
          hideLableAdd={true}
          labelTextEdit={false}
          valueTextEdit={false}
        />
      }
    },
    props.hideContentTemplate && {
      name: 'style', desc: '识别字段', hide: true,
      showRender: true, render: (text, record) => {
        const imageList = record.detail?.file_pages?.map((x, idx) => ({ ...x, file_id: record.file?.file_id })) ?? []
        return record.detail?.file_pages.length ? <OCR
          {...props}
          images={imageList}
          projectType='OCR_VIEW'
          toolPlacement='top'
          isViewMode={true}
          hideEditColumn={true}
          hideLableAdd={true}
          labelTextEdit={false}
          valueTextEdit={false}
        /> : '-'
      }
    },
    !props.hideContentTemplate && {
      name: 'style', desc: '识别字段', hide: true,
      showRender: true, render: (text, record) => {
        return record.details?.length ? <Table
          dataSource={record.details}
          columns={[{
            dataIndex: 'description',
            title: '名称',
            render: t => t || '-'
          }, {
            dataIndex: 'key',
            title: 'ID',
            render: t => t || '-'
          },]}
          rowKey='detail_id'
          pagination={false}
        /> : '-'
      }
    },
    // { name: 'username', desc: '创建人' },
    { name: 'create_time', desc: '创建时间', type: SearchType.KEY_DATE_TIME, sorter: true },
    // {
    //   name: 'action', desc: '操作',
    //   listActions: true,
    //   render: (text, record) => {
    //     return [
    //       <a key='recorgnize' className='btn-action'>识别</a>,
    //     ]
    //   }
    // },
  ].filter(Boolean)
  const insertBtns = []
  return (
    <>
      <Tabs
        items={[
          !props.hideStyleTemplate && {
            label: belongType === 'TEAM' ? '标准样式模板' : '用户模板',
            key: '0',
            children: <>
              {
                belongType === 'TEAM' ? <TablePage
                  {...props}
                  rowSelection={{
                    type: 'radio'
                  }}
                  onSelect={(rows) => props?.onSelect(rows, 'style_template')}
                  rowKey={'style_template_id'}
                  name={'模板'}
                  fields={fields}
                  insertBtns={insertBtns}
                  loadData={(params) => service.getDatas({ ...params, belong_type: belongType || 'PERSONAL' })}
                  service={service}
                  scope={scope}
                  // moduleName='order'
                  getLoadData={setLoadData}
                // showCreate
                // showBatchDelete
                // batchDelete={service.batchDeleteData}
                // showEdit
                // showDelete
                /> : <>
                  {/* <TablePage
                    {...props}
                    rowSelection={{
                      type: 'radio'
                    }}
                    onSelect={(rows) => props?.onSelect(rows, 'style_template')}
                    rowKey={'style_template_id'}
                    name={'模板'}
                    fields={fields}
                    insertBtns={insertBtns}
                    loadData={(params) => builtinStyleTemplateService.getDatas({ ...params, belong_type: belongType || 'PERSONAL' })}
                    service={builtinStyleTemplateService}
                    scope={scope}
                    // moduleName='order'
                    getLoadData={setLoadData}
                  // showCreate
                  // showBatchDelete
                  // batchDelete={service.batchDeleteData}
                  // showEdit
                  // showDelete
                  /> */}
                  <TablePage
                    {...props}
                    rowSelection={{
                      type: 'radio'
                    }}
                    onSelect={(rows) => props?.onSelect(rows, 'style_template')}
                    rowKey={'style_template_id'}
                    name={'模板'}
                    fields={fields}
                    insertBtns={insertBtns}
                    loadData={(params) => service.getDatas({ ...params, belong_type: belongType || 'PERSONAL' })}
                    service={service}
                    scope={scope}
                    // moduleName='order'
                    getLoadData={setLoadData}
                  // showCreate
                  // showBatchDelete
                  // batchDelete={service.batchDeleteData}
                  // showEdit
                  // showDelete
                  />
                </>
              }
            </>
          }, !props.hideContentTemplate && {
            label: '自由样式模板',
            key: '1',
            children: <TablePage
              {...props}
              rowSelection={{
                type: 'radio'
              }}
              onSelect={(rows) => props?.onSelect(rows, 'content_template')}
              rowKey={'content_template_id'}
              name={'模板'}
              fields={[...fields.slice(0, 2), ...fields.slice(3)]}
              insertBtns={insertBtns}
              loadData={(params) => contentService.getDatas({ ...params, belong_type: belongType || 'PERSONAL' })}
              service={service}
              scope={scope}
              // moduleName='order'
              getLoadData={setLoadData}
            // showCreate
            // showBatchDelete
            // batchDelete={service.batchDeleteData}
            // showEdit
            // showDelete
            />
          }].filter(Boolean)}
      />
    </>
  )
}

export default injectIntl(Index);