import axios from 'axios'
import config from 'config'
const MODULE_NAME = 'tenants';

/**
 * 获取数据列表
 * @param {*} params 
 */
const getDatas = (params) => {
    return axios.get(`/v1/${MODULE_NAME}`,
        {
            params, 
            baseURL: config.uc_url
        }
    )
}

/**
 * 创建数据
 * @param {*} body 
 */
const createData = (body) => {
    return axios.post(`/v1/${MODULE_NAME}`, body, { baseURL: config.uc_url })
}

/**
 * 获取数据详情
 * @param {*} id 
 */
const getDataDetail = (id) => {
    return axios.get(`/v1/${MODULE_NAME}/${id}`, { baseURL: config.uc_url })
}

/**
 * 更新数据
 * @param {*} id 
 * @param {*} body 
 */
const updateData = (id, body) => {
    return axios.put(`/v1/${MODULE_NAME}/${id}`, body, { baseURL: config.uc_url })
}

/**
 * 删除数据
 * @param {*} id 
 */
const deleteData = (id) => {
    return axios.delete(`/v1/${MODULE_NAME}/${id}`, { baseURL: config.uc_url })
}

export default {
    getDatas,
    createData,
    getDataDetail,
    updateData,
    deleteData,
}