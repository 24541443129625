import axios from 'axios'
import config from '@/config'
import commonService from '@/modules/common/service'

const getInvitationCode = () => axios.get(`v1/account/get_invite_url`)
// const getInvitationNumber = () => process.env.NODE_ENV === 'development' ? parseInt(Math.random() * 100, 10) : axios.get(`v1/statistics/invitation`)
const getInvitationNumber = commonService.getInvitationNumber

export {
    getInvitationCode,
    getInvitationNumber
}
