let SearchType = {}

/**
 * 类型标记：日期
 */
SearchType.KEY_DATE = 'date';

/**
 * 类型标记：日期范围
 */
SearchType.KEY_DATE_RANGE = 'date_range';
/**
 * 类型标记：日期范围
 */
SearchType.KEY_DATE_MINUTE_RANGE = 'date_minute_range';
/**
 * 类型标记：时间范围
 */
SearchType.KEY_DATE_TIME_RANGE = 'date_time_range';

/**
 * 类型标记：自定义select
 */
SearchType.KEY_CUSTOM_OPTION = 'custom_option';

/**
 * 类型标记：search input
 */
SearchType.KEY_SEARCH_INPUT = 'search_input';

/**
 * 类型标记：日期(yyyy-MM-dd hh:mm:ss)
 */
SearchType.KEY_DATE_TIME = 'date_time';

SearchType.KEY_COMMON_ENMU = 'dic-'
SearchType.KEY_CUSTOM_TABS = 'radio'
SearchType.DIY_COMPONENT = 'component_diy'
SearchType.DIY_RANGE_NUMBER = 'range_diy_number'
SearchType.INPUT_SEARCH = 'input-search'

export default SearchType