import React from "react";
import Icon, { UndoOutlined, RedoOutlined, DeleteOutlined, ZoomInOutlined, ZoomOutOutlined, EditOutlined, DragOutlined } from '@ant-design/icons'
import { eventTypes, toolbarTypes } from './enum.js'

export const Rectangle = () => <svg fill="currentColor" viewBox="0 0  1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3008" width="1em" height="1em"><path d="M141.074286 906.496h741.851428c89.581714 0 134.582857-44.562286 134.582857-132.845714V250.331429c0-88.283429-45.001143-132.845714-134.582857-132.845715H141.074286C51.931429 117.504 6.491429 161.645714 6.491429 250.331429V773.668571c0 88.704 45.44 132.845714 134.582857 132.845715z m1.28-68.992c-42.861714 0-66.852571-22.710857-66.852572-67.291429V253.805714c0-44.580571 23.990857-67.291429 66.852572-67.291428h739.291428c42.422857 0 66.852571 22.710857 66.852572 67.291428V770.194286c0 44.580571-24.429714 67.291429-66.852572 67.291428z" p-id="3009"></path></svg>

export const Polygon = () => <svg fill="currentColor" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1400" width="1em" height="1em"><path d="M448 128c-35.36 0-64 28.64-64 64v2.016L221.984 296A64.288 64.288 0 0 0 192 288c-35.36 0-64 28.64-64 64 0 23.616 12.864 43.872 32 55.008V776.96c-19.136 11.136-32 31.36-32 55.008 0 35.36 28.64 64 64 64 23.616 0 43.872-12.864 55.008-32H648.96c11.136 19.136 31.36 32 55.008 32 35.36 0 64-28.64 64-64 0-12.736-3.52-24.992-10.016-35.008L836 640a64.16 64.16 0 0 0 8-127.008l-60.992-182.976C793.12 318.624 800 304.352 800 288c0-35.36-28.64-64-64-64-16.384 0-30.624 6.88-42.016 16.992L511.04 179.008A64.384 64.384 0 0 0 448 128z m42.016 112l182.976 60c4.896 25.76 25.28 46.112 51.008 51.008l60.992 181.984A63.68 63.68 0 0 0 768 576c0 13.248 4.128 25.76 11.008 36L700 768a63.616 63.616 0 0 0-51.008 32H247.04A63.392 63.392 0 0 0 224 776.992V407.04c19.136-11.136 32-31.36 32-55.008v-2.016l162.016-101.984c8.96 4.736 19.104 8 29.984 8 16.128 0 30.72-6.112 42.016-16z" p-id="1401"></path></svg>

export const Circle = () => <svg fill="currentColor" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1501" width="1em" height="1em"><path d="M512 903.0656c215.9616 0 391.0656-175.104 391.0656-391.0656S727.9616 120.9344 512 120.9344 120.9344 296.0384 120.9344 512s175.104 391.0656 391.0656 391.0656z m0 64c-251.392 0-455.0656-203.776-455.0656-455.0656S260.608 56.9344 512 56.9344s455.0656 203.776 455.0656 455.0656S763.392 967.0656 512 967.0656z" p-id="1502"></path></svg>

export const PicCenter = () => <svg fill="currentColor" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1377" width="1em" height="1em"><path d="M1014.857143 832H9.142857c-5.028571 0-9.142857 4.114286-9.142857 9.142857v64c0 5.028571 4.114286 9.142857 9.142857 9.142857h1005.714286c5.028571 0 9.142857-4.114286 9.142857-9.142857v-64c0-5.028571-4.114286-9.142857-9.142857-9.142857z m0-722.285714H9.142857c-5.028571 0-9.142857 4.114286-9.142857 9.142857v64c0 5.028571 4.114286 9.142857 9.142857 9.142857h1005.714286c5.028571 0 9.142857-4.114286 9.142857-9.142857v-64c0-5.028571-4.114286-9.142857-9.142857-9.142857zM896 681.142857c10.057143 0 18.285714-8.228571 18.285714-18.285714V361.142857c0-10.057143-8.228571-18.285714-18.285714-18.285714H128c-10.057143 0-18.285714 8.228571-18.285714 18.285714v301.714286c0 10.057143 8.228571 18.285714 18.285714 18.285714h768zM192 425.142857h640v173.714286H192V425.142857z" p-id="1378"></path></svg>

export const PerspectiveTransformIcon = () => <svg fill="currentColor" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1816" width="1em" height="1em"><path d="M512 896v32C299.936 928 128 756.064 128 544c0-33.152 4.192-65.312 12.096-96h33.152c-8.64 30.528-13.248 62.72-13.248 96 0 194.4 157.6 352 352 352z m328-480c-10.88-27.84-25.152-53.92-42.368-77.76l17.344-30.176A383.232 383.232 0 0 1 874.176 416H840zM480 161.312V160h32c15.776 0 31.36 0.96 46.624 2.816l-3.616 31.776C540.928 192.896 526.528 192 512 192v32h-32v-30.56c-3.808 0.32-7.584 0.736-11.36 1.216l-5.28-31.616c5.504-0.672 11.072-1.28 16.64-1.728z m-316.768 221.76c13.76-29.696 31.168-57.344 51.68-82.368l24.896 20.096c-18.72 22.784-34.624 48-47.2 75.04l-29.376-12.768z m127.52-152.96c23.52-16.64 49.024-30.656 76.032-41.696l12.288 29.536a351.36 351.36 0 0 0-73.344 40.8l-14.976-28.64z m360.512-44.064c28.832 11.2 55.968 25.824 80.896 43.296l-17.536 26.784a351.136 351.136 0 0 0-75.52-40.48l12.16-29.6zM480 256h32v96h-32V256z m0 128h32v32h-32v-32z" fill="currentColor" fillOpacity=".5" p-id="1817"></path><path d="M886.08 457.024l-48.096-46.08a350.72 350.72 0 0 0-23.808-47.584L768 448h-256v32h-32v-32H173.248c-8.64 30.528-13.248 62.72-13.248 96 0 194.4 157.6 352 352 352 58.304 0 113.28-14.176 161.696-39.264l22.08 24.512A382.272 382.272 0 0 1 512 928C299.936 928 128 756.064 128 544c0-33.152 4.192-65.312 12.096-96H64L256 96h704l-128.32 235.2a381.952 381.952 0 0 1 54.4 125.824zM117.952 416h631.072l157.12-288H274.976l-157.12 288zM480 512h32v96h-32v-96z m0 128h32v96h-32v-96z m0 128h32v96h-32v-96z m387.776-176h-97.76l-86.4 91.008-23.232-22.016 95.904-100.992h111.488l-52.16-52.736 22.752-22.528L928.64 576l-90.24 91.264-22.72-22.528 52.128-52.736z m0 224l-52.16-52.736 22.752-22.528L928.64 832l-90.24 91.264-22.72-22.528 52.128-52.736h-122.88l-144-160H544v-32h71.136l144 160h108.64zM544 752h58.336l5.536-4.48 20.096 24.96-14.304 11.52H544v-32z" fill="currentColor" p-id="1818"></path></svg>

export const commonColors = [
  '#000000', // 黑色
  '#FFFF00', // 黄色
  '#B0E0E6', // 浅灰蓝色
  '#E3CF57', // 香蕉色
  '#4169E1', // 品蓝
  '#708069', // 石板灰
  '#FFE384', // forum gold
  '#FF9912', // 镉黄
  '#6A5ACD', // 石板蓝
  '#FFD700', // 金黄色
  '#00FFFF', // 青色
  '#385E0F', // 绿土
  '#082E54', // 靛青
  '#FF6103', // 镉橙
  '#F0FFFF', // 天蓝色
  '#00FF00', // 绿色
  '#00C957', // 翠绿色
  '#802A2A', // 棕色
  '#FAFFF0', // 象牙白
  '#DA70D6', // 淡紫色
  '#FF0000', // 红色
  '#A020F0', // 紫色
  '#0000FF', // 蓝色
  '#FFC0CB', // 粉红
  '#FF4500', // 桔红
  '#00C78C', // 土耳其玉色
  '#B0171F', // 印度红
  '#F0E68C', // 黄褐色
  '#BDFCC9', // 薄荷色
  '#FFFFCD', // 白杏仁
]
export const initialTool = [
  {
    type: toolbarTypes.RECTANGLE,
    // name: '绘制矩形',
    name: '框选字段',
    Icon: (props) => <Icon component={Rectangle} />,
  },
  {
    type: toolbarTypes.POLYGON,
    name: '绘制多边形',
    Icon: (props) => <Icon component={Polygon} />,
  },
  {
    type: toolbarTypes.UNDO,
    name: '撤销',
    Icon: (props) => <UndoOutlined {...props} />,
  },
  {
    type: toolbarTypes.REDO,
    name: '重做',
    Icon: (props) => <RedoOutlined {...props} />,
  },
  {
    type: toolbarTypes.CLEAR,
    name: '清空',
    Icon: (props) => <DeleteOutlined {...props} />,
  },
  {
    type: toolbarTypes.ZOOM_IN,
    name: '放大',
    Icon: (props) => <ZoomInOutlined {...props} />,
  },
  {
    type: toolbarTypes.ZOOM_OUT,
    name: '缩小',
    Icon: (props) => <ZoomOutOutlined {...props} />,
  },
  {
    type: toolbarTypes.SHAPE_EDIT,
    name: '形状编辑',
    Icon: (props) => <EditOutlined {...props} />,
  },
  {
    type: toolbarTypes.SHAPE_MOVE,
    name: '拖拽',
    Icon: (props) => <DragOutlined {...props} />,
  },
  {
    type: toolbarTypes.SHAPE_MOVE_WHOLE,
    name: '移动',
    Icon: (props) => <DragOutlined {...props} />,
  },
  {
    type: toolbarTypes.BACK_TO_CENTER,
    name: '还原',
    Icon: (props) => <PicCenter {...props} />,
  },
  {
    type: toolbarTypes.PERSPECTIVE_TRANSFORMATION,
    name: '透视矫正',
    // Icon: (props) => <EditOutlined {...props} />,
    Icon: (props) => <PerspectiveTransformIcon {...props} />,
    // },
    // {
    //   type: toolbarTypes.SAVE,
    //   name: '保存',
    //   icon: 'save',
    // },
    // {
    //   type: toolbarTypes.FULL_CLIENT,
    //   name: '全屏',
    //   icon: 'fullscreen',
  },
]
