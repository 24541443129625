const keys = [
  'account', 'user', 'role', 'tenant', 'privilege', 'team_member',

  'model',
  'model_content',
  'model_style',
  'file',
  'team',
  'team_file',
  'team_model_content',
  'team_model_style',
  'document',
  'team_document',
  'tag',
  'receiver',
  'issue',
  'log',
  'up',
  'point',
  // 'team_invite',

  'inference',
  'order',
  'invoice',
  'catalog',

  'buy',
  'pay_modeselect',
  'regist_pay_info',
  'pay_qrscan',
  'pay_to_company',
  'pay_success',
  'pay_fail',

  'msg',
]

let paths = {
  ACCOUNT: '/accout',
  LOGIN: '/login',
  SHARE: '/share',
  Error403: '/403',
  Error404: '/404',
  Error500: '/500',
  'share': {
    'home': '/share/:sid?',
  },
  'team_invite': {
    'home': '/team_invite/:iid?'
  },
}

keys.forEach(key => {
  if (key) {
    paths[key] = {};
    ['home', 'create', 'edit', 'detail'].forEach(k => {
      paths[key][k] = `/${key}/${k !== 'home' ? (k === 'detail' ? 'detail/:id' : k) : 'manage'}`
    })

    if (key === 'model' || key === 'file' || key === 'document') {
      ['team', 'admin'].forEach(k => {
        paths[key][k] = `/${key}/${k}`
      })
    }
    if (key === 'file' || key === 'team_file') {
      ['discern', 'new_create', 'recognize', 'create_folder'].forEach(k => {
        paths[key][k] = `/${key}/${k}`
      })
    }
    if (key.endsWith('model_content') || key.endsWith('model_style')) {
      ['recognize', 'team_recognize'].forEach(k => {
        paths[key][k] = `/${key}/${k}`
      })
    }
    if (key === 'document' || key === 'team_document' || key === 'inference') {
      ['check'].forEach(k => {
        paths[key][k] = `/${key}/${k}`
      })
    }
  }
})

export const pathname = paths;

export const getPathname = (props, propsPath) => {
  const { location = {} } = props
  const { pathname } = location
  if (pathname && pathname.indexOf('/mkt') !== -1) {
    return `/mkt${propsPath}`
  } else if (pathname && pathname.indexOf('/cd') !== -1) {
    return `/cd${propsPath}`
  } else if (pathname && pathname.indexOf('/sr') !== -1) {
    return `/sr${propsPath}`
  } else if (pathname && pathname.indexOf(`/mkt`) !== -1) {
    return `/mkt${propsPath}`
  } else {
    return propsPath
  }
}

export default {
  pathname,
  getPathname
}
