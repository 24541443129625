import React, { useEffect, useState } from 'react';
import SearchType from '@/utils/searchType'
import * as service from './service'
import { injectIntl } from 'react-intl';
import moment from 'moment'
import TablePage from '@/components/TablePage'
import { Button, Tabs, Row, Col, Card, Menu, Tag, Modal, Popconfirm, message } from '@om-tlh/bee'
import { LeftOutlined } from '@ant-design/icons'
import { getOptions, GOODS_TYPES, GOODS_STATUS, OPERATE_TYPE, CHECK_STATUS, VIP_TYPES, PAY_MODE } from 'utils/enum'
import utils from '@/utils/util'

import { guid, downloadFile, customConnect, getQueryStringByName } from '@/utils/utils'
import Path from 'routes/path'
import ViewImages from '@/components/ViewImagesAlbum';

const { TabPane } = Tabs;

let tparams = {}
const Index = (props) => {
  const { match, operationsAllowed = [], intl: { formatMessage } } = props
  const { params } = match || {}
  const { scope } = params || {}

  const [isReload, setIsReload] = useState(false)

  useEffect(() => {
    return () => {
      tparams = {}
    }
  }, [])

  const newLoadData = (pms) => {
    tparams = pms
    return service.getDatas(pms)
  }
  const fields = [
    { name: 'key', desc: '序号', render: (text, record, index) => index + 1, width: 70 },
    {
      name: 'description', desc: '原因',
    },
    {
      name: 'points_change', desc: '积分变化',
      showRender: true, render: text => {
        return text > 0 ? `+${text}` : (text * 1 || 0)
      }
    },
    {
      name: 'create_time', desc: '发放时间', type: SearchType.KEY_DATE_TIME, sorter: true,
    },
    {
      name: 'range_time', desc: '时间范围', hide: true,
      hideInDetail: true, type: SearchType.KEY_DATE_TIME_RANGE, query: true,
    },
  ].filter(Boolean)
  const insertBtns = [
  ]
  return (
    <>
      <div className="content-header" style={{ overflow: 'hidden' }}>
        <LeftOutlined style={{ fontSize: 20, float: 'left', marginRight: 20 }} onClick={() => props.history.goBack()} />
        <h2 style={{ float: 'left' }}>积分明细</h2>
      </div>
      <div className="content-body">
        <TablePage
          rowKey='log_id'
          name={'积分'}
          fields={fields}
          insertBtns={insertBtns}
          isReload={isReload}
          loadData={newLoadData}
          service={service}
          scope={scope}
          moduleName='log'
        // showDelete
        // showBatchDelete
        // rowSelection={{
        //   type: 'checkbox'
        // }}
        />
      </div>
    </>
  )
}


export default injectIntl(customConnect({
  operationsAllowed: [
  ].filter(Boolean),
})(Index));
