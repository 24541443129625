import React, { useEffect, useState, useRef } from 'react';
import { Card, Space, Button, Image, Dropdown, Menu } from '@om-tlh/bee';
import { DownloadOutlined, FolderFilled, DeleteOutlined, ScanOutlined, ShareAltOutlined, FileOutlined, DownOutlined, EllipsisOutlined, EditOutlined, FullscreenOutlined } from '@ant-design/icons'
import './index.less'
import { FOLDER_OR_FILE_ACTION_TYPE } from '@/utils/enum'
import * as utils from '@/utils/util'
import ViewImages from '@/components/ViewImagesAlbum';

const renderFileOperateBtns = (record, data, handleOperateBtnClick) => {
  const opts = data
  return (opts.length ? <Space onClick={e => e.stopPropagation()} >
    <Dropdown
      getPopupContainer={() => document.getElementById(`card-mode-${record.id}`)}
      overlay={<Menu
        onClick={({ key }) => handleOperateBtnClick?.(record, key)}
        style={{ width: 100 }}
        items={
          opts.map(x => ({
            ...x, key: x.operateType, label: <span >
              {x.label}
            </span>,
            title: x.label
          }))
        }
      />}>
      <Button shape='round' htmlType='button'
        type="link"
      >
        <EllipsisOutlined />
        {/* <DownOutlined /> */}
      </Button>
    </Dropdown>
  </Space> : null)
}

const ItemCard = ({ text, size, record, handleCatalogClick, handleToggleSingle, selectedRowKeys, handleOperateBtnClick, rowSelection, finalFolderOpts = [], finalFileOpts = [] }) => {
  if (record.type === 'FOLDER') {
    return <div style={{ position: 'relative', padding: '34px 24px 24px' }}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        handleToggleSingle(record)
      }}
    >
      <div
        style={{ cursor: 'pointer', display: 'flex', position: 'relative', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}
        onClick={(e) => handleCatalogClick(record)}
      >
        <FolderFilled style={{ color: '#1890ff', marginRight: 5, fontSize: '5em', verticalAlign: 'middle', lineHeight: '20px' }} />
        <a className='btn-action'
          style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
          title={text}
        >
          {text}</a>
        <div
          style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
        >{size ? utils.sizeFormat(size) : '-'}</div>
      </div>
      <div className='operate-btns'>
        <label
          className={`dzt-${rowSelection?.type || 'checkbox'}-wrapper ${selectedRowKeys.includes(record.id) ? `dzt-checkbox-wrapper-${rowSelection?.type || 'checkbox'}` : ''} ${rowSelection?.selectType && !rowSelection?.selectType?.includes(record.type.toUpperCase()) ? 'dzt-radio-wrapper-disabled' : ''}`}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            handleToggleSingle(record)
          }}
        >
          <span
            className={`dzt-${rowSelection?.type || 'checkbox'} ${selectedRowKeys.includes(record.id) ? `dzt-${rowSelection?.type || 'checkbox'}-checked` : ''} ${rowSelection?.selectType && !rowSelection?.selectType?.includes(record.type.toUpperCase()) ? 'dzt-radio-disabled' : ''}`}
          >
            <input type={`${rowSelection?.type || 'checkbox'}`} className={`dzt-${rowSelection?.type || 'checkbox'}-input`} disabled={rowSelection?.selectType && !rowSelection?.selectType?.includes(record.type.toUpperCase())} />
            <span
              className={`dzt-${rowSelection?.type || 'checkbox'}-inner`}>

            </span>
          </span>
        </label>
        {
          renderFileOperateBtns(record, finalFolderOpts, handleOperateBtnClick)
        }
      </div>
    </div>
  } else {
    return <div style={{ position: 'relative', padding: '34px 24px 24px', cursor: rowSelection?.selectType && rowSelection?.selectType?.includes(record.type.toUpperCase()) ? 'pointer' : 'auto' }}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        handleToggleSingle(record)
      }}
    >
      <div style={{
        // cursor: 'pointer',
        display: 'flex', position: 'relative', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center'
      }}
      >

        {
          record.raw.file_url.match(/\.(png|jpg|jpeg|gif|pdf|doc|docx|txt)$/i) ? <ViewImages width={'3em'} height={'5em'} images={record.raw.file_pages.map(x => x.file_url)} showFirst /> : <FileOutlined style={{ marginRight: 5, fontSize: '5em', verticalAlign: 'middle', lineHeight: '20px' }} />
        }
        <div
          style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
          title={text}
        >{text}</div>
        <div
          style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
        >{size ? utils.sizeFormat(size) : '-'}</div>
      </div>
      <div className='operate-btns'>
        <label
          className={`dzt-${rowSelection?.type || 'checkbox'}-wrapper ${selectedRowKeys.includes(record.id) ? `dzt-checkbox-wrapper-${rowSelection?.type || 'checkbox'}` : ''} ${rowSelection?.selectType && !rowSelection?.selectType?.includes(record.type.toUpperCase()) ? 'dzt-radio-wrapper-disabled' : ''}`}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            handleToggleSingle(record)
          }}
        >
          <span
            className={`dzt-${rowSelection?.type || 'checkbox'} ${selectedRowKeys.includes(record.id) ? `dzt-${rowSelection?.type || 'checkbox'}-checked` : ''} ${rowSelection?.selectType && !rowSelection?.selectType?.includes(record.type.toUpperCase()) ? 'dzt-radio-disabled' : ''}`}
          >
            <input type={`${rowSelection?.type || 'checkbox'}`} className={`dzt-${rowSelection?.type || 'checkbox'}-input`}
              disabled={rowSelection?.selectType && !rowSelection?.selectType?.includes(record.type.toUpperCase())}
            />
            <span
              className={`dzt-${rowSelection?.type || 'checkbox'}-inner`}>

            </span>
          </span>
        </label>
        {
          renderFileOperateBtns(record, finalFileOpts, handleOperateBtnClick)
        }
      </div>
    </div>
  }
}

export default function Index(props) {
  const { dataSource, columns } = props

  const contentRef = useRef()
  const [selectedRowKeys, setSelectedRowKeys] = useState(props.selectedRowKeys || [])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setSelectedRowKeys(props.selectedRowKeys || [])
  }, [props.selectedRowKeys && props.selectedRowKeys.toString()])

  const handleScroll = (event) => {
    const scrollTop = event.target.scrollTop
    const totalHeight = contentRef.current.scrollHeight
    if (props.hasMore && (totalHeight - props.scroll.y - scrollTop >= 0 && totalHeight - props.scroll.y - scrollTop < 300) && !isLoading) {
      setIsLoading(true)
      props.loadMore?.().finally(() => setIsLoading(false))
    }
  }

  const handleToggleAll = (event) => {
    if (event.target.tagName !== 'INPUT') {
      return
    }
    const keys = dataSource.length && selectedRowKeys.length < dataSource.length ? dataSource.map(x => x.id) : []
    setSelectedRowKeys(keys)
    props.onSelectChange?.(keys)
  }

  const handleToggleSingle = (record) => {
    const keys = selectedRowKeys.includes(record.id) ? selectedRowKeys.filter(x => x !== record.id) : (props.rowSelection?.type === 'radio' ? [record.id] : [...selectedRowKeys, record.id])
    setSelectedRowKeys(keys)
    props.onSelectChange?.(keys)
  }

  return (<>
    <div className="dzt-table dzt-table-fixed-header">
      <div className="dzt-table-container">
        {
          props.rowSelection?.type !== 'radio' && <div className="dzt-table-header" style={{ overflow: 'hidden', marginBottom: 20 }}>
            <div className="dzt-table-selection"
              onClick={handleToggleAll}
            >
              <label className={`dzt-checkbox-wrapper ${selectedRowKeys.length > 0 ? 'dzt-checkbox-wrapper-checked' : ''}`}
              >
                <span
                  className={`dzt-checkbox ${selectedRowKeys.length > 0 && selectedRowKeys.length < dataSource.length ? 'dzt-checkbox-indeterminate' : selectedRowKeys.length === dataSource.length && dataSource.length > 0 ? 'dzt-checkbox-checked' : ''}`}
                >
                  <input type="checkbox" className="dzt-checkbox-input" />
                  <span
                    className="dzt-checkbox-inner">
                  </span>
                </span>
                <span style={{ marginLeft: 10 }}>全选</span>
              </label>
            </div>
          </div>
        }

        <div className="dzt-table-body" style={{ overflowY: 'auto', minHeight: 300, maxHeight: props.scroll.y }}
          onScroll={handleScroll}
        >
          <div ref={contentRef} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
            {
              dataSource.map(record => {
                return (<Card key={record.id}
                  bordered={false}
                  style={{ width: 200, }}
                  className={`card-mode ${selectedRowKeys.includes(record.id) ? 'selected-item' : ''}`}
                  id={`card-mode-${record.id}`}
                  bodyStyle={{ padding: 0 }}
                >
                  {
                    <ItemCard
                      {...props}
                      record={record}
                      text={record.display_name}
                      size={record.size}
                      handleCatalogClick={props.handleCatalogClick}
                      selectedRowKeys={selectedRowKeys}
                      handleToggleSingle={handleToggleSingle}
                      handleOperateBtnClick={props.handleOperateBtnClick}
                      rowSelection={props.rowSelection}
                    />
                  }
                </Card>)
              })
            }
          </div>
        </div>
      </div>
    </div>
  </>);
};
