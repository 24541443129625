import React from 'react';
import { Modal, message } from '@om-tlh/bee';

export default (props) => {
  const { record, service, reloadData } = props
  const { id, user_id, availability: av } = record || {}

  const handleClick = () => {
    Modal.confirm({
      title: '提示',
      content: `确定要${av !== 'UNRECOGNIZED' ? '禁用' : '激活'}数据吗？`,
      onOk: () => {
        service[av !== 'UNRECOGNIZED' ? 'disableData' : 'enableData'](id || user_id).then(data => {
          message.success(`数据${av !== 'UNRECOGNIZED' ? '禁用' : '激活'}成功`)
          reloadData && reloadData()
        }).catch(error => {
          message.error((error.response && error.response.data.message) || '数据操作失败，请联系管理员')
        })
      },
      okButtonProps: { shape: 'round' },
      cancelButtonProps: { shape: 'round' }
    })
  }

  return (
    <>
      <a onClick={() => handleClick()}>{av !== 'UNRECOGNIZED' ? '禁用' : '激活'}</a>
    </>
  )
}