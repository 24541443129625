import { getCookie, setCookie, clearAllCookie } from './util'
/**
 * 设置localstorage
 * @param {*} key 
 * @param {*} value 
 */
function setSessionLocalStorage(key, value) {
  sessionStorage[key] = value
}

/**
 * 获取localstorage
 * @param {*} key 
 */
function getSessionLocalStorage(key) {
  return sessionStorage[key]
}

/**
 * 设置缓存
 * @param {*} key 
 * @param {*} value 
 */
function setSessionStorage(key, value) {
  window.sessionStorage ? setSessionLocalStorage(key, value) : setCookie(key, value)
}

/**
 * 获取缓存
 * @param {*} key 
 */
function getSessionStorage(key) {
  return window.sessionStorage ? getSessionLocalStorage(key) : getCookie(key)
}

function clearSessionStorage() {
  return window.sessionStorage ? sessionStorage.clear() : clearAllCookie()
}
class SessionStorageUtil {

  setSStorage(key, value) {
    return setSessionStorage(key, value)
  }

  getSStorage(key) {
    return getSessionStorage(key)
  }
  clearSStorage(key) {
    return clearSessionStorage(key)
  }
}

export default new SessionStorageUtil();