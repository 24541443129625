import React from 'react';
import { Spin } from '@om-tlh/bee';
import { Loading3QuartersOutlined } from '@ant-design/icons'
import './index.css';

/**
 * 加载页面
 */
class LoadingPage extends React.Component {

  render() {
    const icon = <Loading3QuartersOutlined style={{ fontSize: 50 }} spin />
    return (
      <div className='loading-page'>
        <div className='loading-icon'>
          <Spin indicator={icon} />
        </div>
      </div>
    )
  }

}

export default LoadingPage