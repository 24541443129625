
import Index from './index'

const routes = [
    {
        name: 'knowledge',
        component : Index,
        path: '/knowledge',
        key: 'knowledge'
    }
]

export default {
    routes
}