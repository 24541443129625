import {createStore, combineReducers, compose, applyMiddleware} from 'redux'
import ThunkMiddleware from 'redux-thunk'
import rootReducer from './reducers'
import {connectRouter, routerMiddleware} from 'connected-react-router'
import async from 'tanlh-redux-promise'

const finalCreateStore = history => compose(applyMiddleware(
  ThunkMiddleware,
  ...async({
    // 用于控制是否派发 globalLoadingActionType, 默认值，每个 action 可以在 meta 里面覆盖
    showLoading: true,
    // 全局 actionType 后缀，分别表示三个状态 pending, success, error
    actionTypeSuffixes: ['PENDING', 'SUCCESS', 'ERROR'],
    // 全局 loading action type
    globalLoadingActionType: 'RECEIVE_LOADING_STATE',
    // 全局提示消息 action type
    globalMessageActionType: 'RECEIVE_GLOBAL_MESSAGE'
  }),
  routerMiddleware(history),
))(createStore)

const reducer = history => combineReducers({
  ...rootReducer,
  router: connectRouter(history)
})

export default function configureStore(history, initialState) {
  const store = finalCreateStore(history)(reducer(history), initialState)
  return store
}
