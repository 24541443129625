import { Card, Descriptions, Divider, Button } from '@om-tlh/bee';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ReferField from '@/modules/ModelStyle/components/ReferField'

const Index = (props) => {
  return (
    <>
      <ReferField {...props} />
    </>
  )
}

export default Index;