import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import * as service from './service'
import OCDetection from './ImageDIYLabel'

function Index(props) {
  const { intl: { formatMessage } } = props;
  const { images } = props

  return <div className='label-wrapper'>
    <div id='data-label' style={{
      display: 'block',
      position: 'relative',
      overflow: 'hidden',
      background: '#fff'
    }}>
      <OCDetection {...props} service={service} projectType={props.projectType || 'OCR'} images={images}
      // hideEdit={true}
      />
    </div>
  </div>
}
export default injectIntl(Index)